export const WORDS_TARGET = [
  "ABAJA",
  "ABCIM",
  "ADIJO",
  "AFEKT",
  "AFERA",
  "AFINO",
  "AGAMA",
  "AGAVA",
  "AGENS",
  "AGENT",
  "AGNAT",
  "AGORA",
  "AGUTI",
  "AJATI",
  "AJBIŠ",
  "AJDNO",
  "AJVAR",
  "AKANT",
  "AKATI",
  "AKIJA",
  "AKORD",
  "AKRIL",
  "AKSEL",
  "AKSON",
  "AKTIN",
  "ALARM",
  "ALBIN",
  "ALBUM",
  "ALEJA",
  "ALIBI",
  "ALKAN",
  "ALKAR",
  "ALKEN",
  "ALKIL",
  "ALKIN",
  "ALOIN",
  "ALOJA",
  "ALPID",
  "AMBRA",
  "AMEBA",
  "AMIJA",
  "AMNIJ",
  "AMPER",
  "ANALI",
  "ANEKS",
  "ANGEL",
  "ANIMA",
  "ANIME",
  "ANION",
  "ANODA",
  "ANONA",
  "AORTA",
  "APEKS",
  "APLIT",
  "APNAR",
  "APOEN",
  "APRIL",
  "ARBON",
  "ARDIT",
  "ARENA",
  "ARGON",
  "ARGUS",
  "ARHIV",
  "ARIJA",
  "AROMA",
  "ARŠIN",
  "ARZEN",
  "ASANA",
  "ASKET",
  "ASTAT",
  "ASTMA",
  "ASTRA",
  "ATAŠE",
  "ATEST",
  "ATLAS",
  "ATLET",
  "ATRIJ",
  "AVANS",
  "AVGIT",
  "AVGUR",
  "AVIZO",
  "AVTEK",
  "AVTOR",
  "AZOIK",
  "AZOLA",
  "BABEŽ",
  "BABKA",
  "BACEK",
  "BACIL",
  "BACKA",
  "BAFTA",
  "BAGEL",
  "BAGER",
  "BAHAČ",
  "BAJKA",
  "BAJNO",
  "BAJZA",
  "BAKER",
  "BAKLA",
  "BALET",
  "BALIN",
  "BALON",
  "BALSA",
  "BALZA",
  "BANJA",
  "BANJO",
  "BANKA",
  "BARAL",
  "BARBA",
  "BARIJ",
  "BARIK",
  "BARIT",
  "BARJE",
  "BARKA",
  "BAROK",
  "BARON",
  "BARVA",
  "BARŽA",
  "BASEN",
  "BATEK",
  "BATIČ",
  "BATIK",
  "BATNO",
  "BAVTA",
  "BAZAR",
  "BAZEN",
  "BAZNO",
  "BDETI",
  "BEBEC",
  "BEBKA",
  "BEBOP",
  "BEDAK",
  "BEDNO",
  "BEDRO",
  "BEKON",
  "BELEC",
  "BELEŽ",
  "BELIČ",
  "BELIN",
  "BELKA",
  "BELUH",
  "BELUŠ",
  "BEMIT",
  "BEPOP",
  "BERAČ",
  "BERDA",
  "BERIL",
  "BERMA",
  "BESNO",
  "BETEL",
  "BETIČ",
  "BETON",
  "BETVA",
  "BEVSK",
  "BEZEG",
  "BEŽNO",
  "BIBER",
  "BIČAR",
  "BIČEK",
  "BIČJE",
  "BIDON",
  "BIKEC",
  "BILJE",
  "BILKA",
  "BILNO",
  "BIMBO",
  "BINGO",
  "BINOM",
  "BIRET",
  "BIRIČ",
  "BIRMA",
  "BIRNO",
  "BISER",
  "BISTA",
  "BISUS",
  "BITJE",
  "BITKA",
  "BITNO",
  "BIVAK",
  "BIVOL",
  "BIZON",
  "BLAGO",
  "BLATO",
  "BLEDO",
  "BLESK",
  "BLIDA",
  "BLISK",
  "BLIŠČ",
  "BLUŠČ",
  "BLUZA",
  "BOBAK",
  "BOBEK",
  "BOBEN",
  "BOBER",
  "BOČNO",
  "BODAK",
  "BODIČ",
  "BODNO",
  "BODRO",
  "BOFOR",
  "BOGEC",
  "BOGEK",
  "BOHOT",
  "BOJAR",
  "BOJNO",
  "BOKAL",
  "BOLEN",
  "BOLHA",
  "BOLID",
  "BOLNO",
  "BOLUS",
  "BOMBA",
  "BONEC",
  "BONGO",
  "BONIT",
  "BONUS",
  "BORAT",
  "BORDO",
  "BOREC",
  "BORED",
  "BORKA",
  "BORNO",
  "BORŠČ",
  "BORZA",
  "BOSTI",
  "BOTER",
  "BOTRA",
  "BOVLA",
  "BOZON",
  "BOŽEC",
  "BOŽIČ",
  "BOŽJE",
  "BRADA",
  "BRAKA",
  "BRANA",
  "BRATI",
  "BRBOT",
  "BRBRA",
  "BRCAK",
  "BRDKO",
  "BREČA",
  "BREJE",
  "BREJK",
  "BREKA",
  "BREME",
  "BREST",
  "BREZA",
  "BRHKO",
  "BRIDŽ",
  "BRIGA",
  "BRIOŠ",
  "BRITI",
  "BRIZG",
  "BRKAČ",
  "BRLOG",
  "BROŠČ",
  "BRUNO",
  "BRUTO",
  "BRZDA",
  "BRZEC",
  "BUBON",
  "BUČEK",
  "BUČKA",
  "BUČNO",
  "BUDNO",
  "BUDRA",
  "BUJNO",
  "BUJON",
  "BUKAČ",
  "BUKET",
  "BUKEV",
  "BUKVE",
  "BUNDA",
  "BUNKA",
  "BUREK",
  "BURET",
  "BURIN",
  "BURJA",
  "BURKA",
  "BURNO",
  "BURSA",
  "BURZA",
  "BUŠKA",
  "BUTAN",
  "BUTEC",
  "BUTIK",
  "BUTIL",
  "CAJNA",
  "CAPIN",
  "CARAR",
  "CEDRA",
  "CEFIR",
  "CEKAR",
  "CEKAS",
  "CEKUM",
  "CELEC",
  "CELEK",
  "CELIN",
  "CELOM",
  "CENIK",
  "CEPEC",
  "CEPEK",
  "CEPET",
  "CEPIČ",
  "CEPIN",
  "CEPKO",
  "CERIJ",
  "CERJE",
  "CESAR",
  "CESTA",
  "CETAN",
  "CEVAR",
  "CEVJE",
  "CEVKA",
  "CEVNO",
  "CEZIJ",
  "CIBET",
  "CIFRA",
  "CIGAN",
  "CIKEL",
  "CIMET",
  "CIMIR",
  "CINIK",
  "CIPAR",
  "CIRUS",
  "CISTA",
  "CITAT",
  "CITRE",
  "CIZEK",
  "CMERA",
  "COFEK",
  "COKLA",
  "CONTE",
  "COPAT",
  "COPOT",
  "COVID",
  "CUCEK",
  "CUNJA",
  "CUREK",
  "ČAČKA",
  "ČADOR",
  "ČAJKA",
  "ČAJNO",
  "ČAKMA",
  "ČAKRA",
  "ČAPEC",
  "ČAPKA",
  "ČARNO",
  "ČASEK",
  "ČAŠNO",
  "ČATEŽ",
  "ČEBER",
  "ČEDAR",
  "ČEDNO",
  "ČEDRA",
  "ČEKAN",
  "ČELNO",
  "ČEMAŽ",
  "ČENČA",
  "ČEPAR",
  "ČEPEK",
  "ČEPNO",
  "ČESEN",
  "ČETNO",
  "ČETRT",
  "ČIBUK",
  "ČIČEK",
  "ČIGRA",
  "ČIKAR",
  "ČIMŽA",
  "ČIPKA",
  "ČIPNO",
  "ČISTO",
  "ČIŠČA",
  "ČIŽEK",
  "ČMRLJ",
  "ČOBER",
  "ČOPAR",
  "ČOPEK",
  "ČOPIČ",
  "ČOPKA",
  "ČORBA",
  "ČREDA",
  "ČREVO",
  "ČRKJE",
  "ČRNEC",
  "ČRNKA",
  "ČRTKA",
  "ČRTNO",
  "ČRTUH",
  "ČRVAR",
  "ČRVIČ",
  "ČTIVO",
  "ČUDAK",
  "ČUDEŽ",
  "ČUDNO",
  "ČUKEC",
  "ČUKLA",
  "ČUNJA",
  "ČUTNO",
  "ČUVAJ",
  "ČUVAR",
  "ČUŽKA",
  "DACIT",
  "DAMAN",
  "DANES",
  "DANKA",
  "DATUM",
  "DAVEK",
  "DAVNO",
  "DEBER",
  "DEBET",
  "DEBIL",
  "DEBLO",
  "DECIL",
  "DEČEK",
  "DEČKO",
  "DEDEK",
  "DEDIČ",
  "DEDNO",
  "DEIST",
  "DEKAN",
  "DEKLA",
  "DEKLE",
  "DEKOR",
  "DELEC",
  "DELEŽ",
  "DELNO",
  "DELTA",
  "DEMON",
  "DENAR",
  "DENGA",
  "DERAČ",
  "DERAK",
  "DERBI",
  "DERUH",
  "DESEN",
  "DESET",
  "DESKA",
  "DESNA",
  "DETEL",
  "DEVET",
  "DEVON",
  "DEŽEK",
  "DEŽEN",
  "DEŽNO",
  "DIADA",
  "DIANA",
  "DIETA",
  "DIFON",
  "DIHUR",
  "DIJAK",
  "DIMEK",
  "DIMER",
  "DIMNO",
  "DINAR",
  "DINGI",
  "DINGO",
  "DINJA",
  "DIODA",
  "DIPLE",
  "DIPOL",
  "DIRKA",
  "DIVAN",
  "DLAKA",
  "DLESK",
  "DLETO",
  "DNINA",
  "DOBIT",
  "DOBRO",
  "DOGAR",
  "DOGMA",
  "DOGON",
  "DOHOD",
  "DOJKA",
  "DOKAZ",
  "DOKUP",
  "DOLAR",
  "DOLEK",
  "DOLET",
  "DOLGO",
  "DOLIV",
  "DOMEC",
  "DOMEK",
  "DOMET",
  "DOMIK",
  "DONOR",
  "DONOS",
  "DOPIS",
  "DORKA",
  "DOSEG",
  "DOSJE",
  "DOTIK",
  "DOTIS",
  "DOTOK",
  "DOVOD",
  "DOVOZ",
  "DOŽNO",
  "DRAGA",
  "DRAGI",
  "DRAGO",
  "DRAMA",
  "DRAPE",
  "DRAŽE",
  "DRETA",
  "DRETI",
  "DREVO",
  "DRGET",
  "DRHAL",
  "DRIČA",
  "DRNEC",
  "DRNIČ",
  "DROGA",
  "DRONG",
  "DROZG",
  "DROŽE",
  "DROŽI",
  "DRSKA",
  "DRSNO",
  "DRTJE",
  "DRUGA",
  "DRUID",
  "DRUZA",
  "DRVAR",
  "DRVCA",
  "DRZNO",
  "DRŽAČ",
  "DRŽAJ",
  "DRŽEK",
  "DRŽKA",
  "DUCAT",
  "DUDAR",
  "DUDKA",
  "DUHEC",
  "DUHEK",
  "DUKAT",
  "DULAR",
  "DULEC",
  "DUNIT",
  "DUOLA",
  "DURCA",
  "DURCE",
  "DURJO",
  "DURUM",
  "DUŠIK",
  "DVERI",
  "DZETA",
  "DŽINS",
  "EBOLA",
  "EDIKT",
  "EDINA",
  "EDINI",
  "EDINO",
  "EFIRA",
  "EFRIN",
  "EKCEM",
  "EKIPA",
  "EKRAN",
  "EKSON",
  "EKSOT",
  "ELAND",
  "ELEAT",
  "ELINA",
  "ELISA",
  "ELITA",
  "EMAJL",
  "EMPAT",
  "EMPIR",
  "ENAKO",
  "ENCIM",
  "ENICA",
  "ENOST",
  "ENOTA",
  "EOCEN",
  "EOZIN",
  "EPARH",
  "EPIKA",
  "EPOHA",
  "ERBIJ",
  "ESAUL",
  "ESCIN",
  "ESTER",
  "ESTET",
  "ETAPA",
  "ETAŽA",
  "ETHER",
  "ETIKA",
  "ETRUS",
  "ETUDA",
  "EVNUH",
  "FAGOT",
  "FAKIN",
  "FAKIR",
  "FALIN",
  "FALUS",
  "FANAL",
  "FARAD",
  "FARAN",
  "FARMA",
  "FARNO",
  "FARSA",
  "FATVA",
  "FAZAN",
  "FAZNO",
  "FELAH",
  "FENEK",
  "FENIL",
  "FENOL",
  "FERIT",
  "FETIŠ",
  "FETUS",
  "FIALA",
  "FICEK",
  "FIKUS",
  "FILET",
  "FILIT",
  "FINIŠ",
  "FIRMA",
  "FITIN",
  "FITOL",
  "FIZIK",
  "FIŽOL",
  "FJORD",
  "FLOTA",
  "FLUAT",
  "FLUOR",
  "FOAJE",
  "FOBIK",
  "FOJBA",
  "FOKUS",
  "FOLAT",
  "FOLIJ",
  "FOLIO",
  "FOLNO",
  "FONDI",
  "FONEM",
  "FONON",
  "FORMA",
  "FORUM",
  "FOSIL",
  "FOTON",
  "FRAČA",
  "FRANK",
  "FRAPE",
  "FRATA",
  "FRAZA",
  "FREON",
  "FRFOT",
  "FRFRA",
  "FRIKA",
  "FRULA",
  "FURAN",
  "FURIN",
  "FUTON",
  "GABER",
  "GABEZ",
  "GABRO",
  "GAJBA",
  "GAJIČ",
  "GALEB",
  "GALIJ",
  "GALNO",
  "GALOP",
  "GALUN",
  "GARAČ",
  "GARDA",
  "GARJE",
  "GAVČO",
  "GAVDA",
  "GAVUN",
  "GAZNO",
  "GEJŠA",
  "GEKON",
  "GENIJ",
  "GENOM",
  "GEODA",
  "GEOID",
  "GESLO",
  "GESTA",
  "GIBKO",
  "GIBNO",
  "GIBON",
  "GINKO",
  "GLANS",
  "GLAVA",
  "GLIJA",
  "GLINA",
  "GLIOM",
  "GLIVA",
  "GLOBA",
  "GLOSA",
  "GLOTA",
  "GLUHI",
  "GLUHO",
  "GLUON",
  "GMOTA",
  "GNAJS",
  "GNATI",
  "GNEČA",
  "GNIDA",
  "GNILO",
  "GNITI",
  "GNJAT",
  "GNOZA",
  "GOBAN",
  "GOBAR",
  "GOBEC",
  "GODBA",
  "GODEC",
  "GODNO",
  "GOFER",
  "GOLAČ",
  "GOLAŽ",
  "GOLEC",
  "GOLEN",
  "GOLIČ",
  "GOLOB",
  "GOLŠA",
  "GONIČ",
  "GONJA",
  "GORAL",
  "GORJE",
  "GOSAK",
  "GOSKA",
  "GOSLI",
  "GOSPA",
  "GOSTI",
  "GOSTO",
  "GOŠČA",
  "GOVNO",
  "GOVOR",
  "GRAJA",
  "GRAPA",
  "GRBAČ",
  "GRBAN",
  "GRBEC",
  "GRBEŽ",
  "GRBNO",
  "GREDA",
  "GRELO",
  "GRETI",
  "GRINT",
  "GRIOT",
  "GRIPA",
  "GRIVA",
  "GRIŽA",
  "GRLCE",
  "GRLEC",
  "GRLNO",
  "GRMIČ",
  "GROBO",
  "GROZA",
  "GROZD",
  "GRUČA",
  "GRUDA",
  "GRUDI",
  "GRUND",
  "GRUŠČ",
  "GUBNO",
  "GULAG",
  "GULEŽ",
  "GUMAR",
  "GUMNO",
  "GUSAR",
  "GUSLE",
  "GVANO",
  "GVELF",
  "HABIT",
  "HAFIZ",
  "HAIKU",
  "HAJKA",
  "HALAL",
  "HALIT",
  "HALJA",
  "HALMA",
  "HALON",
  "HALVA",
  "HAMAM",
  "HAMIT",
  "HANIJ",
  "HARAČ",
  "HAREM",
  "HARFA",
  "HARZA",
  "HASKI",
  "HAŠIŠ",
  "HEHET",
  "HEKER",
  "HELIJ",
  "HELOT",
  "HEMIN",
  "HENRI",
  "HERMA",
  "HIBNO",
  "HIDRA",
  "HIHOT",
  "HILUS",
  "HIMNA",
  "HINDI",
  "HIPEC",
  "HIPNO",
  "HIPUS",
  "HIŠKA",
  "HIŠNO",
  "HITIN",
  "HITON",
  "HITRO",
  "HLAČE",
  "HMELJ",
  "HOANA",
  "HOBIT",
  "HODEC",
  "HODNO",
  "HODŽA",
  "HOJCA",
  "HOKEJ",
  "HOLIN",
  "HONDA",
  "HOPAK",
  "HORDA",
  "HORIJ",
  "HORST",
  "HORUK",
  "HOSTA",
  "HOTEL",
  "HOTNO",
  "HOUSE",
  "HRANA",
  "HRAST",
  "HRBET",
  "HRČAK",
  "HRČEK",
  "HRESK",
  "HROMO",
  "HROŠČ",
  "HRUST",
  "HRUŠČ",
  "HUDIČ",
  "HUDIK",
  "HUDIR",
  "HUJŠE",
  "HULEŽ",
  "HULOK",
  "HUMOR",
  "HUMUS",
  "HUNTA",
  "HUSIT",
  "HUZAR",
  "HVALA",
  "IDEAL",
  "IDEJA",
  "IDILA",
  "IDIOM",
  "IDOZA",
  "IFTAR",
  "IGLAR",
  "IKONA",
  "IKRNO",
  "IKTUS",
  "ILEUS",
  "IMETI",
  "IMPRO",
  "IMUNO",
  "INCEL",
  "INČUN",
  "INDIC",
  "INDIJ",
  "INDRI",
  "INFRA",
  "INGOT",
  "INLET",
  "INOKS",
  "INVAR",
  "IRBIS",
  "IRHAR",
  "ISKRA",
  "ISKRO",
  "ISLAM",
  "ISTJE",
  "IŠIAS",
  "ITRIJ",
  "IVRIT",
  "IZBNO",
  "IZBOK",
  "IZBOR",
  "IZDIH",
  "IZGIN",
  "IZGON",
  "IZHOD",
  "IZITI",
  "IZJED",
  "IZKAZ",
  "IZKOP",
  "IZKUP",
  "IZLET",
  "IZLIV",
  "IZLOV",
  "IZMET",
  "IZMIK",
  "IZNOS",
  "IZPAD",
  "IZPAH",
  "IZPIH",
  "IZPIS",
  "IZPIT",
  "IZPUH",
  "IZRAZ",
  "IZREK",
  "IZREZ",
  "IZRIS",
  "IZSEK",
  "IZSEV",
  "IZTEG",
  "IZTEK",
  "IZTIS",
  "IZTOK",
  "IZVES",
  "IZVID",
  "IZVIN",
  "IZVIR",
  "IZVOD",
  "IZVOR",
  "IZVOZ",
  "IZVRG",
  "IZZIV",
  "JADRO",
  "JAGER",
  "JAHAČ",
  "JAHTA",
  "JAJCE",
  "JAKNA",
  "JAMAR",
  "JANEŽ",
  "JAPOK",
  "JAREC",
  "JAREK",
  "JAREM",
  "JASLI",
  "JASNO",
  "JAŠEK",
  "JAVKA",
  "JAVNO",
  "JAVOR",
  "JAZID",
  "JEČAR",
  "JEDEC",
  "JEDEŽ",
  "JEDKA",
  "JEDKO",
  "JEDRO",
  "JEKLO",
  "JELEN",
  "JELKA",
  "JELŠA",
  "JEREB",
  "JESEN",
  "JESTI",
  "JESUR",
  "JEŠČA",
  "JEŠČE",
  "JETRA",
  "JEZID",
  "JEZIK",
  "JEZNO",
  "JEŽEK",
  "JEŽNO",
  "JIDIŠ",
  "JODAT",
  "JODID",
  "JODIT",
  "JODNO",
  "JOKER",
  "JOPIČ",
  "JOSTA",
  "JUDEŽ",
  "JUHAR",
  "JUHTA",
  "JULIJ",
  "JUNAK",
  "JUNEC",
  "JUNIJ",
  "JURIŠ",
  "JURKO",
  "JURTA",
  "JUŠNO",
  "JUTRI",
  "JUTRO",
  "JUŽNO",
  "KABEL",
  "KACET",
  "KAČAR",
  "KAČEK",
  "KAČON",
  "KADER",
  "KADET",
  "KAFRA",
  "KAHON",
  "KAJAK",
  "KAKAV",
  "KAKEC",
  "KALAN",
  "KALEŽ",
  "KALIF",
  "KALIJ",
  "KALIN",
  "KALNO",
  "KALUP",
  "KALUS",
  "KAMBA",
  "KAMEN",
  "KAMIN",
  "KAMPA",
  "KAMPO",
  "KAMRA",
  "KANAL",
  "KANAT",
  "KANEC",
  "KANJA",
  "KAPAR",
  "KAPIČ",
  "KAPNO",
  "KAPOK",
  "KAPRA",
  "KAPRI",
  "KAPUS",
  "KARAT",
  "KARMA",
  "KARTA",
  "KASAČ",
  "KASNO",
  "KASTA",
  "KAŠČA",
  "KAŠER",
  "KAŠNO",
  "KATAR",
  "KATUN",
  "KAURI",
  "KAVAL",
  "KAVER",
  "KAVKA",
  "KAVNO",
  "KAVRI",
  "KAZEN",
  "KEBAB",
  "KEČAP",
  "KEFIR",
  "KELAT",
  "KELIH",
  "KEMIK",
  "KENTA",
  "KEPER",
  "KERUB",
  "KESON",
  "KETOL",
  "KETON",
  "KIANG",
  "KIBUC",
  "KIJEC",
  "KILNO",
  "KIMČI",
  "KINIK",
  "KININ",
  "KINON",
  "KIOSK",
  "KIPAR",
  "KIPEC",
  "KIRIJ",
  "KISIK",
  "KISLO",
  "KITKA",
  "KITNO",
  "KLADA",
  "KLAJA",
  "KLAKA",
  "KLATI",
  "KLENO",
  "KLETI",
  "KLICA",
  "KLIKA",
  "KLINČ",
  "KLIŠE",
  "KLITI",
  "KLJUČ",
  "KLJUN",
  "KLOVN",
  "KLOŽA",
  "KNUTA",
  "KOALA",
  "KOATA",
  "KOBRA",
  "KOBUL",
  "KOCEN",
  "KOCKA",
  "KOČAN",
  "KOČAR",
  "KOČIČ",
  "KOČNA",
  "KODEK",
  "KODER",
  "KODNO",
  "KODON",
  "KODRC",
  "KOGAN",
  "KOJOT",
  "KOKON",
  "KOKOS",
  "KOKOŠ",
  "KOLAČ",
  "KOLAR",
  "KOLAŽ",
  "KOLCA",
  "KOLEB",
  "KOLEK",
  "KOLON",
  "KOLOS",
  "KOLUT",
  "KOMAR",
  "KOMAT",
  "KOMBI",
  "KOMET",
  "KOMIK",
  "KOMIS",
  "KOMIT",
  "KONEC",
  "KONHA",
  "KONTO",
  "KONUS",
  "KONZI",
  "KOPAČ",
  "KOPEL",
  "KOPER",
  "KOPJE",
  "KOPNO",
  "KOPRC",
  "KOPUN",
  "KORAK",
  "KORAL",
  "KOREC",
  "KOREN",
  "KORNO",
  "KOSEC",
  "KOSEM",
  "KOŠEK",
  "KOŠER",
  "KOŠNO",
  "KOTAČ",
  "KOTAR",
  "KOTEL",
  "KOTNO",
  "KOVAČ",
  "KOVID",
  "KOVNO",
  "KOZAK",
  "KOZEL",
  "KOZLE",
  "KOŽAR",
  "KOŽNO",
  "KOŽUH",
  "KRAČA",
  "KRAJA",
  "KRAJT",
  "KRALJ",
  "KRAMA",
  "KRAMP",
  "KRAVA",
  "KRAVL",
  "KRČMA",
  "KRČNO",
  "KREDA",
  "KREMA",
  "KREOL",
  "KREŠA",
  "KRHKO",
  "KRILO",
  "KRITI",
  "KRIVO",
  "KRIZA",
  "KRKON",
  "KRMAR",
  "KRMNO",
  "KRNIŽ",
  "KROKI",
  "KRONA",
  "KROŠE",
  "KRSTA",
  "KRŠEC",
  "KRŠIN",
  "KRŠJE",
  "KRTEK",
  "KRUTO",
  "KRVNO",
  "KRZNO",
  "KUBIT",
  "KUČMA",
  "KUHAR",
  "KUKEC",
  "KULAK",
  "KULAN",
  "KULEN",
  "KULON",
  "KUMIN",
  "KUMIS",
  "KUNEC",
  "KUPEC",
  "KUPKA",
  "KUPNO",
  "KUPON",
  "KURAT",
  "KURIR",
  "KUROL",
  "KUTER",
  "KUTIN",
  "KUŽEK",
  "KUŽNO",
  "KVAGA",
  "KVANT",
  "KVARK",
  "KVART",
  "KVAŠA",
  "KVOTA",
  "LABOD",
  "LAČNO",
  "LADJA",
  "LAGER",
  "LAHET",
  "LAHKO",
  "LAHNO",
  "LAJEŽ",
  "LAJNA",
  "LAKAJ",
  "LAKET",
  "LAPIS",
  "LAPOR",
  "LAPUH",
  "LARGO",
  "LASCI",
  "LASEC",
  "LASEK",
  "LASER",
  "LASJE",
  "LASNO",
  "LATEN",
  "LATJE",
  "LAVNO",
  "LAZAR",
  "LAŽNO",
  "LEČJE",
  "LEČNO",
  "LEDAR",
  "LEDJE",
  "LEDNO",
  "LEGAT",
  "LEGLO",
  "LEMEŽ",
  "LEMUR",
  "LENIČ",
  "LENTA",
  "LENUH",
  "LEPAK",
  "LEPEK",
  "LEPEN",
  "LEPKO",
  "LESAR",
  "LESKA",
  "LESNO",
  "LEŠKA",
  "LETAK",
  "LETEV",
  "LETNO",
  "LEVIČ",
  "LEVIT",
  "LEŽAJ",
  "LEŽAK",
  "LEŽNO",
  "LIAZA",
  "LIBRA",
  "LICEJ",
  "LIČAR",
  "LIČEK",
  "LIČJE",
  "LIČNO",
  "LIDAR",
  "LIGER",
  "LIJAK",
  "LIKAR",
  "LIKER",
  "LIMES",
  "LIMFA",
  "LIMIT",
  "LIPAN",
  "LIPID",
  "LIPOM",
  "LIREŠ",
  "LIRIK",
  "LISAR",
  "LISEC",
  "LISKA",
  "LISTA",
  "LIŠAJ",
  "LITAS",
  "LITER",
  "LITIJ",
  "LITJE",
  "LIVAR",
  "LIVKA",
  "LIVNO",
  "LIVRA",
  "LIZAČ",
  "LIZIL",
  "LIZIN",
  "LIZOL",
  "LIZUN",
  "LJUBA",
  "LJUBI",
  "LJUBO",
  "LOBUL",
  "LOCEN",
  "LOČAJ",
  "LOČBA",
  "LOČEK",
  "LOČJE",
  "LOČNO",
  "LODEN",
  "LODRA",
  "LOGAR",
  "LOGIK",
  "LOGOR",
  "LOGOS",
  "LOJNO",
  "LOKAL",
  "LOKEV",
  "LOKUS",
  "LOMNO",
  "LONEC",
  "LOPAR",
  "LOPNO",
  "LOPOT",
  "LOPOV",
  "LOSOS",
  "LOTOS",
  "LOVAČ",
  "LOVEC",
  "LOVKA",
  "LOVNO",
  "LOVOR",
  "LOŽNO",
  "LUBEN",
  "LUBJE",
  "LUČAJ",
  "LUČCA",
  "LUČKA",
  "LUDIT",
  "LUMEN",
  "LUMNA",
  "LUNEK",
  "LUNIK",
  "LUPAČ",
  "LUPOM",
  "LUPUS",
  "LUSKA",
  "LUTKA",
  "LUŽNO",
  "MAČEK",
  "MAČKA",
  "MADEŽ",
  "MAFIN",
  "MAGIK",
  "MAGMA",
  "MAGOT",
  "MAHAČ",
  "MAHEK",
  "MAJNA",
  "MAJOR",
  "MAJOŠ",
  "MAKAK",
  "MAKAO",
  "MAKRO",
  "MALAT",
  "MALHA",
  "MALIK",
  "MALTA",
  "MALUS",
  "MAMBA",
  "MAMBO",
  "MAMKA",
  "MAMON",
  "MAMUT",
  "MANCE",
  "MANDI",
  "MANGA",
  "MANGO",
  "MANKO",
  "MANTA",
  "MANUL",
  "MAPNO",
  "MARAL",
  "MAREC",
  "MARKA",
  "MARON",
  "MARŽA",
  "MASER",
  "MASIV",
  "MASKA",
  "MASLO",
  "MASNO",
  "MAŠNO",
  "MATNO",
  "MAVEC",
  "MAZAČ",
  "MAZDA",
  "MAZUT",
  "MECEN",
  "MEČAR",
  "MEČEK",
  "MEČNO",
  "MEDAR",
  "MEDIJ",
  "MEDLO",
  "MEDNO",
  "MEGLA",
  "MEHKO",
  "MEHUR",
  "MEJAK",
  "MEJAŠ",
  "MEJNO",
  "MEKET",
  "MELOS",
  "MENEK",
  "MENIH",
  "MENZA",
  "MEREK",
  "MESAR",
  "MESEC",
  "MESNO",
  "MESTI",
  "MESTO",
  "MEŠIČ",
  "METAN",
  "METER",
  "METEŽ",
  "METIL",
  "METJE",
  "METLA",
  "METNO",
  "METRO",
  "MEZDA",
  "MEZEG",
  "MEZGA",
  "MEZON",
  "MEŽIK",
  "MIAZA",
  "MIČNO",
  "MIKRO",
  "MILJA",
  "MILNO",
  "MINER",
  "MINIJ",
  "MINUS",
  "MIOID",
  "MIOZA",
  "MIRNO",
  "MIRTA",
  "MISAL",
  "MISEL",
  "MISLI",
  "MIŠAK",
  "MIŠAR",
  "MIŠEK",
  "MIŠKA",
  "MIŠKO",
  "MITRA",
  "MIVKA",
  "MIZAR",
  "MIZNO",
  "MLADA",
  "MLADI",
  "MLADO",
  "MLAJA",
  "MLAKA",
  "MLASK",
  "MLEKO",
  "MLETI",
  "MNOGA",
  "MNOGI",
  "MNOGO",
  "MOČNO",
  "MODEL",
  "MODEM",
  "MODNO",
  "MODRI",
  "MODRO",
  "MODUL",
  "MODUS",
  "MOGUL",
  "MOHER",
  "MOKAR",
  "MOKOŽ",
  "MOKRC",
  "MOKRO",
  "MOLAR",
  "MOLOH",
  "MOLŽA",
  "MONAL",
  "MONGA",
  "MORJE",
  "MOŠKI",
  "MOŠUS",
  "MOTEK",
  "MOTEL",
  "MOTET",
  "MOTIV",
  "MOTNO",
  "MOTOR",
  "MOZEG",
  "MOŽAK",
  "MOŽEK",
  "MOŽIC",
  "MOŽNO",
  "MRČES",
  "MRENA",
  "MREST",
  "MRETI",
  "MREŽA",
  "MRHAR",
  "MRKAČ",
  "MRLIČ",
  "MRMOT",
  "MRTVI",
  "MRTVO",
  "MRZKO",
  "MRZLO",
  "MTDNA",
  "MTDNK",
  "MUCEK",
  "MUCKA",
  "MUČNO",
  "MUDRA",
  "MUFTI",
  "MUHAR",
  "MULAT",
  "MULEC",
  "MUMPS",
  "MUNEC",
  "MUNGO",
  "MURAL",
  "MUREN",
  "MURKA",
  "MURVA",
  "MUSLI",
  "MUTEC",
  "MUTON",
  "MUTSU",
  "MUZEJ",
  "MUZGA",
  "MUŽIK",
  "NABOB",
  "NABOD",
  "NABOJ",
  "NABOR",
  "NACHR",
  "NAČIN",
  "NAČRT",
  "NADAV",
  "NADEV",
  "NADIH",
  "NADIR",
  "NAFTA",
  "NAGEC",
  "NAGIB",
  "NAGLO",
  "NAGON",
  "NAHOD",
  "NAIRA",
  "NAIVA",
  "NAJEM",
  "NAJTI",
  "NAKIT",
  "NAKUP",
  "NALET",
  "NALIV",
  "NALOG",
  "NALOM",
  "NAMAZ",
  "NAMEN",
  "NAMIG",
  "NAMOK",
  "NAMOT",
  "NANDU",
  "NANOS",
  "NAPAD",
  "NAPEV",
  "NAPIS",
  "NAPOJ",
  "NAPON",
  "NAPOR",
  "NAPUH",
  "NARED",
  "NAREK",
  "NARIS",
  "NARIV",
  "NAROD",
  "NAROK",
  "NASAD",
  "NASEK",
  "NASIP",
  "NATIČ",
  "NATIS",
  "NAVAL",
  "NAVAR",
  "NAVJE",
  "NAVOJ",
  "NAVOR",
  "NAVOZ",
  "NAZIV",
  "NAZOR",
  "NEBES",
  "NEBNO",
  "NEČAK",
  "NEČKE",
  "NEDRA",
  "NEDRC",
  "NEJAZ",
  "NEJUD",
  "NEMIR",
  "NEMOČ",
  "NEPER",
  "NERED",
  "NESIT",
  "NESNO",
  "NESTI",
  "NEŠKE",
  "NETEK",
  "NETKO",
  "NEVEN",
  "NEVMA",
  "NEVUS",
  "NEŽID",
  "NEŽNO",
  "NIALA",
  "NIČLA",
  "NIČNO",
  "NIHAJ",
  "NIKAB",
  "NIMFA",
  "NIŠNO",
  "NITAR",
  "NITJE",
  "NITKA",
  "NITNO",
  "NIZKO",
  "NIŽAJ",
  "NIŽEK",
  "NJIVA",
  "NOČNO",
  "NODUL",
  "NOKSA",
  "NOMAD",
  "NONET",
  "NONIJ",
  "NOREC",
  "NORKA",
  "NORMA",
  "NOSAČ",
  "NOSAN",
  "NOSEK",
  "NOSNO",
  "NOTAR",
  "NOTES",
  "NOTNO",
  "NOVEC",
  "NOVIC",
  "NOŽAR",
  "NOŽEK",
  "NOŽIČ",
  "NOŽNO",
  "NUGAT",
  "NUJNO",
  "NUNKA",
  "OAZNO",
  "OBALA",
  "OBARA",
  "OBČAN",
  "OBESA",
  "OBHOD",
  "OBISK",
  "OBITI",
  "OBJED",
  "OBJEM",
  "OBLAK",
  "OBLAT",
  "OBLET",
  "OBLIČ",
  "OBLIV",
  "OBLIŽ",
  "OBLOK",
  "OBORA",
  "OBRAD",
  "OBRAT",
  "OBRAZ",
  "OBRED",
  "OBRIS",
  "OBROČ",
  "OBROD",
  "OBROK",
  "OBRUS",
  "OBSEG",
  "OBSEV",
  "OBŠIV",
  "OBTOK",
  "OBUTI",
  "OBVOD",
  "OBVOZ",
  "OBZIR",
  "OBŽIG",
  "OCEAN",
  "OCENA",
  "OČALA",
  "ODBOJ",
  "ODBOR",
  "ODCEP",
  "ODDIH",
  "ODEJA",
  "ODEON",
  "ODETI",
  "ODGON",
  "ODHOD",
  "ODITI",
  "ODJEK",
  "ODJEM",
  "ODKAZ",
  "ODKOP",
  "ODKOS",
  "ODKUP",
  "ODLET",
  "ODLIV",
  "ODLOG",
  "ODLOK",
  "ODLOM",
  "ODLOV",
  "ODMET",
  "ODMEV",
  "ODMIK",
  "ODMOR",
  "ODNOS",
  "ODPAD",
  "ODPEV",
  "ODPIS",
  "ODPOR",
  "ODRAZ",
  "ODRED",
  "ODREK",
  "ODREZ",
  "ODRIV",
  "ODSEK",
  "ODSEV",
  "ODSUN",
  "ODTIS",
  "ODTOK",
  "ODVOD",
  "ODVOZ",
  "ODZIV",
  "ODŽIG",
  "OGABA",
  "OGENJ",
  "OGLAR",
  "OGLAS",
  "OGLAV",
  "OGLED",
  "OGLJE",
  "OGRIZ",
  "OHATI",
  "OHLIP",
  "OHOLO",
  "OIDIJ",
  "OJICE",
  "OKAPI",
  "OKATO",
  "OKLAR",
  "OKLEP",
  "OKLIC",
  "OKRAJ",
  "OKRAS",
  "OKROV",
  "OKSID",
  "OKTAN",
  "OKTET",
  "OKVIR",
  "OLEAT",
  "OLEIN",
  "OLIKA",
  "OLIVA",
  "OLJAR",
  "OLJKA",
  "OLJNO",
  "OLTAR",
  "OMAKA",
  "OMAMA",
  "OMARA",
  "OMEGA",
  "OMELA",
  "OMELO",
  "OMETI",
  "OMIKA",
  "ONIKS",
  "ONUČA",
  "OOCIT",
  "OOLIT",
  "OPART",
  "OPAST",
  "OPEČI",
  "OPEKA",
  "OPERA",
  "OPETI",
  "OPIAT",
  "OPICA",
  "OPITI",
  "OPLEN",
  "OPLET",
  "OPOKA",
  "OPOLO",
  "OPORA",
  "OPPNP",
  "OPROG",
  "OPSIN",
  "OPTIK",
  "ORADA",
  "ORANT",
  "ORATI",
  "ORGAN",
  "ORGLE",
  "ORIKS",
  "ORJAK",
  "ORKAN",
  "ORLIČ",
  "ORNAT",
  "OSAMA",
  "OSATO",
  "OSEBA",
  "OSEIN",
  "OSEKA",
  "OSICA",
  "OSKAR",
  "OSLAD",
  "OSLAR",
  "OSLEZ",
  "OSLIČ",
  "OSMIJ",
  "OSNIK",
  "OSOJA",
  "OSTEN",
  "OSTRO",
  "OSTRV",
  "OSTVA",
  "OSTVE",
  "OSUTI",
  "OTAVA",
  "OTEČI",
  "OTROK",
  "OVČAR",
  "OVČIN",
  "OVČKA",
  "OVIRA",
  "OVIST",
  "OVITI",
  "OVNIČ",
  "OZARA",
  "OZEBA",
  "OŽETI",
  "OŽINA",
  "OŽITI",
  "PACEK",
  "PACKA",
  "PADAR",
  "PADEC",
  "PADNO",
  "PAGAT",
  "PAJAC",
  "PAJEK",
  "PAKET",
  "PALEC",
  "PALEŽ",
  "PALIJ",
  "PALIK",
  "PALMA",
  "PAMET",
  "PAMPA",
  "PANDA",
  "PANEL",
  "PANIK",
  "PANKO",
  "PAPEŽ",
  "PAPIR",
  "PARAA",
  "PAREO",
  "PARFE",
  "PARNO",
  "PAROH",
  "PASAN",
  "PASAR",
  "PASAT",
  "PASEK",
  "PASHA",
  "PASMA",
  "PASNO",
  "PASTA",
  "PASTI",
  "PASUS",
  "PAŠNO",
  "PATAS",
  "PATER",
  "PATOS",
  "PAVKA",
  "PEČAR",
  "PEČAT",
  "PEČKA",
  "PEČNO",
  "PEDAL",
  "PEDIC",
  "PEGAM",
  "PEGAT",
  "PEGAZ",
  "PEHAR",
  "PEKAČ",
  "PEKEL",
  "PEKET",
  "PELAT",
  "PELET",
  "PELIN",
  "PELIR",
  "PELOD",
  "PENAT",
  "PENIS",
  "PENJA",
  "PEPEL",
  "PERJE",
  "PERON",
  "PERUT",
  "PESAR",
  "PESEK",
  "PESEM",
  "PESNO",
  "PESTO",
  "PEŠAK",
  "PEŠEC",
  "PEŠKA",
  "PETEK",
  "PETJE",
  "PETKA",
  "PETNO",
  "PEVEC",
  "PEVKA",
  "PEVNO",
  "PHATI",
  "PIANO",
  "PIČLO",
  "PIETA",
  "PIKRO",
  "PILAR",
  "PILAV",
  "PILOT",
  "PILUM",
  "PINCA",
  "PINJA",
  "PINOT",
  "PINTA",
  "PIPAR",
  "PIPEC",
  "PIPER",
  "PIRAT",
  "PIRIT",
  "PIRKA",
  "PIROG",
  "PISAČ",
  "PISAR",
  "PISEC",
  "PISKA",
  "PISMO",
  "PISNO",
  "PISUN",
  "PIŠČE",
  "PIŠKA",
  "PITJE",
  "PITNO",
  "PITON",
  "PIVCE",
  "PIVEC",
  "PIVKA",
  "PIVNO",
  "PIVOT",
  "PLAČA",
  "PLAHO",
  "PLANO",
  "PLAST",
  "PLAŠČ",
  "PLATI",
  "PLAVŽ",
  "PLAZA",
  "PLAŽA",
  "PLEČA",
  "PLEČE",
  "PLEME",
  "PLEŠA",
  "PLETI",
  "PLEVA",
  "PLIKA",
  "PLIMA",
  "PLISE",
  "PLOHA",
  "PLOSK",
  "PLUTA",
  "PLUTI",
  "POBEG",
  "POBIČ",
  "POBOJ",
  "POBOT",
  "POČEP",
  "PODLO",
  "PODNO",
  "PODOJ",
  "PODOR",
  "PODRS",
  "PODUK",
  "POGAN",
  "POGIN",
  "POGOJ",
  "POGON",
  "POGUM",
  "POHOD",
  "POHRA",
  "POITI",
  "POJAV",
  "POJEM",
  "POKAČ",
  "POKAL",
  "POKEC",
  "POKER",
  "POKOJ",
  "POKOL",
  "POKOP",
  "POLAJ",
  "POLET",
  "POLIČ",
  "POLIP",
  "POLIV",
  "POLJE",
  "POLKA",
  "POLNO",
  "POLOG",
  "POLOM",
  "POLOS",
  "POLST",
  "POLZA",
  "POMEN",
  "POMIG",
  "POMIK",
  "POMOČ",
  "POMOL",
  "POMOR",
  "PONČO",
  "PONEV",
  "PONIK",
  "PONOR",
  "PONOS",
  "POPEK",
  "POPER",
  "POPIS",
  "POPJE",
  "POPON",
  "POPRH",
  "PORAZ",
  "POREK",
  "PORIV",
  "PORNO",
  "POROD",
  "POROG",
  "POROK",
  "POSEG",
  "POSEK",
  "POSEL",
  "POSIP",
  "POŠTA",
  "POTEG",
  "POTEK",
  "POTEP",
  "POTKA",
  "POTNO",
  "POTOK",
  "POTOP",
  "POVED",
  "POVOD",
  "POVOJ",
  "POVOZ",
  "POZIV",
  "POZNO",
  "POZOR",
  "POŽAR",
  "POŽIG",
  "PRAČA",
  "PRAHA",
  "PRAMA",
  "PRANA",
  "PRAVA",
  "PRAVI",
  "PRAVO",
  "PRDEC",
  "PREČA",
  "PREJA",
  "PREMA",
  "PREMO",
  "PREŠA",
  "PREŽA",
  "PRHKO",
  "PRHLO",
  "PRHUT",
  "PRIBA",
  "PRIČA",
  "PRINC",
  "PRION",
  "PRIOR",
  "PRIŠČ",
  "PRITI",
  "PROGA",
  "PROJA",
  "PROSO",
  "PROŠT",
  "PROTA",
  "PROZA",
  "PRSCA",
  "PRSNO",
  "PRŠEC",
  "PRŠIČ",
  "PRŠNO",
  "PRŠUT",
  "PRTIČ",
  "PRVAK",
  "PRVEC",
  "PSALM",
  "PSICA",
  "PSIHA",
  "PŠENO",
  "PTICA",
  "PTOZA",
  "PUDER",
  "PUFER",
  "PUHAR",
  "PUHEC",
  "PUHEK",
  "PUHLO",
  "PUJSA",
  "PULPA",
  "PUNČE",
  "PUPEK",
  "PURAN",
  "PURIN",
  "PUSTO",
  "PUŠČA",
  "PUŠKA",
  "PUTKA",
  "RABAT",
  "RABIN",
  "RACAK",
  "RACEM",
  "RAČAK",
  "RAČEK",
  "RAČKA",
  "RAČUN",
  "RADAR",
  "RADIČ",
  "RADIJ",
  "RADIO",
  "RADON",
  "RADŽA",
  "RAFAL",
  "RAGBI",
  "RAHLO",
  "RAJON",
  "RAKAR",
  "RAKEC",
  "RAKEL",
  "RAKEV",
  "RAKUN",
  "RAMBO",
  "RAMPA",
  "RANAR",
  "RAPER",
  "RAPIR",
  "RAROG",
  "RASNO",
  "RASTI",
  "RAŠČE",
  "RAŠPA",
  "RATAN",
  "RAVAN",
  "RAVEN",
  "RAVNO",
  "RAZID",
  "RAZNO",
  "RAZOR",
  "RAZUM",
  "RDEČE",
  "RDETI",
  "REBEK",
  "REBER",
  "REBRO",
  "REBUS",
  "REČJE",
  "REČNO",
  "REDAR",
  "REDKA",
  "REDKI",
  "REDKO",
  "REDNO",
  "REGAL",
  "REIKI",
  "REIST",
  "REJEC",
  "REJKA",
  "REJNO",
  "REJON",
  "REKLO",
  "RENIJ",
  "RENIN",
  "RENTA",
  "REPAK",
  "REPEK",
  "REPIK",
  "REPNO",
  "REPUH",
  "REPUŠ",
  "RESAR",
  "RESJE",
  "RESNO",
  "RESOR",
  "RESUZ",
  "REVEŽ",
  "REVIR",
  "REVNI",
  "REVNO",
  "REVSK",
  "REVŠE",
  "REZEC",
  "REZKO",
  "REZNO",
  "REZUS",
  "REZVA",
  "REŽAJ",
  "REŽIM",
  "REŽNO",
  "RIBEZ",
  "RIBIČ",
  "RIBON",
  "RICIN",
  "RIČEK",
  "RIČET",
  "RIGEC",
  "RIKŠA",
  "RILEC",
  "RIMAČ",
  "RIMAR",
  "RINEŽ",
  "RISAR",
  "RISBA",
  "RITEM",
  "RITJE",
  "RITKA",
  "RITNO",
  "RIZMA",
  "RIŽEK",
  "RJAVO",
  "RJUHA",
  "RJUTI",
  "ROBEC",
  "ROBEK",
  "ROBNO",
  "ROBOT",
  "ROČAJ",
  "ROČEK",
  "ROČKA",
  "ROČNO",
  "RODEO",
  "RODIJ",
  "RODNO",
  "ROGAČ",
  "ROGAR",
  "ROGOZ",
  "ROJAK",
  "ROKAV",
  "ROKER",
  "ROLAR",
  "ROLER",
  "ROLKA",
  "ROMAN",
  "ROMAR",
  "RONDO",
  "ROPAR",
  "ROPOT",
  "ROSNO",
  "ROTOR",
  "ROVAR",
  "ROVAŠ",
  "ROVER",
  "ROVKA",
  "ROVNO",
  "ROVTE",
  "ROZGA",
  "ROŽIČ",
  "ROŽJE",
  "ROŽKA",
  "ROŽNO",
  "RTIČI",
  "RUBEŽ",
  "RUBIN",
  "RUDAR",
  "RUDNO",
  "RUMBA",
  "RUNDA",
  "RUŠJE",
  "RUŠNO",
  "RUTIN",
  "RUTKA",
  "RUTNO",
  "RUVAČ",
  "RVATI",
  "RŽENO",
  "SABAT",
  "SABIR",
  "SABOR",
  "SADEŽ",
  "SADJE",
  "SADNO",
  "SADRA",
  "SADŽA",
  "SAFIR",
  "SAHEL",
  "SAHIB",
  "SAJGA",
  "SAKAR",
  "SALAK",
  "SALAŠ",
  "SALDO",
  "SALNO",
  "SALON",
  "SALPA",
  "SALSA",
  "SALTA",
  "SALTO",
  "SALUN",
  "SALVA",
  "SAMBA",
  "SAMEC",
  "SAMOL",
  "SAMUM",
  "SANJA",
  "SANJE",
  "SANKE",
  "SAPER",
  "SARIN",
  "SARMA",
  "SARUS",
  "SATAN",
  "SATEN",
  "SATIR",
  "SATJE",
  "SATNO",
  "SAVNA",
  "SCENA",
  "SEBEK",
  "SEČNO",
  "SEDEM",
  "SEDEŽ",
  "SEDIJ",
  "SEDJE",
  "SEDLO",
  "SEDNO",
  "SEJEM",
  "SEJNO",
  "SEKAČ",
  "SEKTA",
  "SELEC",
  "SELEN",
  "SELKA",
  "SEMIŠ",
  "SENAR",
  "SENAT",
  "SENCA",
  "SENCE",
  "SENIK",
  "SEPOJ",
  "SEPSA",
  "SERAF",
  "SERAJ",
  "SERAK",
  "SERAP",
  "SEREC",
  "SERIF",
  "SERIN",
  "SERKA",
  "SEROM",
  "SERUM",
  "SESAČ",
  "SESEK",
  "SESIP",
  "SESNO",
  "SESTI",
  "SETER",
  "SETEV",
  "SEVER",
  "SEVKA",
  "SEZAM",
  "SEŽAJ",
  "SEŽIG",
  "SFERA",
  "SHEMA",
  "SIDOL",
  "SIDRO",
  "SIFAK",
  "SIFON",
  "SIGMA",
  "SIJAJ",
  "SIKON",
  "SILAK",
  "SILFA",
  "SILNO",
  "SILOS",
  "SILUR",
  "SINEK",
  "SINKO",
  "SINOD",
  "SINUS",
  "SIPAČ",
  "SIPKO",
  "SIRAR",
  "SIREK",
  "SIRNO",
  "SIRUP",
  "SISAL",
  "SITCE",
  "SITEC",
  "SITJE",
  "SITKA",
  "SITNO",
  "SIVEC",
  "SIVKA",
  "SKALA",
  "SKALD",
  "SKALP",
  "SKANK",
  "SKAVT",
  "SKAZA",
  "SKICA",
  "SKINK",
  "SKIRO",
  "SKLAČ",
  "SKLAD",
  "SKLEP",
  "SKLIC",
  "SKLON",
  "SKLOP",
  "SKOBA",
  "SKOPO",
  "SKORŠ",
  "SKRAM",
  "SKRIL",
  "SKRKA",
  "SKUNK",
  "SKUŠA",
  "SKUTA",
  "SKVOŠ",
  "SKVOT",
  "SLABA",
  "SLABI",
  "SLABO",
  "SLAMA",
  "SLANA",
  "SLANO",
  "SLAST",
  "SLAVA",
  "SLEČI",
  "SLEME",
  "SLENG",
  "SLEPI",
  "SLEPO",
  "SLIKA",
  "SLINA",
  "SLIVA",
  "SLOGA",
  "SLOKA",
  "SLOKO",
  "SLOVO",
  "SLUGA",
  "SMELO",
  "SMETI",
  "SMILJ",
  "SMOLA",
  "SMRAD",
  "SMUKA",
  "SMUKE",
  "SMUTI",
  "SNAGA",
  "SNAHA",
  "SNAST",
  "SNETI",
  "SNITI",
  "SOBAR",
  "SOBNO",
  "SOČNO",
  "SODAR",
  "SODBA",
  "SODEC",
  "SODNO",
  "SODRA",
  "SOKOL",
  "SOKOT",
  "SOLID",
  "SOLNO",
  "SOLZA",
  "SOMAN",
  "SOMIČ",
  "SONAR",
  "SONCE",
  "SONDA",
  "SONET",
  "SOPIH",
  "SOPUH",
  "SOROD",
  "SORTA",
  "SOSED",
  "SOŠNO",
  "SOVEC",
  "SOVIR",
  "SOVKA",
  "SPAKA",
  "SPATI",
  "SPEČI",
  "SPETI",
  "SPINA",
  "SPITI",
  "SPLAV",
  "SPLET",
  "SPONA",
  "SPORA",
  "SPUST",
  "SRAGA",
  "SRAKA",
  "SRBEC",
  "SRBEŽ",
  "SRČEK",
  "SRČNO",
  "SREČA",
  "SREDA",
  "SREPO",
  "SRHKO",
  "SRPAN",
  "SRPEK",
  "SRPIČ",
  "SRPJE",
  "SRŠAJ",
  "SRŠEN",
  "STAJA",
  "STALO",
  "STARA",
  "STARI",
  "STARO",
  "STARŠ",
  "START",
  "STATI",
  "STAVA",
  "STEJK",
  "STELA",
  "STENA",
  "STENJ",
  "STENT",
  "STEPA",
  "STEZA",
  "STINT",
  "STISK",
  "STIVA",
  "STOIK",
  "STOJA",
  "STOLP",
  "STOMA",
  "STOPA",
  "STORŽ",
  "STRAH",
  "STRAN",
  "STREL",
  "STRES",
  "STRIC",
  "STRIG",
  "STRIP",
  "STRMO",
  "STROJ",
  "STROK",
  "STROP",
  "STRUP",
  "STVAR",
  "STVOR",
  "SUČNO",
  "SUFLE",
  "SUHEC",
  "SUITA",
  "SUKAČ",
  "SUKNO",
  "SUKUB",
  "SULEC",
  "SULKI",
  "SUNEK",
  "SUNIS",
  "SUNIT",
  "SURIL",
  "SUŠNO",
  "SUTRA",
  "SVAJA",
  "SVAST",
  "SVEČA",
  "SVETO",
  "SVEŽE",
  "SVILA",
  "SVING",
  "SVIŠČ",
  "SVORA",
  "ŠABAT",
  "ŠAKAL",
  "ŠAMAN",
  "ŠAMOT",
  "ŠAREC",
  "ŠAŠJE",
  "ŠAVRA",
  "ŠČENE",
  "ŠČUKA",
  "ŠEBOJ",
  "ŠEJEK",
  "ŠEKEL",
  "ŠELAK",
  "ŠEPET",
  "ŠERIF",
  "ŠERPA",
  "ŠIBJE",
  "ŠIBKO",
  "ŠIBRA",
  "ŠIFRA",
  "ŠILAR",
  "ŠILCE",
  "ŠINJE",
  "ŠIPEK",
  "ŠIPON",
  "ŠIRNO",
  "ŠIŠKA",
  "ŠIVNO",
  "ŠKAJA",
  "ŠKAMP",
  "ŠKODA",
  "ŠKOLJ",
  "ŠKOPA",
  "ŠKOTA",
  "ŠKRAT",
  "ŠKRGA",
  "ŠKRIC",
  "ŠKROB",
  "ŠKURH",
  "ŠKVER",
  "ŠMENT",
  "ŠNJUR",
  "ŠOBNO",
  "ŠOFER",
  "ŠOGUN",
  "ŠOLAR",
  "ŠOPEK",
  "ŠOTAR",
  "ŠOTIŠ",
  "ŠOTNO",
  "ŠOTOR",
  "ŠPICA",
  "ŠPILA",
  "ŠPORT",
  "ŠTETI",
  "ŠTIRI",
  "ŠTOLA",
  "ŠTRAS",
  "ŠTULA",
  "ŠUMNO",
  "ŠUMOT",
  "ŠUNKA",
  "ŠVIST",
  "TABLA",
  "TABOR",
  "TABOT",
  "TABUN",
  "TAČKA",
  "TAEBO",
  "TAJGA",
  "TAJNA",
  "TAJNO",
  "TAKIN",
  "TAKSI",
  "TALAR",
  "TALEC",
  "TALIB",
  "TALIJ",
  "TALIN",
  "TALKA",
  "TALNO",
  "TALON",
  "TANGO",
  "TANKO",
  "TAPAS",
  "TAPIR",
  "TARČA",
  "TAROK",
  "TAROT",
  "TAŠČA",
  "TATIČ",
  "TEČAJ",
  "TEČKA",
  "TEČNO",
  "TEDEN",
  "TEIST",
  "TEKAČ",
  "TEKMA",
  "TEKUT",
  "TELOH",
  "TELOP",
  "TELUR",
  "TEMNO",
  "TEMPO",
  "TENIS",
  "TENKO",
  "TENOR",
  "TEPEC",
  "TEPEŽ",
  "TEPKA",
  "TERAN",
  "TERCA",
  "TEREN",
  "TERJE",
  "TERMA",
  "TERME",
  "TERNA",
  "TEROR",
  "TESAČ",
  "TESAR",
  "TESEN",
  "TESLA",
  "TESLO",
  "TESNO",
  "TESTO",
  "TEŠČE",
  "TETKA",
  "TETRA",
  "TEVJE",
  "TEŽAK",
  "TEŽEC",
  "TEŽKO",
  "TEŽNO",
  "THETA",
  "TIARA",
  "TIČEK",
  "TIČKA",
  "TIFUS",
  "TIGER",
  "TIGON",
  "TILDA",
  "TIMIN",
  "TIMOL",
  "TIMOM",
  "TIPKA",
  "TIPNO",
  "TIRAN",
  "TIRNO",
  "TISOČ",
  "TITAN",
  "TITER",
  "TKATI",
  "TKIVO",
  "TLAKA",
  "TLESK",
  "TLETI",
  "TNALO",
  "TOAST",
  "TOBAK",
  "TOČAJ",
  "TOČKA",
  "TOČNO",
  "TOKAJ",
  "TOKEJ",
  "TOKOJ",
  "TOLAR",
  "TOLPA",
  "TONEM",
  "TONER",
  "TONIK",
  "TONUS",
  "TOPAZ",
  "TOPIČ",
  "TOPLO",
  "TOPNO",
  "TOPOL",
  "TOPOT",
  "TORBA",
  "TOREK",
  "TORIJ",
  "TORNO",
  "TORTA",
  "TORZO",
  "TOTAL",
  "TOTEM",
  "TOVOR",
  "TOŽBA",
  "TOŽNO",
  "TRAKT",
  "TRAPA",
  "TRASA",
  "TRATA",
  "TRAVA",
  "TRDNO",
  "TREMA",
  "TREND",
  "TRESK",
  "TRETI",
  "TRGAČ",
  "TRGEC",
  "TRHLO",
  "TRIAK",
  "TRIAS",
  "TRIKO",
  "TRIPS",
  "TRLJA",
  "TRNAČ",
  "TRNEK",
  "TRNEŽ",
  "TRNJE",
  "TROHA",
  "TROPI",
  "TRPIN",
  "TRPKO",
  "TRPNO",
  "TRSJE",
  "TRSKA",
  "TRSNO",
  "TRTNO",
  "TRULA",
  "TRUMA",
  "TRUST",
  "TRUŠČ",
  "TRZAJ",
  "TRŽAN",
  "TRŽNO",
  "TUBIT",
  "TUBUS",
  "TUFIT",
  "TUJEC",
  "TUJEK",
  "TUJKA",
  "TUKAN",
  "TUKLA",
  "TULEC",
  "TULEŽ",
  "TULIJ",
  "TULKA",
  "TUMOR",
  "TUMUL",
  "TUNEL",
  "TURAK",
  "TURBO",
  "TUREK",
  "TURNO",
  "TUTOR",
  "UAKAR",
  "UBITA",
  "UBITI",
  "UBOGI",
  "UBOGO",
  "UČILO",
  "UČITI",
  "UČNIK",
  "UGLED",
  "UGREZ",
  "UGRIZ",
  "UHATO",
  "UHELJ",
  "UJEDA",
  "UJEMA",
  "UJETI",
  "UKANA",
  "UKATI",
  "UKLON",
  "UKREP",
  "ULEČI",
  "ULEKS",
  "ULEMA",
  "ULICA",
  "ULITI",
  "ULKUS",
  "ULTRA",
  "UMAMI",
  "UMBRA",
  "UMETI",
  "UMITI",
  "UMRLA",
  "UMRLI",
  "UNIAT",
  "UNIJA",
  "UPATI",
  "UPETI",
  "UPNIK",
  "URBAR",
  "UREČI",
  "URICA",
  "URITI",
  "URNIK",
  "URZON",
  "USNJE",
  "USODA",
  "USPEH",
  "USTAŠ",
  "USTEŽ",
  "USTJE",
  "USTKA",
  "USTNA",
  "USTNO",
  "USUTI",
  "UŠATA",
  "UŠČIP",
  "UŠICA",
  "UŠIVO",
  "UTAJA",
  "UTEČI",
  "UTEHA",
  "UTICA",
  "UTRIP",
  "UVALA",
  "UVELO",
  "UVITI",
  "UŽITI",
  "VADBA",
  "VAGON",
  "VAJET",
  "VALAT",
  "VALIN",
  "VALUK",
  "VAMPI",
  "VANEŽ",
  "VANGA",
  "VARAN",
  "VAREK",
  "VARNO",
  "VAROA",
  "VARPA",
  "VARUH",
  "VATEL",
  "VATKA",
  "VATNO",
  "VAZAL",
  "VAZNO",
  "VAŽNO",
  "VBITI",
  "VDAJA",
  "VDATI",
  "VDETI",
  "VDOVA",
  "VEBER",
  "VEČER",
  "VEČNO",
  "VEDEŽ",
  "VEDRO",
  "VEGAN",
  "VEJNO",
  "VELUR",
  "VENEC",
  "VENGE",
  "VEPER",
  "VERNO",
  "VESLO",
  "VESPA",
  "VESTI",
  "VEŠČA",
  "VEŠČE",
  "VEŠEC",
  "VETER",
  "VETJE",
  "VETRC",
  "VEZAČ",
  "VEZAJ",
  "VEZIR",
  "VEZJE",
  "VEZNO",
  "VEŽNO",
  "VIDEC",
  "VIDEO",
  "VIDEZ",
  "VIDIK",
  "VIDNO",
  "VIDON",
  "VIDRA",
  "VIGNA",
  "VIHAR",
  "VIHER",
  "VIHRA",
  "VIJAK",
  "VIKAR",
  "VILIN",
  "VINAR",
  "VINCE",
  "VINIL",
  "VIOLA",
  "VIRUS",
  "VISKI",
  "VIŠAJ",
  "VIŠEK",
  "VITEL",
  "VITEZ",
  "VITJE",
  "VITKO",
  "VITLO",
  "VITRA",
  "VIZIR",
  "VIZUM",
  "VKLOP",
  "VLAČA",
  "VLADA",
  "VLAGA",
  "VLAKA",
  "VLEČI",
  "VLEKA",
  "VLITI",
  "VLOGA",
  "VNEMA",
  "VNETI",
  "VODAR",
  "VODEB",
  "VODIČ",
  "VODIK",
  "VODJA",
  "VODKA",
  "VODNO",
  "VOGAL",
  "VOGEL",
  "VOHUN",
  "VOJAK",
  "VOJNA",
  "VOJNO",
  "VOKAL",
  "VOLAN",
  "VOLAR",
  "VOLEJ",
  "VOLEK",
  "VOLJA",
  "VOLNA",
  "VOLVO",
  "VOMER",
  "VOSEK",
  "VOTEK",
  "VOTLO",
  "VOZAČ",
  "VOZAR",
  "VOZEK",
  "VOZEL",
  "VOZNO",
  "VPETI",
  "VPITI",
  "VPLET",
  "VPLIV",
  "VPONA",
  "VPUST",
  "VRANA",
  "VRATA",
  "VRATI",
  "VRAŽA",
  "VRBAR",
  "VRBJE",
  "VRBNO",
  "VRČEK",
  "VREČA",
  "VREČI",
  "VREME",
  "VRESA",
  "VRETI",
  "VREŽA",
  "VRHAČ",
  "VRISK",
  "VRIŠČ",
  "VROČE",
  "VRSTA",
  "VRŠAC",
  "VRŠAJ",
  "VRŠEK",
  "VRŠIČ",
  "VRŠNO",
  "VRTAČ",
  "VRTEC",
  "VRTEK",
  "VRTEŽ",
  "VRTNO",
  "VRVAR",
  "VRVCA",
  "VRVEŽ",
  "VRVJE",
  "VRVNO",
  "VRZEL",
  "VSKOK",
  "VSOTA",
  "VSTOP",
  "VSUTI",
  "VŠITI",
  "VTEČI",
  "VTISK",
  "VULVA",
  "VZBUH",
  "VZDIG",
  "VZDIH",
  "VZETI",
  "VZGIB",
  "VZGON",
  "VZHOD",
  "VZITI",
  "VZKIP",
  "VZLET",
  "VZMAH",
  "VZMET",
  "VZMIK",
  "VZNIK",
  "VZPON",
  "VZROK",
  "VZVOD",
  "ZABOD",
  "ZABOJ",
  "ZADAH",
  "ZADEK",
  "ZAGIB",
  "ZAGON",
  "ZAHOD",
  "ZAITI",
  "ZAJEC",
  "ZAJEM",
  "ZAJKA",
  "ZAKOL",
  "ZAKON",
  "ZAKOP",
  "ZAKOV",
  "ZAKUP",
  "ZALAZ",
  "ZALET",
  "ZALIV",
  "ZALOM",
  "ZAMAH",
  "ZAMAZ",
  "ZAMET",
  "ZAMIK",
  "ZANIČ",
  "ZANKA",
  "ZANOS",
  "ZAPAH",
  "ZAPAŽ",
  "ZAPIK",
  "ZAPIS",
  "ZAPOR",
  "ZARIS",
  "ZARJA",
  "ZAROD",
  "ZASEG",
  "ZASEK",
  "ZASIJ",
  "ZASIP",
  "ZASUK",
  "ZASUN",
  "ZATIČ",
  "ZATIK",
  "ZATOK",
  "ZATON",
  "ZAVER",
  "ZAVIH",
  "ZAVOD",
  "ZAVOJ",
  "ZAZIB",
  "ZAŽIG",
  "ZBATI",
  "ZBITI",
  "ZBRIS",
  "ZDELO",
  "ZDETI",
  "ZDRIZ",
  "ZDROB",
  "ZEBRA",
  "ZELJE",
  "ZELOT",
  "ZENIT",
  "ZEVKA",
  "ZGAGA",
  "ZGLED",
  "ZGLOB",
  "ZGODA",
  "ZGUBA",
  "ZIBEL",
  "ZIBKA",
  "ZIDAK",
  "ZIDAR",
  "ZIDEC",
  "ZIDEK",
  "ZIDNO",
  "ZLATO",
  "ZLITI",
  "ZLOBA",
  "ZMAGA",
  "ZMEDA",
  "ZMENE",
  "ZMETI",
  "ZMITI",
  "ZMOTA",
  "ZNANI",
  "ZNANO",
  "ZNATI",
  "ZOBAČ",
  "ZOBEC",
  "ZOBEK",
  "ZOBER",
  "ZOBNO",
  "ZOMBI",
  "ZORNO",
  "ZRAST",
  "ZRELO",
  "ZRETI",
  "ZRITI",
  "ZRKLO",
  "ZRNCE",
  "ZRNEC",
  "ZRNJE",
  "ZVEZA",
  "ZVITI",
  "ZVRST",
  "ŽABEC",
  "ŽABON",
  "ŽADNO",
  "ŽAGAR",
  "ŽAGNO",
  "ŽALNO",
  "ŽAMET",
  "ŽAREK",
  "ŽARJA",
  "ŽARKO",
  "ŽARNO",
  "ŽBICA",
  "ŽDETI",
  "ŽEJNO",
  "ŽELJA",
  "ŽELOD",
  "ŽELVA",
  "ŽENIN",
  "ŽENKA",
  "ŽEPAR",
  "ŽEPEK",
  "ŽEPIČ",
  "ŽEPNO",
  "ŽERKA",
  "ŽERUH",
  "ŽETEV",
  "ŽETJE",
  "ŽETON",
  "ŽEZLO",
  "ŽGANO",
  "ŽGATI",
  "ŽIČKA",
  "ŽIČNO",
  "ŽIDKO",
  "ŽILJE",
  "ŽILNO",
  "ŽITEC",
  "ŽITJE",
  "ŽITNO",
  "ŽIVAL",
  "ŽIVEC",
  "ŽIVEŽ",
  "ŽLEZA",
  "ŽLICA",
  "ŽMULA",
  "ŽOLCA",
  "ŽOLNA",
  "ŽREBE",
  "ŽRELO",
  "ŽRETI",
  "ŽRTEV",
  "ŽUPAN",
  "ŽUPNO",
  "ŽUŽEK",
  "ŽVALA",
  "ŽVENK",
  "ŽVIŽG",
];

export const WORDS_VALID = new Set([
  "ABAJA",
  "ABAJE",
  "ABAJI",
  "ABAJO",
  "ABAKA",
  "ABAKE",
  "ABAKI",
  "ABAKU",
  "ABCIM",
  "ABEJA",
  "ABEJE",
  "ABEJI",
  "ABEJU",
  "ABIOZ",
  "ABOTA",
  "ABOTE",
  "ABOTI",
  "ABOTO",
  "ABRAH",
  "ABRAM",
  "ACIDA",
  "ACIDU",
  "ADEPT",
  "ADIJO",
  "ADIRA",
  "ADMIN",
  "ADRAH",
  "ADRAM",
  "ADREM",
  "ADRES",
  "ADULT",
  "ADUTA",
  "ADUTE",
  "ADUTI",
  "ADUTK",
  "ADUTU",
  "AFEKT",
  "AFERA",
  "AFERE",
  "AFERI",
  "AFERO",
  "AFIKS",
  "AFINA",
  "AFINE",
  "AFINI",
  "AFINO",
  "AFNAH",
  "AFNAJ",
  "AFNAL",
  "AFNAM",
  "AFNAŠ",
  "AFNAT",
  "AFPAC",
  "AFRIK",
  "AFTAH",
  "AFTAM",
  "AFTOZ",
  "AGAMA",
  "AGAME",
  "AGAMI",
  "AGAMO",
  "AGAVA",
  "AGAVE",
  "AGAVI",
  "AGAVO",
  "AGEND",
  "AGENS",
  "AGENT",
  "AGIRA",
  "AGITK",
  "AGNAT",
  "AGORA",
  "AGORE",
  "AGORI",
  "AGORO",
  "AGRAF",
  "AGUTI",
  "AHATA",
  "AHATE",
  "AHATI",
  "AHATU",
  "AHLAH",
  "AHLAM",
  "AHMEJ",
  "AHNEM",
  "AHNEŠ",
  "AHNIL",
  "AHNIT",
  "AHTAJ",
  "AHTAL",
  "AHTAM",
  "AHTAŠ",
  "AIDSA",
  "AIDSU",
  "AJAJE",
  "AJAJO",
  "AJALA",
  "AJALE",
  "AJALI",
  "AJALO",
  "AJAMO",
  "AJANJ",
  "AJATA",
  "AJATE",
  "AJATI",
  "AJAVA",
  "AJBIŠ",
  "AJDEN",
  "AJDIH",
  "AJDJE",
  "AJDNA",
  "AJDNE",
  "AJDNI",
  "AJDNO",
  "AJDOM",
  "AJDOV",
  "AJFRA",
  "AJKAH",
  "AJKAM",
  "AJMER",
  "AJNCA",
  "AJNCU",
  "AJVAR",
  "AKAJE",
  "AKAJO",
  "AKALA",
  "AKALE",
  "AKALI",
  "AKALO",
  "AKAMO",
  "AKANJ",
  "AKANT",
  "AKATA",
  "AKATE",
  "AKATI",
  "AKAVA",
  "AKCIJ",
  "AKIJA",
  "AKIJE",
  "AKIJI",
  "AKIJO",
  "AKNAH",
  "AKNAM",
  "AKORD",
  "AKRIH",
  "AKRIL",
  "AKROM",
  "AKROV",
  "AKSAH",
  "AKSAM",
  "AKSEL",
  "AKSIL",
  "AKSIS",
  "AKSLA",
  "AKSLE",
  "AKSLI",
  "AKSLU",
  "AKSON",
  "AKTER",
  "AKTIH",
  "AKTIN",
  "AKTIV",
  "AKTOM",
  "AKTOV",
  "AKUTA",
  "AKUTE",
  "AKUTI",
  "AKUTU",
  "ALAJA",
  "ALAJU",
  "ALARM",
  "ALBAH",
  "ALBAM",
  "ALBIN",
  "ALBUM",
  "ALDOZ",
  "ALEJA",
  "ALEJE",
  "ALEJI",
  "ALEJO",
  "ALELA",
  "ALELE",
  "ALELI",
  "ALELU",
  "ALFAH",
  "ALFAM",
  "ALGAH",
  "ALGAM",
  "ALGOZ",
  "ALIBI",
  "ALILA",
  "ALILE",
  "ALILI",
  "ALILU",
  "ALKAH",
  "ALKAM",
  "ALKAN",
  "ALKAR",
  "ALKEN",
  "ALKIH",
  "ALKIL",
  "ALKIN",
  "ALKOM",
  "ALKOV",
  "ALOIN",
  "ALOJA",
  "ALOJE",
  "ALOJI",
  "ALOJO",
  "ALPAH",
  "ALPAK",
  "ALPAM",
  "ALPID",
  "ALTAJ",
  "ALTAN",
  "ALTER",
  "ALTIH",
  "ALTOM",
  "ALTOV",
  "AMBRA",
  "AMBRE",
  "AMBRI",
  "AMBRO",
  "AMEBA",
  "AMEBE",
  "AMEBI",
  "AMEBO",
  "AMEJV",
  "AMENA",
  "AMENE",
  "AMENI",
  "AMENU",
  "AMFOR",
  "AMIDA",
  "AMIDE",
  "AMIDI",
  "AMIDU",
  "AMIJA",
  "AMIJE",
  "AMIJI",
  "AMIJO",
  "AMILA",
  "AMILE",
  "AMILI",
  "AMILU",
  "AMINA",
  "AMINE",
  "AMINI",
  "AMINU",
  "AMIŠA",
  "AMIŠE",
  "AMIŠI",
  "AMIŠU",
  "AMNIJ",
  "AMOKA",
  "AMOKE",
  "AMOKI",
  "AMOKU",
  "AMORF",
  "AMPAK",
  "AMPER",
  "AMPUL",
  "ANALA",
  "ANALE",
  "ANALI",
  "ANALO",
  "ANDIH",
  "ANDOM",
  "ANDOV",
  "ANEKS",
  "ANGEL",
  "ANGIN",
  "ANIMA",
  "ANIME",
  "ANIMI",
  "ANIMO",
  "ANION",
  "ANKET",
  "ANODA",
  "ANODE",
  "ANODI",
  "ANODO",
  "ANONA",
  "ANONE",
  "ANONI",
  "ANONO",
  "ANONS",
  "ANTEN",
  "ANUSA",
  "ANUSE",
  "ANUSI",
  "ANUSU",
  "AORTA",
  "AORTE",
  "AORTI",
  "AORTO",
  "APEKS",
  "APELA",
  "APELE",
  "APELI",
  "APELU",
  "APLIT",
  "APNAR",
  "APNAT",
  "APNEČ",
  "APNEJ",
  "APNEL",
  "APNEN",
  "APNET",
  "APNIC",
  "APNIH",
  "APNIK",
  "APNIL",
  "APNIM",
  "APNIŠ",
  "APNIT",
  "APNOM",
  "APOEN",
  "APRIL",
  "APSID",
  "ARAKA",
  "ARAKU",
  "ARAMA",
  "ARAMI",
  "ARBON",
  "ARDIT",
  "AREAL",
  "ARENA",
  "ARENE",
  "ARENI",
  "ARENO",
  "AREOL",
  "AREST",
  "ARGON",
  "ARGUS",
  "ARHEJ",
  "ARHIV",
  "ARIJA",
  "ARIJE",
  "ARIJI",
  "ARIJO",
  "ARKAD",
  "ARMAD",
  "ARMES",
  "ARMIJ",
  "ARNIK",
  "AROMA",
  "AROME",
  "AROMI",
  "AROMO",
  "ARŠIN",
  "ARZEN",
  "ASAJA",
  "ASAJE",
  "ASAJI",
  "ASAJU",
  "ASANA",
  "ASANE",
  "ASANI",
  "ASANO",
  "ASCII",
  "ASEPS",
  "ASICA",
  "ASICE",
  "ASICI",
  "ASICO",
  "ASKET",
  "ASKEZ",
  "ASOMA",
  "ASPIK",
  "ASTAT",
  "ASTER",
  "ASTMA",
  "ASTME",
  "ASTMI",
  "ASTMO",
  "ASTRA",
  "ASTRE",
  "ASTRI",
  "ASTRO",
  "AŠRAM",
  "ATAKA",
  "ATAKE",
  "ATAKI",
  "ATAKO",
  "ATAMA",
  "ATAMI",
  "ATAŠE",
  "ATEST",
  "ATIJA",
  "ATIJE",
  "ATIJI",
  "ATIJU",
  "ATKIH",
  "ATKOM",
  "ATKOV",
  "ATLAS",
  "ATLET",
  "ATOLA",
  "ATOLE",
  "ATOLI",
  "ATOLU",
  "ATOMA",
  "ATOME",
  "ATOMI",
  "ATOMU",
  "ATOSA",
  "ATOSU",
  "ATRAP",
  "ATRIJ",
  "AVALA",
  "AVALE",
  "AVALI",
  "AVALU",
  "AVANS",
  "AVBAH",
  "AVBAM",
  "AVBIC",
  "AVDIA",
  "AVDIE",
  "AVDII",
  "AVDIO",
  "AVDIU",
  "AVERZ",
  "AVGIT",
  "AVGUR",
  "AVION",
  "AVIZA",
  "AVIZE",
  "AVIZI",
  "AVIZO",
  "AVIZU",
  "AVLAH",
  "AVLAM",
  "AVRAH",
  "AVRAM",
  "AVŠAH",
  "AVŠAM",
  "AVŠKA",
  "AVŠKE",
  "AVŠKI",
  "AVŠKO",
  "AVTEK",
  "AVTIH",
  "AVTKA",
  "AVTKE",
  "AVTKI",
  "AVTKU",
  "AVTOM",
  "AVTOR",
  "AVTOV",
  "AZIDA",
  "AZIDE",
  "AZIDI",
  "AZIDU",
  "AZIJA",
  "AZIJE",
  "AZIJI",
  "AZIJO",
  "AZILA",
  "AZILE",
  "AZILI",
  "AZILU",
  "AZOIK",
  "AZOLA",
  "AZOLE",
  "AZOLI",
  "AZOLO",
  "AZORE",
  "AZORI",
  "BABAH",
  "BABAM",
  "BABEŽ",
  "BABIC",
  "BABIN",
  "BABJA",
  "BABJE",
  "BABJI",
  "BABJO",
  "BABKA",
  "BABKE",
  "BABKI",
  "BABKO",
  "BABŠE",
  "BABUR",
  "BACEK",
  "BACIL",
  "BACKA",
  "BACKE",
  "BACKI",
  "BACKO",
  "BACKU",
  "BAČKA",
  "BAČKE",
  "BAČKI",
  "BAČKO",
  "BAFTA",
  "BAFTE",
  "BAFTI",
  "BAFTO",
  "BAGEL",
  "BAGER",
  "BAGET",
  "BAGRA",
  "BAGRE",
  "BAGRI",
  "BAGRU",
  "BAHAČ",
  "BAHAJ",
  "BAHAL",
  "BAHAM",
  "BAHAŠ",
  "BAHAT",
  "BAHAV",
  "BAHTA",
  "BAHTE",
  "BAHTI",
  "BAHTU",
  "BAJAJ",
  "BAJAL",
  "BAJAM",
  "BAJAŠ",
  "BAJAT",
  "BAJEN",
  "BAJER",
  "BAJKA",
  "BAJKE",
  "BAJKI",
  "BAJKO",
  "BAJNA",
  "BAJNE",
  "BAJNI",
  "BAJNO",
  "BAJSA",
  "BAJSE",
  "BAJSI",
  "BAJSO",
  "BAJSU",
  "BAJTA",
  "BAJTE",
  "BAJTI",
  "BAJTO",
  "BAJTU",
  "BAJZA",
  "BAJZE",
  "BAJZI",
  "BAJZO",
  "BAKEL",
  "BAKER",
  "BAKIC",
  "BAKLA",
  "BAKLE",
  "BAKLI",
  "BAKLO",
  "BAKRA",
  "BAKRI",
  "BAKRU",
  "BALAD",
  "BALAH",
  "BALAM",
  "BALET",
  "BALIH",
  "BALIN",
  "BALOM",
  "BALON",
  "BALOV",
  "BALSA",
  "BALSE",
  "BALSI",
  "BALSO",
  "BALZA",
  "BALZE",
  "BALZI",
  "BALZO",
  "BAMIJ",
  "BANAN",
  "BANDA",
  "BANDE",
  "BANDI",
  "BANDO",
  "BANDU",
  "BANIC",
  "BANIH",
  "BANJA",
  "BANJE",
  "BANJI",
  "BANJO",
  "BANJU",
  "BANKA",
  "BANKE",
  "BANKI",
  "BANKO",
  "BANOM",
  "BANOV",
  "BANSA",
  "BANSE",
  "BANSI",
  "BANSU",
  "BARAB",
  "BARAK",
  "BARAL",
  "BARAŽ",
  "BARBA",
  "BARBE",
  "BARBI",
  "BARBO",
  "BARDA",
  "BARDE",
  "BARDI",
  "BARDU",
  "BAREČ",
  "BARIH",
  "BARIJ",
  "BARIK",
  "BARIL",
  "BARIM",
  "BARIŠ",
  "BARIT",
  "BARJA",
  "BARJE",
  "BARJI",
  "BARJU",
  "BARKA",
  "BARKE",
  "BARKI",
  "BARKO",
  "BAROK",
  "BAROM",
  "BARON",
  "BAROV",
  "BARVA",
  "BARVE",
  "BARVI",
  "BARVO",
  "BARŽA",
  "BARŽE",
  "BARŽI",
  "BARŽO",
  "BASAJ",
  "BASAL",
  "BASAT",
  "BASEN",
  "BASIH",
  "BASIL",
  "BASNI",
  "BASOM",
  "BASOV",
  "BAŠEM",
  "BAŠEŠ",
  "BAŠKA",
  "BAŠKE",
  "BAŠKI",
  "BAŠKO",
  "BAŠOČ",
  "BATAN",
  "BATAT",
  "BATEK",
  "BATEN",
  "BATIČ",
  "BATIH",
  "BATIK",
  "BATIN",
  "BATKA",
  "BATKE",
  "BATKI",
  "BATKU",
  "BATNA",
  "BATNE",
  "BATNI",
  "BATNO",
  "BATOM",
  "BATOV",
  "BAVČE",
  "BAVČI",
  "BAVEČ",
  "BAVHA",
  "BAVHU",
  "BAVIL",
  "BAVIM",
  "BAVIŠ",
  "BAVIT",
  "BAVKA",
  "BAVTA",
  "BAVTE",
  "BAVTI",
  "BAVTO",
  "BAZAH",
  "BAZAM",
  "BAZAR",
  "BAZEN",
  "BAZNA",
  "BAZNE",
  "BAZNI",
  "BAZNO",
  "BAZUK",
  "BAŽAH",
  "BAŽAM",
  "BDEČA",
  "BDEČE",
  "BDEČI",
  "BDEČO",
  "BDELA",
  "BDELE",
  "BDELI",
  "BDELO",
  "BDENJ",
  "BDETI",
  "BDIJO",
  "BDIMO",
  "BDITA",
  "BDITE",
  "BDIVA",
  "BEBAV",
  "BEBCA",
  "BEBCE",
  "BEBCI",
  "BEBCU",
  "BEBEC",
  "BEBKA",
  "BEBKE",
  "BEBKI",
  "BEBKO",
  "BEBOP",
  "BEDAH",
  "BEDAK",
  "BEDAM",
  "BEDEČ",
  "BEDEL",
  "BEDEN",
  "BEDER",
  "BEDET",
  "BEDIM",
  "BEDIŠ",
  "BEDNA",
  "BEDNE",
  "BEDNI",
  "BEDNO",
  "BEDRA",
  "BEDRC",
  "BEDRI",
  "BEDRN",
  "BEDRO",
  "BEDRU",
  "BEDŽA",
  "BEDŽE",
  "BEDŽI",
  "BEDŽU",
  "BEFEL",
  "BEGAJ",
  "BEGAL",
  "BEGAM",
  "BEGAŠ",
  "BEGAT",
  "BEGAV",
  "BEGIC",
  "BEGIH",
  "BEGIN",
  "BEGOM",
  "BEGOV",
  "BEGUN",
  "BEJBA",
  "BEJBE",
  "BEJBI",
  "BEJBO",
  "BEKAH",
  "BEKAM",
  "BEKIC",
  "BEKON",
  "BELCA",
  "BELCE",
  "BELCI",
  "BELCU",
  "BELEC",
  "BELEČ",
  "BELEH",
  "BELEM",
  "BELEŽ",
  "BELIC",
  "BELIČ",
  "BELIH",
  "BELIL",
  "BELIM",
  "BELIN",
  "BELIŠ",
  "BELIT",
  "BELJO",
  "BELKA",
  "BELKE",
  "BELKI",
  "BELKO",
  "BELMI",
  "BELOB",
  "BELOT",
  "BELUG",
  "BELUH",
  "BELUŠ",
  "BEMIT",
  "BEMTI",
  "BENDA",
  "BENDE",
  "BENDI",
  "BENDU",
  "BENTE",
  "BENTI",
  "BEOMA",
  "BEPOP",
  "BERAČ",
  "BERDA",
  "BERDE",
  "BERDI",
  "BERDO",
  "BEREM",
  "BEREŠ",
  "BERIL",
  "BERIV",
  "BERMA",
  "BERME",
  "BERMI",
  "BERMO",
  "BEROČ",
  "BESED",
  "BESEN",
  "BESIH",
  "BESNA",
  "BESNE",
  "BESNI",
  "BESNO",
  "BESOM",
  "BESOV",
  "BEŠKA",
  "BEŠKE",
  "BEŠKI",
  "BEŠKO",
  "BETAH",
  "BETAM",
  "BETEL",
  "BETEV",
  "BETIC",
  "BETIČ",
  "BETIH",
  "BETOM",
  "BETON",
  "BETOV",
  "BETVA",
  "BETVE",
  "BETVI",
  "BETVO",
  "BEVČE",
  "BEVČI",
  "BEVKA",
  "BEVSK",
  "BEZAJ",
  "BEZAL",
  "BEZAM",
  "BEZAŠ",
  "BEZAT",
  "BEZEG",
  "BEZGA",
  "BEZGU",
  "BEŽAL",
  "BEŽAT",
  "BEŽEČ",
  "BEŽEN",
  "BEŽIM",
  "BEŽIŠ",
  "BEŽNA",
  "BEŽNE",
  "BEŽNI",
  "BEŽNO",
  "BIBAH",
  "BIBAM",
  "BIBER",
  "BIBRA",
  "BIBRU",
  "BIČAJ",
  "BIČAL",
  "BIČAM",
  "BIČAN",
  "BIČAR",
  "BIČAŠ",
  "BIČAT",
  "BIČEK",
  "BIČEM",
  "BIČEV",
  "BIČIH",
  "BIČIJ",
  "BIČJA",
  "BIČJE",
  "BIČJI",
  "BIČJO",
  "BIČJU",
  "BIČKA",
  "BIČKE",
  "BIČKI",
  "BIČKU",
  "BIDON",
  "BIFOR",
  "BIGAH",
  "BIGAM",
  "BIJEM",
  "BIJEŠ",
  "BIJMO",
  "BIJOČ",
  "BIJTA",
  "BIJTE",
  "BIJVA",
  "BIKCA",
  "BIKCE",
  "BIKCI",
  "BIKCU",
  "BIKEC",
  "BIKER",
  "BIKIC",
  "BIKIH",
  "BIKOM",
  "BIKOV",
  "BIKSA",
  "BILEN",
  "BILJA",
  "BILJE",
  "BILJU",
  "BILKA",
  "BILKE",
  "BILKI",
  "BILKO",
  "BILNA",
  "BILNE",
  "BILNI",
  "BILNO",
  "BIMBA",
  "BIMBE",
  "BIMBI",
  "BIMBO",
  "BIMBU",
  "BINGA",
  "BINGE",
  "BINGI",
  "BINGO",
  "BINGU",
  "BINOM",
  "BIOMA",
  "BIOME",
  "BIOMI",
  "BIOMU",
  "BIREM",
  "BIREN",
  "BIRET",
  "BIRIČ",
  "BIRMA",
  "BIRME",
  "BIRMI",
  "BIRMO",
  "BIRNA",
  "BIRNE",
  "BIRNI",
  "BIRNO",
  "BIRNU",
  "BIRTA",
  "BIRTE",
  "BIRTI",
  "BIRTU",
  "BISAG",
  "BISER",
  "BISTA",
  "BISTE",
  "BISTI",
  "BISTO",
  "BISUS",
  "BIŠKA",
  "BIŠKE",
  "BIŠKI",
  "BIŠKO",
  "BITEM",
  "BITEN",
  "BITEV",
  "BITIH",
  "BITIJ",
  "BITIM",
  "BITJA",
  "BITJE",
  "BITJI",
  "BITJO",
  "BITJU",
  "BITKA",
  "BITKE",
  "BITKI",
  "BITKO",
  "BITMA",
  "BITMI",
  "BITNA",
  "BITNE",
  "BITNI",
  "BITNO",
  "BITOM",
  "BITOV",
  "BITVA",
  "BITVE",
  "BITVI",
  "BITVO",
  "BIVAJ",
  "BIVAK",
  "BIVAL",
  "BIVAM",
  "BIVAŠ",
  "BIVAT",
  "BIVOL",
  "BIVŠA",
  "BIVŠE",
  "BIVŠI",
  "BIVŠO",
  "BIZON",
  "BJOND",
  "BLAGA",
  "BLAGE",
  "BLAGI",
  "BLAGO",
  "BLAGU",
  "BLAST",
  "BLATA",
  "BLATI",
  "BLATO",
  "BLATU",
  "BLAŽE",
  "BLAŽI",
  "BLEDA",
  "BLEDE",
  "BLEDI",
  "BLEDO",
  "BLEFA",
  "BLEFE",
  "BLEFI",
  "BLEFU",
  "BLEJA",
  "BLEJE",
  "BLEKA",
  "BLEND",
  "BLESK",
  "BLEST",
  "BLIDA",
  "BLIDE",
  "BLIDI",
  "BLIDO",
  "BLISK",
  "BLIŠČ",
  "BLIZU",
  "BLIŽA",
  "BLIŽE",
  "BLJAK",
  "BLJUJ",
  "BLODI",
  "BLOGA",
  "BLOGE",
  "BLOGI",
  "BLOGU",
  "BLOKA",
  "BLOKE",
  "BLOKI",
  "BLOKU",
  "BLOND",
  "BLUŠČ",
  "BLUZA",
  "BLUZE",
  "BLUZI",
  "BLUZO",
  "BLUZU",
  "BOAMA",
  "BOAMI",
  "BOBAK",
  "BOBEK",
  "BOBEN",
  "BOBER",
  "BOBIH",
  "BOBKA",
  "BOBKE",
  "BOBKI",
  "BOBKU",
  "BOBNA",
  "BOBNE",
  "BOBNI",
  "BOBNU",
  "BOBOM",
  "BOBOV",
  "BOBRA",
  "BOBRE",
  "BOBRI",
  "BOBRU",
  "BOČEČ",
  "BOČEM",
  "BOČEN",
  "BOČIL",
  "BOČIM",
  "BOČIŠ",
  "BOČIT",
  "BOČNA",
  "BOČNE",
  "BOČNI",
  "BOČNO",
  "BODAK",
  "BODAL",
  "BODEČ",
  "BODEL",
  "BODEM",
  "BODEN",
  "BODER",
  "BODEŠ",
  "BODEŽ",
  "BODIC",
  "BODIČ",
  "BODIK",
  "BODIL",
  "BODLA",
  "BODLE",
  "BODLI",
  "BODLO",
  "BODNA",
  "BODNE",
  "BODNI",
  "BODNO",
  "BODOČ",
  "BODRA",
  "BODRE",
  "BODRI",
  "BODRO",
  "BOEMA",
  "BOEME",
  "BOEMI",
  "BOEMK",
  "BOEMU",
  "BOFOR",
  "BOGAT",
  "BOGCA",
  "BOGCE",
  "BOGCI",
  "BOGCU",
  "BOGEC",
  "BOGEK",
  "BOGEM",
  "BOGIH",
  "BOGIM",
  "BOGKA",
  "BOGKE",
  "BOGKI",
  "BOGKU",
  "BOGOM",
  "BOGOV",
  "BOHOR",
  "BOHOT",
  "BOJAH",
  "BOJAM",
  "BOJAR",
  "BOJDA",
  "BOJEČ",
  "BOJEM",
  "BOJEN",
  "BOJEV",
  "BOJIH",
  "BOJIM",
  "BOJIŠ",
  "BOJMO",
  "BOJNA",
  "BOJNE",
  "BOJNI",
  "BOJNO",
  "BOJTA",
  "BOJTE",
  "BOJUJ",
  "BOJVA",
  "BOKAJ",
  "BOKAL",
  "BOKAM",
  "BOKAN",
  "BOKAŠ",
  "BOKAT",
  "BOKIH",
  "BOKOM",
  "BOKOV",
  "BOKSA",
  "BOKSE",
  "BOKSI",
  "BOKSU",
  "BOLAN",
  "BOLEČ",
  "BOLEH",
  "BOLEL",
  "BOLEM",
  "BOLEN",
  "BOLET",
  "BOLHA",
  "BOLHE",
  "BOLHI",
  "BOLHO",
  "BOLID",
  "BOLIM",
  "BOLIŠ",
  "BOLJE",
  "BOLJK",
  "BOLJO",
  "BOLMI",
  "BOLNA",
  "BOLNE",
  "BOLNI",
  "BOLNO",
  "BOLUJ",
  "BOLUS",
  "BOMBA",
  "BOMBE",
  "BOMBI",
  "BOMBO",
  "BONCA",
  "BONCE",
  "BONCI",
  "BONCU",
  "BONEC",
  "BONGA",
  "BONGE",
  "BONGI",
  "BONGO",
  "BONGU",
  "BONIH",
  "BONIT",
  "BONOM",
  "BONOV",
  "BONUS",
  "BORAG",
  "BORAT",
  "BORBA",
  "BORBE",
  "BORBI",
  "BORBO",
  "BORCA",
  "BORCE",
  "BORCI",
  "BORCU",
  "BORDA",
  "BORDE",
  "BORDI",
  "BORDO",
  "BORDU",
  "BOREC",
  "BOREČ",
  "BORED",
  "BOREM",
  "BOREN",
  "BORIH",
  "BORIL",
  "BORIM",
  "BORIŠ",
  "BORIT",
  "BORKA",
  "BORKE",
  "BORKI",
  "BORKO",
  "BORLA",
  "BORLU",
  "BORNA",
  "BORNE",
  "BORNI",
  "BORNO",
  "BOROM",
  "BOROV",
  "BORŠA",
  "BORŠČ",
  "BORŠE",
  "BORŠI",
  "BORŠO",
  "BORŠT",
  "BORZA",
  "BORZE",
  "BORZI",
  "BORZO",
  "BOSAJ",
  "BOSAL",
  "BOSAM",
  "BOSAŠ",
  "BOSAT",
  "BOSEM",
  "BOSIH",
  "BOSIM",
  "BOSNA",
  "BOSNE",
  "BOSNI",
  "BOSNO",
  "BOSTA",
  "BOSTE",
  "BOSTI",
  "BOŠKA",
  "BOŠKE",
  "BOŠKI",
  "BOŠKO",
  "BOTER",
  "BOTIH",
  "BOTOM",
  "BOTOV",
  "BOTRA",
  "BOTRE",
  "BOTRI",
  "BOTRO",
  "BOTRU",
  "BOVIN",
  "BOVLA",
  "BOVLE",
  "BOVLI",
  "BOVLO",
  "BOZON",
  "BOŽAJ",
  "BOŽAL",
  "BOŽAM",
  "BOŽAŠ",
  "BOŽAT",
  "BOŽCA",
  "BOŽCE",
  "BOŽCI",
  "BOŽCO",
  "BOŽCU",
  "BOŽEC",
  "BOŽIC",
  "BOŽIČ",
  "BOŽJA",
  "BOŽJE",
  "BOŽJI",
  "BOŽJO",
  "BOŽKA",
  "BOŽNA",
  "BOŽNE",
  "BOŽNI",
  "BOŽNO",
  "BRADA",
  "BRADE",
  "BRADI",
  "BRADO",
  "BRAJD",
  "BRAKA",
  "BRAKE",
  "BRAKI",
  "BRAKO",
  "BRAKU",
  "BRALA",
  "BRALE",
  "BRALI",
  "BRALK",
  "BRALO",
  "BRAMB",
  "BRANA",
  "BRANE",
  "BRANI",
  "BRANJ",
  "BRANO",
  "BRANU",
  "BRANŽ",
  "BRATA",
  "BRATE",
  "BRATI",
  "BRATU",
  "BRAVO",
  "BRAZD",
  "BRBER",
  "BRBOT",
  "BRBRA",
  "BRBRE",
  "BRBRI",
  "BRBRO",
  "BRCAH",
  "BRCAJ",
  "BRCAK",
  "BRCAL",
  "BRCAM",
  "BRCAŠ",
  "BRCAT",
  "BRCNE",
  "BRCNI",
  "BRČIC",
  "BRDEK",
  "BRDEM",
  "BRDIH",
  "BRDKA",
  "BRDKE",
  "BRDKI",
  "BRDKO",
  "BRDOM",
  "BREAK",
  "BREČA",
  "BREČE",
  "BREČI",
  "BREČO",
  "BREDA",
  "BREDE",
  "BREDI",
  "BREDO",
  "BREGA",
  "BREGE",
  "BREGI",
  "BREGU",
  "BREJA",
  "BREJE",
  "BREJI",
  "BREJK",
  "BREJO",
  "BREKA",
  "BREKE",
  "BREKI",
  "BREKO",
  "BREKU",
  "BREME",
  "BREMZ",
  "BRENT",
  "BREST",
  "BREZA",
  "BREZE",
  "BREZI",
  "BREZO",
  "BRGEŠ",
  "BRHEK",
  "BRHKA",
  "BRHKE",
  "BRHKI",
  "BRHKO",
  "BRIDŽ",
  "BRIGA",
  "BRIGE",
  "BRIGI",
  "BRIGO",
  "BRIGU",
  "BRIJE",
  "BRIKA",
  "BRIKE",
  "BRIKI",
  "BRIKU",
  "BRILA",
  "BRILE",
  "BRILI",
  "BRILO",
  "BRINA",
  "BRINE",
  "BRINI",
  "BRINU",
  "BRIOŠ",
  "BRISA",
  "BRISE",
  "BRISI",
  "BRISU",
  "BRIŠE",
  "BRIŠI",
  "BRITI",
  "BRIVK",
  "BRIZG",
  "BRKAČ",
  "BRKAT",
  "BRKIH",
  "BRKOM",
  "BRKOV",
  "BRLEČ",
  "BRLEL",
  "BRLET",
  "BRLIM",
  "BRLIŠ",
  "BRLOG",
  "BRNEČ",
  "BRNEL",
  "BRNET",
  "BRNIM",
  "BRNIŠ",
  "BROČA",
  "BROČE",
  "BROČI",
  "BROČU",
  "BRODA",
  "BRODE",
  "BRODI",
  "BRODU",
  "BROMA",
  "BROMU",
  "BRONA",
  "BRONE",
  "BRONI",
  "BRONU",
  "BROŠA",
  "BROŠČ",
  "BROŠE",
  "BROŠI",
  "BROŠK",
  "BROŠO",
  "BROZG",
  "BRSKA",
  "BRSKE",
  "BRSKI",
  "BRSKO",
  "BRSTA",
  "BRSTE",
  "BRSTI",
  "BRSTU",
  "BRŠKA",
  "BRŠKE",
  "BRŠKI",
  "BRŠKO",
  "BRUCA",
  "BRUCE",
  "BRUCI",
  "BRUCK",
  "BRUCU",
  "BRUHA",
  "BRUNA",
  "BRUNI",
  "BRUNO",
  "BRUNU",
  "BRUSA",
  "BRUSE",
  "BRUSI",
  "BRUSU",
  "BRUTO",
  "BRVEH",
  "BRVEM",
  "BRVJO",
  "BRVMI",
  "BRZCA",
  "BRZCE",
  "BRZCI",
  "BRZCU",
  "BRZDA",
  "BRZDE",
  "BRZDI",
  "BRZDO",
  "BRZEC",
  "BRZEČ",
  "BRZEL",
  "BRZEM",
  "BRZIC",
  "BRZIH",
  "BRZIM",
  "BRZIN",
  "BRZIŠ",
  "BRŽDA",
  "BRŽOL",
  "BUBAH",
  "BUBAM",
  "BUBON",
  "BUCIK",
  "BUCNE",
  "BUCNI",
  "BUČAH",
  "BUČAL",
  "BUČAM",
  "BUČAT",
  "BUČEČ",
  "BUČEK",
  "BUČEN",
  "BUČIC",
  "BUČIM",
  "BUČIŠ",
  "BUČKA",
  "BUČKE",
  "BUČKI",
  "BUČKO",
  "BUČKU",
  "BUČNA",
  "BUČNE",
  "BUČNI",
  "BUČNO",
  "BUDAL",
  "BUDEN",
  "BUDER",
  "BUDIL",
  "BUDIM",
  "BUDIŠ",
  "BUDIT",
  "BUDNA",
  "BUDNE",
  "BUDNI",
  "BUDNO",
  "BUDRA",
  "BUDRE",
  "BUDRI",
  "BUDRO",
  "BUGIH",
  "BUGOM",
  "BUGOV",
  "BUHNE",
  "BUHNI",
  "BUHTE",
  "BUHTI",
  "BUJEN",
  "BUJNA",
  "BUJNE",
  "BUJNI",
  "BUJNO",
  "BUJON",
  "BUKAČ",
  "BUKEL",
  "BUKET",
  "BUKEV",
  "BUKLA",
  "BUKLE",
  "BUKLI",
  "BUKLO",
  "BUKOV",
  "BUKVA",
  "BUKVE",
  "BUKVI",
  "BUKVO",
  "BULAH",
  "BULAM",
  "BULAV",
  "BULER",
  "BULIC",
  "BULJI",
  "BUMIH",
  "BUMOM",
  "BUMOV",
  "BUNDA",
  "BUNDE",
  "BUNDI",
  "BUNDO",
  "BUNEČ",
  "BUNIK",
  "BUNIL",
  "BUNIM",
  "BUNIŠ",
  "BUNKA",
  "BUNKE",
  "BUNKI",
  "BUNKO",
  "BUREK",
  "BUREN",
  "BURET",
  "BURIJ",
  "BURIL",
  "BURIM",
  "BURIN",
  "BURIŠ",
  "BURIT",
  "BURJA",
  "BURJE",
  "BURJI",
  "BURJO",
  "BURKA",
  "BURKE",
  "BURKI",
  "BURKO",
  "BURMA",
  "BURME",
  "BURMI",
  "BURMO",
  "BURNA",
  "BURNE",
  "BURNI",
  "BURNO",
  "BURSA",
  "BURSE",
  "BURSI",
  "BURSO",
  "BURŠA",
  "BURŠE",
  "BURŠI",
  "BURŠU",
  "BURZA",
  "BURZE",
  "BURZI",
  "BURZO",
  "BUSIH",
  "BUSOL",
  "BUSOM",
  "BUSOV",
  "BUŠKA",
  "BUŠKE",
  "BUŠKI",
  "BUŠKO",
  "BUŠNE",
  "BUŠNI",
  "BUTAJ",
  "BUTAL",
  "BUTAM",
  "BUTAN",
  "BUTAR",
  "BUTAŠ",
  "BUTAT",
  "BUTCA",
  "BUTCE",
  "BUTCI",
  "BUTCU",
  "BUTEC",
  "BUTIC",
  "BUTIK",
  "BUTIL",
  "BUTNE",
  "BUTNI",
  "BUTOM",
  "BZIKA",
  "BZIKE",
  "BZIKI",
  "BZIKU",
  "CAGAJ",
  "CAGAL",
  "CAGAM",
  "CAGAŠ",
  "CAGAV",
  "CAHEN",
  "CAHNA",
  "CAHNE",
  "CAHNI",
  "CAHNU",
  "CAJGA",
  "CAJGU",
  "CAJNA",
  "CAJNE",
  "CAJNI",
  "CAJNO",
  "CAJTA",
  "CAJTE",
  "CAJTI",
  "CAJTU",
  "CAKAH",
  "CAKAM",
  "CAKIH",
  "CAKOM",
  "CAKOV",
  "CANGE",
  "CAPAH",
  "CAPAJ",
  "CAPAL",
  "CAPAM",
  "CAPAŠ",
  "CAPAT",
  "CAPIN",
  "CAPKA",
  "CARAR",
  "CARIC",
  "CARIN",
  "CARJA",
  "CARJE",
  "CARJI",
  "CARJU",
  "CARTA",
  "CEDEČ",
  "CEDER",
  "CEDIK",
  "CEDIL",
  "CEDIM",
  "CEDIŠ",
  "CEDIT",
  "CEDRA",
  "CEDRE",
  "CEDRI",
  "CEDRO",
  "CEFIR",
  "CEFRA",
  "CEGEL",
  "CEGLA",
  "CEGLE",
  "CEGLI",
  "CEGLU",
  "CEHIH",
  "CEHOM",
  "CEHOV",
  "CEJEN",
  "CEKAR",
  "CEKAS",
  "CEKIN",
  "CEKUM",
  "CELCA",
  "CELCU",
  "CELEC",
  "CELEČ",
  "CELEK",
  "CELEM",
  "CELIC",
  "CELIH",
  "CELIL",
  "CELIM",
  "CELIN",
  "CELIŠ",
  "CELIT",
  "CELKA",
  "CELKE",
  "CELKI",
  "CELKU",
  "CELOM",
  "CELOT",
  "CENAH",
  "CENAM",
  "CENEČ",
  "CENEN",
  "CENIK",
  "CENIL",
  "CENIM",
  "CENIŠ",
  "CENIT",
  "CENTA",
  "CENTE",
  "CENTI",
  "CENTU",
  "CEPAJ",
  "CEPAL",
  "CEPAM",
  "CEPAŠ",
  "CEPAT",
  "CEPCA",
  "CEPCE",
  "CEPCI",
  "CEPCU",
  "CEPEC",
  "CEPEČ",
  "CEPEK",
  "CEPET",
  "CEPIČ",
  "CEPIL",
  "CEPIM",
  "CEPIN",
  "CEPIŠ",
  "CEPIT",
  "CEPIV",
  "CEPKA",
  "CEPKE",
  "CEPKI",
  "CEPKO",
  "CEPKU",
  "CEPNE",
  "CEPNI",
  "CERAD",
  "CERIH",
  "CERIJ",
  "CERJA",
  "CERJE",
  "CERJU",
  "CEROM",
  "CEROV",
  "CESAR",
  "CESIJ",
  "CESTA",
  "CESTE",
  "CESTI",
  "CESTO",
  "CEŠKA",
  "CEŠKE",
  "CEŠKI",
  "CEŠKO",
  "CETAN",
  "CEVAČ",
  "CEVAR",
  "CEVEH",
  "CEVEM",
  "CEVEN",
  "CEVJA",
  "CEVJE",
  "CEVJO",
  "CEVJU",
  "CEVKA",
  "CEVKE",
  "CEVKI",
  "CEVKO",
  "CEVMI",
  "CEVNA",
  "CEVNE",
  "CEVNI",
  "CEVNO",
  "CEZEČ",
  "CEZEL",
  "CEZET",
  "CEZIJ",
  "CEZIM",
  "CEZIŠ",
  "CEZUR",
  "CHATA",
  "CHATE",
  "CHATI",
  "CHATU",
  "CHEFA",
  "CHEFE",
  "CHEFI",
  "CHEFU",
  "CIBET",
  "CIBOR",
  "CIFER",
  "CIFRA",
  "CIFRE",
  "CIFRI",
  "CIFRO",
  "CIGAN",
  "CIGAR",
  "CIKAD",
  "CIKAJ",
  "CIKAL",
  "CIKAM",
  "CIKAŠ",
  "CIKEL",
  "CIKLA",
  "CIKLE",
  "CIKLI",
  "CIKLU",
  "CIKNE",
  "CIKNI",
  "CIKOM",
  "CILIJ",
  "CILJA",
  "CILJE",
  "CILJI",
  "CILJU",
  "CIMAH",
  "CIMAM",
  "CIMAZ",
  "CIMER",
  "CIMET",
  "CIMIL",
  "CIMIM",
  "CIMIR",
  "CIMIŠ",
  "CIMIT",
  "CIMRA",
  "CIMRE",
  "CIMRI",
  "CIMRO",
  "CIMRU",
  "CINAH",
  "CINAM",
  "CINCA",
  "CINEČ",
  "CINIJ",
  "CINIK",
  "CINIL",
  "CINIM",
  "CINIŠ",
  "CINIT",
  "CINKA",
  "CINKU",
  "CINOM",
  "CIPAH",
  "CIPAM",
  "CIPAR",
  "CIPER",
  "CIPIC",
  "CIPRA",
  "CIPRU",
  "CIRKA",
  "CIROZ",
  "CIRUS",
  "CISTA",
  "CISTE",
  "CISTI",
  "CISTO",
  "CITAT",
  "CITER",
  "CITRA",
  "CITRE",
  "CIVET",
  "CIVIL",
  "CIZAH",
  "CIZAM",
  "CIZEK",
  "CIZKA",
  "CIZKE",
  "CIZKI",
  "CIZKU",
  "CMAKA",
  "CMARE",
  "CMARI",
  "CMERA",
  "CMERE",
  "CMERI",
  "CMERO",
  "CMIHA",
  "CMIRA",
  "CMIRU",
  "CMOKA",
  "CMOKE",
  "CMOKI",
  "CMOKU",
  "CMRKA",
  "CMRKE",
  "CMRKI",
  "CMRKU",
  "COACH",
  "COFEK",
  "COFIH",
  "COFKA",
  "COFKE",
  "COFKI",
  "COFKU",
  "COFOM",
  "COFOV",
  "COKEL",
  "COKLA",
  "COKLE",
  "COKLI",
  "COKLO",
  "COKLU",
  "COLAH",
  "COLAM",
  "CONAH",
  "CONAM",
  "CONTE",
  "COPAT",
  "COPOT",
  "COPRA",
  "COTAH",
  "COTAM",
  "COVID",
  "CRKNE",
  "CRKNI",
  "CRKUJ",
  "CUCAL",
  "CUCEK",
  "CUCKA",
  "CUCKE",
  "CUCKI",
  "CUCKU",
  "CUFAJ",
  "CUFAL",
  "CUFAM",
  "CUFAŠ",
  "CUFAT",
  "CUKAJ",
  "CUKAL",
  "CUKAM",
  "CUKAŠ",
  "CUKER",
  "CUKNE",
  "CUKNI",
  "CUKRA",
  "CUKRU",
  "CULAH",
  "CULAM",
  "CULIC",
  "CUNJA",
  "CUNJE",
  "CUNJI",
  "CUNJO",
  "CUREČ",
  "CUREK",
  "CUREL",
  "CURET",
  "CURIM",
  "CURIŠ",
  "CURKA",
  "CURKE",
  "CURKI",
  "CURKU",
  "CUZAJ",
  "CUZAL",
  "CUZAM",
  "CUZAŠ",
  "CUZAT",
  "CVEKA",
  "CVEKE",
  "CVEKI",
  "CVEKU",
  "CVENK",
  "CVEST",
  "CVETA",
  "CVETE",
  "CVETI",
  "CVETK",
  "CVETO",
  "CVETU",
  "CVIKA",
  "CVIKU",
  "CVILI",
  "CVRČE",
  "CVRČI",
  "CVREM",
  "CVREŠ",
  "CVRET",
  "CVRKA",
  "CVRLA",
  "CVRLE",
  "CVRLI",
  "CVRLO",
  "CVROČ",
  "CVRTA",
  "CVRTE",
  "CVRTI",
  "CVRTO",
  "ČABAT",
  "ČAČAH",
  "ČAČAM",
  "ČAČKA",
  "ČAČKE",
  "ČAČKI",
  "ČAČKO",
  "ČADAV",
  "ČADOM",
  "ČADOR",
  "ČAGAH",
  "ČAGAM",
  "ČAJEM",
  "ČAJEN",
  "ČAJEV",
  "ČAJIH",
  "ČAJKA",
  "ČAJKE",
  "ČAJKI",
  "ČAJKO",
  "ČAJNA",
  "ČAJNE",
  "ČAJNI",
  "ČAJNO",
  "ČAJOT",
  "ČAKAH",
  "ČAKAJ",
  "ČAKAL",
  "ČAKAM",
  "ČAKAŠ",
  "ČAKAT",
  "ČAKEM",
  "ČAKER",
  "ČAKMA",
  "ČAKME",
  "ČAKMI",
  "ČAKMO",
  "ČAKRA",
  "ČAKRE",
  "ČAKRI",
  "ČAKRO",
  "ČAPCA",
  "ČAPCE",
  "ČAPCI",
  "ČAPCU",
  "ČAPEC",
  "ČAPKA",
  "ČAPKE",
  "ČAPKI",
  "ČAPKO",
  "ČARAJ",
  "ČARAL",
  "ČARAM",
  "ČARAŠ",
  "ČARAT",
  "ČAREN",
  "ČARIH",
  "ČARIL",
  "ČARNA",
  "ČARNE",
  "ČARNI",
  "ČARNO",
  "ČAROM",
  "ČAROV",
  "ČASEK",
  "ČASEN",
  "ČASIH",
  "ČASKA",
  "ČASKE",
  "ČASKI",
  "ČASKU",
  "ČASNA",
  "ČASNE",
  "ČASNI",
  "ČASNO",
  "ČASOM",
  "ČASOV",
  "ČASTI",
  "ČAŠAH",
  "ČAŠAM",
  "ČAŠEN",
  "ČAŠIC",
  "ČAŠNA",
  "ČAŠNE",
  "ČAŠNI",
  "ČAŠNO",
  "ČATEŽ",
  "ČAVEN",
  "ČAVNA",
  "ČAVNU",
  "ČEBEL",
  "ČEBER",
  "ČEBRA",
  "ČEBRE",
  "ČEBRI",
  "ČEBRU",
  "ČEBUL",
  "ČEČKA",
  "ČEDAR",
  "ČEDEČ",
  "ČEDEN",
  "ČEDER",
  "ČEDIL",
  "ČEDIM",
  "ČEDIŠ",
  "ČEDIT",
  "ČEDNA",
  "ČEDNE",
  "ČEDNI",
  "ČEDNO",
  "ČEDRA",
  "ČEDRE",
  "ČEDRI",
  "ČEDRN",
  "ČEDRO",
  "ČEFUR",
  "ČEKAN",
  "ČEKIH",
  "ČEKOM",
  "ČEKOV",
  "ČELAD",
  "ČELAT",
  "ČELEN",
  "ČELIH",
  "ČELNA",
  "ČELNE",
  "ČELNI",
  "ČELNO",
  "ČELOM",
  "ČELOV",
  "ČEMAŽ",
  "ČEMEČ",
  "ČEMEL",
  "ČEMER",
  "ČEMET",
  "ČEMIM",
  "ČEMIŠ",
  "ČEMUR",
  "ČENČA",
  "ČENČE",
  "ČENČI",
  "ČENČO",
  "ČEPAH",
  "ČEPAM",
  "ČEPAR",
  "ČEPEČ",
  "ČEPEK",
  "ČEPEL",
  "ČEPEN",
  "ČEPET",
  "ČEPIC",
  "ČEPIH",
  "ČEPIM",
  "ČEPIŠ",
  "ČEPKA",
  "ČEPKE",
  "ČEPKI",
  "ČEPKU",
  "ČEPNA",
  "ČEPNE",
  "ČEPNI",
  "ČEPNO",
  "ČEPOM",
  "ČEPOV",
  "ČEREH",
  "ČEREM",
  "ČEREN",
  "ČERJO",
  "ČERMI",
  "ČESAL",
  "ČESAN",
  "ČESAR",
  "ČESAT",
  "ČESEN",
  "ČESNA",
  "ČESNE",
  "ČESNI",
  "ČESNU",
  "ČESTA",
  "ČESTE",
  "ČESTI",
  "ČESTO",
  "ČEŠEM",
  "ČEŠEŠ",
  "ČEŠKA",
  "ČEŠKE",
  "ČEŠKI",
  "ČEŠKO",
  "ČEŠOČ",
  "ČETAH",
  "ČETAM",
  "ČETEN",
  "ČETIC",
  "ČETNA",
  "ČETNE",
  "ČETNI",
  "ČETNO",
  "ČETRT",
  "ČIBUK",
  "ČIČAH",
  "ČIČAJ",
  "ČIČAL",
  "ČIČAM",
  "ČIČAŠ",
  "ČIČAT",
  "ČIČEK",
  "ČIČKA",
  "ČIČKE",
  "ČIČKI",
  "ČIČKU",
  "ČIGAR",
  "ČIGAV",
  "ČIGER",
  "ČIGRA",
  "ČIGRE",
  "ČIGRI",
  "ČIGRO",
  "ČIJAH",
  "ČIJAM",
  "ČIJEM",
  "ČIKAR",
  "ČIKIH",
  "ČIKOM",
  "ČIKOV",
  "ČILEM",
  "ČILIH",
  "ČILIM",
  "ČILOM",
  "ČIMER",
  "ČIMŽA",
  "ČIMŽE",
  "ČIMŽI",
  "ČIMŽO",
  "ČINEL",
  "ČINIH",
  "ČINOM",
  "ČINOV",
  "ČIPEN",
  "ČIPIH",
  "ČIPKA",
  "ČIPKE",
  "ČIPKI",
  "ČIPKO",
  "ČIPNA",
  "ČIPNE",
  "ČIPNI",
  "ČIPNO",
  "ČIPOM",
  "ČIPOV",
  "ČIPSA",
  "ČIPSE",
  "ČIPSI",
  "ČIPSU",
  "ČIRAV",
  "ČIRIH",
  "ČIROM",
  "ČIROV",
  "ČISEL",
  "ČISLA",
  "ČISLI",
  "ČISLO",
  "ČISLU",
  "ČISTA",
  "ČISTE",
  "ČISTI",
  "ČISTK",
  "ČISTO",
  "ČIŠČA",
  "ČIŠČE",
  "ČIŠČI",
  "ČIŠČO",
  "ČIŠKA",
  "ČIŠKE",
  "ČIŠKI",
  "ČIŠKO",
  "ČITAJ",
  "ČITAL",
  "ČITAM",
  "ČITAN",
  "ČITAŠ",
  "ČITAT",
  "ČIVAV",
  "ČIVKA",
  "ČIVKE",
  "ČIVKI",
  "ČIVKU",
  "ČIŽEK",
  "ČIŽEM",
  "ČIŽKA",
  "ČIŽKE",
  "ČIŽKI",
  "ČIŽKU",
  "ČIŽMA",
  "ČIŽME",
  "ČIŽMI",
  "ČIŽMU",
  "ČLANA",
  "ČLANE",
  "ČLANI",
  "ČLANU",
  "ČLENA",
  "ČLENE",
  "ČLENI",
  "ČLENU",
  "ČMRLJ",
  "ČOBER",
  "ČOBRA",
  "ČOBRE",
  "ČOBRI",
  "ČOBRU",
  "ČOFAJ",
  "ČOFAL",
  "ČOFAM",
  "ČOFAŠ",
  "ČOFAT",
  "ČOFNE",
  "ČOFNI",
  "ČOHAJ",
  "ČOHAL",
  "ČOHAM",
  "ČOHAŠ",
  "ČOHAT",
  "ČOKAT",
  "ČOKET",
  "ČOKIH",
  "ČOKOM",
  "ČOKOV",
  "ČOLNA",
  "ČOLNE",
  "ČOLNI",
  "ČOLNU",
  "ČOPAR",
  "ČOPEK",
  "ČOPIČ",
  "ČOPIH",
  "ČOPKA",
  "ČOPKE",
  "ČOPKI",
  "ČOPKO",
  "ČOPKU",
  "ČOPOM",
  "ČOPOV",
  "ČORAV",
  "ČORBA",
  "ČORBE",
  "ČORBI",
  "ČORBO",
  "ČREDA",
  "ČREDE",
  "ČREDI",
  "ČREDO",
  "ČREMS",
  "ČRETA",
  "ČRETE",
  "ČRETI",
  "ČRETU",
  "ČREVA",
  "ČREVC",
  "ČREVI",
  "ČREVO",
  "ČRHNE",
  "ČRHNI",
  "ČRIČE",
  "ČRIČI",
  "ČRIKA",
  "ČRKAH",
  "ČRKAM",
  "ČRKIC",
  "ČRKIJ",
  "ČRKJA",
  "ČRKJE",
  "ČRKJI",
  "ČRKJU",
  "ČRKUJ",
  "ČRNAV",
  "ČRNCA",
  "ČRNCE",
  "ČRNCI",
  "ČRNCU",
  "ČRNČK",
  "ČRNEC",
  "ČRNEČ",
  "ČRNEL",
  "ČRNEM",
  "ČRNET",
  "ČRNIC",
  "ČRNIH",
  "ČRNIK",
  "ČRNIL",
  "ČRNIM",
  "ČRNIN",
  "ČRNIŠ",
  "ČRNIT",
  "ČRNJO",
  "ČRNKA",
  "ČRNKE",
  "ČRNKI",
  "ČRNKO",
  "ČRNOB",
  "ČRNOG",
  "ČRNUH",
  "ČRPAJ",
  "ČRPAL",
  "ČRPAM",
  "ČRPAN",
  "ČRPAŠ",
  "ČRPAT",
  "ČRTAH",
  "ČRTAJ",
  "ČRTAL",
  "ČRTAM",
  "ČRTAN",
  "ČRTAŠ",
  "ČRTAT",
  "ČRTEČ",
  "ČRTEN",
  "ČRTEŽ",
  "ČRTIC",
  "ČRTIL",
  "ČRTIM",
  "ČRTIŠ",
  "ČRTIT",
  "ČRTKA",
  "ČRTKE",
  "ČRTKI",
  "ČRTKO",
  "ČRTNA",
  "ČRTNE",
  "ČRTNI",
  "ČRTNO",
  "ČRTUH",
  "ČRVAR",
  "ČRVIČ",
  "ČRVIH",
  "ČRVIN",
  "ČRVIV",
  "ČRVJA",
  "ČRVJE",
  "ČRVJI",
  "ČRVJO",
  "ČRVOM",
  "ČRVOV",
  "ČTIVA",
  "ČTIVI",
  "ČTIVO",
  "ČTIVU",
  "ČUDAK",
  "ČUDEČ",
  "ČUDEN",
  "ČUDES",
  "ČUDEŽ",
  "ČUDIH",
  "ČUDIL",
  "ČUDIM",
  "ČUDIŠ",
  "ČUDIT",
  "ČUDJO",
  "ČUDMA",
  "ČUDMI",
  "ČUDNA",
  "ČUDNE",
  "ČUDNI",
  "ČUDNO",
  "ČUDOM",
  "ČUFTA",
  "ČUFTE",
  "ČUFTI",
  "ČUFTU",
  "ČUGAH",
  "ČUGAM",
  "ČUJEČ",
  "ČUJEM",
  "ČUJEŠ",
  "ČUJMO",
  "ČUJTA",
  "ČUJTE",
  "ČUJVA",
  "ČUKCA",
  "ČUKCE",
  "ČUKCI",
  "ČUKCU",
  "ČUKEC",
  "ČUKEL",
  "ČUKIH",
  "ČUKLA",
  "ČUKLE",
  "ČUKLI",
  "ČUKLO",
  "ČUKOM",
  "ČUKOV",
  "ČUNJA",
  "ČUNJE",
  "ČUNJI",
  "ČUNJO",
  "ČUPAH",
  "ČUPAM",
  "ČUTAR",
  "ČUTEČ",
  "ČUTEM",
  "ČUTEN",
  "ČUTIH",
  "ČUTIJ",
  "ČUTIL",
  "ČUTIM",
  "ČUTIŠ",
  "ČUTIT",
  "ČUTJA",
  "ČUTJE",
  "ČUTJI",
  "ČUTJU",
  "ČUTNA",
  "ČUTNE",
  "ČUTNI",
  "ČUTNO",
  "ČUTOM",
  "ČUTOV",
  "ČUVAJ",
  "ČUVAL",
  "ČUVAM",
  "ČUVAN",
  "ČUVAR",
  "ČUVAŠ",
  "ČUVAT",
  "ČUZAH",
  "ČUZAM",
  "ČUŽKA",
  "ČUŽKE",
  "ČUŽKI",
  "ČUŽKO",
  "ČVEKA",
  "ČVEKE",
  "ČVEKI",
  "ČVEKU",
  "ČVRČE",
  "ČVRČI",
  "ČVRLE",
  "ČVRLI",
  "ČVRST",
  "ČVRŠA",
  "ČVRŠE",
  "ČVRŠI",
  "ČVRŠU",
  "DACAR",
  "DACIT",
  "DAČAH",
  "DAČAM",
  "DAHIH",
  "DAHIJ",
  "DAHNE",
  "DAHNI",
  "DAHOM",
  "DAHOV",
  "DAJAJ",
  "DAJAL",
  "DAJAN",
  "DAJAT",
  "DAJEM",
  "DAJEŠ",
  "DAJMO",
  "DAJTA",
  "DAJTE",
  "DAJVA",
  "DAKOT",
  "DALEČ",
  "DALIJ",
  "DALJA",
  "DALJE",
  "DALJI",
  "DALJO",
  "DAMAH",
  "DAMAM",
  "DAMAN",
  "DAMIC",
  "DANEČ",
  "DANEM",
  "DANES",
  "DANIH",
  "DANIL",
  "DANIM",
  "DANIŠ",
  "DANIT",
  "DANJA",
  "DANJE",
  "DANJI",
  "DANJO",
  "DANKA",
  "DANKE",
  "DANKI",
  "DANKO",
  "DARIL",
  "DAROM",
  "DAROV",
  "DARUJ",
  "DAŠKA",
  "DAŠKE",
  "DAŠKI",
  "DAŠKO",
  "DATIV",
  "DATUM",
  "DAVEČ",
  "DAVEK",
  "DAVEN",
  "DAVIH",
  "DAVIL",
  "DAVIM",
  "DAVIŠ",
  "DAVIT",
  "DAVKA",
  "DAVKE",
  "DAVKI",
  "DAVKU",
  "DAVNA",
  "DAVNE",
  "DAVNI",
  "DAVNO",
  "DAVOM",
  "DAVOV",
  "DEBAT",
  "DEBEL",
  "DEBER",
  "DEBET",
  "DEBIL",
  "DEBLA",
  "DEBLI",
  "DEBLO",
  "DEBLU",
  "DEBRI",
  "DECEM",
  "DECEV",
  "DECIH",
  "DECIL",
  "DEČEK",
  "DEČEV",
  "DEČJA",
  "DEČJE",
  "DEČJI",
  "DEČJO",
  "DEČKA",
  "DEČKE",
  "DEČKI",
  "DEČKO",
  "DEČKU",
  "DEČVA",
  "DEČVE",
  "DEČVI",
  "DEČVO",
  "DEDAH",
  "DEDAM",
  "DEDCA",
  "DEDCE",
  "DEDCI",
  "DEDCU",
  "DEDEC",
  "DEDEK",
  "DEDEN",
  "DEDIČ",
  "DEDIH",
  "DEDJE",
  "DEDKA",
  "DEDKE",
  "DEDKI",
  "DEDKU",
  "DEDNA",
  "DEDNE",
  "DEDNI",
  "DEDNO",
  "DEDOM",
  "DEDOV",
  "DEDUJ",
  "DEHTI",
  "DEIST",
  "DEJAL",
  "DEJAN",
  "DEJAT",
  "DEJEM",
  "DEJEŠ",
  "DEJMO",
  "DEJTA",
  "DEJTE",
  "DEJVA",
  "DEKAD",
  "DEKAH",
  "DEKAM",
  "DEKAN",
  "DEKEL",
  "DEKIC",
  "DEKLA",
  "DEKLE",
  "DEKLI",
  "DEKLO",
  "DEKOR",
  "DELAJ",
  "DELAL",
  "DELAM",
  "DELAN",
  "DELAŠ",
  "DELAT",
  "DELCA",
  "DELCE",
  "DELCI",
  "DELCU",
  "DELEC",
  "DELEČ",
  "DELEN",
  "DELEŽ",
  "DELIH",
  "DELIL",
  "DELIM",
  "DELIŠ",
  "DELIT",
  "DELNA",
  "DELNE",
  "DELNI",
  "DELNO",
  "DELOM",
  "DELOV",
  "DELTA",
  "DELTE",
  "DELTI",
  "DELTO",
  "DELUJ",
  "DEMIH",
  "DEMOM",
  "DEMON",
  "DEMOV",
  "DENAR",
  "DENEM",
  "DENEŠ",
  "DENGA",
  "DENGE",
  "DENGI",
  "DENGO",
  "DEPER",
  "DEPEŠ",
  "DEPRA",
  "DEPRE",
  "DEPRI",
  "DEPRO",
  "DERAČ",
  "DERAK",
  "DERBI",
  "DEREM",
  "DEREŠ",
  "DEREZ",
  "DEROČ",
  "DERUH",
  "DESAK",
  "DESEN",
  "DESET",
  "DESKA",
  "DESKE",
  "DESKI",
  "DESKO",
  "DESNA",
  "DESNE",
  "DESNI",
  "DESNO",
  "DESTE",
  "DEŠKA",
  "DEŠKE",
  "DEŠKI",
  "DEŠKO",
  "DETEC",
  "DETEL",
  "DETET",
  "DETLA",
  "DETLE",
  "DETLI",
  "DETLU",
  "DEVAH",
  "DEVAJ",
  "DEVAL",
  "DEVAM",
  "DEVAŠ",
  "DEVAT",
  "DEVER",
  "DEVET",
  "DEVIC",
  "DEVIZ",
  "DEVON",
  "DEŽEK",
  "DEŽEL",
  "DEŽEN",
  "DEŽJA",
  "DEŽJE",
  "DEŽJI",
  "DEŽJU",
  "DEŽKA",
  "DEŽKE",
  "DEŽKI",
  "DEŽKU",
  "DEŽNA",
  "DEŽNE",
  "DEŽNI",
  "DEŽNO",
  "DEŽNU",
  "DEŽUJ",
  "DIADA",
  "DIADE",
  "DIADI",
  "DIADO",
  "DIANA",
  "DIANE",
  "DIANI",
  "DIANO",
  "DIASA",
  "DIASE",
  "DIASI",
  "DIASU",
  "DIČEČ",
  "DIČEN",
  "DIČIL",
  "DIČIM",
  "DIČIŠ",
  "DIČIT",
  "DIETA",
  "DIETE",
  "DIETI",
  "DIETO",
  "DIFON",
  "DIHAJ",
  "DIHAL",
  "DIHAM",
  "DIHAŠ",
  "DIHAT",
  "DIHIH",
  "DIHNE",
  "DIHNI",
  "DIHOM",
  "DIHOV",
  "DIHTA",
  "DIHUR",
  "DIJAK",
  "DILAH",
  "DILAJ",
  "DILAL",
  "DILAM",
  "DILAŠ",
  "DILCA",
  "DILDA",
  "DILDE",
  "DILDI",
  "DILDO",
  "DILDU",
  "DILEM",
  "DILER",
  "DIMEČ",
  "DIMEK",
  "DIMEN",
  "DIMER",
  "DIMIH",
  "DIMIJ",
  "DIMIL",
  "DIMIM",
  "DIMIŠ",
  "DIMIT",
  "DIMKA",
  "DIMKE",
  "DIMKI",
  "DIMKU",
  "DIMNA",
  "DIMNE",
  "DIMNI",
  "DIMNO",
  "DIMOM",
  "DIMOV",
  "DINAR",
  "DINGA",
  "DINGE",
  "DINGI",
  "DINGO",
  "DINGU",
  "DINJA",
  "DINJE",
  "DINJI",
  "DINJO",
  "DIODA",
  "DIODE",
  "DIODI",
  "DIODO",
  "DIPEL",
  "DIPLE",
  "DIPOL",
  "DIRAH",
  "DIRAM",
  "DIRJA",
  "DIRKA",
  "DIRKE",
  "DIRKI",
  "DIRKO",
  "DIROM",
  "DISKA",
  "DISKE",
  "DISKI",
  "DISKO",
  "DISKU",
  "DIŠAL",
  "DIŠAT",
  "DIŠAV",
  "DIŠEČ",
  "DIŠIM",
  "DIŠIŠ",
  "DIVAH",
  "DIVAM",
  "DIVAN",
  "DIVJA",
  "DIVJE",
  "DIVJI",
  "DIVJO",
  "DIZEL",
  "DIZLA",
  "DIZLE",
  "DIZLI",
  "DIZLU",
  "DLAKA",
  "DLAKE",
  "DLAKI",
  "DLAKO",
  "DLANI",
  "DLESK",
  "DLETA",
  "DLETC",
  "DLETI",
  "DLETO",
  "DLETU",
  "DNEVA",
  "DNEVI",
  "DNEVU",
  "DNINA",
  "DNINE",
  "DNINI",
  "DNINO",
  "DNIŠČ",
  "DNOMA",
  "DOBAH",
  "DOBAM",
  "DOBAV",
  "DOBER",
  "DOBIH",
  "DOBIL",
  "DOBIM",
  "DOBIŠ",
  "DOBIT",
  "DOBOM",
  "DOBOV",
  "DOBRA",
  "DOBRE",
  "DOBRI",
  "DOBRO",
  "DOČIM",
  "DODAJ",
  "DODAL",
  "DODAM",
  "DODAN",
  "DODAŠ",
  "DODAT",
  "DODIH",
  "DODOM",
  "DODOV",
  "DOGAH",
  "DOGAM",
  "DOGAR",
  "DOGEM",
  "DOGMA",
  "DOGME",
  "DOGMI",
  "DOGMO",
  "DOGON",
  "DOHOD",
  "DOJEČ",
  "DOJEJ",
  "DOJEL",
  "DOJEM",
  "DOJEN",
  "DOJEŠ",
  "DOJET",
  "DOJIL",
  "DOJIM",
  "DOJIŠ",
  "DOJIT",
  "DOJKA",
  "DOJKE",
  "DOJKI",
  "DOJKO",
  "DOKAJ",
  "DOKAZ",
  "DOKIH",
  "DOKOM",
  "DOKOV",
  "DOKUJ",
  "DOKUP",
  "DOLAR",
  "DOLBE",
  "DOLBI",
  "DOLEK",
  "DOLET",
  "DOLGA",
  "DOLGE",
  "DOLGI",
  "DOLGO",
  "DOLGU",
  "DOLIH",
  "DOLIJ",
  "DOLIL",
  "DOLIN",
  "DOLIT",
  "DOLIV",
  "DOLKA",
  "DOLKE",
  "DOLKI",
  "DOLKU",
  "DOLOM",
  "DOLOV",
  "DOLUS",
  "DOLŽE",
  "DOLŽI",
  "DOMAČ",
  "DOMCA",
  "DOMCE",
  "DOMCI",
  "DOMCU",
  "DOMEC",
  "DOMEK",
  "DOMEN",
  "DOMET",
  "DOMIK",
  "DOMIN",
  "DOMKA",
  "DOMKE",
  "DOMKI",
  "DOMKU",
  "DOMOM",
  "DOMOV",
  "DOMUJ",
  "DONAH",
  "DONAM",
  "DONEČ",
  "DONEL",
  "DONET",
  "DONIH",
  "DONIM",
  "DONIŠ",
  "DONOM",
  "DONOR",
  "DONOS",
  "DONOV",
  "DOPEČ",
  "DOPIK",
  "DOPIS",
  "DOREČ",
  "DOREN",
  "DORKA",
  "DORKE",
  "DORKI",
  "DORKO",
  "DORNA",
  "DORNE",
  "DORNI",
  "DORNO",
  "DOSEČ",
  "DOSEG",
  "DOSEJ",
  "DOSJE",
  "DOSPE",
  "DOSPI",
  "DOSTI",
  "DOSUJ",
  "DOSUL",
  "DOSUT",
  "DOŠAH",
  "DOŠAM",
  "DOŠIJ",
  "DOŠIL",
  "DOŠIT",
  "DOŠKA",
  "DOŠKE",
  "DOŠKI",
  "DOŠKO",
  "DOTAH",
  "DOTAM",
  "DOTIC",
  "DOTIK",
  "DOTIS",
  "DOTOK",
  "DOULA",
  "DOULE",
  "DOULI",
  "DOULO",
  "DOUME",
  "DOUMI",
  "DOVOD",
  "DOVOZ",
  "DOZAH",
  "DOZAM",
  "DOZER",
  "DOŽEM",
  "DOŽEN",
  "DOŽEV",
  "DOŽIC",
  "DOŽIH",
  "DOŽNA",
  "DOŽNE",
  "DOŽNI",
  "DOŽNO",
  "DRAGA",
  "DRAGE",
  "DRAGI",
  "DRAGO",
  "DRAMA",
  "DRAME",
  "DRAMI",
  "DRAMO",
  "DRAPE",
  "DRAST",
  "DRATA",
  "DRATE",
  "DRATI",
  "DRATU",
  "DRAVA",
  "DRAVE",
  "DRAVI",
  "DRAVO",
  "DRAŽB",
  "DRAŽE",
  "DRAŽI",
  "DRČAH",
  "DRČAL",
  "DRČAM",
  "DRČAT",
  "DRČAV",
  "DRČEČ",
  "DRČEN",
  "DRČIM",
  "DRČIŠ",
  "DRČNA",
  "DRČNE",
  "DRČNI",
  "DRČNO",
  "DRDRA",
  "DREČK",
  "DREDA",
  "DREDE",
  "DREDI",
  "DREDU",
  "DREGA",
  "DREJA",
  "DREJO",
  "DREKA",
  "DREKE",
  "DREKI",
  "DREKU",
  "DREMA",
  "DREMO",
  "DRENA",
  "DRENE",
  "DRENI",
  "DRENJ",
  "DRENU",
  "DRESA",
  "DRESE",
  "DRESI",
  "DRESU",
  "DRETA",
  "DRETE",
  "DRETI",
  "DRETO",
  "DREVA",
  "DREVC",
  "DREVI",
  "DREVO",
  "DREZA",
  "DRGAL",
  "DRGET",
  "DRGNE",
  "DRGNI",
  "DRHAL",
  "DRHTE",
  "DRHTI",
  "DRIAD",
  "DRIČA",
  "DRIČE",
  "DRIČI",
  "DRIČO",
  "DRILA",
  "DRILE",
  "DRILI",
  "DRILU",
  "DRIMO",
  "DRINK",
  "DRISK",
  "DRITA",
  "DRITE",
  "DRIVA",
  "DRKAČ",
  "DRKAJ",
  "DRKAL",
  "DRKAM",
  "DRKAŠ",
  "DRKAT",
  "DRNCA",
  "DRNCE",
  "DRNCI",
  "DRNCU",
  "DRNEC",
  "DRNIC",
  "DRNIČ",
  "DRNOM",
  "DROBA",
  "DROBE",
  "DROBI",
  "DROBU",
  "DROGA",
  "DROGE",
  "DROGI",
  "DROGO",
  "DROGU",
  "DRONA",
  "DRONE",
  "DRONG",
  "DRONI",
  "DRONT",
  "DRONU",
  "DROZG",
  "DROŽE",
  "DROŽI",
  "DROŽK",
  "DRSAJ",
  "DRSAL",
  "DRSAM",
  "DRSAŠ",
  "DRSAT",
  "DRSEČ",
  "DRSEL",
  "DRSEN",
  "DRSET",
  "DRSIM",
  "DRSIN",
  "DRSIŠ",
  "DRSKA",
  "DRSKE",
  "DRSKI",
  "DRSKO",
  "DRSKU",
  "DRSNA",
  "DRSNE",
  "DRSNI",
  "DRSNO",
  "DRSTI",
  "DRŠKA",
  "DRŠKE",
  "DRŠKI",
  "DRŠKO",
  "DRTIJ",
  "DRTJA",
  "DRTJE",
  "DRTJI",
  "DRTJU",
  "DRUGA",
  "DRUGE",
  "DRUGI",
  "DRUGO",
  "DRUGU",
  "DRUID",
  "DRUZA",
  "DRUZE",
  "DRUZI",
  "DRUZO",
  "DRUŽB",
  "DRUŽI",
  "DRVAČ",
  "DRVAR",
  "DRVCA",
  "DRVCI",
  "DRVEČ",
  "DRVEH",
  "DRVEL",
  "DRVET",
  "DRVIH",
  "DRVIM",
  "DRVIŠ",
  "DRVMI",
  "DRVOM",
  "DRZAJ",
  "DRZAL",
  "DRZAM",
  "DRZAN",
  "DRZAŠ",
  "DRZAT",
  "DRZEK",
  "DRZEN",
  "DRZKA",
  "DRZKE",
  "DRZKI",
  "DRZKO",
  "DRZNA",
  "DRZNE",
  "DRZNI",
  "DRZNO",
  "DRŽAČ",
  "DRŽAH",
  "DRŽAJ",
  "DRŽAL",
  "DRŽAM",
  "DRŽAT",
  "DRŽAV",
  "DRŽEČ",
  "DRŽEK",
  "DRŽIM",
  "DRŽIŠ",
  "DRŽKA",
  "DRŽKE",
  "DRŽKI",
  "DRŽKO",
  "DRŽKU",
  "DUALA",
  "DUALE",
  "DUALI",
  "DUALU",
  "DUBAJ",
  "DUBOM",
  "DUCAT",
  "DUDAČ",
  "DUDAH",
  "DUDAJ",
  "DUDAL",
  "DUDAM",
  "DUDAN",
  "DUDAR",
  "DUDAŠ",
  "DUDAT",
  "DUDIC",
  "DUDKA",
  "DUDKE",
  "DUDKI",
  "DUDKO",
  "DUDLA",
  "DUELA",
  "DUELE",
  "DUELI",
  "DUELU",
  "DUETA",
  "DUETE",
  "DUETI",
  "DUETU",
  "DUHAJ",
  "DUHAL",
  "DUHAM",
  "DUHAŠ",
  "DUHAT",
  "DUHCA",
  "DUHCE",
  "DUHCI",
  "DUHCU",
  "DUHEC",
  "DUHEK",
  "DUHKA",
  "DUHKE",
  "DUHKI",
  "DUHKU",
  "DUHOM",
  "DUHOV",
  "DUHTE",
  "DUHTI",
  "DUKAT",
  "DULAH",
  "DULAM",
  "DULAR",
  "DULCA",
  "DULCE",
  "DULCI",
  "DULCU",
  "DULEC",
  "DUMAH",
  "DUMAM",
  "DUNIT",
  "DUOLA",
  "DUOLE",
  "DUOLI",
  "DUOLO",
  "DUOMA",
  "DUPAT",
  "DUPEL",
  "DUPLA",
  "DUPLI",
  "DUPLO",
  "DUPLU",
  "DURCA",
  "DURCE",
  "DURCI",
  "DURIH",
  "DURIM",
  "DURJA",
  "DURJE",
  "DURJI",
  "DURJO",
  "DURJU",
  "DURMI",
  "DUROM",
  "DUROV",
  "DURUM",
  "DUŠAH",
  "DUŠAM",
  "DUŠEČ",
  "DUŠEK",
  "DUŠEN",
  "DUŠIC",
  "DUŠIK",
  "DUŠIL",
  "DUŠIM",
  "DUŠIŠ",
  "DUŠIT",
  "DUŠKA",
  "DUŠKU",
  "DUŠNA",
  "DUŠNE",
  "DUŠNI",
  "DUŠNO",
  "DVEMA",
  "DVERC",
  "DVERI",
  "DVIGA",
  "DVIGE",
  "DVIGI",
  "DVIGU",
  "DVOJA",
  "DVOJI",
  "DVOJK",
  "DVOJO",
  "DVOMA",
  "DVOME",
  "DVOMI",
  "DVOMU",
  "DVOOK",
  "DVORA",
  "DVORE",
  "DVORI",
  "DVORU",
  "DZETA",
  "DZETE",
  "DZETI",
  "DZETO",
  "DŽABE",
  "DŽAVR",
  "DŽEKA",
  "DŽEKE",
  "DŽEKI",
  "DŽEKU",
  "DŽEMA",
  "DŽEME",
  "DŽEMI",
  "DŽEMU",
  "DŽEZA",
  "DŽEZU",
  "DŽINA",
  "DŽINE",
  "DŽINI",
  "DŽINS",
  "DŽINU",
  "DŽIPA",
  "DŽIPE",
  "DŽIPI",
  "DŽIPU",
  "DŽULA",
  "DŽULE",
  "DŽULI",
  "DŽULU",
  "DŽUNK",
  "DŽUSA",
  "DŽUSE",
  "DŽUSI",
  "DŽUSU",
  "EARLA",
  "EARLE",
  "EARLI",
  "EARLU",
  "EBOLA",
  "EBOLE",
  "EBOLI",
  "EBOLO",
  "EDEMA",
  "EDEME",
  "EDEMI",
  "EDEMU",
  "EDIKT",
  "EDILA",
  "EDILE",
  "EDILI",
  "EDILU",
  "EDINA",
  "EDINE",
  "EDINI",
  "EDINK",
  "EDINO",
  "EFEKT",
  "EFIRA",
  "EFIRE",
  "EFIRI",
  "EFIRO",
  "EFRIN",
  "EGIPT",
  "EGOMA",
  "EKAVK",
  "EKCEM",
  "EKIPA",
  "EKIPE",
  "EKIPI",
  "EKIPO",
  "EKLOG",
  "EKRAN",
  "EKSAJ",
  "EKSAL",
  "EKSAM",
  "EKSAN",
  "EKSAŠ",
  "EKSIH",
  "EKSOM",
  "EKSON",
  "EKSOT",
  "EKSOV",
  "EKTIM",
  "EKUJA",
  "EKUJE",
  "EKUJI",
  "EKUJU",
  "ELANA",
  "ELAND",
  "ELANE",
  "ELANI",
  "ELANK",
  "ELANU",
  "ELEAT",
  "ELFIH",
  "ELFOM",
  "ELFOV",
  "ELINA",
  "ELINE",
  "ELINI",
  "ELINO",
  "ELIPS",
  "ELISA",
  "ELISE",
  "ELISI",
  "ELISO",
  "ELITA",
  "ELITE",
  "ELITI",
  "ELITO",
  "ELKAJ",
  "ELKAL",
  "ELKAM",
  "ELKAŠ",
  "ELKAT",
  "EMAJL",
  "EMPAT",
  "EMPIR",
  "EMPOR",
  "EMŠEM",
  "EMŠEV",
  "EMŠIH",
  "EMUJA",
  "EMUJE",
  "EMUJI",
  "EMUJU",
  "ENAČB",
  "ENAČI",
  "ENAKA",
  "ENAKE",
  "ENAKI",
  "ENAKO",
  "ENCIM",
  "ENEMU",
  "ENGOB",
  "ENICA",
  "ENICE",
  "ENICI",
  "ENICO",
  "ENIMA",
  "ENIMI",
  "ENKAH",
  "ENKAM",
  "ENOJA",
  "ENOJE",
  "ENOJI",
  "ENOJK",
  "ENOJO",
  "ENOKA",
  "ENOKE",
  "ENOKI",
  "ENOKU",
  "ENOLA",
  "ENOLE",
  "ENOLI",
  "ENOLU",
  "ENOOK",
  "ENOST",
  "ENOTA",
  "ENOTE",
  "ENOTI",
  "ENOTO",
  "ENTAZ",
  "ENTER",
  "ENTLA",
  "ENTRA",
  "ENTRE",
  "ENTRI",
  "ENTRU",
  "EOCEN",
  "EONIH",
  "EONOM",
  "EONOV",
  "EOZIN",
  "EPAKT",
  "EPARH",
  "EPIKA",
  "EPIKE",
  "EPIKI",
  "EPIKO",
  "EPIKU",
  "EPIRA",
  "EPOHA",
  "EPOHE",
  "EPOHI",
  "EPOHO",
  "EPOMA",
  "EPONA",
  "EPONU",
  "EPSKA",
  "EPSKE",
  "EPSKI",
  "EPSKO",
  "ERAMA",
  "ERAMI",
  "ERBIJ",
  "ERGIH",
  "ERGOM",
  "ERGOV",
  "EROSA",
  "EROSE",
  "EROSI",
  "EROSU",
  "EROTA",
  "EROTE",
  "EROTI",
  "EROTU",
  "ERRAT",
  "ESAST",
  "ESAUL",
  "ESCIN",
  "ESEJA",
  "ESEJE",
  "ESEJI",
  "ESEJU",
  "ESENA",
  "ESENC",
  "ESENE",
  "ESENI",
  "ESENK",
  "ESENU",
  "ESERK",
  "ESHAR",
  "ESPAD",
  "ESTER",
  "ESTET",
  "ESTRA",
  "ESTRE",
  "ESTRI",
  "ESTRU",
  "EŠARP",
  "ETAMA",
  "ETAMI",
  "ETANA",
  "ETANU",
  "ETAPA",
  "ETAPE",
  "ETAPI",
  "ETAPO",
  "ETATA",
  "ETATE",
  "ETATI",
  "ETATU",
  "ETAŽA",
  "ETAŽE",
  "ETAŽI",
  "ETAŽO",
  "ETENA",
  "ETENU",
  "ETHER",
  "ETIKA",
  "ETIKE",
  "ETIKI",
  "ETIKO",
  "ETIKU",
  "ETILA",
  "ETILE",
  "ETILI",
  "ETILU",
  "ETINA",
  "ETINU",
  "ETNIJ",
  "ETNOM",
  "ETOSA",
  "ETOSE",
  "ETOSI",
  "ETOSU",
  "ETRIH",
  "ETROM",
  "ETROV",
  "ETRUS",
  "ETUDA",
  "ETUDE",
  "ETUDI",
  "ETUDO",
  "EVENT",
  "EVNUH",
  "EVRIH",
  "EVROM",
  "EVROV",
  "EZULA",
  "EZULE",
  "EZULI",
  "EZULK",
  "EZULU",
  "FABEL",
  "FABUL",
  "FACAH",
  "FACAM",
  "FAČAH",
  "FAČAM",
  "FADOM",
  "FAFAČ",
  "FAFAJ",
  "FAFAL",
  "FAFAM",
  "FAFAŠ",
  "FAFAT",
  "FAGIH",
  "FAGOM",
  "FAGOT",
  "FAGOV",
  "FAJFA",
  "FAJFE",
  "FAJFI",
  "FAJFO",
  "FAJLA",
  "FAJLE",
  "FAJLI",
  "FAJLU",
  "FAJTA",
  "FAKIN",
  "FAKIR",
  "FAKSA",
  "FAKSE",
  "FAKSI",
  "FAKSU",
  "FAKTA",
  "FAKTE",
  "FAKTI",
  "FAKTU",
  "FALEČ",
  "FALIL",
  "FALIM",
  "FALIN",
  "FALIŠ",
  "FALOT",
  "FALUS",
  "FAMAH",
  "FAMAM",
  "FANAL",
  "FANIC",
  "FANIH",
  "FANOM",
  "FANOV",
  "FANTA",
  "FANTE",
  "FANTI",
  "FANTU",
  "FARAD",
  "FARAH",
  "FARAM",
  "FARAN",
  "FARBA",
  "FARBE",
  "FARBI",
  "FARBO",
  "FAREN",
  "FARJA",
  "FARJE",
  "FARJI",
  "FARJU",
  "FARMA",
  "FARME",
  "FARMI",
  "FARMO",
  "FARNA",
  "FARNE",
  "FARNI",
  "FARNO",
  "FARSA",
  "FARSE",
  "FARSI",
  "FARSO",
  "FASAD",
  "FASAL",
  "FASAN",
  "FASET",
  "FAŠEM",
  "FAŠEŠ",
  "FAŠIN",
  "FAŠOČ",
  "FATEV",
  "FATUM",
  "FATVA",
  "FATVE",
  "FATVI",
  "FATVO",
  "FAVEL",
  "FAVLA",
  "FAVLE",
  "FAVLI",
  "FAVLU",
  "FAVNA",
  "FAVNE",
  "FAVNI",
  "FAVNO",
  "FAVNU",
  "FAZAH",
  "FAZAM",
  "FAZAN",
  "FAZEN",
  "FAZNA",
  "FAZNE",
  "FAZNI",
  "FAZNO",
  "FEDER",
  "FEDRA",
  "FEDRE",
  "FEDRI",
  "FEDRU",
  "FEHTA",
  "FEJKA",
  "FEJKE",
  "FEJKI",
  "FEJKU",
  "FEJOJ",
  "FEJST",
  "FELAH",
  "FELER",
  "FELGA",
  "FELGE",
  "FELGI",
  "FELGO",
  "FELUK",
  "FEMUR",
  "FENAJ",
  "FENAL",
  "FENAM",
  "FENAŠ",
  "FENAT",
  "FENEK",
  "FENIH",
  "FENIL",
  "FENKA",
  "FENKE",
  "FENKI",
  "FENKU",
  "FENOL",
  "FENOM",
  "FENOV",
  "FENSI",
  "FENTA",
  "FERAT",
  "FERIT",
  "FERNK",
  "FESIH",
  "FESOM",
  "FESOV",
  "FESTA",
  "FESTE",
  "FESTI",
  "FESTU",
  "FEŠTA",
  "FEŠTE",
  "FEŠTI",
  "FEŠTO",
  "FETAH",
  "FETAM",
  "FETEV",
  "FETIŠ",
  "FETUS",
  "FETVA",
  "FETVE",
  "FETVI",
  "FETVO",
  "FEVDA",
  "FEVDE",
  "FEVDI",
  "FEVDU",
  "FIALA",
  "FIALE",
  "FIALI",
  "FIALO",
  "FIATA",
  "FIATE",
  "FIATI",
  "FIATU",
  "FIBUL",
  "FICEK",
  "FICKA",
  "FICKE",
  "FICKI",
  "FICKU",
  "FIČKA",
  "FIČKE",
  "FIČKI",
  "FIČKO",
  "FIČKU",
  "FIDŽI",
  "FIESA",
  "FIESE",
  "FIESI",
  "FIESO",
  "FIEST",
  "FIGAH",
  "FIGAM",
  "FIGIC",
  "FIGOV",
  "FIGUR",
  "FIJEM",
  "FIJEV",
  "FIJIH",
  "FIKSA",
  "FIKSE",
  "FIKSI",
  "FIKSU",
  "FIKUS",
  "FILAH",
  "FILAJ",
  "FILAL",
  "FILAM",
  "FILAN",
  "FILAŠ",
  "FILCA",
  "FILCE",
  "FILCI",
  "FILCU",
  "FILET",
  "FILIT",
  "FILMA",
  "FILME",
  "FILMI",
  "FILMU",
  "FIMOZ",
  "FINAL",
  "FINEM",
  "FINES",
  "FINIH",
  "FINIM",
  "FINIŠ",
  "FINTA",
  "FINTE",
  "FINTI",
  "FINTO",
  "FIOLA",
  "FIOLE",
  "FIOLI",
  "FIOLO",
  "FIRER",
  "FIRMA",
  "FIRME",
  "FIRMI",
  "FIRMO",
  "FIRNA",
  "FIRNE",
  "FIRNI",
  "FIRNU",
  "FISIJ",
  "FISUR",
  "FITIN",
  "FITOL",
  "FIZIK",
  "FIŽOL",
  "FJELA",
  "FJELE",
  "FJELI",
  "FJELU",
  "FJORD",
  "FLAKA",
  "FLAKE",
  "FLAKI",
  "FLAKU",
  "FLAMA",
  "FLAME",
  "FLAMI",
  "FLAMU",
  "FLANC",
  "FLAPA",
  "FLAPE",
  "FLAPI",
  "FLAPU",
  "FLAŠA",
  "FLAŠE",
  "FLAŠI",
  "FLAŠK",
  "FLAŠO",
  "FLAVT",
  "FLEHA",
  "FLEHE",
  "FLEHI",
  "FLEHO",
  "FLEKA",
  "FLEKE",
  "FLEKI",
  "FLEKU",
  "FLENČ",
  "FLEŠA",
  "FLEŠE",
  "FLEŠI",
  "FLEŠU",
  "FLETA",
  "FLETE",
  "FLETI",
  "FLETU",
  "FLIKA",
  "FLIKE",
  "FLIKI",
  "FLIKO",
  "FLINT",
  "FLIRT",
  "FLISA",
  "FLISE",
  "FLISI",
  "FLISU",
  "FLIŠA",
  "FLIŠU",
  "FLOKA",
  "FLOKE",
  "FLOKI",
  "FLOKU",
  "FLOPA",
  "FLOPE",
  "FLOPI",
  "FLOPU",
  "FLORA",
  "FLORE",
  "FLORI",
  "FLORO",
  "FLOSA",
  "FLOSE",
  "FLOSI",
  "FLOSU",
  "FLOTA",
  "FLOTE",
  "FLOTI",
  "FLOTO",
  "FLUAT",
  "FLUID",
  "FLUKI",
  "FLUKS",
  "FLUOR",
  "FOAJE",
  "FOBIJ",
  "FOBIK",
  "FOHIH",
  "FOHOM",
  "FOHOV",
  "FOJBA",
  "FOJBE",
  "FOJBI",
  "FOJBO",
  "FOKUS",
  "FOLAT",
  "FOLEN",
  "FOLGA",
  "FOLIA",
  "FOLIE",
  "FOLII",
  "FOLIJ",
  "FOLIO",
  "FOLIU",
  "FOLKA",
  "FOLKU",
  "FOLNA",
  "FOLNE",
  "FOLNI",
  "FOLNO",
  "FONDA",
  "FONDE",
  "FONDI",
  "FONDU",
  "FONEM",
  "FONIH",
  "FONOM",
  "FONON",
  "FONOV",
  "FONTA",
  "FONTE",
  "FONTI",
  "FONTU",
  "FOPAJ",
  "FOPAL",
  "FOPAM",
  "FOPAN",
  "FOPAŠ",
  "FORAH",
  "FORAM",
  "FORMA",
  "FORME",
  "FORMI",
  "FORMO",
  "FORTA",
  "FORTE",
  "FORTI",
  "FORTU",
  "FORUM",
  "FOSAH",
  "FOSAM",
  "FOSIL",
  "FOŠKA",
  "FOŠKE",
  "FOŠKI",
  "FOŠKO",
  "FOTER",
  "FOTKA",
  "FOTKE",
  "FOTKI",
  "FOTKO",
  "FOTON",
  "FOTRA",
  "FOTRE",
  "FOTRI",
  "FOTRU",
  "FRAČA",
  "FRAČE",
  "FRAČI",
  "FRAČO",
  "FRAJH",
  "FRAJL",
  "FRAKA",
  "FRAKE",
  "FRAKI",
  "FRAKU",
  "FRANA",
  "FRANK",
  "FRANŽ",
  "FRAPE",
  "FRATA",
  "FRATE",
  "FRATI",
  "FRATO",
  "FRAZA",
  "FRAZE",
  "FRAZI",
  "FRAZO",
  "FRCAJ",
  "FRCAL",
  "FRCAM",
  "FRCAŠ",
  "FRCAT",
  "FRCNE",
  "FRCNI",
  "FRČAD",
  "FRČAL",
  "FRČAT",
  "FRČEČ",
  "FRČIM",
  "FRČIŠ",
  "FRČKA",
  "FREND",
  "FREON",
  "FRESK",
  "FREZA",
  "FREZE",
  "FREZI",
  "FREZO",
  "FRFER",
  "FRFOT",
  "FRFRA",
  "FRFRE",
  "FRFRI",
  "FRFRO",
  "FRIKA",
  "FRIKE",
  "FRIKI",
  "FRIKO",
  "FRIKU",
  "FRISA",
  "FRISE",
  "FRISI",
  "FRISU",
  "FRIZA",
  "FRIZE",
  "FRIZI",
  "FRIZU",
  "FRKAH",
  "FRKAM",
  "FRLEČ",
  "FRLEL",
  "FRLET",
  "FRLIM",
  "FRLIŠ",
  "FROCA",
  "FROCE",
  "FROCI",
  "FROCU",
  "FROND",
  "FRONT",
  "FRULA",
  "FRULE",
  "FRULI",
  "FRULO",
  "FTIZA",
  "FTIZE",
  "FTIZI",
  "FTIZO",
  "FUČKA",
  "FUDŽI",
  "FUFEL",
  "FUFLA",
  "FUFLE",
  "FUFLI",
  "FUFLO",
  "FUGAH",
  "FUGAM",
  "FUGAS",
  "FUKAČ",
  "FUKAJ",
  "FUKAL",
  "FUKAM",
  "FUKAR",
  "FUKAŠ",
  "FUKAT",
  "FUKIH",
  "FUKNE",
  "FUKNI",
  "FUKOM",
  "FUKOV",
  "FUKOZ",
  "FUNKA",
  "FUNKU",
  "FUNTA",
  "FUNTE",
  "FUNTI",
  "FUNTU",
  "FURAH",
  "FURAJ",
  "FURAL",
  "FURAM",
  "FURAN",
  "FURAŠ",
  "FURAT",
  "FURIJ",
  "FURIN",
  "FUŠAJ",
  "FUŠAL",
  "FUŠAM",
  "FUŠAR",
  "FUŠAŠ",
  "FUŠEM",
  "FUŠEV",
  "FUŠIH",
  "FUTER",
  "FUTON",
  "FUTRA",
  "FUTRU",
  "FUTUR",
  "FUZIJ",
  "FUZLA",
  "FUŽEM",
  "FUŽEV",
  "FUŽIH",
  "FUŽIN",
  "GABER",
  "GABEZ",
  "GABIL",
  "GABIM",
  "GABIŠ",
  "GABIT",
  "GABON",
  "GABRA",
  "GABRE",
  "GABRI",
  "GABRO",
  "GABRU",
  "GAČAH",
  "GAČAM",
  "GAČEČ",
  "GAČIL",
  "GAČIM",
  "GAČIŠ",
  "GAČIT",
  "GADIH",
  "GADJA",
  "GADJE",
  "GADJI",
  "GADJO",
  "GADOM",
  "GADOV",
  "GAGAH",
  "GAGAJ",
  "GAGAL",
  "GAGAM",
  "GAGAŠ",
  "GAGAT",
  "GAGNE",
  "GAGNI",
  "GAJBA",
  "GAJBE",
  "GAJBI",
  "GAJBO",
  "GAJEM",
  "GAJET",
  "GAJEV",
  "GAJIČ",
  "GAJIH",
  "GALEB",
  "GALEJ",
  "GALEN",
  "GALER",
  "GALIC",
  "GALIJ",
  "GALNA",
  "GALNE",
  "GALNI",
  "GALNO",
  "GALON",
  "GALOP",
  "GALOŠ",
  "GALUN",
  "GAMAH",
  "GAMAM",
  "GAMAŠ",
  "GAMET",
  "GAMSA",
  "GAMSE",
  "GAMSI",
  "GAMSU",
  "GANEM",
  "GANEŠ",
  "GANIL",
  "GANIT",
  "GARAČ",
  "GARAH",
  "GARAJ",
  "GARAL",
  "GARAM",
  "GARAN",
  "GARAŠ",
  "GARAT",
  "GARAŽ",
  "GARBA",
  "GARDA",
  "GARDE",
  "GARDI",
  "GARDO",
  "GARIC",
  "GARIJ",
  "GARJE",
  "GAROT",
  "GASEČ",
  "GASIH",
  "GASIL",
  "GASIM",
  "GASIŠ",
  "GASIT",
  "GASOM",
  "GASOV",
  "GAŠEN",
  "GATAH",
  "GATAM",
  "GATKE",
  "GAVČA",
  "GAVČE",
  "GAVČI",
  "GAVČO",
  "GAVČU",
  "GAVDA",
  "GAVDE",
  "GAVDI",
  "GAVDO",
  "GAVGE",
  "GAVUN",
  "GAZDA",
  "GAZDE",
  "GAZDI",
  "GAZDO",
  "GAZEČ",
  "GAZEH",
  "GAZEL",
  "GAZEM",
  "GAZEN",
  "GAZIL",
  "GAZIM",
  "GAZIŠ",
  "GAZIT",
  "GAZJO",
  "GAZMI",
  "GAZNA",
  "GAZNE",
  "GAZNI",
  "GAZNO",
  "GAŽEN",
  "GEJEM",
  "GEJEV",
  "GEJIH",
  "GEJŠA",
  "GEJŠE",
  "GEJŠI",
  "GEJŠO",
  "GEKON",
  "GELIH",
  "GELOM",
  "GELOV",
  "GELOZ",
  "GEMUL",
  "GENEZ",
  "GENIH",
  "GENIJ",
  "GENOM",
  "GENOV",
  "GEODA",
  "GEODE",
  "GEODI",
  "GEODO",
  "GEOID",
  "GESEL",
  "GESLA",
  "GESLI",
  "GESLO",
  "GESLU",
  "GESTA",
  "GESTE",
  "GESTI",
  "GESTO",
  "GETIH",
  "GETOM",
  "GETOV",
  "GIBAJ",
  "GIBAL",
  "GIBAM",
  "GIBAŠ",
  "GIBAT",
  "GIBEK",
  "GIBEN",
  "GIBIH",
  "GIBKA",
  "GIBKE",
  "GIBKI",
  "GIBKO",
  "GIBNA",
  "GIBNE",
  "GIBNI",
  "GIBNO",
  "GIBOM",
  "GIBON",
  "GIBOV",
  "GIBUL",
  "GIFTA",
  "GIGAH",
  "GIGAM",
  "GIGIH",
  "GIGOM",
  "GIGOV",
  "GINEČ",
  "GINEM",
  "GINEŠ",
  "GINIL",
  "GINIT",
  "GINKA",
  "GINKE",
  "GINKI",
  "GINKO",
  "GINKU",
  "GINOM",
  "GIPSA",
  "GIPSE",
  "GIPSI",
  "GIPSU",
  "GIRIC",
  "GIRUS",
  "GISIH",
  "GISOM",
  "GISOV",
  "GIŠKA",
  "GIŠKE",
  "GIŠKI",
  "GIŠKO",
  "GLADA",
  "GLADE",
  "GLADI",
  "GLADU",
  "GLANS",
  "GLASB",
  "GLASI",
  "GLASU",
  "GLAVA",
  "GLAVE",
  "GLAVI",
  "GLAVO",
  "GLAŽA",
  "GLAŽE",
  "GLAŽI",
  "GLAŽU",
  "GLEDA",
  "GLEDE",
  "GLENA",
  "GLENE",
  "GLENI",
  "GLENU",
  "GLIFA",
  "GLIFE",
  "GLIFI",
  "GLIFU",
  "GLIHA",
  "GLIHE",
  "GLIHI",
  "GLIHO",
  "GLIJA",
  "GLIJE",
  "GLIJI",
  "GLIJO",
  "GLINA",
  "GLINE",
  "GLINI",
  "GLINO",
  "GLIOM",
  "GLIOZ",
  "GLIST",
  "GLIVA",
  "GLIVE",
  "GLIVI",
  "GLIVO",
  "GLOBA",
  "GLOBE",
  "GLOBI",
  "GLOBO",
  "GLODA",
  "GLOGA",
  "GLOGU",
  "GLOSA",
  "GLOSE",
  "GLOSI",
  "GLOSO",
  "GLOSU",
  "GLOTA",
  "GLOTE",
  "GLOTI",
  "GLOTO",
  "GLUHA",
  "GLUHE",
  "GLUHI",
  "GLUHO",
  "GLUMI",
  "GLUON",
  "GLUPA",
  "GLUPE",
  "GLUPI",
  "GLUPO",
  "GLUŠE",
  "GLUŠI",
  "GMAJN",
  "GMOTA",
  "GMOTE",
  "GMOTI",
  "GMOTO",
  "GNADA",
  "GNADE",
  "GNADI",
  "GNADO",
  "GNAJS",
  "GNALA",
  "GNALE",
  "GNALI",
  "GNALO",
  "GNANA",
  "GNANE",
  "GNANI",
  "GNANJ",
  "GNANO",
  "GNATI",
  "GNEČA",
  "GNEČE",
  "GNEČI",
  "GNEČO",
  "GNEST",
  "GNETE",
  "GNETI",
  "GNEVA",
  "GNEVU",
  "GNEZD",
  "GNIDA",
  "GNIDE",
  "GNIDI",
  "GNIDO",
  "GNIJE",
  "GNIJO",
  "GNILA",
  "GNILE",
  "GNILI",
  "GNILO",
  "GNITI",
  "GNJAT",
  "GNOJA",
  "GNOJI",
  "GNOJU",
  "GNOZA",
  "GNOZE",
  "GNOZI",
  "GNOZO",
  "GNUJA",
  "GNUJE",
  "GNUJI",
  "GNUJU",
  "GNUSA",
  "GNUSE",
  "GNUSI",
  "GNUSU",
  "GOAMA",
  "GOAMI",
  "GOBAH",
  "GOBAM",
  "GOBAN",
  "GOBAR",
  "GOBAV",
  "GOBCA",
  "GOBCE",
  "GOBCI",
  "GOBCU",
  "GOBEC",
  "GOBIC",
  "GOBJA",
  "GOBJE",
  "GOBJI",
  "GOBJO",
  "GOBOV",
  "GODAL",
  "GODAN",
  "GODBA",
  "GODBE",
  "GODBI",
  "GODBO",
  "GODCA",
  "GODCE",
  "GODCI",
  "GODCU",
  "GODEC",
  "GODEČ",
  "GODEL",
  "GODEM",
  "GODEN",
  "GODEŠ",
  "GODIL",
  "GODIM",
  "GODIŠ",
  "GODIT",
  "GODLA",
  "GODLE",
  "GODLI",
  "GODLO",
  "GODNA",
  "GODNE",
  "GODNI",
  "GODNO",
  "GODOM",
  "GODOV",
  "GODUJ",
  "GOFER",
  "GOFIH",
  "GOFOM",
  "GOFOV",
  "GOJEČ",
  "GOJEM",
  "GOJEN",
  "GOJIL",
  "GOJIM",
  "GOJIŠ",
  "GOJIT",
  "GOJIV",
  "GOJKA",
  "GOJKE",
  "GOJKI",
  "GOJKO",
  "GOLAČ",
  "GOLAK",
  "GOLAŽ",
  "GOLCA",
  "GOLCE",
  "GOLCI",
  "GOLCU",
  "GOLČE",
  "GOLČI",
  "GOLEC",
  "GOLEČ",
  "GOLEH",
  "GOLEM",
  "GOLEN",
  "GOLFA",
  "GOLFU",
  "GOLIC",
  "GOLIČ",
  "GOLID",
  "GOLIH",
  "GOLIL",
  "GOLIM",
  "GOLIN",
  "GOLIŠ",
  "GOLIT",
  "GOLJO",
  "GOLMI",
  "GOLOB",
  "GOLOM",
  "GOLOT",
  "GOLOV",
  "GOLŠA",
  "GOLŠE",
  "GOLŠI",
  "GOLŠO",
  "GOLTA",
  "GOLTE",
  "GOLTI",
  "GOLTU",
  "GOMIL",
  "GONAD",
  "GONEČ",
  "GONGA",
  "GONGE",
  "GONGI",
  "GONGU",
  "GONIČ",
  "GONIH",
  "GONIL",
  "GONIM",
  "GONIŠ",
  "GONIT",
  "GONJA",
  "GONJE",
  "GONJI",
  "GONJO",
  "GONOM",
  "GONOV",
  "GORAH",
  "GORAK",
  "GORAL",
  "GORAM",
  "GORAT",
  "GOREČ",
  "GOREK",
  "GOREL",
  "GORET",
  "GORIC",
  "GORIJ",
  "GORIL",
  "GORIM",
  "GORIŠ",
  "GORIV",
  "GORJA",
  "GORJE",
  "GORJI",
  "GORJU",
  "GORKA",
  "GORKE",
  "GORKI",
  "GORKO",
  "GOSAK",
  "GOSEH",
  "GOSEM",
  "GOSJA",
  "GOSJE",
  "GOSJI",
  "GOSJO",
  "GOSKA",
  "GOSKE",
  "GOSKI",
  "GOSKO",
  "GOSLI",
  "GOSMI",
  "GOSPA",
  "GOSPE",
  "GOSPO",
  "GOSTA",
  "GOSTE",
  "GOSTI",
  "GOSTO",
  "GOSTU",
  "GOŠČA",
  "GOŠČE",
  "GOŠČI",
  "GOŠČO",
  "GOŠKA",
  "GOŠKE",
  "GOŠKI",
  "GOŠKO",
  "GOTOV",
  "GOVED",
  "GOVNA",
  "GOVNO",
  "GOVNU",
  "GOVOR",
  "GOZDA",
  "GOZDU",
  "GOŽEM",
  "GOŽEV",
  "GOŽIH",
  "GOŽJA",
  "GOŽJE",
  "GOŽJI",
  "GOŽJO",
  "GRABI",
  "GRADB",
  "GRADI",
  "GRADU",
  "GRAFA",
  "GRAFE",
  "GRAFI",
  "GRAFU",
  "GRAHA",
  "GRAHU",
  "GRAJA",
  "GRAJE",
  "GRAJI",
  "GRAJO",
  "GRALA",
  "GRALE",
  "GRALI",
  "GRALU",
  "GRAMA",
  "GRAME",
  "GRAMI",
  "GRAMU",
  "GRANA",
  "GRAND",
  "GRANE",
  "GRANI",
  "GRANU",
  "GRAPA",
  "GRAPE",
  "GRAPI",
  "GRAPO",
  "GRBAČ",
  "GRBAH",
  "GRBAM",
  "GRBAN",
  "GRBAV",
  "GRBCA",
  "GRBCE",
  "GRBCI",
  "GRBCU",
  "GRBEC",
  "GRBEČ",
  "GRBEN",
  "GRBEŽ",
  "GRBIC",
  "GRBIH",
  "GRBIL",
  "GRBIM",
  "GRBIN",
  "GRBIŠ",
  "GRBIT",
  "GRBNA",
  "GRBNE",
  "GRBNI",
  "GRBNO",
  "GRBOM",
  "GRBOV",
  "GRČAH",
  "GRČAL",
  "GRČAM",
  "GRČAT",
  "GRČAV",
  "GRČEČ",
  "GRČIC",
  "GRČIM",
  "GRČIŠ",
  "GRDEČ",
  "GRDEM",
  "GRDEN",
  "GRDIH",
  "GRDIL",
  "GRDIM",
  "GRDIŠ",
  "GRDIT",
  "GRDOB",
  "GRDOT",
  "GRDUN",
  "GREBA",
  "GREBE",
  "GREBI",
  "GREDA",
  "GREDE",
  "GREDI",
  "GREDO",
  "GREHA",
  "GREHE",
  "GREHI",
  "GREHU",
  "GREJA",
  "GREJE",
  "GREJI",
  "GREJO",
  "GREJU",
  "GRELA",
  "GRELE",
  "GRELI",
  "GRELK",
  "GRELO",
  "GRELU",
  "GREMO",
  "GRENE",
  "GRENI",
  "GRESA",
  "GRESE",
  "GRESI",
  "GRESU",
  "GREŠE",
  "GREŠI",
  "GRETA",
  "GRETE",
  "GRETI",
  "GRETO",
  "GREVA",
  "GREŽA",
  "GREŽU",
  "GRGRA",
  "GRIČA",
  "GRIČE",
  "GRIČI",
  "GRIČU",
  "GRIFA",
  "GRIFE",
  "GRIFI",
  "GRIFU",
  "GRINT",
  "GRIOT",
  "GRIPA",
  "GRIPE",
  "GRIPI",
  "GRIPO",
  "GRIST",
  "GRIVA",
  "GRIVE",
  "GRIVI",
  "GRIVN",
  "GRIVO",
  "GRIZA",
  "GRIZE",
  "GRIZI",
  "GRIZU",
  "GRIŽA",
  "GRIŽE",
  "GRIŽI",
  "GRIŽO",
  "GRKNE",
  "GRKNI",
  "GRLAT",
  "GRLCA",
  "GRLCE",
  "GRLCI",
  "GRLCU",
  "GRLEC",
  "GRLEČ",
  "GRLEL",
  "GRLEN",
  "GRLET",
  "GRLIC",
  "GRLIH",
  "GRLIM",
  "GRLIŠ",
  "GRLNA",
  "GRLNE",
  "GRLNI",
  "GRLNO",
  "GRLOM",
  "GRMAD",
  "GRMAT",
  "GRMEČ",
  "GRMEL",
  "GRMET",
  "GRMIČ",
  "GRMIH",
  "GRMIM",
  "GRMIŠ",
  "GRMJA",
  "GRMJU",
  "GRMOM",
  "GRMOV",
  "GRMUŠ",
  "GROBA",
  "GROBE",
  "GROBI",
  "GROBO",
  "GROBU",
  "GROFA",
  "GROFE",
  "GROFI",
  "GROFU",
  "GROGA",
  "GROGE",
  "GROGI",
  "GROGU",
  "GROMA",
  "GROME",
  "GROMI",
  "GROMU",
  "GROŠA",
  "GROŠE",
  "GROŠI",
  "GROŠU",
  "GROTA",
  "GROTE",
  "GROTI",
  "GROTU",
  "GROZA",
  "GROZD",
  "GROZE",
  "GROZI",
  "GROZO",
  "GRŠAJ",
  "GRŠAL",
  "GRŠAM",
  "GRŠAŠ",
  "GRŠAT",
  "GRŠEM",
  "GRŠIH",
  "GRŠIM",
  "GRŠKA",
  "GRŠKE",
  "GRŠKI",
  "GRŠKO",
  "GRUČA",
  "GRUČE",
  "GRUČI",
  "GRUČO",
  "GRUDA",
  "GRUDE",
  "GRUDI",
  "GRUDO",
  "GRUJA",
  "GRUJE",
  "GRUJI",
  "GRUJU",
  "GRULI",
  "GRUND",
  "GRUNT",
  "GRUPA",
  "GRUPE",
  "GRUPI",
  "GRUPO",
  "GRUŠČ",
  "GRUŠT",
  "GUAMA",
  "GUAMU",
  "GUBAH",
  "GUBAJ",
  "GUBAL",
  "GUBAM",
  "GUBAN",
  "GUBAŠ",
  "GUBAT",
  "GUBAV",
  "GUBEČ",
  "GUBEN",
  "GUBIC",
  "GUBIL",
  "GUBIM",
  "GUBIŠ",
  "GUBIT",
  "GUBNA",
  "GUBNE",
  "GUBNI",
  "GUBNO",
  "GUGAJ",
  "GUGAL",
  "GUGAM",
  "GUGAŠ",
  "GUGAT",
  "GUGAV",
  "GUGLA",
  "GULAG",
  "GULEČ",
  "GULEŽ",
  "GULIL",
  "GULIM",
  "GULIŠ",
  "GULIT",
  "GUMAH",
  "GUMAM",
  "GUMAR",
  "GUMBA",
  "GUMBE",
  "GUMBI",
  "GUMBU",
  "GUMEN",
  "GUMIC",
  "GUMNA",
  "GUMNI",
  "GUMNO",
  "GUMNU",
  "GUMPK",
  "GUNCA",
  "GUSAR",
  "GUSEL",
  "GUSLE",
  "GUŠTA",
  "GUŠTE",
  "GUŠTI",
  "GUŠTU",
  "GUZNE",
  "GUZNI",
  "GUŽVA",
  "GUŽVE",
  "GUŽVI",
  "GUŽVO",
  "GVANA",
  "GVANO",
  "GVANT",
  "GVANU",
  "GVAŠA",
  "GVAŠE",
  "GVAŠI",
  "GVAŠU",
  "GVELF",
  "GVIRA",
  "HABEČ",
  "HABIL",
  "HABIM",
  "HABIŠ",
  "HABIT",
  "HADOM",
  "HADŽA",
  "HADŽE",
  "HADŽI",
  "HADŽU",
  "HAFIZ",
  "HAIKA",
  "HAIKE",
  "HAIKI",
  "HAIKU",
  "HAITI",
  "HAJAJ",
  "HAJAL",
  "HAJAM",
  "HAJAN",
  "HAJAŠ",
  "HAJCA",
  "HAJKA",
  "HAJKE",
  "HAJKI",
  "HAJKO",
  "HALAH",
  "HALAL",
  "HALAM",
  "HALIT",
  "HALJA",
  "HALJE",
  "HALJI",
  "HALJO",
  "HALMA",
  "HALME",
  "HALMI",
  "HALMO",
  "HALOG",
  "HALON",
  "HALOZ",
  "HALTI",
  "HALUG",
  "HALVA",
  "HALVE",
  "HALVI",
  "HALVO",
  "HAMAD",
  "HAMAM",
  "HAMIT",
  "HANIJ",
  "HARAČ",
  "HARAJ",
  "HARAL",
  "HARAM",
  "HARAŠ",
  "HAREM",
  "HARFA",
  "HARFE",
  "HARFI",
  "HARFO",
  "HARZA",
  "HARZE",
  "HARZI",
  "HARZO",
  "HASEK",
  "HASKA",
  "HASKE",
  "HASKI",
  "HASKU",
  "HASNE",
  "HASNI",
  "HAŠIŠ",
  "HAVBA",
  "HAVBE",
  "HAVBI",
  "HAVBO",
  "HČERA",
  "HČERE",
  "HČERI",
  "HČERK",
  "HECAJ",
  "HECAL",
  "HECAM",
  "HECAŠ",
  "HECAT",
  "HECEM",
  "HECEN",
  "HECEV",
  "HECIH",
  "HECNA",
  "HECNE",
  "HECNI",
  "HECNO",
  "HEFTA",
  "HEHET",
  "HEKAJ",
  "HEKAL",
  "HEKAM",
  "HEKAŠ",
  "HEKAT",
  "HEKER",
  "HEKIH",
  "HEKOM",
  "HEKOV",
  "HELIJ",
  "HELOT",
  "HEMIN",
  "HEMOM",
  "HEMOZ",
  "HENRI",
  "HERCA",
  "HERCE",
  "HERCI",
  "HERCU",
  "HERMA",
  "HERME",
  "HERMI",
  "HERMO",
  "HEROJ",
  "HERTZ",
  "HESIH",
  "HESOM",
  "HESOV",
  "HETER",
  "HIATA",
  "HIATE",
  "HIATI",
  "HIATU",
  "HIBAH",
  "HIBAM",
  "HIBAV",
  "HIBEN",
  "HIBIC",
  "HIBNA",
  "HIBNE",
  "HIBNI",
  "HIBNO",
  "HIDER",
  "HIDRA",
  "HIDRE",
  "HIDRI",
  "HIDRO",
  "HIFAH",
  "HIFAM",
  "HIFEM",
  "HIHOT",
  "HIJEM",
  "HIJEN",
  "HIJEV",
  "HIJIH",
  "HILUS",
  "HIMEN",
  "HIMER",
  "HIMNA",
  "HIMNE",
  "HIMNI",
  "HIMNO",
  "HINDI",
  "HINIJ",
  "HIPCA",
  "HIPCE",
  "HIPCI",
  "HIPCU",
  "HIPEC",
  "HIPEN",
  "HIPIH",
  "HIPNA",
  "HIPNE",
  "HIPNI",
  "HIPNO",
  "HIPOM",
  "HIPOV",
  "HIPUS",
  "HIRAJ",
  "HIRAL",
  "HIRAM",
  "HIRAŠ",
  "HIRAT",
  "HIRAV",
  "HIŠAH",
  "HIŠAM",
  "HIŠEN",
  "HIŠIC",
  "HIŠKA",
  "HIŠKE",
  "HIŠKI",
  "HIŠKO",
  "HIŠNA",
  "HIŠNE",
  "HIŠNI",
  "HIŠNO",
  "HIŠUR",
  "HITEČ",
  "HITEL",
  "HITER",
  "HITET",
  "HITIH",
  "HITIM",
  "HITIN",
  "HITIŠ",
  "HITOM",
  "HITON",
  "HITOV",
  "HITRA",
  "HITRE",
  "HITRI",
  "HITRO",
  "HIVOM",
  "HLAČA",
  "HLAČE",
  "HLAČK",
  "HLADA",
  "HLADE",
  "HLADI",
  "HLADU",
  "HLAPA",
  "HLAPE",
  "HLAPI",
  "HLAPU",
  "HLEBA",
  "HLEBE",
  "HLEBI",
  "HLEBU",
  "HLEPE",
  "HLEPI",
  "HLEVA",
  "HLEVE",
  "HLEVI",
  "HLEVU",
  "HLINI",
  "HLIPA",
  "HLODA",
  "HLODE",
  "HLODI",
  "HLODU",
  "HMELJ",
  "HOANA",
  "HOANE",
  "HOANI",
  "HOANO",
  "HOBIT",
  "HOBOK",
  "HOČEM",
  "HOČEŠ",
  "HODCA",
  "HODCE",
  "HODCI",
  "HODCU",
  "HODEC",
  "HODEČ",
  "HODEN",
  "HODIH",
  "HODIL",
  "HODIM",
  "HODIŠ",
  "HODIT",
  "HODNA",
  "HODNE",
  "HODNI",
  "HODNO",
  "HODOM",
  "HODOV",
  "HODŽA",
  "HODŽE",
  "HODŽI",
  "HODŽO",
  "HOJAH",
  "HOJAM",
  "HOJCA",
  "HOJCE",
  "HOJCI",
  "HOJCO",
  "HOJIC",
  "HOJLA",
  "HOKEJ",
  "HOLEN",
  "HOLIN",
  "HOLMA",
  "HOLME",
  "HOLMI",
  "HOLMU",
  "HOLNA",
  "HOLNE",
  "HOLNI",
  "HOLNO",
  "HOMIČ",
  "HONAJ",
  "HONAL",
  "HONAM",
  "HONAN",
  "HONAŠ",
  "HONAT",
  "HONDA",
  "HONDE",
  "HONDI",
  "HONDO",
  "HOPAK",
  "HOPLA",
  "HOPSA",
  "HORAH",
  "HORAM",
  "HORDA",
  "HORDE",
  "HORDI",
  "HORDO",
  "HORIJ",
  "HORSA",
  "HORSE",
  "HORSI",
  "HORST",
  "HORSU",
  "HORUK",
  "HOSTA",
  "HOSTE",
  "HOSTI",
  "HOSTO",
  "HOŠKA",
  "HOŠKE",
  "HOŠKI",
  "HOŠKO",
  "HOTEČ",
  "HOTEL",
  "HOTEN",
  "HOTNA",
  "HOTNE",
  "HOTNI",
  "HOTNO",
  "HOUSA",
  "HOUSE",
  "HOUSU",
  "HOZAN",
  "HRAMA",
  "HRAMB",
  "HRAME",
  "HRAMI",
  "HRAMU",
  "HRANA",
  "HRANE",
  "HRANI",
  "HRANO",
  "HRAST",
  "HRBET",
  "HRBTA",
  "HRBTE",
  "HRBTI",
  "HRBTU",
  "HRČAK",
  "HRČAL",
  "HRČAT",
  "HRČEČ",
  "HRČEK",
  "HRČIC",
  "HRČIM",
  "HRČIŠ",
  "HRČJA",
  "HRČJE",
  "HRČJI",
  "HRČJO",
  "HRČKA",
  "HRČKE",
  "HRČKI",
  "HRČKU",
  "HRECA",
  "HRENA",
  "HRENE",
  "HRENI",
  "HRENU",
  "HRESK",
  "HRIBA",
  "HRIBE",
  "HRIBI",
  "HRIBU",
  "HRIPA",
  "HRKAJ",
  "HRKAL",
  "HRKAM",
  "HRKAŠ",
  "HRKAT",
  "HRKAV",
  "HRKNE",
  "HRKNI",
  "HROMA",
  "HROME",
  "HROMI",
  "HROMO",
  "HROPE",
  "HROPI",
  "HROŠČ",
  "HRSKA",
  "HRSKE",
  "HRSKI",
  "HRSKU",
  "HRSTA",
  "HRSTI",
  "HRTIC",
  "HRTIH",
  "HRTOM",
  "HRTOV",
  "HRULE",
  "HRULI",
  "HRUMA",
  "HRUME",
  "HRUMI",
  "HRUMU",
  "HRUPA",
  "HRUPE",
  "HRUPI",
  "HRUPU",
  "HRUST",
  "HRUŠČ",
  "HRUŠK",
  "HRZAJ",
  "HRZAL",
  "HRZAM",
  "HRZAŠ",
  "HRZAT",
  "HRZAV",
  "HRZNE",
  "HRZNI",
  "HRŽEM",
  "HRŽEŠ",
  "HRŽIC",
  "HTTPS",
  "HUBAH",
  "HUBAM",
  "HUDEM",
  "HUDIČ",
  "HUDIH",
  "HUDIK",
  "HUDIM",
  "HUDIR",
  "HUDOB",
  "HUDUJ",
  "HUJŠA",
  "HUJŠE",
  "HUJŠI",
  "HUJŠO",
  "HUKAJ",
  "HUKAL",
  "HUKAM",
  "HUKAŠ",
  "HUKAT",
  "HUKNE",
  "HUKNI",
  "HULEČ",
  "HULEŽ",
  "HULIL",
  "HULIM",
  "HULIŠ",
  "HULIT",
  "HULJA",
  "HULJE",
  "HULJI",
  "HULJO",
  "HULOK",
  "HUMAN",
  "HUMOM",
  "HUMOR",
  "HUMUS",
  "HUNTA",
  "HUNTE",
  "HUNTI",
  "HUNTO",
  "HUPAH",
  "HUPAJ",
  "HUPAL",
  "HUPAM",
  "HUPAŠ",
  "HUPAT",
  "HURIJ",
  "HUSIT",
  "HUŠKA",
  "HUŠKE",
  "HUŠKI",
  "HUŠKO",
  "HUŠNE",
  "HUŠNI",
  "HUZAR",
  "HVALA",
  "HVALE",
  "HVALI",
  "HVALO",
  "HVARA",
  "HVARU",
  "IBISA",
  "IBISE",
  "IBISI",
  "IBISU",
  "IBIZA",
  "IBIZE",
  "IBIZI",
  "IBIZO",
  "IDAMI",
  "IDEAL",
  "IDEJA",
  "IDEJE",
  "IDEJI",
  "IDEJO",
  "IDILA",
  "IDILE",
  "IDILI",
  "IDILO",
  "IDIOM",
  "IDIOT",
  "IDOLA",
  "IDOLE",
  "IDOLI",
  "IDOLU",
  "IDOMA",
  "IDOZA",
  "IDOZE",
  "IDOZI",
  "IDOZO",
  "IFTAR",
  "IGALK",
  "IGELN",
  "IGLAH",
  "IGLAJ",
  "IGLAL",
  "IGLAM",
  "IGLAN",
  "IGLAR",
  "IGLAŠ",
  "IGLAT",
  "IGLIC",
  "IGRAČ",
  "IGRAH",
  "IGRAJ",
  "IGRAL",
  "IGRAM",
  "IGRAN",
  "IGRAŠ",
  "IGRAT",
  "IGRAV",
  "IGRIC",
  "IGRIV",
  "IHTAV",
  "IHTEČ",
  "IHTEL",
  "IHTET",
  "IHTIM",
  "IHTIŠ",
  "IKAVK",
  "IKONA",
  "IKONE",
  "IKONI",
  "IKONO",
  "IKRAH",
  "IKRAM",
  "IKRAV",
  "IKRIC",
  "IKRNA",
  "IKRNE",
  "IKRNI",
  "IKRNO",
  "IKSIH",
  "IKSOM",
  "IKSOV",
  "IKTIH",
  "IKTOM",
  "IKTOV",
  "IKTUS",
  "ILEUS",
  "ILIRK",
  "ILNAT",
  "IMAGE",
  "IMAJO",
  "IMAMA",
  "IMAME",
  "IMAMI",
  "IMAMO",
  "IMAMU",
  "IMATA",
  "IMATE",
  "IMAVA",
  "IMBUS",
  "IMELA",
  "IMELE",
  "IMELI",
  "IMELO",
  "IMENA",
  "IMENI",
  "IMENU",
  "IMETI",
  "IMIDA",
  "IMIDE",
  "IMIDI",
  "IMIDU",
  "IMIDŽ",
  "IMINA",
  "IMINE",
  "IMINI",
  "IMINU",
  "IMPAL",
  "IMPRO",
  "IMUNA",
  "IMUNE",
  "IMUNI",
  "IMUNO",
  "INCEL",
  "INČAH",
  "INČAM",
  "INČEM",
  "INČEV",
  "INČIH",
  "INČUN",
  "INDIC",
  "INDIJ",
  "INDRI",
  "INFRA",
  "INGOT",
  "INLET",
  "INOKS",
  "INPUT",
  "INSKA",
  "INSKE",
  "INSKI",
  "INSKO",
  "INSUL",
  "INTIM",
  "INVAR",
  "IONIH",
  "IONOM",
  "IONOV",
  "IRAKA",
  "IRAKU",
  "IRANA",
  "IRANU",
  "IRBIS",
  "IRCAJ",
  "IRCAL",
  "IRCAM",
  "IRCAŠ",
  "IRCAT",
  "IRHAR",
  "IRHOM",
  "IRISA",
  "IRISE",
  "IRISI",
  "IRISU",
  "IRSKA",
  "IRSKE",
  "IRSKI",
  "IRSKO",
  "IRŠKA",
  "IRŠKE",
  "IRŠKI",
  "IRŠKO",
  "ISKAL",
  "ISKAN",
  "ISKAT",
  "ISKER",
  "ISKRA",
  "ISKRE",
  "ISKRI",
  "ISKRN",
  "ISKRO",
  "ISLAM",
  "ISTEČ",
  "ISTEJ",
  "ISTEM",
  "ISTEN",
  "ISTIH",
  "ISTIJ",
  "ISTIL",
  "ISTIM",
  "ISTIŠ",
  "ISTIT",
  "ISTJE",
  "ISTRA",
  "ISTRE",
  "ISTRI",
  "ISTRO",
  "IŠČEM",
  "IŠČEŠ",
  "IŠČOČ",
  "IŠIAS",
  "IŠKEM",
  "IŠKIH",
  "IŠKIM",
  "ITRIJ",
  "IUSOM",
  "IVAMA",
  "IVAMI",
  "IVERI",
  "IVERK",
  "IVJEM",
  "IVNAT",
  "IVRIT",
  "IZBAH",
  "IZBAM",
  "IZBEN",
  "IZBIC",
  "IZBIJ",
  "IZBIL",
  "IZBIR",
  "IZBIT",
  "IZBNA",
  "IZBNE",
  "IZBNI",
  "IZBNO",
  "IZBOK",
  "IZBOR",
  "IZBUH",
  "IZDAJ",
  "IZDAL",
  "IZDAM",
  "IZDAN",
  "IZDAŠ",
  "IZDAT",
  "IZDIH",
  "IZDRE",
  "IZDRI",
  "IZDRL",
  "IZDRT",
  "IZDUH",
  "IZGIN",
  "IZGON",
  "IZGUB",
  "IZHOD",
  "IZIDA",
  "IZIDE",
  "IZIDI",
  "IZIDU",
  "IZITI",
  "IZJAV",
  "IZJED",
  "IZJEJ",
  "IZJEM",
  "IZJEŠ",
  "IZKAZ",
  "IZKOP",
  "IZKUP",
  "IZLEČ",
  "IZLET",
  "IZLIJ",
  "IZLIL",
  "IZLIT",
  "IZLIV",
  "IZLOV",
  "IZMED",
  "IZMEN",
  "IZMER",
  "IZMET",
  "IZMIH",
  "IZMIJ",
  "IZMIK",
  "IZMIL",
  "IZMIT",
  "IZMOM",
  "IZMOV",
  "IZNAD",
  "IZNOS",
  "IZPAD",
  "IZPAH",
  "IZPEL",
  "IZPET",
  "IZPIH",
  "IZPIJ",
  "IZPIL",
  "IZPIS",
  "IZPIT",
  "IZPNE",
  "IZPNI",
  "IZPOD",
  "IZPOJ",
  "IZPRE",
  "IZPRI",
  "IZPRL",
  "IZPRT",
  "IZPUH",
  "IZRAB",
  "IZRAZ",
  "IZREČ",
  "IZREK",
  "IZREZ",
  "IZRIJ",
  "IZRIL",
  "IZRIS",
  "IZRIT",
  "IZRUJ",
  "IZRUL",
  "IZRUT",
  "IZSEK",
  "IZSEV",
  "IZŠEL",
  "IZŠLA",
  "IZŠLE",
  "IZŠLI",
  "IZŠLO",
  "IZTEČ",
  "IZTEG",
  "IZTEK",
  "IZTIS",
  "IZTOK",
  "IZUČI",
  "IZUMA",
  "IZUME",
  "IZUMI",
  "IZUMU",
  "IZURI",
  "IZVEM",
  "IZVEN",
  "IZVES",
  "IZVEŠ",
  "IZVID",
  "IZVIJ",
  "IZVIL",
  "IZVIN",
  "IZVIR",
  "IZVIT",
  "IZVOD",
  "IZVOR",
  "IZVOZ",
  "IZVRE",
  "IZVRG",
  "IZVRI",
  "IZZIV",
  "IZŽEL",
  "IZŽET",
  "IZŽGE",
  "IZŽGI",
  "IZŽME",
  "IZŽMI",
  "IZŽRE",
  "IZŽRI",
  "IZŽRL",
  "IZŽRT",
  "IŽESA",
  "IŽESI",
  "IŽESU",
  "JABEL",
  "JABLE",
  "JAČAJ",
  "JAČAL",
  "JAČAM",
  "JAČAŠ",
  "JAČAT",
  "JAČEČ",
  "JAČEN",
  "JAČIL",
  "JAČIM",
  "JAČIN",
  "JAČIŠ",
  "JAČJE",
  "JADER",
  "JADRA",
  "JADRC",
  "JADRI",
  "JADRN",
  "JADRO",
  "JADRU",
  "JAGAH",
  "JAGAM",
  "JAGER",
  "JAGOD",
  "JAGRA",
  "JAGRE",
  "JAGRI",
  "JAGRU",
  "JAHAČ",
  "JAHAJ",
  "JAHAL",
  "JAHAM",
  "JAHAŠ",
  "JAHAT",
  "JAHTA",
  "JAHTE",
  "JAHTI",
  "JAHTO",
  "JAJCA",
  "JAJCE",
  "JAJCI",
  "JAJCU",
  "JAJČK",
  "JAKAH",
  "JAKAM",
  "JAKEM",
  "JAKEN",
  "JAKIH",
  "JAKIM",
  "JAKNA",
  "JAKNE",
  "JAKNI",
  "JAKNO",
  "JAKOM",
  "JAKOV",
  "JAKUZ",
  "JALOV",
  "JAMAH",
  "JAMAM",
  "JAMAR",
  "JAMBA",
  "JAMBE",
  "JAMBI",
  "JAMBU",
  "JAMČI",
  "JAMEM",
  "JAMEŠ",
  "JAMIC",
  "JAMOM",
  "JAMRA",
  "JANEŽ",
  "JANGA",
  "JANGE",
  "JANGI",
  "JANGU",
  "JAPOK",
  "JARCA",
  "JARCE",
  "JARCI",
  "JARCU",
  "JARDA",
  "JARDE",
  "JARDI",
  "JARDU",
  "JAREC",
  "JAREK",
  "JAREM",
  "JARIC",
  "JARIH",
  "JARIM",
  "JARKA",
  "JARKE",
  "JARKI",
  "JARKO",
  "JARKU",
  "JARMA",
  "JARME",
  "JARMI",
  "JARMU",
  "JASAH",
  "JASAM",
  "JASAN",
  "JASEN",
  "JASIC",
  "JASLI",
  "JASNA",
  "JASNE",
  "JASNI",
  "JASNO",
  "JAŠEK",
  "JAŠKA",
  "JAŠKE",
  "JAŠKI",
  "JAŠKU",
  "JATAH",
  "JATAM",
  "JATIH",
  "JATOM",
  "JATOV",
  "JAVEN",
  "JAVIL",
  "JAVIM",
  "JAVIŠ",
  "JAVIT",
  "JAVKA",
  "JAVKE",
  "JAVKI",
  "JAVKO",
  "JAVNA",
  "JAVNE",
  "JAVNI",
  "JAVNO",
  "JAVOR",
  "JAZID",
  "JAZIH",
  "JAZOM",
  "JAZOV",
  "JAZZA",
  "JAZZU",
  "JEBAČ",
  "JEBAH",
  "JEBAL",
  "JEBAM",
  "JEBAN",
  "JEBAT",
  "JEBEM",
  "JEBEŠ",
  "JECAV",
  "JECNE",
  "JECNI",
  "JEČAH",
  "JEČAL",
  "JEČAM",
  "JEČAR",
  "JEČAT",
  "JEČAV",
  "JEČEČ",
  "JEČIM",
  "JEČIŠ",
  "JEDCA",
  "JEDCE",
  "JEDCI",
  "JEDCU",
  "JEDEC",
  "JEDEH",
  "JEDEK",
  "JEDEL",
  "JEDEM",
  "JEDER",
  "JEDEŽ",
  "JEDIL",
  "JEDJO",
  "JEDKA",
  "JEDKE",
  "JEDKI",
  "JEDKO",
  "JEDLA",
  "JEDLE",
  "JEDLI",
  "JEDLO",
  "JEDMI",
  "JEDOČ",
  "JEDRA",
  "JEDRC",
  "JEDRE",
  "JEDRI",
  "JEDRN",
  "JEDRO",
  "JEDRU",
  "JEJMO",
  "JEJTA",
  "JEJTE",
  "JEJVA",
  "JEKEL",
  "JEKIH",
  "JEKLA",
  "JEKLE",
  "JEKLI",
  "JEKLO",
  "JEKLU",
  "JEKNE",
  "JEKNI",
  "JEKOM",
  "JEKOV",
  "JELAH",
  "JELAM",
  "JELEN",
  "JELKA",
  "JELKE",
  "JELKI",
  "JELKO",
  "JELŠA",
  "JELŠE",
  "JELŠI",
  "JELŠO",
  "JEMAL",
  "JEMAN",
  "JEMAT",
  "JEMEN",
  "JEMNA",
  "JEMNU",
  "JENIH",
  "JENJA",
  "JENKI",
  "JENOM",
  "JENOV",
  "JERAH",
  "JERAM",
  "JEREB",
  "JEROB",
  "JESEN",
  "JESIH",
  "JESTA",
  "JESTE",
  "JESTI",
  "JESUR",
  "JEŠČA",
  "JEŠČE",
  "JEŠČI",
  "JEŠČO",
  "JEŠIV",
  "JEŠKA",
  "JEŠKE",
  "JEŠKI",
  "JEŠKO",
  "JETER",
  "JETRA",
  "JETRC",
  "JETRI",
  "JETRN",
  "JETRV",
  "JEZAV",
  "JEZDI",
  "JEZEČ",
  "JEZEN",
  "JEZER",
  "JEZIC",
  "JEZID",
  "JEZIK",
  "JEZIL",
  "JEZIM",
  "JEZIŠ",
  "JEZIT",
  "JEZNA",
  "JEZNE",
  "JEZNI",
  "JEZNO",
  "JEZOM",
  "JEZOV",
  "JEŽAH",
  "JEŽAM",
  "JEŽEK",
  "JEŽEM",
  "JEŽEN",
  "JEŽEŠ",
  "JEŽEV",
  "JEŽIC",
  "JEŽIH",
  "JEŽIL",
  "JEŽIM",
  "JEŽIŠ",
  "JEŽIT",
  "JEŽKA",
  "JEŽKE",
  "JEŽKI",
  "JEŽKU",
  "JEŽNA",
  "JEŽNE",
  "JEŽNI",
  "JEŽNO",
  "JICAM",
  "JIDIŠ",
  "JINIH",
  "JINOM",
  "JINOV",
  "JOČEM",
  "JOČEŠ",
  "JODAT",
  "JODEN",
  "JODID",
  "JODIT",
  "JODLA",
  "JODNA",
  "JODNE",
  "JODNI",
  "JODNO",
  "JODOM",
  "JOJME",
  "JOJOB",
  "JOKAJ",
  "JOKAL",
  "JOKAM",
  "JOKAŠ",
  "JOKAT",
  "JOKAV",
  "JOKCA",
  "JOKER",
  "JOKOM",
  "JOPAH",
  "JOPAM",
  "JOPIC",
  "JOPIČ",
  "JOSTA",
  "JOSTE",
  "JOSTI",
  "JOSTO",
  "JOŠKA",
  "JOŠKE",
  "JOŠKI",
  "JOŠKO",
  "JOŠKU",
  "JOTAH",
  "JOTAM",
  "JUANA",
  "JUANE",
  "JUANI",
  "JUANU",
  "JUDEŽ",
  "JUDIH",
  "JUDJE",
  "JUDOM",
  "JUDOV",
  "JUGIH",
  "JUGOM",
  "JUGOV",
  "JUHAH",
  "JUHAM",
  "JUHAR",
  "JUHEJ",
  "JUHIC",
  "JUHTA",
  "JUHTE",
  "JUHTI",
  "JUHTO",
  "JUKAH",
  "JUKAM",
  "JULIJ",
  "JUMBO",
  "JUNAK",
  "JUNCA",
  "JUNCE",
  "JUNCI",
  "JUNCU",
  "JUNEC",
  "JUNIC",
  "JUNIJ",
  "JURIŠ",
  "JURJA",
  "JURJE",
  "JURJI",
  "JURJU",
  "JURKA",
  "JURKE",
  "JURKI",
  "JURKO",
  "JURKU",
  "JURTA",
  "JURTE",
  "JURTI",
  "JURTO",
  "JUŠEN",
  "JUŠNA",
  "JUŠNE",
  "JUŠNI",
  "JUŠNO",
  "JUTAH",
  "JUTAM",
  "JUTER",
  "JUTRA",
  "JUTRI",
  "JUTRO",
  "JUTRU",
  "JUŽEČ",
  "JUŽEN",
  "JUŽIL",
  "JUŽIM",
  "JUŽIN",
  "JUŽIŠ",
  "JUŽIT",
  "JUŽNA",
  "JUŽNE",
  "JUŽNI",
  "JUŽNO",
  "JZZPR",
  "KABEL",
  "KABIN",
  "KABLA",
  "KABLE",
  "KABLI",
  "KABLU",
  "KACET",
  "KAČAH",
  "KAČAM",
  "KAČAR",
  "KAČEK",
  "KAČIC",
  "KAČJA",
  "KAČJE",
  "KAČJI",
  "KAČJO",
  "KAČKA",
  "KAČKE",
  "KAČKI",
  "KAČKU",
  "KAČON",
  "KADAR",
  "KADEČ",
  "KADEH",
  "KADEM",
  "KADER",
  "KADET",
  "KADIC",
  "KADIJ",
  "KADIL",
  "KADIM",
  "KADIŠ",
  "KADIT",
  "KADJO",
  "KADMI",
  "KADRA",
  "KADRE",
  "KADRI",
  "KADRU",
  "KAFER",
  "KAFIČ",
  "KAFRA",
  "KAFRE",
  "KAFRI",
  "KAFRN",
  "KAFRO",
  "KAHEL",
  "KAHLA",
  "KAHLE",
  "KAHLI",
  "KAHLO",
  "KAHON",
  "KAJAH",
  "KAJAK",
  "KAJAM",
  "KAJLA",
  "KAJLE",
  "KAJLI",
  "KAJLO",
  "KAJNE",
  "KAJPA",
  "KAJTA",
  "KAJTE",
  "KAJTI",
  "KAJTU",
  "KAJUT",
  "KAJŽA",
  "KAJŽE",
  "KAJŽI",
  "KAJŽO",
  "KAKAJ",
  "KAKAL",
  "KAKAM",
  "KAKAŠ",
  "KAKAT",
  "KAKAV",
  "KAKCA",
  "KAKCE",
  "KAKCI",
  "KAKCU",
  "KAKEC",
  "KAKEM",
  "KAKIH",
  "KAKIM",
  "KAKOJ",
  "KAKOR",
  "KALAH",
  "KALAM",
  "KALAN",
  "KALEČ",
  "KALEH",
  "KALEM",
  "KALEN",
  "KALEŽ",
  "KALIF",
  "KALIH",
  "KALIJ",
  "KALIL",
  "KALIM",
  "KALIN",
  "KALIŠ",
  "KALIT",
  "KALIV",
  "KALJO",
  "KALKA",
  "KALKE",
  "KALKI",
  "KALKU",
  "KALMI",
  "KALNA",
  "KALNE",
  "KALNI",
  "KALNO",
  "KALOM",
  "KALOT",
  "KALOV",
  "KALUP",
  "KALUS",
  "KALUŽ",
  "KAMAH",
  "KAMAM",
  "KAMBA",
  "KAMBE",
  "KAMBI",
  "KAMBO",
  "KAMEJ",
  "KAMEL",
  "KAMEN",
  "KAMER",
  "KAMIN",
  "KAMNA",
  "KAMNE",
  "KAMNI",
  "KAMNU",
  "KAMOR",
  "KAMPA",
  "KAMPE",
  "KAMPI",
  "KAMPO",
  "KAMPU",
  "KAMRA",
  "KAMRE",
  "KAMRI",
  "KAMRO",
  "KAMŠT",
  "KANAH",
  "KANAL",
  "KANAM",
  "KANAT",
  "KANCA",
  "KANCE",
  "KANCI",
  "KANCU",
  "KANEC",
  "KANEL",
  "KANEM",
  "KANEŠ",
  "KANIH",
  "KANIL",
  "KANIM",
  "KANIN",
  "KANIŠ",
  "KANIT",
  "KANJA",
  "KANJE",
  "KANJI",
  "KANJO",
  "KANOM",
  "KANON",
  "KANOP",
  "KANOV",
  "KANTA",
  "KANTE",
  "KANTI",
  "KANTO",
  "KAOSA",
  "KAOSE",
  "KAOSI",
  "KAOSU",
  "KAPAH",
  "KAPAJ",
  "KAPAL",
  "KAPAM",
  "KAPAN",
  "KAPAR",
  "KAPAŠ",
  "KAPAT",
  "KAPEH",
  "KAPEL",
  "KAPEM",
  "KAPEN",
  "KAPER",
  "KAPIC",
  "KAPIČ",
  "KAPIH",
  "KAPJO",
  "KAPMI",
  "KAPNA",
  "KAPNE",
  "KAPNI",
  "KAPNO",
  "KAPOK",
  "KAPOM",
  "KAPOV",
  "KAPRA",
  "KAPRE",
  "KAPRI",
  "KAPRN",
  "KAPRO",
  "KAPUC",
  "KAPUS",
  "KAPUT",
  "KARAF",
  "KARAJ",
  "KARAL",
  "KARAM",
  "KARAN",
  "KARAŠ",
  "KARAT",
  "KARET",
  "KARIH",
  "KARIN",
  "KARMA",
  "KARME",
  "KARMI",
  "KARMO",
  "KAROM",
  "KAROV",
  "KARTA",
  "KARTE",
  "KARTI",
  "KARTO",
  "KASAČ",
  "KASAV",
  "KASEN",
  "KASET",
  "KASKA",
  "KASKE",
  "KASKI",
  "KASKO",
  "KASKU",
  "KASNA",
  "KASNE",
  "KASNI",
  "KASNO",
  "KASOM",
  "KASTA",
  "KASTE",
  "KASTI",
  "KASTO",
  "KAŠAH",
  "KAŠAM",
  "KAŠČA",
  "KAŠČE",
  "KAŠČI",
  "KAŠČO",
  "KAŠEN",
  "KAŠER",
  "KAŠIC",
  "KAŠKA",
  "KAŠKE",
  "KAŠKI",
  "KAŠKO",
  "KAŠNA",
  "KAŠNE",
  "KAŠNI",
  "KAŠNO",
  "KATAH",
  "KATAM",
  "KATAN",
  "KATAR",
  "KATER",
  "KATET",
  "KATOD",
  "KATOM",
  "KATRA",
  "KATRC",
  "KATRE",
  "KATRI",
  "KATRO",
  "KATUN",
  "KAURI",
  "KAUSA",
  "KAUSE",
  "KAUSI",
  "KAUSO",
  "KAVAH",
  "KAVAL",
  "KAVAM",
  "KAVČA",
  "KAVČE",
  "KAVČI",
  "KAVČU",
  "KAVEN",
  "KAVER",
  "KAVIC",
  "KAVIN",
  "KAVKA",
  "KAVKE",
  "KAVKI",
  "KAVKO",
  "KAVNA",
  "KAVNE",
  "KAVNI",
  "KAVNO",
  "KAVRA",
  "KAVRE",
  "KAVRI",
  "KAVRU",
  "KAVSA",
  "KAVSE",
  "KAVSI",
  "KAVSU",
  "KAVZA",
  "KAVZE",
  "KAVZI",
  "KAVZO",
  "KAZAL",
  "KAZAT",
  "KAZEČ",
  "KAZEN",
  "KAZIL",
  "KAZIM",
  "KAZIŠ",
  "KAZIT",
  "KAZNI",
  "KAZUL",
  "KAŽEM",
  "KAŽEŠ",
  "KAŽOČ",
  "KDOVE",
  "KEAMA",
  "KEAMI",
  "KEBAB",
  "KEČAP",
  "KEČIG",
  "KEDAJ",
  "KEFIJ",
  "KEFIR",
  "KEHAH",
  "KEHAM",
  "KEKSA",
  "KEKSE",
  "KEKSI",
  "KEKSU",
  "KELAH",
  "KELAM",
  "KELAT",
  "KELIH",
  "KEMIK",
  "KENTA",
  "KENTE",
  "KENTI",
  "KENTO",
  "KEPAH",
  "KEPAJ",
  "KEPAL",
  "KEPAM",
  "KEPAŠ",
  "KEPAT",
  "KEPER",
  "KEPIC",
  "KEPRA",
  "KEPRE",
  "KEPRI",
  "KEPRU",
  "KEREM",
  "KERIH",
  "KERIJ",
  "KERIM",
  "KERLC",
  "KERUB",
  "KESAJ",
  "KESAL",
  "KESAM",
  "KESAŠ",
  "KESAT",
  "KESOM",
  "KESON",
  "KEŠEM",
  "KETEN",
  "KETNA",
  "KETNE",
  "KETNI",
  "KETNO",
  "KETOL",
  "KETON",
  "KETOZ",
  "KIANG",
  "KIBEL",
  "KIBIC",
  "KIBLA",
  "KIBLE",
  "KIBLI",
  "KIBLO",
  "KIBUC",
  "KIČEM",
  "KIDAJ",
  "KIDAL",
  "KIDAM",
  "KIDAŠ",
  "KIDAT",
  "KIFOZ",
  "KIHAJ",
  "KIHAL",
  "KIHAM",
  "KIHAŠ",
  "KIHAT",
  "KIHIH",
  "KIHNE",
  "KIHNI",
  "KIHOM",
  "KIHOV",
  "KIJCA",
  "KIJCE",
  "KIJCI",
  "KIJCU",
  "KIJEC",
  "KIJEM",
  "KIJEV",
  "KIJIH",
  "KIKEL",
  "KIKLA",
  "KIKLE",
  "KIKLI",
  "KIKLO",
  "KIKSA",
  "KIKSE",
  "KIKSI",
  "KIKSU",
  "KILAH",
  "KILAM",
  "KILAV",
  "KILAŽ",
  "KILEN",
  "KILER",
  "KILHA",
  "KILHE",
  "KILHI",
  "KILHU",
  "KILNA",
  "KILNE",
  "KILNI",
  "KILNO",
  "KILTA",
  "KILTE",
  "KILTI",
  "KILTU",
  "KIMAJ",
  "KIMAL",
  "KIMAM",
  "KIMAŠ",
  "KIMAT",
  "KIMAV",
  "KIMČI",
  "KINAZ",
  "KINET",
  "KINIH",
  "KINIK",
  "KININ",
  "KINKA",
  "KINOM",
  "KINON",
  "KINOV",
  "KIOSK",
  "KIPAJ",
  "KIPAL",
  "KIPAM",
  "KIPAR",
  "KIPAŠ",
  "KIPAT",
  "KIPCA",
  "KIPCE",
  "KIPCI",
  "KIPCU",
  "KIPEC",
  "KIPEČ",
  "KIPEL",
  "KIPER",
  "KIPET",
  "KIPIH",
  "KIPIM",
  "KIPIŠ",
  "KIPOM",
  "KIPOV",
  "KIRET",
  "KIRIJ",
  "KIRNJ",
  "KISAJ",
  "KISAL",
  "KISAM",
  "KISAN",
  "KISAŠ",
  "KISAT",
  "KISEL",
  "KISIH",
  "KISIK",
  "KISLA",
  "KISLE",
  "KISLI",
  "KISLO",
  "KISOM",
  "KISOV",
  "KIŠTA",
  "KIŠTE",
  "KIŠTI",
  "KIŠTO",
  "KITAH",
  "KITAJ",
  "KITAL",
  "KITAM",
  "KITAR",
  "KITAŠ",
  "KITAT",
  "KITEN",
  "KITIC",
  "KITIH",
  "KITIL",
  "KITIM",
  "KITIŠ",
  "KITIT",
  "KITKA",
  "KITKE",
  "KITKI",
  "KITKO",
  "KITNA",
  "KITNE",
  "KITNI",
  "KITNO",
  "KITOM",
  "KITOV",
  "KIVET",
  "KJUŠU",
  "KLADA",
  "KLADE",
  "KLADI",
  "KLADO",
  "KLADU",
  "KLAJA",
  "KLAJE",
  "KLAJI",
  "KLAJO",
  "KLAKA",
  "KLAKE",
  "KLAKI",
  "KLAKO",
  "KLALA",
  "KLALE",
  "KLALI",
  "KLALO",
  "KLAMA",
  "KLAMF",
  "KLANA",
  "KLANE",
  "KLANI",
  "KLANJ",
  "KLANO",
  "KLANU",
  "KLAPA",
  "KLAPE",
  "KLAPI",
  "KLAPO",
  "KLASA",
  "KLASE",
  "KLASI",
  "KLASU",
  "KLATI",
  "KLAVK",
  "KLAVŽ",
  "KLECA",
  "KLEČE",
  "KLEČI",
  "KLEJA",
  "KLEJE",
  "KLEJI",
  "KLEJU",
  "KLEKA",
  "KLEKE",
  "KLEKI",
  "KLEKU",
  "KLELA",
  "KLELE",
  "KLELI",
  "KLELO",
  "KLEMA",
  "KLEME",
  "KLEMI",
  "KLEMO",
  "KLENA",
  "KLENE",
  "KLENI",
  "KLENO",
  "KLENU",
  "KLERA",
  "KLERU",
  "KLEŠČ",
  "KLEŠE",
  "KLEŠI",
  "KLETI",
  "KLETK",
  "KLICA",
  "KLICE",
  "KLICI",
  "KLICO",
  "KLICU",
  "KLIČE",
  "KLIČI",
  "KLIFA",
  "KLIFE",
  "KLIFI",
  "KLIFU",
  "KLIJE",
  "KLIKA",
  "KLIKE",
  "KLIKI",
  "KLIKO",
  "KLIKU",
  "KLILA",
  "KLILE",
  "KLILI",
  "KLILO",
  "KLIMA",
  "KLIME",
  "KLIMI",
  "KLIMO",
  "KLINA",
  "KLINC",
  "KLINČ",
  "KLINE",
  "KLINI",
  "KLINO",
  "KLINU",
  "KLIŠE",
  "KLITI",
  "KLJUB",
  "KLJUČ",
  "KLJUJ",
  "KLJUK",
  "KLJUN",
  "KLOAK",
  "KLOBK",
  "KLOFA",
  "KLONA",
  "KLONE",
  "KLONI",
  "KLONU",
  "KLOPA",
  "KLOPC",
  "KLOPE",
  "KLOPI",
  "KLOPU",
  "KLORA",
  "KLORU",
  "KLOVN",
  "KLOŽA",
  "KLOŽE",
  "KLOŽI",
  "KLOŽO",
  "KLUBA",
  "KLUBE",
  "KLUBI",
  "KLUBU",
  "KLUMP",
  "KMALU",
  "KMETA",
  "KMETE",
  "KMETI",
  "KMETU",
  "KNAPA",
  "KNAPE",
  "KNAPI",
  "KNAPU",
  "KNEZA",
  "KNEZE",
  "KNEZI",
  "KNEZU",
  "KNJIG",
  "KNOFA",
  "KNOFE",
  "KNOFI",
  "KNOFU",
  "KNUTA",
  "KNUTE",
  "KNUTI",
  "KNUTO",
  "KOALA",
  "KOALE",
  "KOALI",
  "KOALO",
  "KOANA",
  "KOANE",
  "KOANI",
  "KOANU",
  "KOATA",
  "KOATE",
  "KOATI",
  "KOATO",
  "KOBAL",
  "KOBER",
  "KOBIL",
  "KOBLA",
  "KOBLE",
  "KOBLI",
  "KOBLO",
  "KOBRA",
  "KOBRE",
  "KOBRI",
  "KOBRO",
  "KOBUL",
  "KOCEM",
  "KOCEN",
  "KOCEV",
  "KOCIH",
  "KOCIN",
  "KOCKA",
  "KOCKE",
  "KOCKI",
  "KOCKO",
  "KOČAH",
  "KOČAM",
  "KOČAN",
  "KOČAR",
  "KOČEN",
  "KOČIC",
  "KOČIČ",
  "KOČIJ",
  "KOČKA",
  "KOČKE",
  "KOČKI",
  "KOČKO",
  "KOČNA",
  "KOČNE",
  "KOČNI",
  "KOČNO",
  "KOČUR",
  "KODAH",
  "KODAM",
  "KODEK",
  "KODEN",
  "KODER",
  "KODIH",
  "KODNA",
  "KODNE",
  "KODNI",
  "KODNO",
  "KODOM",
  "KODON",
  "KODOV",
  "KODRA",
  "KODRC",
  "KODRE",
  "KODRI",
  "KODRU",
  "KOFOZ",
  "KOGAN",
  "KOGAR",
  "KOGEL",
  "KOGLA",
  "KOGLU",
  "KOHAR",
  "KOJEM",
  "KOJIH",
  "KOJIM",
  "KOJOT",
  "KOKAH",
  "KOKAM",
  "KOKER",
  "KOKET",
  "KOKIC",
  "KOKIH",
  "KOKIL",
  "KOKOM",
  "KOKON",
  "KOKOS",
  "KOKOŠ",
  "KOKOV",
  "KOKRA",
  "KOKRE",
  "KOKRI",
  "KOKRO",
  "KOKSA",
  "KOKSU",
  "KOLAČ",
  "KOLAH",
  "KOLAM",
  "KOLAR",
  "KOLAŽ",
  "KOLCA",
  "KOLCE",
  "KOLCI",
  "KOLCU",
  "KOLČE",
  "KOLČI",
  "KOLEB",
  "KOLEH",
  "KOLEK",
  "KOLEN",
  "KOLES",
  "KOLIB",
  "KOLIH",
  "KOLIK",
  "KOLIN",
  "KOLJE",
  "KOLJI",
  "KOLKA",
  "KOLKE",
  "KOLKI",
  "KOLKU",
  "KOLMA",
  "KOLMI",
  "KOLMU",
  "KOLNE",
  "KOLNI",
  "KOLOM",
  "KOLON",
  "KOLOS",
  "KOLOV",
  "KOLPA",
  "KOLPE",
  "KOLPI",
  "KOLPO",
  "KOLTA",
  "KOLTE",
  "KOLTI",
  "KOLTU",
  "KOLUT",
  "KOMAD",
  "KOMAH",
  "KOMAJ",
  "KOMAM",
  "KOMAR",
  "KOMAT",
  "KOMBI",
  "KOMER",
  "KOMET",
  "KOMIK",
  "KOMIS",
  "KOMIT",
  "KOMNA",
  "KOMNE",
  "KOMNI",
  "KOMNO",
  "KOMOD",
  "KOMOR",
  "KOMOT",
  "KOMPA",
  "KOMPE",
  "KOMPI",
  "KOMPU",
  "KOMUN",
  "KOMUR",
  "KONCA",
  "KONCE",
  "KONCI",
  "KONCU",
  "KONČA",
  "KONEC",
  "KONGA",
  "KONGO",
  "KONGU",
  "KONHA",
  "KONHE",
  "KONHI",
  "KONHO",
  "KONIC",
  "KONJA",
  "KONJC",
  "KONJE",
  "KONJI",
  "KONJU",
  "KONTA",
  "KONTE",
  "KONTI",
  "KONTO",
  "KONTU",
  "KONUS",
  "KONZI",
  "KOPAČ",
  "KOPAH",
  "KOPAJ",
  "KOPAL",
  "KOPAM",
  "KOPAN",
  "KOPAŠ",
  "KOPAT",
  "KOPEL",
  "KOPEN",
  "KOPER",
  "KOPIC",
  "KOPIH",
  "KOPIJ",
  "KOPIT",
  "KOPJA",
  "KOPJE",
  "KOPJI",
  "KOPJU",
  "KOPNA",
  "KOPNE",
  "KOPNI",
  "KOPNO",
  "KOPOM",
  "KOPOV",
  "KOPRA",
  "KOPRC",
  "KOPRU",
  "KOPUČ",
  "KOPUL",
  "KOPUN",
  "KORAB",
  "KORAK",
  "KORAL",
  "KORAR",
  "KORBA",
  "KORBE",
  "KORBI",
  "KORBO",
  "KORCA",
  "KORCE",
  "KORCI",
  "KORCU",
  "KOREC",
  "KOREN",
  "KORET",
  "KORID",
  "KORIH",
  "KORIT",
  "KORMA",
  "KORME",
  "KORMI",
  "KORMU",
  "KORNA",
  "KORNE",
  "KORNI",
  "KORNO",
  "KOROM",
  "KORON",
  "KOROV",
  "KORZA",
  "KORZE",
  "KORZI",
  "KORZO",
  "KORZU",
  "KOSAH",
  "KOSAJ",
  "KOSAL",
  "KOSAM",
  "KOSAŠ",
  "KOSAT",
  "KOSCA",
  "KOSCE",
  "KOSCI",
  "KOSCU",
  "KOSEC",
  "KOSEČ",
  "KOSEM",
  "KOSIC",
  "KOSIH",
  "KOSIL",
  "KOSIM",
  "KOSIŠ",
  "KOSIT",
  "KOSJA",
  "KOSJE",
  "KOSJI",
  "KOSJO",
  "KOSMA",
  "KOSME",
  "KOSMI",
  "KOSMU",
  "KOSOM",
  "KOSOV",
  "KOSTI",
  "KOŠAR",
  "KOŠAT",
  "KOŠEK",
  "KOŠEM",
  "KOŠEN",
  "KOŠER",
  "KOŠEV",
  "KOŠIH",
  "KOŠKA",
  "KOŠKE",
  "KOŠKI",
  "KOŠKO",
  "KOŠKU",
  "KOŠNA",
  "KOŠNE",
  "KOŠNI",
  "KOŠNO",
  "KOŠTA",
  "KOŠTE",
  "KOŠTI",
  "KOŠTO",
  "KOŠUT",
  "KOTAČ",
  "KOTAH",
  "KOTAM",
  "KOTAR",
  "KOTEČ",
  "KOTEL",
  "KOTEN",
  "KOTEV",
  "KOTIH",
  "KOTIL",
  "KOTIM",
  "KOTIŠ",
  "KOTIT",
  "KOTLA",
  "KOTLE",
  "KOTLI",
  "KOTLU",
  "KOTNA",
  "KOTNE",
  "KOTNI",
  "KOTNO",
  "KOTOM",
  "KOTOV",
  "KOTVA",
  "KOTVE",
  "KOTVI",
  "KOTVO",
  "KOVAČ",
  "KOVAL",
  "KOVAN",
  "KOVAT",
  "KOVČA",
  "KOVČE",
  "KOVČI",
  "KOVČU",
  "KOVEN",
  "KOVIC",
  "KOVID",
  "KOVIN",
  "KOVJO",
  "KOVNA",
  "KOVNE",
  "KOVNI",
  "KOVNO",
  "KOVOM",
  "KOZAČ",
  "KOZAH",
  "KOZAK",
  "KOZAM",
  "KOZAV",
  "KOZEL",
  "KOZIC",
  "KOZJA",
  "KOZJE",
  "KOZJI",
  "KOZJO",
  "KOZLA",
  "KOZLE",
  "KOZLI",
  "KOZLU",
  "KOŽAH",
  "KOŽAM",
  "KOŽAR",
  "KOŽEN",
  "KOŽIC",
  "KOŽNA",
  "KOŽNE",
  "KOŽNI",
  "KOŽNO",
  "KOŽUH",
  "KRACA",
  "KRAČA",
  "KRAČE",
  "KRAČI",
  "KRAČO",
  "KRADE",
  "KRADI",
  "KRAFA",
  "KRAFE",
  "KRAFI",
  "KRAFU",
  "KRAHA",
  "KRAHE",
  "KRAHI",
  "KRAHU",
  "KRAJA",
  "KRAJE",
  "KRAJI",
  "KRAJO",
  "KRAJT",
  "KRAJU",
  "KRAKA",
  "KRAKE",
  "KRAKI",
  "KRAKU",
  "KRALJ",
  "KRAMA",
  "KRAME",
  "KRAMI",
  "KRAMO",
  "KRAMP",
  "KRAPA",
  "KRAPE",
  "KRAPI",
  "KRAPU",
  "KRASA",
  "KRASI",
  "KRAST",
  "KRASU",
  "KRATA",
  "KRATE",
  "KRATI",
  "KRATU",
  "KRAVA",
  "KRAVE",
  "KRAVI",
  "KRAVL",
  "KRAVO",
  "KRAVS",
  "KRCAJ",
  "KRCAL",
  "KRCAM",
  "KRCAN",
  "KRCAŠ",
  "KRCAT",
  "KRCEM",
  "KRCEV",
  "KRCIH",
  "KRCNE",
  "KRCNI",
  "KRČEČ",
  "KRČEM",
  "KRČEN",
  "KRČEV",
  "KRČIH",
  "KRČIL",
  "KRČIM",
  "KRČIŠ",
  "KRČIT",
  "KRČMA",
  "KRČME",
  "KRČMI",
  "KRČMO",
  "KRČNA",
  "KRČNE",
  "KRČNI",
  "KRČNO",
  "KRDEL",
  "KREDA",
  "KREDE",
  "KREDI",
  "KREDO",
  "KREDU",
  "KREGA",
  "KREGE",
  "KREGI",
  "KREGU",
  "KREHA",
  "KREKA",
  "KREKU",
  "KREMA",
  "KREME",
  "KREMI",
  "KREMO",
  "KRENE",
  "KRENI",
  "KREOL",
  "KREPA",
  "KREPE",
  "KREPI",
  "KREPU",
  "KRESA",
  "KRESE",
  "KRESI",
  "KRESU",
  "KREŠA",
  "KREŠE",
  "KREŠI",
  "KREŠO",
  "KRETA",
  "KRETE",
  "KRETI",
  "KRETO",
  "KRHAJ",
  "KRHAL",
  "KRHAM",
  "KRHAŠ",
  "KRHAT",
  "KRHEK",
  "KRHKA",
  "KRHKE",
  "KRHKI",
  "KRHKO",
  "KRIČE",
  "KRIČI",
  "KRIJE",
  "KRIKA",
  "KRIKE",
  "KRIKI",
  "KRIKU",
  "KRILA",
  "KRILC",
  "KRILE",
  "KRILI",
  "KRILK",
  "KRILO",
  "KRILU",
  "KRIMA",
  "KRIMU",
  "KRINK",
  "KRIPA",
  "KRIPE",
  "KRIPI",
  "KRIPO",
  "KRIPT",
  "KRITA",
  "KRITE",
  "KRITI",
  "KRITO",
  "KRIVA",
  "KRIVD",
  "KRIVE",
  "KRIVI",
  "KRIVK",
  "KRIVO",
  "KRIZA",
  "KRIZE",
  "KRIZI",
  "KRIZO",
  "KRIŽA",
  "KRIŽE",
  "KRIŽI",
  "KRIŽU",
  "KRKOM",
  "KRKON",
  "KRLJA",
  "KRLJE",
  "KRLJI",
  "KRLJU",
  "KRMAH",
  "KRMAM",
  "KRMAR",
  "KRMEN",
  "KRMIL",
  "KRMIM",
  "KRMIŠ",
  "KRMIT",
  "KRMNA",
  "KRMNE",
  "KRMNI",
  "KRMNO",
  "KRNEČ",
  "KRNEL",
  "KRNET",
  "KRNIC",
  "KRNIH",
  "KRNIL",
  "KRNIM",
  "KRNIŠ",
  "KRNIT",
  "KRNIŽ",
  "KRNOM",
  "KRNOV",
  "KROFA",
  "KROFE",
  "KROFI",
  "KROFU",
  "KROGA",
  "KROGE",
  "KROGI",
  "KROGU",
  "KROJA",
  "KROJE",
  "KROJI",
  "KROJU",
  "KROKA",
  "KROKI",
  "KROMA",
  "KROMU",
  "KRONA",
  "KRONE",
  "KRONI",
  "KRONO",
  "KROPA",
  "KROPE",
  "KROPI",
  "KROPU",
  "KROSA",
  "KROSE",
  "KROSI",
  "KROSU",
  "KROŠE",
  "KROTA",
  "KROTE",
  "KROTI",
  "KROTO",
  "KROVA",
  "KROVE",
  "KROVI",
  "KROVK",
  "KROVU",
  "KROŽE",
  "KROŽI",
  "KRPAČ",
  "KRPAH",
  "KRPAJ",
  "KRPAL",
  "KRPAM",
  "KRPAR",
  "KRPAŠ",
  "KRPAT",
  "KRPIC",
  "KRSTA",
  "KRSTE",
  "KRSTI",
  "KRSTO",
  "KRSTU",
  "KRŠCA",
  "KRŠCE",
  "KRŠCI",
  "KRŠCU",
  "KRŠEC",
  "KRŠEČ",
  "KRŠEN",
  "KRŠIL",
  "KRŠIM",
  "KRŠIN",
  "KRŠIŠ",
  "KRŠIT",
  "KRŠJA",
  "KRŠJE",
  "KRŠJU",
  "KRŠKA",
  "KRŠKE",
  "KRŠKI",
  "KRŠKO",
  "KRTAČ",
  "KRTEK",
  "KRTIC",
  "KRTIH",
  "KRTIN",
  "KRTKA",
  "KRTKE",
  "KRTKI",
  "KRTKU",
  "KRTOM",
  "KRTOV",
  "KRUHA",
  "KRUHU",
  "KRULI",
  "KRUPA",
  "KRUPE",
  "KRUPI",
  "KRUPO",
  "KRUPU",
  "KRUŠI",
  "KRUTA",
  "KRUTE",
  "KRUTI",
  "KRUTO",
  "KRVAV",
  "KRVEN",
  "KRVJO",
  "KRVNA",
  "KRVNE",
  "KRVNI",
  "KRVNO",
  "KRZEN",
  "KRZNA",
  "KRZNI",
  "KRZNO",
  "KRZNU",
  "KSIHT",
  "KSIJA",
  "KSIJE",
  "KSIJI",
  "KSIJU",
  "KŠEFT",
  "KUBIH",
  "KUBIK",
  "KUBIT",
  "KUBOM",
  "KUBOV",
  "KUBUS",
  "KUČEM",
  "KUČMA",
  "KUČME",
  "KUČMI",
  "KUČMO",
  "KUFER",
  "KUFRA",
  "KUFRE",
  "KUFRI",
  "KUFRU",
  "KUGAH",
  "KUGAM",
  "KUGEL",
  "KUGLA",
  "KUGLE",
  "KUGLI",
  "KUGLO",
  "KUHAJ",
  "KUHAL",
  "KUHAM",
  "KUHAN",
  "KUHAR",
  "KUHAŠ",
  "KUHAT",
  "KUJAJ",
  "KUJAL",
  "KUJAM",
  "KUJAŠ",
  "KUJAT",
  "KUJAV",
  "KUJEM",
  "KUJEŠ",
  "KUJMO",
  "KUJOČ",
  "KUJON",
  "KUJTA",
  "KUJTE",
  "KUJVA",
  "KUKAJ",
  "KUKAL",
  "KUKAM",
  "KUKAŠ",
  "KUKAT",
  "KUKCA",
  "KUKCE",
  "KUKCI",
  "KUKCU",
  "KUKEB",
  "KUKEC",
  "KULAH",
  "KULAK",
  "KULAM",
  "KULAN",
  "KULEN",
  "KULIC",
  "KULIS",
  "KULMA",
  "KULMU",
  "KULON",
  "KULTA",
  "KULTE",
  "KULTI",
  "KULTU",
  "KUMAR",
  "KUMIN",
  "KUMIS",
  "KUMOM",
  "KUMRN",
  "KUNAH",
  "KUNAM",
  "KUNCA",
  "KUNCE",
  "KUNCI",
  "KUNCU",
  "KUNEC",
  "KUNJA",
  "KUNJE",
  "KUNJI",
  "KUNJO",
  "KUNŠT",
  "KUPAH",
  "KUPAM",
  "KUPCA",
  "KUPCE",
  "KUPCI",
  "KUPCU",
  "KUPEC",
  "KUPEN",
  "KUPIC",
  "KUPIH",
  "KUPIL",
  "KUPIM",
  "KUPIŠ",
  "KUPIT",
  "KUPKA",
  "KUPKE",
  "KUPKI",
  "KUPKO",
  "KUPNA",
  "KUPNE",
  "KUPNI",
  "KUPNO",
  "KUPOL",
  "KUPOM",
  "KUPON",
  "KUPOV",
  "KUPUJ",
  "KURAC",
  "KURAH",
  "KURAM",
  "KURAT",
  "KURBA",
  "KURBE",
  "KURBI",
  "KURBO",
  "KURCA",
  "KURCE",
  "KURCI",
  "KURCU",
  "KUREC",
  "KUREČ",
  "KURIJ",
  "KURIL",
  "KURIM",
  "KURIR",
  "KURIŠ",
  "KURIT",
  "KURIV",
  "KURJA",
  "KURJE",
  "KURJI",
  "KURJO",
  "KUROL",
  "KURZA",
  "KURZE",
  "KURZI",
  "KURZU",
  "KUŠNE",
  "KUŠNI",
  "KUŠUJ",
  "KUTAH",
  "KUTAM",
  "KUTER",
  "KUTIN",
  "KUZEL",
  "KUZLA",
  "KUZLE",
  "KUZLI",
  "KUZLO",
  "KUŽAH",
  "KUŽAM",
  "KUŽEK",
  "KUŽEN",
  "KUŽET",
  "KUŽEV",
  "KUŽIL",
  "KUŽKA",
  "KUŽKE",
  "KUŽKI",
  "KUŽKU",
  "KUŽNA",
  "KUŽNE",
  "KUŽNI",
  "KUŽNO",
  "KVAČK",
  "KVAGA",
  "KVAGE",
  "KVAGI",
  "KVAGO",
  "KVAKA",
  "KVANT",
  "KVARC",
  "KVARI",
  "KVARK",
  "KVART",
  "KVASA",
  "KVASE",
  "KVASI",
  "KVASU",
  "KVAŠA",
  "KVAŠE",
  "KVAŠI",
  "KVAŠO",
  "KVAZI",
  "KVIAZ",
  "KVIHT",
  "KVINT",
  "KVIZA",
  "KVIZE",
  "KVIZI",
  "KVIZU",
  "KVOTA",
  "KVOTE",
  "KVOTI",
  "KVOTO",
  "LABIH",
  "LABOD",
  "LABOM",
  "LABOV",
  "LAČEN",
  "LAČNA",
  "LAČNE",
  "LAČNI",
  "LAČNO",
  "LADIJ",
  "LADJA",
  "LADJE",
  "LADJI",
  "LADJO",
  "LAFET",
  "LAGAL",
  "LAGAT",
  "LAGER",
  "LAGUN",
  "LAHEK",
  "LAHEN",
  "LAHET",
  "LAHKA",
  "LAHKE",
  "LAHKI",
  "LAHKO",
  "LAHNA",
  "LAHNE",
  "LAHNI",
  "LAHNO",
  "LAHTI",
  "LAIKA",
  "LAIKE",
  "LAIKI",
  "LAIKU",
  "LAJAJ",
  "LAJAL",
  "LAJAM",
  "LAJAŠ",
  "LAJAT",
  "LAJAV",
  "LAJEŽ",
  "LAJFA",
  "LAJFE",
  "LAJFI",
  "LAJFU",
  "LAJHA",
  "LAJKA",
  "LAJKE",
  "LAJKI",
  "LAJKU",
  "LAJNA",
  "LAJNE",
  "LAJNI",
  "LAJNO",
  "LAJŠA",
  "LAJŠT",
  "LAKAJ",
  "LAKAZ",
  "LAKET",
  "LAKIH",
  "LAKOM",
  "LAKOT",
  "LAKOV",
  "LAKSA",
  "LAKSE",
  "LAKSI",
  "LAKSU",
  "LAKTA",
  "LAKTE",
  "LAKTI",
  "LAKTU",
  "LAKUN",
  "LAMAH",
  "LAMAM",
  "LAMBD",
  "LAMEL",
  "LAMET",
  "LAMJA",
  "LAMJE",
  "LAMJI",
  "LAMJO",
  "LAMPA",
  "LAMPE",
  "LAMPI",
  "LAMPO",
  "LANEN",
  "LANIK",
  "LANOM",
  "LAOSA",
  "LAOSU",
  "LAPAH",
  "LAPAJ",
  "LAPAL",
  "LAPAM",
  "LAPAŠ",
  "LAPIS",
  "LAPOR",
  "LAPUH",
  "LARFA",
  "LARFE",
  "LARFI",
  "LARFO",
  "LARGA",
  "LARGE",
  "LARGI",
  "LARGO",
  "LARGU",
  "LARIH",
  "LAROM",
  "LAROV",
  "LARVA",
  "LARVE",
  "LARVI",
  "LARVO",
  "LASAJ",
  "LASAL",
  "LASAM",
  "LASAŠ",
  "LASAT",
  "LASCA",
  "LASCE",
  "LASCI",
  "LASCU",
  "LASEC",
  "LASEH",
  "LASEK",
  "LASEM",
  "LASEN",
  "LASER",
  "LASIH",
  "LASJE",
  "LASKA",
  "LASKE",
  "LASKI",
  "LASKU",
  "LASMI",
  "LASNA",
  "LASNE",
  "LASNI",
  "LASNO",
  "LASOM",
  "LASOV",
  "LASTI",
  "LAŠKA",
  "LAŠKE",
  "LAŠKI",
  "LAŠKO",
  "LATAH",
  "LATAM",
  "LATEN",
  "LATIC",
  "LATIH",
  "LATJA",
  "LATJE",
  "LATJU",
  "LATOM",
  "LATOV",
  "LATSA",
  "LATSE",
  "LATSI",
  "LATSU",
  "LAUFA",
  "LAVAH",
  "LAVAM",
  "LAVAŽ",
  "LAVEN",
  "LAVIC",
  "LAVIN",
  "LAVNA",
  "LAVNE",
  "LAVNI",
  "LAVNO",
  "LAVOR",
  "LAZAR",
  "LAZEČ",
  "LAZIC",
  "LAZIH",
  "LAZIL",
  "LAZIM",
  "LAZIŠ",
  "LAZIT",
  "LAZOM",
  "LAZOV",
  "LAZUR",
  "LAŽEH",
  "LAŽEM",
  "LAŽEN",
  "LAŽEŠ",
  "LAŽJA",
  "LAŽJE",
  "LAŽJI",
  "LAŽJO",
  "LAŽMI",
  "LAŽNA",
  "LAŽNE",
  "LAŽNI",
  "LAŽNO",
  "LEBDE",
  "LEBDI",
  "LECAH",
  "LECAM",
  "LECTA",
  "LECTE",
  "LECTI",
  "LECTU",
  "LEČAH",
  "LEČAM",
  "LEČEČ",
  "LEČEN",
  "LEČIC",
  "LEČIJ",
  "LEČIL",
  "LEČIM",
  "LEČIŠ",
  "LEČJA",
  "LEČJE",
  "LEČJI",
  "LEČJU",
  "LEČNA",
  "LEČNE",
  "LEČNI",
  "LEČNO",
  "LEDAR",
  "LEDEN",
  "LEDER",
  "LEDIC",
  "LEDIH",
  "LEDIJ",
  "LEDIK",
  "LEDIN",
  "LEDJA",
  "LEDJE",
  "LEDJI",
  "LEDJU",
  "LEDNA",
  "LEDNE",
  "LEDNI",
  "LEDNO",
  "LEDOM",
  "LEDOV",
  "LEDRA",
  "LEDRU",
  "LEGAH",
  "LEGAJ",
  "LEGAL",
  "LEGAM",
  "LEGAR",
  "LEGAŠ",
  "LEGAT",
  "LEGEL",
  "LEGIC",
  "LEGIJ",
  "LEGLA",
  "LEGLE",
  "LEGLI",
  "LEGLO",
  "LEGLU",
  "LEGUR",
  "LEHAH",
  "LEHAM",
  "LEJDI",
  "LEJEM",
  "LEJEV",
  "LEJIH",
  "LEKIH",
  "LEKOM",
  "LEKOV",
  "LEMAH",
  "LEMAM",
  "LEMEŽ",
  "LEMUR",
  "LENEČ",
  "LENEM",
  "LENGA",
  "LENGE",
  "LENGI",
  "LENGU",
  "LENIČ",
  "LENIH",
  "LENIL",
  "LENIM",
  "LENIŠ",
  "LENIT",
  "LENIV",
  "LENOB",
  "LENTA",
  "LENTE",
  "LENTI",
  "LENTO",
  "LENTU",
  "LENUH",
  "LEPAK",
  "LEPEČ",
  "LEPEK",
  "LEPEL",
  "LEPEM",
  "LEPEN",
  "LEPET",
  "LEPIC",
  "LEPIH",
  "LEPIL",
  "LEPIM",
  "LEPIŠ",
  "LEPIT",
  "LEPKA",
  "LEPKE",
  "LEPKI",
  "LEPKO",
  "LEPKU",
  "LEPOT",
  "LEPRA",
  "LEPRE",
  "LEPRI",
  "LEPRO",
  "LEPŠA",
  "LEPŠE",
  "LEPŠI",
  "LEPŠO",
  "LEROM",
  "LESAH",
  "LESAM",
  "LESAR",
  "LESEN",
  "LESIH",
  "LESKA",
  "LESKE",
  "LESKI",
  "LESKO",
  "LESKU",
  "LESNA",
  "LESNE",
  "LESNI",
  "LESNO",
  "LESOM",
  "LESOV",
  "LESTI",
  "LEŠKA",
  "LEŠKE",
  "LEŠKI",
  "LEŠKO",
  "LETAJ",
  "LETAK",
  "LETAL",
  "LETAM",
  "LETAŠ",
  "LETAT",
  "LETEČ",
  "LETEL",
  "LETEN",
  "LETET",
  "LETEV",
  "LETIH",
  "LETIM",
  "LETIN",
  "LETIŠ",
  "LETNA",
  "LETNE",
  "LETNI",
  "LETNO",
  "LETOM",
  "LETOS",
  "LETOV",
  "LETUJ",
  "LETVE",
  "LETVI",
  "LEVEČ",
  "LEVEM",
  "LEVIC",
  "LEVIČ",
  "LEVIH",
  "LEVIL",
  "LEVIM",
  "LEVIŠ",
  "LEVIT",
  "LEVJA",
  "LEVJE",
  "LEVJI",
  "LEVJO",
  "LEVOM",
  "LEVOV",
  "LEVTA",
  "LEVTE",
  "LEVTI",
  "LEVTU",
  "LEZBA",
  "LEZBE",
  "LEZBI",
  "LEZBO",
  "LEZEL",
  "LEZEM",
  "LEZEŠ",
  "LEZIJ",
  "LEZLA",
  "LEZLE",
  "LEZLI",
  "LEZLO",
  "LEZOČ",
  "LEŽAJ",
  "LEŽAK",
  "LEŽAL",
  "LEŽAT",
  "LEŽEČ",
  "LEŽEM",
  "LEŽEN",
  "LEŽEŠ",
  "LEŽIM",
  "LEŽIŠ",
  "LEŽNA",
  "LEŽNE",
  "LEŽNI",
  "LEŽNO",
  "LIAZA",
  "LIAZE",
  "LIAZI",
  "LIAZO",
  "LIBAH",
  "LIBAM",
  "LIBEL",
  "LIBER",
  "LIBRA",
  "LIBRE",
  "LIBRI",
  "LIBRO",
  "LICEJ",
  "LICEM",
  "LICIH",
  "LIČAN",
  "LIČAR",
  "LIČEC",
  "LIČEČ",
  "LIČEK",
  "LIČEN",
  "LIČIL",
  "LIČIM",
  "LIČIŠ",
  "LIČIT",
  "LIČJA",
  "LIČJE",
  "LIČJU",
  "LIČKA",
  "LIČKE",
  "LIČKI",
  "LIČKU",
  "LIČNA",
  "LIČNE",
  "LIČNI",
  "LIČNO",
  "LIDAR",
  "LIDER",
  "LIFTA",
  "LIFTE",
  "LIFTI",
  "LIFTU",
  "LIGAH",
  "LIGAM",
  "LIGAŠ",
  "LIGAZ",
  "LIGER",
  "LIGRA",
  "LIGRE",
  "LIGRI",
  "LIGRU",
  "LIHEM",
  "LIHIH",
  "LIHIM",
  "LIJAK",
  "LIJAN",
  "LIJEM",
  "LIJEŠ",
  "LIJEV",
  "LIJIH",
  "LIJMO",
  "LIJOČ",
  "LIJTA",
  "LIJTE",
  "LIJVA",
  "LIKAJ",
  "LIKAL",
  "LIKAM",
  "LIKAR",
  "LIKAŠ",
  "LIKAT",
  "LIKER",
  "LIKIH",
  "LIKOF",
  "LIKOM",
  "LIKOV",
  "LILIJ",
  "LIMAJ",
  "LIMAL",
  "LIMAM",
  "LIMAN",
  "LIMAŠ",
  "LIMAT",
  "LIMBA",
  "LIMBE",
  "LIMBI",
  "LIMBU",
  "LIMEL",
  "LIMES",
  "LIMET",
  "LIMFA",
  "LIMFE",
  "LIMFI",
  "LIMFO",
  "LIMIH",
  "LIMIT",
  "LIMLA",
  "LIMLE",
  "LIMLI",
  "LIMLU",
  "LIMOM",
  "LIMON",
  "LIMOV",
  "LINAH",
  "LINAM",
  "LINČA",
  "LINČE",
  "LINČI",
  "LINČU",
  "LINIC",
  "LINIJ",
  "LINJA",
  "LINJE",
  "LINJI",
  "LINJU",
  "LINKA",
  "LINKE",
  "LINKI",
  "LINKU",
  "LIPAH",
  "LIPAM",
  "LIPAN",
  "LIPAZ",
  "LIPIC",
  "LIPID",
  "LIPOM",
  "LIPOV",
  "LIRAH",
  "LIRAM",
  "LIREŠ",
  "LIRIK",
  "LISAH",
  "LISAJ",
  "LISAL",
  "LISAM",
  "LISAN",
  "LISAR",
  "LISAŠ",
  "LISAT",
  "LISCA",
  "LISCE",
  "LISCI",
  "LISCO",
  "LISCU",
  "LISEC",
  "LISIC",
  "LISKA",
  "LISKE",
  "LISKI",
  "LISKO",
  "LISTA",
  "LISTE",
  "LISTI",
  "LISTO",
  "LISTU",
  "LIŠAJ",
  "LIŠKA",
  "LIŠKE",
  "LIŠKI",
  "LIŠKO",
  "LIŠPA",
  "LIŠPE",
  "LIŠPI",
  "LIŠPU",
  "LITAS",
  "LITEM",
  "LITER",
  "LITIH",
  "LITIJ",
  "LITIM",
  "LITIN",
  "LITJA",
  "LITJE",
  "LITJI",
  "LITJU",
  "LITOT",
  "LITRA",
  "LITRE",
  "LITRI",
  "LITRU",
  "LITVA",
  "LITVE",
  "LITVI",
  "LITVO",
  "LIVAD",
  "LIVAR",
  "LIVEN",
  "LIVER",
  "LIVIH",
  "LIVKA",
  "LIVKE",
  "LIVKI",
  "LIVKO",
  "LIVNA",
  "LIVNE",
  "LIVNI",
  "LIVNO",
  "LIVOM",
  "LIVOV",
  "LIVRA",
  "LIVRE",
  "LIVRI",
  "LIVRO",
  "LIZAČ",
  "LIZAH",
  "LIZAL",
  "LIZAM",
  "LIZAT",
  "LIZIH",
  "LIZIK",
  "LIZIL",
  "LIZIN",
  "LIZOL",
  "LIZOM",
  "LIZOV",
  "LIZUN",
  "LIŽEM",
  "LIŽEŠ",
  "LJUBA",
  "LJUBE",
  "LJUBI",
  "LJUBO",
  "LJUDI",
  "LOAOZ",
  "LOBIH",
  "LOBOD",
  "LOBOM",
  "LOBOV",
  "LOBUL",
  "LOBUS",
  "LOCEN",
  "LOCNA",
  "LOCNE",
  "LOCNI",
  "LOCNU",
  "LOČAJ",
  "LOČBA",
  "LOČBE",
  "LOČBI",
  "LOČBO",
  "LOČEČ",
  "LOČEK",
  "LOČEM",
  "LOČEN",
  "LOČEŠ",
  "LOČIH",
  "LOČIL",
  "LOČIM",
  "LOČIN",
  "LOČIŠ",
  "LOČIT",
  "LOČJA",
  "LOČJE",
  "LOČJO",
  "LOČJU",
  "LOČKA",
  "LOČKE",
  "LOČKI",
  "LOČKO",
  "LOČKU",
  "LOČMA",
  "LOČMI",
  "LOČNA",
  "LOČNE",
  "LOČNI",
  "LOČNO",
  "LOČUJ",
  "LODEN",
  "LODER",
  "LODNA",
  "LODNU",
  "LODRA",
  "LODRE",
  "LODRI",
  "LODRO",
  "LOGAN",
  "LOGAR",
  "LOGEH",
  "LOGIH",
  "LOGIK",
  "LOGJE",
  "LOGMI",
  "LOGOM",
  "LOGOR",
  "LOGOS",
  "LOGOV",
  "LOHIJ",
  "LOJAV",
  "LOJEM",
  "LOJEN",
  "LOJNA",
  "LOJNE",
  "LOJNI",
  "LOJNO",
  "LOKAH",
  "LOKAJ",
  "LOKAL",
  "LOKAM",
  "LOKAŠ",
  "LOKAT",
  "LOKAV",
  "LOKEV",
  "LOKIH",
  "LOKOM",
  "LOKOV",
  "LOKUS",
  "LOKVE",
  "LOKVI",
  "LOLEK",
  "LOMAČ",
  "LOMEČ",
  "LOMEN",
  "LOMIH",
  "LOMIL",
  "LOMIM",
  "LOMIŠ",
  "LOMIT",
  "LOMNA",
  "LOMNE",
  "LOMNI",
  "LOMNO",
  "LOMOM",
  "LOMOV",
  "LONCA",
  "LONCE",
  "LONCI",
  "LONCU",
  "LONEC",
  "LONOM",
  "LOPAH",
  "LOPAM",
  "LOPAR",
  "LOPAT",
  "LOPEN",
  "LOPIC",
  "LOPNA",
  "LOPNE",
  "LOPNI",
  "LOPNO",
  "LOPOT",
  "LOPOV",
  "LOPUT",
  "LORDA",
  "LORDE",
  "LORDI",
  "LORDU",
  "LOSAJ",
  "LOSAL",
  "LOSAM",
  "LOSAŠ",
  "LOSIH",
  "LOSJA",
  "LOSJE",
  "LOSJI",
  "LOSJO",
  "LOSOM",
  "LOSOS",
  "LOSOV",
  "LOŠČA",
  "LOŠČI",
  "LOŠČU",
  "LOŠKA",
  "LOŠKE",
  "LOŠKI",
  "LOŠKO",
  "LOTAJ",
  "LOTAL",
  "LOTAM",
  "LOTAN",
  "LOTAŠ",
  "LOTEČ",
  "LOTIH",
  "LOTIL",
  "LOTIM",
  "LOTIŠ",
  "LOTIT",
  "LOTOM",
  "LOTOS",
  "LOTOV",
  "LOVAČ",
  "LOVCA",
  "LOVCE",
  "LOVCI",
  "LOVCU",
  "LOVEC",
  "LOVEČ",
  "LOVEN",
  "LOVIH",
  "LOVIL",
  "LOVIM",
  "LOVIŠ",
  "LOVIT",
  "LOVKA",
  "LOVKE",
  "LOVKI",
  "LOVKO",
  "LOVNA",
  "LOVNE",
  "LOVNI",
  "LOVNO",
  "LOVOM",
  "LOVOR",
  "LOVOV",
  "LOZAH",
  "LOZAM",
  "LOŽAH",
  "LOŽAM",
  "LOŽEN",
  "LOŽNA",
  "LOŽNE",
  "LOŽNI",
  "LOŽNO",
  "LUBEN",
  "LUBJA",
  "LUBJE",
  "LUBJU",
  "LUCKA",
  "LUČAJ",
  "LUČAL",
  "LUČAM",
  "LUČAŠ",
  "LUČAT",
  "LUČCA",
  "LUČCE",
  "LUČCI",
  "LUČCO",
  "LUČEH",
  "LUČEM",
  "LUČIC",
  "LUČJO",
  "LUČKA",
  "LUČKE",
  "LUČKI",
  "LUČKO",
  "LUČMI",
  "LUDIT",
  "LUFTA",
  "LUFTU",
  "LUGIH",
  "LUGOM",
  "LUGOV",
  "LUKAH",
  "LUKAM",
  "LUKIH",
  "LUKOM",
  "LUKOV",
  "LUKSA",
  "LUKSE",
  "LUKSI",
  "LUKSU",
  "LULAH",
  "LULAJ",
  "LULAL",
  "LULAM",
  "LULAŠ",
  "LULAT",
  "LULEK",
  "LULIC",
  "LULIK",
  "LULKA",
  "LULKE",
  "LULKI",
  "LULKU",
  "LUMEN",
  "LUMNA",
  "LUMNE",
  "LUMNI",
  "LUMNO",
  "LUMNU",
  "LUMPA",
  "LUMPE",
  "LUMPI",
  "LUMPU",
  "LUNAH",
  "LUNAM",
  "LUNEK",
  "LUNET",
  "LUNIC",
  "LUNIK",
  "LUNJA",
  "LUNJE",
  "LUNJI",
  "LUNJU",
  "LUNKA",
  "LUNKE",
  "LUNKI",
  "LUNKU",
  "LUNUL",
  "LUPAČ",
  "LUPAH",
  "LUPAM",
  "LUPIL",
  "LUPIM",
  "LUPIN",
  "LUPIŠ",
  "LUPIT",
  "LUPOM",
  "LUPUS",
  "LUSKA",
  "LUSKE",
  "LUSKI",
  "LUSKO",
  "LUŠČE",
  "LUŠČI",
  "LUŠKA",
  "LUŠKE",
  "LUŠKI",
  "LUŠKO",
  "LUŠTA",
  "LUŠTE",
  "LUŠTI",
  "LUŠTU",
  "LUTKA",
  "LUTKE",
  "LUTKI",
  "LUTKO",
  "LUZER",
  "LUŽAH",
  "LUŽAM",
  "LUŽEČ",
  "LUŽEN",
  "LUŽIC",
  "LUŽIL",
  "LUŽIM",
  "LUŽIŠ",
  "LUŽIT",
  "LUŽNA",
  "LUŽNE",
  "LUŽNI",
  "LUŽNO",
  "MACOL",
  "MAČEH",
  "MAČEK",
  "MAČET",
  "MAČIC",
  "MAČIH",
  "MAČIN",
  "MAČJA",
  "MAČJE",
  "MAČJI",
  "MAČJO",
  "MAČKA",
  "MAČKE",
  "MAČKI",
  "MAČKO",
  "MAČKU",
  "MAČOM",
  "MAČOV",
  "MADAM",
  "MADEŽ",
  "MADON",
  "MAFIJ",
  "MAFIN",
  "MAGIH",
  "MAGIJ",
  "MAGIK",
  "MAGMA",
  "MAGME",
  "MAGMI",
  "MAGMO",
  "MAGOM",
  "MAGOT",
  "MAGOV",
  "MAHAČ",
  "MAHAJ",
  "MAHAL",
  "MAHAM",
  "MAHAŠ",
  "MAHAT",
  "MAHEK",
  "MAHER",
  "MAHIH",
  "MAHKA",
  "MAHKE",
  "MAHKI",
  "MAHKU",
  "MAHNE",
  "MAHNI",
  "MAHOM",
  "MAHOV",
  "MAILA",
  "MAILE",
  "MAILI",
  "MAILU",
  "MAJAH",
  "MAJAJ",
  "MAJAL",
  "MAJAM",
  "MAJAŠ",
  "MAJAT",
  "MAJAV",
  "MAJČK",
  "MAJEM",
  "MAJEŠ",
  "MAJEV",
  "MAJIC",
  "MAJIH",
  "MAJKA",
  "MAJKE",
  "MAJKI",
  "MAJKO",
  "MAJNA",
  "MAJNE",
  "MAJNI",
  "MAJNO",
  "MAJOR",
  "MAJOŠ",
  "MAKAA",
  "MAKAE",
  "MAKAH",
  "MAKAI",
  "MAKAK",
  "MAKAM",
  "MAKAO",
  "MAKAU",
  "MAKET",
  "MAKIH",
  "MAKIJ",
  "MAKIN",
  "MAKOM",
  "MAKOV",
  "MAKRA",
  "MAKRE",
  "MAKRI",
  "MAKRO",
  "MAKRU",
  "MAKSI",
  "MAKUL",
  "MALAJ",
  "MALAL",
  "MALAM",
  "MALAN",
  "MALAR",
  "MALAŠ",
  "MALAT",
  "MALCE",
  "MALEM",
  "MALHA",
  "MALHE",
  "MALHI",
  "MALHO",
  "MALIC",
  "MALIH",
  "MALIK",
  "MALIM",
  "MALIN",
  "MALTA",
  "MALTE",
  "MALTI",
  "MALTO",
  "MALUS",
  "MAMAH",
  "MAMAM",
  "MAMBA",
  "MAMBE",
  "MAMBI",
  "MAMBO",
  "MAMBU",
  "MAMEČ",
  "MAMIC",
  "MAMIL",
  "MAMIM",
  "MAMIŠ",
  "MAMIT",
  "MAMKA",
  "MAMKE",
  "MAMKI",
  "MAMKO",
  "MAMON",
  "MAMUT",
  "MANAH",
  "MANAM",
  "MANCE",
  "MANDI",
  "MANEČ",
  "MANEM",
  "MANEŠ",
  "MANEŽ",
  "MANGA",
  "MANGE",
  "MANGI",
  "MANGO",
  "MANGU",
  "MANIH",
  "MANIJ",
  "MANIR",
  "MANKA",
  "MANKE",
  "MANKI",
  "MANKO",
  "MANKU",
  "MANOM",
  "MANOV",
  "MANOZ",
  "MANTA",
  "MANTE",
  "MANTI",
  "MANTO",
  "MANUL",
  "MAPAH",
  "MAPAM",
  "MAPEN",
  "MAPER",
  "MAPNA",
  "MAPNE",
  "MAPNI",
  "MAPNO",
  "MARAJ",
  "MARAL",
  "MARAM",
  "MARAŠ",
  "MARCA",
  "MARCE",
  "MARCI",
  "MARCU",
  "MAREC",
  "MAREL",
  "MARIC",
  "MARIN",
  "MARKA",
  "MARKE",
  "MARKI",
  "MARKO",
  "MAROD",
  "MAROG",
  "MARON",
  "MARŠA",
  "MARŠE",
  "MARŠI",
  "MARŠU",
  "MARŽA",
  "MARŽE",
  "MARŽI",
  "MARŽO",
  "MASAH",
  "MASAM",
  "MASAŽ",
  "MASEN",
  "MASER",
  "MASIV",
  "MASKA",
  "MASKE",
  "MASKI",
  "MASKO",
  "MASLA",
  "MASLO",
  "MASLU",
  "MASNA",
  "MASNE",
  "MASNI",
  "MASNO",
  "MASTI",
  "MAŠAH",
  "MAŠAM",
  "MAŠEČ",
  "MAŠEN",
  "MAŠIL",
  "MAŠIM",
  "MAŠIN",
  "MAŠIŠ",
  "MAŠIT",
  "MAŠNA",
  "MAŠNE",
  "MAŠNI",
  "MAŠNO",
  "MAŠUJ",
  "MAŠUN",
  "MATEN",
  "MATER",
  "MATIC",
  "MATIH",
  "MATIJ",
  "MATNA",
  "MATNE",
  "MATNI",
  "MATNO",
  "MATOM",
  "MATOV",
  "MATRA",
  "MATUR",
  "MAVCA",
  "MAVCU",
  "MAVČE",
  "MAVČI",
  "MAVEC",
  "MAVER",
  "MAVRA",
  "MAVRE",
  "MAVRI",
  "MAVRO",
  "MAVSA",
  "MAVŽA",
  "MAZAČ",
  "MAZAL",
  "MAZAN",
  "MAZAT",
  "MAZAV",
  "MAZDA",
  "MAZDE",
  "MAZDI",
  "MAZDO",
  "MAZIL",
  "MAZIV",
  "MAZUT",
  "MAŽAH",
  "MAŽAM",
  "MAŽEM",
  "MAŽEŠ",
  "MECEN",
  "MEČAR",
  "MEČAV",
  "MEČEK",
  "MEČEM",
  "MEČEN",
  "MEČEŠ",
  "MEČEV",
  "MEČIC",
  "MEČIH",
  "MEČKA",
  "MEČKE",
  "MEČKI",
  "MEČKU",
  "MEČNA",
  "MEČNE",
  "MEČNI",
  "MEČNO",
  "MEČUJ",
  "MEDAR",
  "MEDEČ",
  "MEDEL",
  "MEDEM",
  "MEDEN",
  "MEDEŠ",
  "MEDIC",
  "MEDIJ",
  "MEDIL",
  "MEDIM",
  "MEDIŠ",
  "MEDIT",
  "MEDJO",
  "MEDLA",
  "MEDLE",
  "MEDLI",
  "MEDLO",
  "MEDNA",
  "MEDNE",
  "MEDNI",
  "MEDNO",
  "MEDOČ",
  "MEDOM",
  "MEDUL",
  "MEDUZ",
  "MEDVE",
  "MEGEL",
  "MEGLA",
  "MEGLE",
  "MEGLI",
  "MEGLO",
  "MEHAK",
  "MEHČA",
  "MEHEK",
  "MEHIH",
  "MEHKA",
  "MEHKE",
  "MEHKI",
  "MEHKO",
  "MEHOM",
  "MEHOV",
  "MEHUR",
  "MEIOZ",
  "MEJAH",
  "MEJAK",
  "MEJAM",
  "MEJAŠ",
  "MEJEČ",
  "MEJEN",
  "MEJIC",
  "MEJIL",
  "MEJIM",
  "MEJIN",
  "MEJIŠ",
  "MEJIT",
  "MEJLA",
  "MEJLE",
  "MEJLI",
  "MEJLU",
  "MEJNA",
  "MEJNE",
  "MEJNI",
  "MEJNO",
  "MEJOZ",
  "MEKAH",
  "MEKAM",
  "MEKET",
  "MELAS",
  "MELEN",
  "MELIS",
  "MELJA",
  "MELJE",
  "MELJI",
  "MELJU",
  "MELON",
  "MELOS",
  "MEMIH",
  "MEMOM",
  "MEMOV",
  "MENAH",
  "MENAM",
  "MENCA",
  "MENDA",
  "MENEČ",
  "MENEK",
  "MENIC",
  "MENIH",
  "MENIL",
  "MENIM",
  "MENIŠ",
  "MENIT",
  "MENJA",
  "MENKA",
  "MENKE",
  "MENKI",
  "MENKU",
  "MENOJ",
  "MENOR",
  "MENZA",
  "MENZE",
  "MENZI",
  "MENZO",
  "MERAH",
  "MERAM",
  "MEREČ",
  "MEREK",
  "MERIC",
  "MERIL",
  "MERIM",
  "MERIŠ",
  "MERIT",
  "MERKA",
  "MERKE",
  "MERKI",
  "MERKU",
  "MESAR",
  "MESEC",
  "MESEČ",
  "MESEK",
  "MESEN",
  "MESIJ",
  "MESIL",
  "MESIM",
  "MESIŠ",
  "MESIT",
  "MESKA",
  "MESKU",
  "MESNA",
  "MESNE",
  "MESNI",
  "MESNO",
  "MESOM",
  "MESTA",
  "MESTI",
  "MESTO",
  "MESTU",
  "MEŠAJ",
  "MEŠAL",
  "MEŠAM",
  "MEŠAN",
  "MEŠAŠ",
  "MEŠAT",
  "MEŠEN",
  "MEŠIČ",
  "MEŠIN",
  "MEŠKA",
  "MEŠKE",
  "MEŠKI",
  "MEŠKO",
  "METAL",
  "METAN",
  "METAT",
  "METEK",
  "METEL",
  "METEM",
  "METEN",
  "METER",
  "METEŠ",
  "METEŽ",
  "METIH",
  "METIJ",
  "METIL",
  "METIM",
  "METJA",
  "METJE",
  "METJI",
  "METJU",
  "METKA",
  "METKE",
  "METKI",
  "METKU",
  "METLA",
  "METLE",
  "METLI",
  "METLO",
  "METNA",
  "METNE",
  "METNI",
  "METNO",
  "METOČ",
  "METOD",
  "METOM",
  "METOP",
  "METOV",
  "METRA",
  "METRE",
  "METRI",
  "METRO",
  "METRU",
  "MEVŽA",
  "MEVŽE",
  "MEVŽI",
  "MEVŽO",
  "MEZDA",
  "MEZDE",
  "MEZDI",
  "MEZDO",
  "MEZEČ",
  "MEZEG",
  "MEZEL",
  "MEZET",
  "MEZGA",
  "MEZGE",
  "MEZGI",
  "MEZGO",
  "MEZGU",
  "MEZIM",
  "MEZIŠ",
  "MEZON",
  "MEŽIK",
  "MIAZA",
  "MIAZE",
  "MIAZI",
  "MIAZO",
  "MICEN",
  "MIČEN",
  "MIČNA",
  "MIČNE",
  "MIČNI",
  "MIČNO",
  "MIDVA",
  "MIGAJ",
  "MIGAL",
  "MIGAM",
  "MIGAŠ",
  "MIGAT",
  "MIGIH",
  "MIGNE",
  "MIGNI",
  "MIGOM",
  "MIGOV",
  "MIJAV",
  "MIJEM",
  "MIJEŠ",
  "MIJEV",
  "MIJIH",
  "MIJMO",
  "MIJTA",
  "MIJTE",
  "MIJVA",
  "MIKAJ",
  "MIKAL",
  "MIKAM",
  "MIKAN",
  "MIKAŠ",
  "MIKAT",
  "MIKIC",
  "MIKIH",
  "MIKOM",
  "MIKOV",
  "MIKOZ",
  "MIKRA",
  "MIKRE",
  "MIKRI",
  "MIKRO",
  "MIKRU",
  "MIKSA",
  "MIKSE",
  "MIKSI",
  "MIKSU",
  "MILEČ",
  "MILEM",
  "MILEN",
  "MILIC",
  "MILIH",
  "MILIL",
  "MILIM",
  "MILIŠ",
  "MILIT",
  "MILJA",
  "MILJE",
  "MILJI",
  "MILJO",
  "MILJU",
  "MILKA",
  "MILKE",
  "MILKI",
  "MILKO",
  "MILNA",
  "MILNE",
  "MILNI",
  "MILNO",
  "MILOB",
  "MILOM",
  "MIMIK",
  "MIMOZ",
  "MINAH",
  "MINAM",
  "MINEM",
  "MINER",
  "MINEŠ",
  "MINIC",
  "MINIJ",
  "MINIK",
  "MINIL",
  "MINIT",
  "MINKA",
  "MINKE",
  "MINKI",
  "MINKU",
  "MINUL",
  "MINUS",
  "MINUT",
  "MIOID",
  "MIOMA",
  "MIOME",
  "MIOMI",
  "MIOMU",
  "MIONA",
  "MIONE",
  "MIONI",
  "MIONU",
  "MIOPA",
  "MIOPE",
  "MIOPI",
  "MIOPU",
  "MIOZA",
  "MIOZE",
  "MIOZI",
  "MIOZO",
  "MIREČ",
  "MIREN",
  "MIRIH",
  "MIRIL",
  "MIRIM",
  "MIRIŠ",
  "MIRIT",
  "MIRKA",
  "MIRNA",
  "MIRNE",
  "MIRNI",
  "MIRNO",
  "MIROM",
  "MIROV",
  "MIRTA",
  "MIRTE",
  "MIRTI",
  "MIRTO",
  "MIRUJ",
  "MISAL",
  "MISEL",
  "MISIC",
  "MISIJ",
  "MISLE",
  "MISLI",
  "MIŠAK",
  "MIŠAR",
  "MIŠEK",
  "MIŠIC",
  "MIŠIH",
  "MIŠIM",
  "MIŠJA",
  "MIŠJE",
  "MIŠJI",
  "MIŠJO",
  "MIŠKA",
  "MIŠKE",
  "MIŠKI",
  "MIŠKO",
  "MIŠKU",
  "MIŠMA",
  "MIŠMI",
  "MITER",
  "MITIH",
  "MITOM",
  "MITOV",
  "MITOZ",
  "MITRA",
  "MITRE",
  "MITRI",
  "MITRO",
  "MIVKA",
  "MIVKE",
  "MIVKI",
  "MIVKO",
  "MIZAH",
  "MIZAM",
  "MIZAR",
  "MIZEN",
  "MIZIC",
  "MIZIJ",
  "MIZNA",
  "MIZNE",
  "MIZNI",
  "MIZNO",
  "MIŽAL",
  "MIŽAT",
  "MIŽEČ",
  "MIŽIM",
  "MIŽIŠ",
  "MLADA",
  "MLADE",
  "MLADI",
  "MLADO",
  "MLAJA",
  "MLAJE",
  "MLAJI",
  "MLAJO",
  "MLAJU",
  "MLAKA",
  "MLAKE",
  "MLAKI",
  "MLAKO",
  "MLASK",
  "MLATA",
  "MLATE",
  "MLATI",
  "MLATU",
  "MLEKA",
  "MLEKO",
  "MLEKU",
  "MLELA",
  "MLELE",
  "MLELI",
  "MLELO",
  "MLETA",
  "MLETE",
  "MLETI",
  "MLETO",
  "MLINA",
  "MLINE",
  "MLINI",
  "MLINU",
  "MNENJ",
  "MNOGA",
  "MNOGE",
  "MNOGI",
  "MNOGO",
  "MNOŽE",
  "MNOŽI",
  "MOAMA",
  "MOAMI",
  "MOČAN",
  "MOČEH",
  "MOČEM",
  "MOČEN",
  "MOČIL",
  "MOČIM",
  "MOČIŠ",
  "MOČIT",
  "MOČJO",
  "MOČMI",
  "MOČNA",
  "MOČNE",
  "MOČNI",
  "MOČNO",
  "MODAH",
  "MODAM",
  "MODEL",
  "MODEM",
  "MODEN",
  "MODER",
  "MODIH",
  "MODLA",
  "MODLE",
  "MODLI",
  "MODLU",
  "MODNA",
  "MODNE",
  "MODNI",
  "MODNO",
  "MODOM",
  "MODRA",
  "MODRC",
  "MODRE",
  "MODRI",
  "MODRO",
  "MODUL",
  "MODUS",
  "MOFET",
  "MOGEL",
  "MOGLA",
  "MOGLE",
  "MOGLI",
  "MOGLO",
  "MOGOČ",
  "MOGOT",
  "MOGUL",
  "MOHER",
  "MOJEM",
  "MOJIH",
  "MOJIM",
  "MOKAH",
  "MOKAJ",
  "MOKAL",
  "MOKAM",
  "MOKAR",
  "MOKAŠ",
  "MOKAT",
  "MOKER",
  "MOKOŠ",
  "MOKOŽ",
  "MOKRA",
  "MOKRC",
  "MOKRE",
  "MOKRI",
  "MOKRO",
  "MOLAR",
  "MOLČE",
  "MOLČI",
  "MOLEČ",
  "MOLEK",
  "MOLEL",
  "MOLET",
  "MOLID",
  "MOLIH",
  "MOLIK",
  "MOLIL",
  "MOLIM",
  "MOLIŠ",
  "MOLIT",
  "MOLJA",
  "MOLJE",
  "MOLJI",
  "MOLJU",
  "MOLKA",
  "MOLKE",
  "MOLKI",
  "MOLKU",
  "MOLOH",
  "MOLOM",
  "MOLOV",
  "MOLST",
  "MOLZE",
  "MOLZI",
  "MOLŽA",
  "MOLŽE",
  "MOLŽI",
  "MOLŽO",
  "MONAD",
  "MONAH",
  "MONAL",
  "MONAM",
  "MONDA",
  "MONDE",
  "MONDI",
  "MONDU",
  "MONGA",
  "MONGE",
  "MONGI",
  "MONGO",
  "MOPED",
  "MOPSA",
  "MOPSE",
  "MOPSI",
  "MOPSU",
  "MORAH",
  "MORAL",
  "MORAM",
  "MORAŠ",
  "MORDA",
  "MOREČ",
  "MOREM",
  "MOREN",
  "MOREŠ",
  "MOREŽ",
  "MORIJ",
  "MORIL",
  "MORIM",
  "MORIŠ",
  "MORIT",
  "MORJA",
  "MORJE",
  "MORJI",
  "MORJU",
  "MORUL",
  "MOSSO",
  "MOSTU",
  "MOŠEJ",
  "MOŠKA",
  "MOŠKE",
  "MOŠKI",
  "MOŠKO",
  "MOŠTA",
  "MOŠTU",
  "MOŠUS",
  "MOTAJ",
  "MOTAL",
  "MOTAM",
  "MOTAŠ",
  "MOTAT",
  "MOTEČ",
  "MOTEK",
  "MOTEL",
  "MOTEN",
  "MOTET",
  "MOTIH",
  "MOTIK",
  "MOTIL",
  "MOTIM",
  "MOTIŠ",
  "MOTIT",
  "MOTIV",
  "MOTKA",
  "MOTKE",
  "MOTKI",
  "MOTKU",
  "MOTNA",
  "MOTNE",
  "MOTNI",
  "MOTNO",
  "MOTOM",
  "MOTOR",
  "MOTOV",
  "MOTRI",
  "MOZEG",
  "MOZGA",
  "MOZGE",
  "MOZGI",
  "MOZGU",
  "MOŽAČ",
  "MOŽAK",
  "MOŽAT",
  "MOŽEČ",
  "MOŽEH",
  "MOŽEK",
  "MOŽEM",
  "MOŽEN",
  "MOŽIC",
  "MOŽIL",
  "MOŽIM",
  "MOŽIN",
  "MOŽIŠ",
  "MOŽIT",
  "MOŽJE",
  "MOŽKA",
  "MOŽKE",
  "MOŽKI",
  "MOŽKU",
  "MOŽMI",
  "MOŽNA",
  "MOŽNE",
  "MOŽNI",
  "MOŽNO",
  "MOŽUJ",
  "MRAČI",
  "MRAKA",
  "MRAKU",
  "MRAZA",
  "MRAZE",
  "MRAZI",
  "MRAZU",
  "MRCIN",
  "MRČAV",
  "MRČEM",
  "MRČES",
  "MRČEV",
  "MRČIH",
  "MRDAJ",
  "MRDAL",
  "MRDAM",
  "MRDAŠ",
  "MRDAT",
  "MRDNE",
  "MRDNI",
  "MREJO",
  "MREMO",
  "MRENA",
  "MRENE",
  "MRENI",
  "MRENO",
  "MREST",
  "MRETA",
  "MRETE",
  "MRETI",
  "MREVA",
  "MREŽA",
  "MREŽE",
  "MREŽI",
  "MREŽO",
  "MRHAH",
  "MRHAM",
  "MRHAR",
  "MRHIC",
  "MRIMO",
  "MRITA",
  "MRITE",
  "MRIVA",
  "MRJEM",
  "MRJEŠ",
  "MRKAČ",
  "MRKEL",
  "MRKEM",
  "MRKIH",
  "MRKIM",
  "MRKLA",
  "MRKLE",
  "MRKLI",
  "MRKLO",
  "MRKNE",
  "MRKNI",
  "MRKOM",
  "MRKOV",
  "MRLEČ",
  "MRLEL",
  "MRLET",
  "MRLIČ",
  "MRLIM",
  "MRLIŠ",
  "MRMOT",
  "MRMRA",
  "MROČA",
  "MROČE",
  "MROČI",
  "MROČO",
  "MROŽA",
  "MROŽE",
  "MROŽI",
  "MROŽU",
  "MRSAH",
  "MRSAM",
  "MRŠAV",
  "MRŠČI",
  "MRŠIL",
  "MRŠIM",
  "MRŠIŠ",
  "MRŠIT",
  "MRŠKA",
  "MRŠKE",
  "MRŠKI",
  "MRŠKO",
  "MRTEV",
  "MRTVA",
  "MRTVE",
  "MRTVI",
  "MRTVO",
  "MRVIC",
  "MRZEČ",
  "MRZEK",
  "MRZEL",
  "MRZEN",
  "MRZIL",
  "MRZIM",
  "MRZIŠ",
  "MRZIT",
  "MRZKA",
  "MRZKE",
  "MRZKI",
  "MRZKO",
  "MRZLA",
  "MRZLE",
  "MRZLI",
  "MRZLO",
  "MTDNA",
  "MTDNK",
  "MUCAH",
  "MUCAM",
  "MUCEK",
  "MUCEM",
  "MUCEV",
  "MUCIC",
  "MUCIH",
  "MUCIK",
  "MUCKA",
  "MUCKE",
  "MUCKI",
  "MUCKO",
  "MUCKU",
  "MUČEČ",
  "MUČEN",
  "MUČIL",
  "MUČIM",
  "MUČIŠ",
  "MUČIT",
  "MUČNA",
  "MUČNE",
  "MUČNI",
  "MUČNO",
  "MUDEČ",
  "MUDER",
  "MUDIL",
  "MUDIM",
  "MUDIŠ",
  "MUDIT",
  "MUDRA",
  "MUDRE",
  "MUDRI",
  "MUDRO",
  "MUFAH",
  "MUFAM",
  "MUFEN",
  "MUFIH",
  "MUFNA",
  "MUFNE",
  "MUFNI",
  "MUFNO",
  "MUFOM",
  "MUFOV",
  "MUFTI",
  "MUHAH",
  "MUHAJ",
  "MUHAL",
  "MUHAM",
  "MUHAR",
  "MUHAŠ",
  "MUHAT",
  "MUHAV",
  "MUHIC",
  "MUJAJ",
  "MUJAL",
  "MUJAM",
  "MUJAŠ",
  "MUJEN",
  "MUKAH",
  "MUKAJ",
  "MUKAL",
  "MUKAM",
  "MUKAŠ",
  "MUKAT",
  "MUKNE",
  "MUKNI",
  "MUKUS",
  "MULAH",
  "MULAM",
  "MULAT",
  "MULAŽ",
  "MULCA",
  "MULCE",
  "MULCI",
  "MULCU",
  "MULČI",
  "MULDA",
  "MULDE",
  "MULDI",
  "MULDO",
  "MULEC",
  "MULEČ",
  "MULIH",
  "MULIL",
  "MULIM",
  "MULIŠ",
  "MULIT",
  "MULJA",
  "MULJE",
  "MULJI",
  "MULJO",
  "MULJU",
  "MULOM",
  "MULOV",
  "MUMIJ",
  "MUMPS",
  "MUNCA",
  "MUNCE",
  "MUNCI",
  "MUNCU",
  "MUNEC",
  "MUNGA",
  "MUNGE",
  "MUNGI",
  "MUNGO",
  "MUNGU",
  "MUNIK",
  "MURAL",
  "MUREN",
  "MURKA",
  "MURKE",
  "MURKI",
  "MURKO",
  "MURNA",
  "MURNE",
  "MURNI",
  "MURNU",
  "MURVA",
  "MURVE",
  "MURVI",
  "MURVO",
  "MUSAK",
  "MUSIH",
  "MUSKA",
  "MUSKE",
  "MUSKI",
  "MUSKO",
  "MUSLI",
  "MUSOM",
  "MUSOV",
  "MUŠAT",
  "MUŠIC",
  "MUŠJA",
  "MUŠJE",
  "MUŠJI",
  "MUŠJO",
  "MUŠKA",
  "MUŠKE",
  "MUŠKI",
  "MUŠKO",
  "MUTAZ",
  "MUTCA",
  "MUTCE",
  "MUTCI",
  "MUTCU",
  "MUTEC",
  "MUTON",
  "MUTSU",
  "MUZAH",
  "MUZAJ",
  "MUZAL",
  "MUZAM",
  "MUZAŠ",
  "MUZAT",
  "MUZEJ",
  "MUZGA",
  "MUZGE",
  "MUZGI",
  "MUZGO",
  "MUZIK",
  "MUŽCE",
  "MUŽCI",
  "MUŽEČ",
  "MUŽIK",
  "MUŽIL",
  "MUŽIM",
  "MUŽIŠ",
  "MUŽIT",
  "NABAV",
  "NABIJ",
  "NABIL",
  "NABIT",
  "NABOB",
  "NABOD",
  "NABOJ",
  "NABOR",
  "NACHR",
  "NACIJ",
  "NAČEL",
  "NAČET",
  "NAČIN",
  "NAČNE",
  "NAČNI",
  "NAČNO",
  "NAČRT",
  "NADAH",
  "NADAM",
  "NADAV",
  "NADEL",
  "NADET",
  "NADEV",
  "NADIH",
  "NADIR",
  "NADRE",
  "NADRI",
  "NADRL",
  "NADRT",
  "NADUH",
  "NADUR",
  "NADUT",
  "NAFTA",
  "NAFTE",
  "NAFTI",
  "NAFTO",
  "NAGCA",
  "NAGCE",
  "NAGCI",
  "NAGCU",
  "NAGEC",
  "NAGEL",
  "NAGEM",
  "NAGIB",
  "NAGIC",
  "NAGIH",
  "NAGIM",
  "NAGLA",
  "NAGLE",
  "NAGLI",
  "NAGLO",
  "NAGNE",
  "NAGNI",
  "NAGON",
  "NAHIJ",
  "NAHOD",
  "NAIRA",
  "NAIRE",
  "NAIRI",
  "NAIRO",
  "NAIVA",
  "NAIVE",
  "NAIVI",
  "NAIVK",
  "NAIVO",
  "NAJAD",
  "NAJAV",
  "NAJDB",
  "NAJDE",
  "NAJDI",
  "NAJEJ",
  "NAJEL",
  "NAJEM",
  "NAJEŠ",
  "NAJET",
  "NAJID",
  "NAJIN",
  "NAJSI",
  "NAJTI",
  "NAKAN",
  "NAKAR",
  "NAKAZ",
  "NAKIT",
  "NAKUJ",
  "NAKUP",
  "NALEČ",
  "NALET",
  "NALIJ",
  "NALIK",
  "NALIL",
  "NALIT",
  "NALIV",
  "NALOG",
  "NALOM",
  "NAMAZ",
  "NAMEN",
  "NAMER",
  "NAMIB",
  "NAMIG",
  "NAMOK",
  "NAMOT",
  "NANDU",
  "NANIH",
  "NANOM",
  "NANOS",
  "NANOV",
  "NAPAD",
  "NAPAH",
  "NAPAK",
  "NAPAM",
  "NAPEČ",
  "NAPEL",
  "NAPER",
  "NAPET",
  "NAPEV",
  "NAPHA",
  "NAPIJ",
  "NAPIL",
  "NAPIS",
  "NAPIT",
  "NAPNE",
  "NAPNI",
  "NAPNO",
  "NAPOJ",
  "NAPOK",
  "NAPOL",
  "NAPON",
  "NAPOR",
  "NAPUH",
  "NARAV",
  "NARED",
  "NAREK",
  "NARIJ",
  "NARIL",
  "NARIS",
  "NARIT",
  "NARIV",
  "NAROD",
  "NAROK",
  "NARTA",
  "NARTE",
  "NARTI",
  "NARTU",
  "NASAD",
  "NASEJ",
  "NASEK",
  "NASIP",
  "NASPI",
  "NASUJ",
  "NASUL",
  "NASUT",
  "NAŠEL",
  "NAŠEM",
  "NAŠIH",
  "NAŠIJ",
  "NAŠIL",
  "NAŠIM",
  "NAŠIT",
  "NAŠLA",
  "NAŠLE",
  "NAŠLI",
  "NAŠLO",
  "NATEČ",
  "NATEG",
  "NATIČ",
  "NATIS",
  "NATKE",
  "NATKI",
  "NATRE",
  "NATRI",
  "NATRL",
  "NATRT",
  "NATUR",
  "NAUČI",
  "NAUKA",
  "NAUKE",
  "NAUKI",
  "NAUKU",
  "NAURU",
  "NAVAD",
  "NAVAL",
  "NAVAR",
  "NAVDA",
  "NAVEZ",
  "NAVIJ",
  "NAVIL",
  "NAVIT",
  "NAVJA",
  "NAVJE",
  "NAVJU",
  "NAVOJ",
  "NAVOR",
  "NAVOZ",
  "NAVRE",
  "NAVRI",
  "NAZAJ",
  "NAZAL",
  "NAZIV",
  "NAZOR",
  "NAŽEL",
  "NAŽET",
  "NAŽGE",
  "NAŽGI",
  "NAŽRE",
  "NAŽRI",
  "NAŽRL",
  "NAŽRT",
  "NEBEL",
  "NEBEN",
  "NEBES",
  "NEBIH",
  "NEBIN",
  "NEBNA",
  "NEBNE",
  "NEBNI",
  "NEBNO",
  "NEBOM",
  "NEBUL",
  "NECEL",
  "NEČAK",
  "NEČEM",
  "NEČIM",
  "NEČKE",
  "NEDER",
  "NEDRA",
  "NEDRC",
  "NEDRI",
  "NEFER",
  "NEGAH",
  "NEGAM",
  "NEGUJ",
  "NEHAJ",
  "NEHAL",
  "NEHAM",
  "NEHAŠ",
  "NEHAT",
  "NEHUJ",
  "NEJAZ",
  "NEJUD",
  "NEKAJ",
  "NEKAK",
  "NEKAM",
  "NEKDO",
  "NEKEM",
  "NEKIH",
  "NEKIM",
  "NEKJE",
  "NEKOČ",
  "NEKOD",
  "NEKOM",
  "NELEP",
  "NEMČE",
  "NEMČI",
  "NEMEČ",
  "NEMEL",
  "NEMEM",
  "NEMET",
  "NEMIH",
  "NEMIL",
  "NEMIM",
  "NEMIR",
  "NEMIŠ",
  "NEMOČ",
  "NEONA",
  "NEONK",
  "NEONU",
  "NEPAL",
  "NEPER",
  "NERAD",
  "NERCA",
  "NERCE",
  "NERCI",
  "NERCU",
  "NERED",
  "NERGA",
  "NEROD",
  "NESEL",
  "NESEM",
  "NESEN",
  "NESEŠ",
  "NESIT",
  "NESLA",
  "NESLE",
  "NESLI",
  "NESLO",
  "NESNA",
  "NESNE",
  "NESNI",
  "NESNO",
  "NESOČ",
  "NESTI",
  "NEŠKE",
  "NETEČ",
  "NETEK",
  "NETIH",
  "NETIL",
  "NETIM",
  "NETIŠ",
  "NETIT",
  "NETIV",
  "NETKA",
  "NETKE",
  "NETKI",
  "NETKO",
  "NETKU",
  "NETOM",
  "NETOV",
  "NEUKA",
  "NEUKE",
  "NEUKI",
  "NEUKO",
  "NEVEN",
  "NEVER",
  "NEVMA",
  "NEVME",
  "NEVMI",
  "NEVMO",
  "NEVUS",
  "NEŽAH",
  "NEŽAM",
  "NEŽEN",
  "NEŽIC",
  "NEŽID",
  "NEŽIV",
  "NEŽNA",
  "NEŽNE",
  "NEŽNI",
  "NEŽNO",
  "NIALA",
  "NIALE",
  "NIALI",
  "NIALO",
  "NIANS",
  "NIČEČ",
  "NIČEL",
  "NIČEM",
  "NIČEN",
  "NIČET",
  "NIČEV",
  "NIČIH",
  "NIČIL",
  "NIČIM",
  "NIČIŠ",
  "NIČIT",
  "NIČLA",
  "NIČLE",
  "NIČLI",
  "NIČLO",
  "NIČNA",
  "NIČNE",
  "NIČNI",
  "NIČNO",
  "NIČTA",
  "NIČTE",
  "NIČTI",
  "NIČTO",
  "NIGER",
  "NIGRA",
  "NIGRU",
  "NIHAJ",
  "NIHAL",
  "NIHAM",
  "NIHAŠ",
  "NIHAT",
  "NIHČE",
  "NIJEM",
  "NIJEV",
  "NIJIH",
  "NIKAB",
  "NIKAK",
  "NIKAR",
  "NIKDO",
  "NILOM",
  "NIMAM",
  "NIMAŠ",
  "NIMBA",
  "NIMBE",
  "NIMBI",
  "NIMBO",
  "NIMBU",
  "NIMFA",
  "NIMFE",
  "NIMFI",
  "NIMFO",
  "NINDŽ",
  "NINIC",
  "NINJA",
  "NINJE",
  "NINJI",
  "NINJO",
  "NISEM",
  "NISMO",
  "NISTA",
  "NISTE",
  "NISVA",
  "NIŠAH",
  "NIŠAM",
  "NIŠEN",
  "NIŠKA",
  "NIŠKE",
  "NIŠKI",
  "NIŠKO",
  "NIŠNA",
  "NIŠNE",
  "NIŠNI",
  "NIŠNO",
  "NITAR",
  "NITAV",
  "NITEN",
  "NITIH",
  "NITIJ",
  "NITIM",
  "NITJA",
  "NITJE",
  "NITJI",
  "NITJO",
  "NITJU",
  "NITKA",
  "NITKE",
  "NITKI",
  "NITKO",
  "NITMA",
  "NITMI",
  "NITNA",
  "NITNE",
  "NITNI",
  "NITNO",
  "NIZAJ",
  "NIZAL",
  "NIZAM",
  "NIZAŠ",
  "NIZAT",
  "NIZEK",
  "NIZIH",
  "NIZKA",
  "NIZKE",
  "NIZKI",
  "NIZKO",
  "NIZOM",
  "NIZOV",
  "NIŽAJ",
  "NIŽAL",
  "NIŽAM",
  "NIŽAŠ",
  "NIŽAT",
  "NIŽAV",
  "NIŽEK",
  "NIŽEM",
  "NIŽEN",
  "NIŽEŠ",
  "NIŽIN",
  "NIŽJA",
  "NIŽJE",
  "NIŽJI",
  "NIŽJO",
  "NIŽKA",
  "NIŽKE",
  "NIŽKI",
  "NIŽKU",
  "NIŽNA",
  "NIŽNE",
  "NIŽNI",
  "NIŽNO",
  "NJAMI",
  "NJEGA",
  "NJEMU",
  "NJENA",
  "NJENE",
  "NJENI",
  "NJENO",
  "NJIJU",
  "NJIMA",
  "NJIMI",
  "NJIVA",
  "NJIVE",
  "NJIVI",
  "NJIVK",
  "NJIVO",
  "NJOKA",
  "NJOKE",
  "NJOKI",
  "NJOKU",
  "NJORK",
  "NJUHA",
  "NJUNA",
  "NJUNE",
  "NJUNI",
  "NJUNO",
  "NOBEL",
  "NOBEN",
  "NOCOJ",
  "NOČEČ",
  "NOČEH",
  "NOČEM",
  "NOČEN",
  "NOČEŠ",
  "NOČIL",
  "NOČIM",
  "NOČIŠ",
  "NOČIT",
  "NOČJO",
  "NOČKA",
  "NOČKE",
  "NOČKI",
  "NOČKO",
  "NOČMI",
  "NOČNA",
  "NOČNE",
  "NOČNI",
  "NOČNO",
  "NOČUJ",
  "NODUL",
  "NODUS",
  "NOGAH",
  "NOGAM",
  "NOGIC",
  "NOHTA",
  "NOHTE",
  "NOHTI",
  "NOHTU",
  "NOJEM",
  "NOJEV",
  "NOJIH",
  "NOKOT",
  "NOKSA",
  "NOKSE",
  "NOKSI",
  "NOKSO",
  "NOMAD",
  "NONAH",
  "NONAM",
  "NONET",
  "NONIC",
  "NONIH",
  "NONIJ",
  "NONOM",
  "NONOV",
  "NORCA",
  "NORCE",
  "NORCI",
  "NORCU",
  "NOREC",
  "NOREČ",
  "NOREL",
  "NOREM",
  "NORET",
  "NORIC",
  "NORIH",
  "NORIJ",
  "NORIK",
  "NORIM",
  "NORIŠ",
  "NORKA",
  "NORKE",
  "NORKI",
  "NORKO",
  "NORMA",
  "NORME",
  "NORMI",
  "NORMO",
  "NOSAČ",
  "NOSAN",
  "NOSAT",
  "NOSEČ",
  "NOSEH",
  "NOSEK",
  "NOSEM",
  "NOSEN",
  "NOSIL",
  "NOSIM",
  "NOSIŠ",
  "NOSIT",
  "NOSKA",
  "NOSKE",
  "NOSKI",
  "NOSKU",
  "NOSNA",
  "NOSNE",
  "NOSNI",
  "NOSNO",
  "NOSOM",
  "NOSOV",
  "NOŠAH",
  "NOŠAM",
  "NOŠEN",
  "NOŠKA",
  "NOŠKE",
  "NOŠKI",
  "NOŠKO",
  "NOTAH",
  "NOTAM",
  "NOTAR",
  "NOTEN",
  "NOTER",
  "NOTES",
  "NOTIC",
  "NOTNA",
  "NOTNE",
  "NOTNI",
  "NOTNO",
  "NOTRI",
  "NOVAH",
  "NOVAM",
  "NOVCA",
  "NOVCE",
  "NOVCI",
  "NOVCU",
  "NOVEC",
  "NOVEL",
  "NOVEM",
  "NOVIC",
  "NOVIH",
  "NOVIM",
  "NOVOT",
  "NOŽAR",
  "NOŽEK",
  "NOŽEM",
  "NOŽEN",
  "NOŽEV",
  "NOŽIC",
  "NOŽIČ",
  "NOŽIH",
  "NOŽKA",
  "NOŽKE",
  "NOŽKI",
  "NOŽKU",
  "NOŽNA",
  "NOŽNE",
  "NOŽNI",
  "NOŽNO",
  "NRAVA",
  "NRAVE",
  "NRAVI",
  "NRAVU",
  "NUCAJ",
  "NUCAL",
  "NUCAM",
  "NUCAN",
  "NUCAŠ",
  "NUCAT",
  "NUDEČ",
  "NUDEN",
  "NUDIL",
  "NUDIM",
  "NUDIŠ",
  "NUDIT",
  "NUGAT",
  "NUJAH",
  "NUJAM",
  "NUJEN",
  "NUJNA",
  "NUJNE",
  "NUJNI",
  "NUJNO",
  "NULAH",
  "NULAM",
  "NULTA",
  "NULTE",
  "NULTI",
  "NULTO",
  "NUNAH",
  "NUNAM",
  "NUNKA",
  "NUNKE",
  "NUNKI",
  "NUNKO",
  "OAZAH",
  "OAZAM",
  "OAZEN",
  "OAZIC",
  "OAZNA",
  "OAZNE",
  "OAZNI",
  "OAZNO",
  "OBABI",
  "OBADA",
  "OBADE",
  "OBADI",
  "OBADU",
  "OBALA",
  "OBALE",
  "OBALI",
  "OBALO",
  "OBARA",
  "OBARE",
  "OBARI",
  "OBARO",
  "OBČAN",
  "OBČEM",
  "OBČEN",
  "OBČIH",
  "OBČIL",
  "OBČIM",
  "OBČIN",
  "OBČNA",
  "OBČNE",
  "OBČNI",
  "OBČNO",
  "OBČUJ",
  "OBDAJ",
  "OBDAL",
  "OBDAM",
  "OBDAN",
  "OBDAŠ",
  "OBDAT",
  "OBEDA",
  "OBEDE",
  "OBEDI",
  "OBEDU",
  "OBELI",
  "OBEMA",
  "OBERE",
  "OBERI",
  "OBESA",
  "OBESE",
  "OBESI",
  "OBESO",
  "OBEŠA",
  "OBETA",
  "OBETE",
  "OBETI",
  "OBETU",
  "OBHOD",
  "OBIDE",
  "OBIDI",
  "OBIJA",
  "OBIJE",
  "OBILA",
  "OBILE",
  "OBILI",
  "OBILO",
  "OBIRA",
  "OBISK",
  "OBIST",
  "OBITA",
  "OBITE",
  "OBITI",
  "OBITO",
  "OBJAV",
  "OBJED",
  "OBJEJ",
  "OBJEL",
  "OBJEM",
  "OBJEŠ",
  "OBJET",
  "OBLAH",
  "OBLAJ",
  "OBLAK",
  "OBLAL",
  "OBLAM",
  "OBLAN",
  "OBLAŠ",
  "OBLAT",
  "OBLEČ",
  "OBLEK",
  "OBLEM",
  "OBLET",
  "OBLIC",
  "OBLIČ",
  "OBLIH",
  "OBLIJ",
  "OBLIK",
  "OBLIL",
  "OBLIM",
  "OBLIN",
  "OBLIŠ",
  "OBLIT",
  "OBLIV",
  "OBLIŽ",
  "OBLOG",
  "OBLOK",
  "OBNOV",
  "OBOAH",
  "OBOAM",
  "OBODA",
  "OBODE",
  "OBODI",
  "OBODU",
  "OBOJA",
  "OBOJE",
  "OBOJI",
  "OBOJK",
  "OBOJO",
  "OBOJU",
  "OBOKA",
  "OBOKE",
  "OBOKI",
  "OBOKU",
  "OBOLA",
  "OBOLE",
  "OBOLI",
  "OBOLU",
  "OBORA",
  "OBORE",
  "OBORI",
  "OBORO",
  "OBORU",
  "OBOST",
  "OBRAB",
  "OBRAD",
  "OBRAL",
  "OBRAN",
  "OBRAT",
  "OBRAZ",
  "OBRCA",
  "OBREČ",
  "OBRED",
  "OBREZ",
  "OBRHA",
  "OBRHE",
  "OBRHI",
  "OBRHU",
  "OBRIJ",
  "OBRIL",
  "OBRIS",
  "OBRIT",
  "OBRNE",
  "OBRNI",
  "OBROB",
  "OBROČ",
  "OBROD",
  "OBROK",
  "OBRTI",
  "OBRUS",
  "OBRVI",
  "OBSEČ",
  "OBSEG",
  "OBSEJ",
  "OBSEV",
  "OBSIJ",
  "OBSKA",
  "OBSKE",
  "OBSKI",
  "OBSKO",
  "OBSUJ",
  "OBSUL",
  "OBSUT",
  "OBŠEL",
  "OBŠIJ",
  "OBŠIL",
  "OBŠIT",
  "OBŠIV",
  "OBŠLA",
  "OBŠLE",
  "OBŠLI",
  "OBŠLO",
  "OBTEČ",
  "OBTOK",
  "OBUDI",
  "OBUJA",
  "OBUJE",
  "OBUJK",
  "OBULA",
  "OBULE",
  "OBULI",
  "OBULO",
  "OBUPA",
  "OBUPE",
  "OBUPI",
  "OBUPU",
  "OBUTA",
  "OBUTE",
  "OBUTI",
  "OBUTO",
  "OBUVA",
  "OBVEZ",
  "OBVIJ",
  "OBVIL",
  "OBVIT",
  "OBVOD",
  "OBVOZ",
  "OBZIR",
  "OBZOR",
  "OBŽDI",
  "OBŽEL",
  "OBŽET",
  "OBŽGE",
  "OBŽGI",
  "OBŽIG",
  "OBŽRE",
  "OBŽRI",
  "OBŽRL",
  "OBŽRT",
  "OCEAN",
  "OCEDI",
  "OCENA",
  "OCENE",
  "OCENI",
  "OCENO",
  "OCTOM",
  "OCVRE",
  "OCVRI",
  "OCVRL",
  "OCVRT",
  "OČAKA",
  "OČAKE",
  "OČAKI",
  "OČAKU",
  "OČALA",
  "OČALI",
  "OČAMA",
  "OČAMI",
  "OČARA",
  "OČCEM",
  "OČCEV",
  "OČCIH",
  "OČEDI",
  "OČESA",
  "OČESC",
  "OČESI",
  "OČESU",
  "OČEŠE",
  "OČEŠI",
  "OČETA",
  "OČETE",
  "OČETI",
  "OČETU",
  "OČIJA",
  "OČIJE",
  "OČIJI",
  "OČIJU",
  "OČIMA",
  "OČIME",
  "OČIMI",
  "OČIMU",
  "OČIŠČ",
  "OČITA",
  "OČKAH",
  "OČKAM",
  "OČKIH",
  "OČKOM",
  "OČKOV",
  "OČNIC",
  "OČOHA",
  "OČRNI",
  "OČRTA",
  "OČRTE",
  "OČRTI",
  "OČRTU",
  "OČUVA",
  "ODAMA",
  "ODAMI",
  "ODBIJ",
  "ODBIL",
  "ODBIR",
  "ODBIT",
  "ODBOJ",
  "ODBOR",
  "ODCEP",
  "ODDAJ",
  "ODDAL",
  "ODDAM",
  "ODDAN",
  "ODDAŠ",
  "ODDAT",
  "ODDIH",
  "ODEJA",
  "ODEJE",
  "ODEJI",
  "ODEJO",
  "ODELA",
  "ODELE",
  "ODELI",
  "ODELO",
  "ODENE",
  "ODENI",
  "ODEON",
  "ODERE",
  "ODERI",
  "ODETA",
  "ODETE",
  "ODETI",
  "ODETO",
  "ODEVA",
  "ODGON",
  "ODHOD",
  "ODIDE",
  "ODIDI",
  "ODIJA",
  "ODIJE",
  "ODIJI",
  "ODIJU",
  "ODIMI",
  "ODIRA",
  "ODITI",
  "ODJAV",
  "ODJEJ",
  "ODJEK",
  "ODJEM",
  "ODJEŠ",
  "ODJUG",
  "ODKAR",
  "ODKAZ",
  "ODKOP",
  "ODKOS",
  "ODKUJ",
  "ODKUP",
  "ODLEČ",
  "ODLET",
  "ODLIJ",
  "ODLIK",
  "ODLIL",
  "ODLIT",
  "ODLIV",
  "ODLOG",
  "ODLOK",
  "ODLOM",
  "ODLOV",
  "ODMER",
  "ODMET",
  "ODMEV",
  "ODMIK",
  "ODMOR",
  "ODMRE",
  "ODMRI",
  "ODMRL",
  "ODMRT",
  "ODNOS",
  "ODPAD",
  "ODPEL",
  "ODPET",
  "ODPEV",
  "ODPIJ",
  "ODPIL",
  "ODPIS",
  "ODPIT",
  "ODPNE",
  "ODPNI",
  "ODPOJ",
  "ODPOR",
  "ODPRE",
  "ODPRI",
  "ODPRL",
  "ODPRO",
  "ODPRT",
  "ODRAJ",
  "ODRAL",
  "ODRAM",
  "ODRAN",
  "ODRAŠ",
  "ODRAT",
  "ODRAZ",
  "ODRCA",
  "ODRCE",
  "ODRCI",
  "ODRCU",
  "ODREČ",
  "ODRED",
  "ODREK",
  "ODREM",
  "ODREŠ",
  "ODRET",
  "ODREZ",
  "ODRIH",
  "ODRIV",
  "ODRLA",
  "ODRLE",
  "ODRLI",
  "ODRLO",
  "ODROM",
  "ODROV",
  "ODRSA",
  "ODRTA",
  "ODRTE",
  "ODRTI",
  "ODRTO",
  "ODRZA",
  "ODSEK",
  "ODSEV",
  "ODSIJ",
  "ODSPI",
  "ODSUJ",
  "ODSUL",
  "ODSUN",
  "ODSUT",
  "ODŠEL",
  "ODŠLA",
  "ODŠLE",
  "ODŠLI",
  "ODŠLO",
  "ODTEČ",
  "ODTIS",
  "ODTOK",
  "ODUČI",
  "ODVEČ",
  "ODVEZ",
  "ODVIJ",
  "ODVIL",
  "ODVIT",
  "ODVOD",
  "ODVOZ",
  "ODVRE",
  "ODVRI",
  "ODVRL",
  "ODVRT",
  "ODZAD",
  "ODZIV",
  "ODŽEL",
  "ODŽET",
  "ODŽGE",
  "ODŽGI",
  "ODŽIG",
  "ODŽRE",
  "ODŽRI",
  "ODŽRL",
  "OFIAZ",
  "OFNAJ",
  "OFNAL",
  "OFNAM",
  "OFNAN",
  "OFNAŠ",
  "OFRAJ",
  "OFRAL",
  "OFRAM",
  "OFRAN",
  "OFRAŠ",
  "OFRIH",
  "OFROM",
  "OFROV",
  "OGABA",
  "OGABE",
  "OGABI",
  "OGABO",
  "OGELN",
  "OGENJ",
  "OGIBA",
  "OGLAR",
  "OGLAS",
  "OGLAT",
  "OGLAV",
  "OGLED",
  "OGLEJ",
  "OGLEN",
  "OGLIH",
  "OGLJA",
  "OGLJE",
  "OGLJU",
  "OGLOM",
  "OGLOV",
  "OGNEM",
  "OGNEŠ",
  "OGNIJ",
  "OGNIL",
  "OGNIT",
  "OGNJA",
  "OGNJE",
  "OGNJI",
  "OGNJU",
  "OGOLI",
  "OGONA",
  "OGONE",
  "OGONI",
  "OGONU",
  "OGORI",
  "OGRAD",
  "OGRAJ",
  "OGRCA",
  "OGRCE",
  "OGRCI",
  "OGRCU",
  "OGRDI",
  "OGREJ",
  "OGREL",
  "OGRET",
  "OGRIC",
  "OGRIZ",
  "OGRNE",
  "OGRNI",
  "OGULI",
  "OHAJE",
  "OHAJO",
  "OHALA",
  "OHALE",
  "OHALI",
  "OHALO",
  "OHAMO",
  "OHANJ",
  "OHATA",
  "OHATE",
  "OHATI",
  "OHAVA",
  "OHCET",
  "OHLIP",
  "OHOLA",
  "OHOLE",
  "OHOLI",
  "OHOLO",
  "OHRNA",
  "OHRNE",
  "OHRNI",
  "OHRNO",
  "OIDIJ",
  "OJAČA",
  "OJAČI",
  "OJESA",
  "OJESI",
  "OJESU",
  "OJICE",
  "OJNIC",
  "OJUŽI",
  "OKADI",
  "OKAJA",
  "OKALI",
  "OKAPI",
  "OKARA",
  "OKATA",
  "OKATE",
  "OKATI",
  "OKATO",
  "OKENC",
  "OKEPA",
  "OKISA",
  "OKITI",
  "OKLAL",
  "OKLAN",
  "OKLAR",
  "OKLAT",
  "OKLEP",
  "OKLIC",
  "OKLIH",
  "OKLOM",
  "OKLOV",
  "OKNIC",
  "OKNIH",
  "OKNOM",
  "OKOLI",
  "OKOPA",
  "OKOPE",
  "OKOPI",
  "OKOPU",
  "OKORI",
  "OKOVA",
  "OKOVE",
  "OKOVI",
  "OKOVU",
  "OKRAH",
  "OKRAJ",
  "OKRAM",
  "OKRAS",
  "OKRCA",
  "OKRHA",
  "OKRNE",
  "OKRNI",
  "OKROG",
  "OKROM",
  "OKROV",
  "OKSID",
  "OKTAN",
  "OKTAV",
  "OKTET",
  "OKUJE",
  "OKUNA",
  "OKUNE",
  "OKUNI",
  "OKUNU",
  "OKUSA",
  "OKUSE",
  "OKUSI",
  "OKUSU",
  "OKUŠA",
  "OKUŽB",
  "OKUŽI",
  "OKVAR",
  "OKVIR",
  "OLEAT",
  "OLEIN",
  "OLEVI",
  "OLIKA",
  "OLIKE",
  "OLIKI",
  "OLIKO",
  "OLIMP",
  "OLIVA",
  "OLIVE",
  "OLIVI",
  "OLIVO",
  "OLJAR",
  "OLJEČ",
  "OLJEM",
  "OLJEN",
  "OLJIH",
  "OLJIL",
  "OLJIM",
  "OLJIŠ",
  "OLJIT",
  "OLJKA",
  "OLJKE",
  "OLJKI",
  "OLJKO",
  "OLJNA",
  "OLJNE",
  "OLJNI",
  "OLJNO",
  "OLTAR",
  "OLUPI",
  "OLUŽI",
  "OMAGA",
  "OMAJA",
  "OMAJE",
  "OMAJI",
  "OMAKA",
  "OMAKE",
  "OMAKI",
  "OMAKO",
  "OMAMA",
  "OMAME",
  "OMAMI",
  "OMAMO",
  "OMANA",
  "OMANE",
  "OMANI",
  "OMANU",
  "OMARA",
  "OMARE",
  "OMARI",
  "OMARO",
  "OMEČE",
  "OMEČI",
  "OMEDE",
  "OMEDI",
  "OMEGA",
  "OMEGE",
  "OMEGI",
  "OMEGO",
  "OMEJA",
  "OMEJE",
  "OMEJI",
  "OMEJU",
  "OMELA",
  "OMELC",
  "OMELE",
  "OMELI",
  "OMELO",
  "OMELU",
  "OMEMB",
  "OMENA",
  "OMENE",
  "OMENI",
  "OMENU",
  "OMEST",
  "OMETA",
  "OMETE",
  "OMETI",
  "OMETO",
  "OMETU",
  "OMIKA",
  "OMIKE",
  "OMIKI",
  "OMIKO",
  "OMILI",
  "OMLET",
  "OMOČI",
  "OMOMA",
  "OMOTA",
  "OMOTE",
  "OMOTI",
  "OMOTU",
  "OMOŽI",
  "OMSKA",
  "OMSKE",
  "OMSKI",
  "OMSKO",
  "OMULI",
  "ONALE",
  "ONDAN",
  "ONDOD",
  "ONEGA",
  "ONELE",
  "ONEMI",
  "ONEMU",
  "ONIKA",
  "ONIKS",
  "ONILE",
  "ONIMA",
  "ONIMI",
  "ONOLE",
  "ONUČA",
  "ONUČE",
  "ONUČI",
  "ONUČO",
  "OOCIT",
  "OOLIT",
  "OPAJA",
  "OPALA",
  "OPALE",
  "OPALI",
  "OPALU",
  "OPANK",
  "OPARI",
  "OPART",
  "OPAST",
  "OPAŠE",
  "OPAŠI",
  "OPATA",
  "OPATE",
  "OPATI",
  "OPATU",
  "OPAZI",
  "OPAZK",
  "OPAŽA",
  "OPAŽE",
  "OPAŽI",
  "OPAŽU",
  "OPCIJ",
  "OPECI",
  "OPEČE",
  "OPEČI",
  "OPEKA",
  "OPEKE",
  "OPEKI",
  "OPEKO",
  "OPELA",
  "OPELE",
  "OPELI",
  "OPELO",
  "OPENI",
  "OPERA",
  "OPERE",
  "OPERI",
  "OPERO",
  "OPEŠA",
  "OPETA",
  "OPETE",
  "OPETI",
  "OPETO",
  "OPEVA",
  "OPHAJ",
  "OPHAL",
  "OPHAM",
  "OPHAN",
  "OPHAŠ",
  "OPHAT",
  "OPIAT",
  "OPICA",
  "OPICE",
  "OPICI",
  "OPICO",
  "OPIČK",
  "OPIHA",
  "OPIHE",
  "OPIHI",
  "OPIHU",
  "OPIJA",
  "OPIJE",
  "OPIJU",
  "OPIKA",
  "OPILA",
  "OPILE",
  "OPILI",
  "OPILO",
  "OPIRA",
  "OPISA",
  "OPISE",
  "OPISI",
  "OPISU",
  "OPIŠE",
  "OPIŠI",
  "OPITA",
  "OPITE",
  "OPITI",
  "OPITO",
  "OPLAT",
  "OPLEL",
  "OPLEN",
  "OPLET",
  "OPLIH",
  "OPLOM",
  "OPLOV",
  "OPNAH",
  "OPNAM",
  "OPNEM",
  "OPNEŠ",
  "OPOJA",
  "OPOJE",
  "OPOJI",
  "OPOJU",
  "OPOKA",
  "OPOKE",
  "OPOKI",
  "OPOKO",
  "OPOLA",
  "OPOLE",
  "OPOLI",
  "OPOLO",
  "OPOLU",
  "OPOMB",
  "OPONK",
  "OPORA",
  "OPORE",
  "OPORI",
  "OPORO",
  "OPPNP",
  "OPRAL",
  "OPRAN",
  "OPRAT",
  "OPRAV",
  "OPREK",
  "OPREM",
  "OPREŠ",
  "OPRET",
  "OPRHA",
  "OPRHE",
  "OPRHI",
  "OPRHU",
  "OPRLA",
  "OPRLE",
  "OPRLI",
  "OPRLO",
  "OPROD",
  "OPROG",
  "OPRTA",
  "OPRTE",
  "OPRTI",
  "OPRTO",
  "OPSIN",
  "OPSUJ",
  "OPTIK",
  "OPUSA",
  "OPUSE",
  "OPUSI",
  "OPUSU",
  "ORAČA",
  "ORAČE",
  "ORAČI",
  "ORAČU",
  "ORADA",
  "ORADE",
  "ORADI",
  "ORADO",
  "ORALA",
  "ORALE",
  "ORALI",
  "ORALO",
  "ORALU",
  "ORANA",
  "ORANE",
  "ORANI",
  "ORANJ",
  "ORANO",
  "ORANT",
  "ORANŽ",
  "ORATI",
  "ORBIT",
  "ORDEN",
  "OREČA",
  "OREČE",
  "OREČI",
  "OREČO",
  "OREHA",
  "OREHE",
  "OREHI",
  "OREHU",
  "ORGAN",
  "ORGEL",
  "ORGIJ",
  "ORGLA",
  "ORGLE",
  "ORIBA",
  "ORIJO",
  "ORIKS",
  "ORILA",
  "ORILE",
  "ORILI",
  "ORILO",
  "ORIMO",
  "ORISA",
  "ORISE",
  "ORISI",
  "ORISU",
  "ORIŠE",
  "ORIŠI",
  "ORITA",
  "ORITE",
  "ORITI",
  "ORIVA",
  "ORJAK",
  "ORJEM",
  "ORJEŠ",
  "ORKAH",
  "ORKAM",
  "ORKAN",
  "ORKIH",
  "ORKOM",
  "ORKOV",
  "ORLIC",
  "ORLIČ",
  "ORLIH",
  "ORLJA",
  "ORLJE",
  "ORLJI",
  "ORLJO",
  "ORLOM",
  "ORLOV",
  "ORNAT",
  "ORNEM",
  "ORNIH",
  "ORNIM",
  "OROČA",
  "OROČI",
  "OROPA",
  "OROSI",
  "OROŠA",
  "ORTOZ",
  "OSAJI",
  "OSAMA",
  "OSAME",
  "OSAMI",
  "OSAMO",
  "OSAST",
  "OSATA",
  "OSATE",
  "OSATI",
  "OSATO",
  "OSATU",
  "OSCAL",
  "OSCAN",
  "OSCAT",
  "OSEBA",
  "OSEBE",
  "OSEBI",
  "OSEBO",
  "OSEIN",
  "OSEKA",
  "OSEKE",
  "OSEKI",
  "OSEKO",
  "OSEMA",
  "OSICA",
  "OSICE",
  "OSICI",
  "OSICO",
  "OSIPA",
  "OSIPE",
  "OSIPI",
  "OSIPU",
  "OSIRA",
  "OSIŠČ",
  "OSIVI",
  "OSJEM",
  "OSJIH",
  "OSJIM",
  "OSKAR",
  "OSLAD",
  "OSLAH",
  "OSLAM",
  "OSLAR",
  "OSLEZ",
  "OSLIC",
  "OSLIČ",
  "OSLIH",
  "OSLOM",
  "OSLOV",
  "OSMEM",
  "OSMER",
  "OSMIC",
  "OSMIČ",
  "OSMIH",
  "OSMIJ",
  "OSMIM",
  "OSMIN",
  "OSNEM",
  "OSNIH",
  "OSNIK",
  "OSNIM",
  "OSNOV",
  "OSNUJ",
  "OSOČI",
  "OSOJA",
  "OSOJE",
  "OSOJI",
  "OSOJO",
  "OSOLI",
  "OSRAL",
  "OSRAN",
  "OSRAT",
  "OSRČI",
  "OSTAL",
  "OSTAT",
  "OSTEH",
  "OSTEM",
  "OSTEN",
  "OSTER",
  "OSTEV",
  "OSTIJ",
  "OSTJO",
  "OSTMI",
  "OSTNA",
  "OSTNE",
  "OSTNI",
  "OSTNU",
  "OSTRA",
  "OSTRE",
  "OSTRI",
  "OSTRO",
  "OSTRV",
  "OSTUD",
  "OSTVA",
  "OSTVE",
  "OSTVI",
  "OSTVO",
  "OSUJE",
  "OSULA",
  "OSULE",
  "OSULI",
  "OSULO",
  "OSUMI",
  "OSUPI",
  "OSUŠI",
  "OSUTA",
  "OSUTE",
  "OSUTI",
  "OSUTO",
  "OSUVA",
  "OSVET",
  "OŠČEP",
  "OŠČIJ",
  "OŠČIM",
  "OŠČIŠ",
  "OŠIBA",
  "OŠIBI",
  "OŠILI",
  "OŠINE",
  "OŠINI",
  "OŠKEM",
  "OŠKIH",
  "OŠKIM",
  "OŠPIC",
  "OŠTEJ",
  "OŠTEL",
  "OŠTET",
  "OŠTIR",
  "OTAJA",
  "OTALI",
  "OTARE",
  "OTAVA",
  "OTAVE",
  "OTAVI",
  "OTAVO",
  "OTCEM",
  "OTCEV",
  "OTCIH",
  "OTECI",
  "OTEČE",
  "OTEČI",
  "OTEKA",
  "OTELA",
  "OTELE",
  "OTELI",
  "OTELO",
  "OTEPA",
  "OTEPE",
  "OTEPI",
  "OTEPU",
  "OTEŠE",
  "OTEŠI",
  "OTETA",
  "OTETE",
  "OTETI",
  "OTETO",
  "OTEŽI",
  "OTIPA",
  "OTIPE",
  "OTIPI",
  "OTIPU",
  "OTIRA",
  "OTISK",
  "OTKAH",
  "OTKAM",
  "OTMEM",
  "OTMEŠ",
  "OTOKA",
  "OTOKE",
  "OTOKI",
  "OTOKU",
  "OTOLČ",
  "OTOPI",
  "OTRDI",
  "OTREM",
  "OTREŠ",
  "OTRET",
  "OTRKA",
  "OTRLA",
  "OTRLE",
  "OTRLI",
  "OTRLO",
  "OTRNE",
  "OTRNI",
  "OTROK",
  "OTROV",
  "OTRTA",
  "OTRTE",
  "OTRTI",
  "OTRTO",
  "OTUJI",
  "OTVAH",
  "OTVAM",
  "OUZOM",
  "OVADB",
  "OVADI",
  "OVAJA",
  "OVALA",
  "OVALE",
  "OVALI",
  "OVALU",
  "OVCAH",
  "OVCAM",
  "OVČAR",
  "OVČIC",
  "OVČIN",
  "OVČJA",
  "OVČJE",
  "OVČJI",
  "OVČJO",
  "OVČKA",
  "OVČKE",
  "OVČKI",
  "OVČKO",
  "OVEDA",
  "OVEDI",
  "OVEDO",
  "OVEMO",
  "OVENE",
  "OVENI",
  "OVERI",
  "OVESI",
  "OVEŠA",
  "OVEVA",
  "OVIJA",
  "OVIJE",
  "OVILA",
  "OVILE",
  "OVILI",
  "OVILO",
  "OVIRA",
  "OVIRE",
  "OVIRI",
  "OVIRO",
  "OVIST",
  "OVITA",
  "OVITE",
  "OVITI",
  "OVITO",
  "OVNIC",
  "OVNIČ",
  "OVNIH",
  "OVNOM",
  "OVNOV",
  "OVOHA",
  "OVOJA",
  "OVOJE",
  "OVOJI",
  "OVOJK",
  "OVOJU",
  "OVREČ",
  "OVRZI",
  "OVRŽB",
  "OVRŽE",
  "OVSEN",
  "OVSIK",
  "OVSOM",
  "OZARA",
  "OZARE",
  "OZARI",
  "OZARO",
  "OZDIH",
  "OZDOM",
  "OZDOV",
  "OZEBA",
  "OZEBE",
  "OZEBI",
  "OZEBO",
  "OZIRA",
  "OZIRE",
  "OZIRI",
  "OZIRU",
  "OZKEM",
  "OZKIH",
  "OZKIM",
  "OZNAK",
  "OZOBA",
  "OZOBI",
  "OZONA",
  "OZONU",
  "OZREM",
  "OZREŠ",
  "OZRET",
  "OZRLA",
  "OZRLE",
  "OZRLI",
  "OZRLO",
  "OZRNI",
  "OŽAJE",
  "OŽAME",
  "OŽARI",
  "OŽEČA",
  "OŽEČE",
  "OŽEČI",
  "OŽEČO",
  "OŽELA",
  "OŽELE",
  "OŽELI",
  "OŽELO",
  "OŽEMA",
  "OŽEMI",
  "OŽENA",
  "OŽENE",
  "OŽENI",
  "OŽENJ",
  "OŽENO",
  "OŽETA",
  "OŽETE",
  "OŽETI",
  "OŽETO",
  "OŽGAL",
  "OŽGAN",
  "OŽGAT",
  "OŽGEM",
  "OŽGEŠ",
  "OŽIČI",
  "OŽIGA",
  "OŽIGE",
  "OŽIGI",
  "OŽIGU",
  "OŽIJO",
  "OŽILA",
  "OŽILE",
  "OŽILI",
  "OŽILO",
  "OŽIMO",
  "OŽINA",
  "OŽINE",
  "OŽINI",
  "OŽINO",
  "OŽIRA",
  "OŽITA",
  "OŽITE",
  "OŽITI",
  "OŽIVA",
  "OŽIVE",
  "OŽIVI",
  "OŽJEM",
  "OŽJIH",
  "OŽJIM",
  "OŽMEM",
  "OŽMEŠ",
  "OŽREM",
  "OŽREŠ",
  "OŽRET",
  "OŽRLA",
  "OŽRLE",
  "OŽRLI",
  "OŽRLO",
  "OŽULI",
  "PACAH",
  "PACAJ",
  "PACAL",
  "PACAM",
  "PACAŠ",
  "PACAT",
  "PACEK",
  "PACKA",
  "PACKE",
  "PACKI",
  "PACKO",
  "PACKU",
  "PAČEČ",
  "PAČEN",
  "PAČIL",
  "PAČIM",
  "PAČIŠ",
  "PAČIT",
  "PADAJ",
  "PADAL",
  "PADAM",
  "PADAR",
  "PADAŠ",
  "PADAT",
  "PADCA",
  "PADCE",
  "PADCI",
  "PADCU",
  "PADEC",
  "PADEL",
  "PADEM",
  "PADEN",
  "PADEŠ",
  "PADIH",
  "PADLA",
  "PADLE",
  "PADLI",
  "PADLO",
  "PADNA",
  "PADNE",
  "PADNI",
  "PADNO",
  "PADOM",
  "PADOV",
  "PAELJ",
  "PAELL",
  "PAFIJ",
  "PAGAT",
  "PAGER",
  "PAGIN",
  "PAGOD",
  "PAGOM",
  "PAHNE",
  "PAHNI",
  "PAJAC",
  "PAJCA",
  "PAJEK",
  "PAJKA",
  "PAJKE",
  "PAJKI",
  "PAJKU",
  "PAKAH",
  "PAKAM",
  "PAKEN",
  "PAKET",
  "PAKNA",
  "PAKNE",
  "PAKNI",
  "PAKNO",
  "PAKTA",
  "PAKTE",
  "PAKTI",
  "PAKTU",
  "PALAČ",
  "PALAH",
  "PALAM",
  "PALCA",
  "PALCE",
  "PALCI",
  "PALCU",
  "PALČK",
  "PALEC",
  "PALEČ",
  "PALET",
  "PALEŽ",
  "PALIC",
  "PALIJ",
  "PALIK",
  "PALIL",
  "PALIM",
  "PALIŠ",
  "PALMA",
  "PALME",
  "PALMI",
  "PALMO",
  "PALUB",
  "PAMET",
  "PAMIR",
  "PAMPA",
  "PAMPE",
  "PAMPI",
  "PAMPO",
  "PAMŽA",
  "PAMŽE",
  "PAMŽI",
  "PAMŽU",
  "PANAD",
  "PANAJ",
  "PANAL",
  "PANAM",
  "PANAN",
  "PANAŠ",
  "PANDA",
  "PANDE",
  "PANDI",
  "PANDO",
  "PANEL",
  "PANIK",
  "PANIN",
  "PANJA",
  "PANJE",
  "PANJI",
  "PANJU",
  "PANKA",
  "PANKO",
  "PANKU",
  "PANOG",
  "PANTA",
  "PANTE",
  "PANTI",
  "PANTU",
  "PAPAH",
  "PAPAJ",
  "PAPAL",
  "PAPAM",
  "PAPAN",
  "PAPAŠ",
  "PAPAT",
  "PAPCA",
  "PAPEŽ",
  "PAPIG",
  "PAPIL",
  "PAPIR",
  "PAPUA",
  "PAPUE",
  "PAPUI",
  "PAPUK",
  "PAPUL",
  "PAPUO",
  "PARAA",
  "PARAD",
  "PARAF",
  "PARAH",
  "PARAJ",
  "PARAL",
  "PARAM",
  "PARAŠ",
  "PARAT",
  "PAREČ",
  "PAREN",
  "PAREO",
  "PAREZ",
  "PARFE",
  "PARIC",
  "PARIH",
  "PARIJ",
  "PARIL",
  "PARIM",
  "PARIŠ",
  "PARIT",
  "PARKA",
  "PARKE",
  "PARKI",
  "PARKU",
  "PARNA",
  "PARNE",
  "PARNI",
  "PARNO",
  "PAROA",
  "PAROE",
  "PAROH",
  "PAROI",
  "PAROL",
  "PAROM",
  "PAROU",
  "PAROV",
  "PARTA",
  "PARTE",
  "PARTI",
  "PARTO",
  "PARTU",
  "PASAL",
  "PASAN",
  "PASAR",
  "PASAT",
  "PASAŽ",
  "PASEK",
  "PASEL",
  "PASEM",
  "PASEN",
  "PASEŠ",
  "PASHA",
  "PASHE",
  "PASHI",
  "PASHO",
  "PASIC",
  "PASIH",
  "PASIV",
  "PASJA",
  "PASJE",
  "PASJI",
  "PASJO",
  "PASKA",
  "PASKE",
  "PASKI",
  "PASKU",
  "PASLA",
  "PASLE",
  "PASLI",
  "PASLO",
  "PASMA",
  "PASME",
  "PASMI",
  "PASMO",
  "PASNA",
  "PASNE",
  "PASNI",
  "PASNO",
  "PASOČ",
  "PASOM",
  "PASOŠ",
  "PASOV",
  "PASTA",
  "PASTE",
  "PASTI",
  "PASTO",
  "PASUS",
  "PASUŠ",
  "PAŠAH",
  "PAŠAM",
  "PAŠEM",
  "PAŠEN",
  "PAŠEŠ",
  "PAŠKA",
  "PAŠKE",
  "PAŠKI",
  "PAŠKO",
  "PAŠNA",
  "PAŠNE",
  "PAŠNI",
  "PAŠNO",
  "PAŠTA",
  "PAŠTE",
  "PAŠTI",
  "PAŠTO",
  "PAŠUJ",
  "PATAS",
  "PATEN",
  "PATER",
  "PATIH",
  "PATOM",
  "PATOS",
  "PATOV",
  "PATRA",
  "PATRE",
  "PATRI",
  "PATRU",
  "PAVIC",
  "PAVIH",
  "PAVJA",
  "PAVJE",
  "PAVJI",
  "PAVJO",
  "PAVKA",
  "PAVKE",
  "PAVKI",
  "PAVKO",
  "PAVOM",
  "PAVOV",
  "PAVZA",
  "PAVZE",
  "PAVZI",
  "PAVZO",
  "PAZEČ",
  "PAZEN",
  "PAZIL",
  "PAZIM",
  "PAZIŠ",
  "PAZIT",
  "PAZNA",
  "PAZNE",
  "PAZNI",
  "PAZNO",
  "PAŽEČ",
  "PAŽEM",
  "PAŽEN",
  "PAŽEV",
  "PAŽIH",
  "PAŽIL",
  "PAŽIM",
  "PAŽIŠ",
  "PAŽIT",
  "PECAJ",
  "PECAL",
  "PECAM",
  "PECAŠ",
  "PECIV",
  "PECKA",
  "PEČAJ",
  "PEČAL",
  "PEČAM",
  "PEČAR",
  "PEČAŠ",
  "PEČAT",
  "PEČEH",
  "PEČEM",
  "PEČEN",
  "PEČEŠ",
  "PEČIC",
  "PEČIN",
  "PEČJO",
  "PEČKA",
  "PEČKE",
  "PEČKI",
  "PEČKO",
  "PEČMI",
  "PEČNA",
  "PEČNE",
  "PEČNI",
  "PEČNO",
  "PEDAL",
  "PEDEH",
  "PEDEM",
  "PEDER",
  "PEDIC",
  "PEDJO",
  "PEDMI",
  "PEDRA",
  "PEDRE",
  "PEDRI",
  "PEDRU",
  "PEGAH",
  "PEGAM",
  "PEGAT",
  "PEGAV",
  "PEGAZ",
  "PEGIC",
  "PEGLA",
  "PEHAJ",
  "PEHAL",
  "PEHAM",
  "PEHAR",
  "PEHAŠ",
  "PEHAT",
  "PEHOT",
  "PEKAČ",
  "PEKAH",
  "PEKAM",
  "PEKEL",
  "PEKET",
  "PEKIH",
  "PEKLA",
  "PEKLE",
  "PEKLI",
  "PEKLO",
  "PEKLU",
  "PEKOČ",
  "PEKOM",
  "PEKOV",
  "PELAT",
  "PELCA",
  "PELCE",
  "PELCI",
  "PELCU",
  "PELET",
  "PELIN",
  "PELIR",
  "PELJE",
  "PELJI",
  "PELOD",
  "PELOT",
  "PENAH",
  "PENAL",
  "PENAM",
  "PENAT",
  "PENEČ",
  "PENEZ",
  "PENIC",
  "PENIL",
  "PENIM",
  "PENIN",
  "PENIS",
  "PENIŠ",
  "PENIT",
  "PENJA",
  "PENJE",
  "PENJI",
  "PENJO",
  "PENUŠ",
  "PEPEL",
  "PEREČ",
  "PEREM",
  "PERES",
  "PEREŠ",
  "PERIC",
  "PERIK",
  "PERJA",
  "PERJE",
  "PERJU",
  "PERLA",
  "PERLE",
  "PERLI",
  "PERLO",
  "PERMA",
  "PERMU",
  "PEROČ",
  "PERON",
  "PEROT",
  "PERUŠ",
  "PERUT",
  "PESAR",
  "PESEK",
  "PESEM",
  "PESEN",
  "PESIH",
  "PESIN",
  "PESKA",
  "PESKE",
  "PESKI",
  "PESKU",
  "PESMI",
  "PESNA",
  "PESNE",
  "PESNI",
  "PESNO",
  "PESOM",
  "PESOV",
  "PESTA",
  "PESTE",
  "PESTI",
  "PESTO",
  "PESTU",
  "PEŠAJ",
  "PEŠAK",
  "PEŠAL",
  "PEŠAM",
  "PEŠAŠ",
  "PEŠAT",
  "PEŠCA",
  "PEŠCE",
  "PEŠCI",
  "PEŠCU",
  "PEŠEC",
  "PEŠKA",
  "PEŠKE",
  "PEŠKI",
  "PEŠKO",
  "PEŠTA",
  "PETAH",
  "PETAM",
  "PETEK",
  "PETEM",
  "PETEN",
  "PETER",
  "PETIC",
  "PETIČ",
  "PETIH",
  "PETIJ",
  "PETIM",
  "PETIN",
  "PETJA",
  "PETJE",
  "PETJI",
  "PETJU",
  "PETKA",
  "PETKE",
  "PETKI",
  "PETKO",
  "PETKU",
  "PETNA",
  "PETNE",
  "PETNI",
  "PETNO",
  "PETOL",
  "PEVAJ",
  "PEVAL",
  "PEVAM",
  "PEVAŠ",
  "PEVAT",
  "PEVCA",
  "PEVCE",
  "PEVCI",
  "PEVCU",
  "PEVEC",
  "PEVEN",
  "PEVKA",
  "PEVKE",
  "PEVKI",
  "PEVKO",
  "PEVNA",
  "PEVNE",
  "PEVNI",
  "PEVNO",
  "PEZAH",
  "PEZAM",
  "PEZDE",
  "PEZET",
  "PHAJO",
  "PHALA",
  "PHALE",
  "PHALI",
  "PHALO",
  "PHAMO",
  "PHANA",
  "PHANE",
  "PHANI",
  "PHANJ",
  "PHANO",
  "PHATA",
  "PHATE",
  "PHATI",
  "PHAVA",
  "PIANA",
  "PIANE",
  "PIANI",
  "PIANO",
  "PIANU",
  "PIARA",
  "PIARE",
  "PIARI",
  "PIARU",
  "PICAH",
  "PICAM",
  "PICET",
  "PIČEL",
  "PIČEN",
  "PIČIC",
  "PIČIL",
  "PIČIM",
  "PIČIŠ",
  "PIČIT",
  "PIČKA",
  "PIČKE",
  "PIČKI",
  "PIČKO",
  "PIČLA",
  "PIČLE",
  "PIČLI",
  "PIČLO",
  "PIDIH",
  "PIDOM",
  "PIDOV",
  "PIETA",
  "PIFLA",
  "PIHAJ",
  "PIHAL",
  "PIHAM",
  "PIHAN",
  "PIHAŠ",
  "PIHAT",
  "PIHIH",
  "PIHNE",
  "PIHNI",
  "PIHOM",
  "PIHOV",
  "PIJAČ",
  "PIJAN",
  "PIJEM",
  "PIJEŠ",
  "PIJEV",
  "PIJIH",
  "PIJMO",
  "PIJOČ",
  "PIJTA",
  "PIJTE",
  "PIJVA",
  "PIKAH",
  "PIKAJ",
  "PIKAL",
  "PIKAM",
  "PIKAŠ",
  "PIKAT",
  "PIKČA",
  "PIKER",
  "PIKIC",
  "PIKIH",
  "PIKNE",
  "PIKNI",
  "PIKOM",
  "PIKOV",
  "PIKRA",
  "PIKRE",
  "PIKRI",
  "PIKRO",
  "PILAH",
  "PILAM",
  "PILAR",
  "PILAV",
  "PILEČ",
  "PILIC",
  "PILIL",
  "PILIM",
  "PILIŠ",
  "PILIT",
  "PILOT",
  "PILUL",
  "PILUM",
  "PINCA",
  "PINCE",
  "PINCI",
  "PINCO",
  "PINČA",
  "PINČE",
  "PINČI",
  "PINČU",
  "PINDA",
  "PINDU",
  "PINIH",
  "PINIJ",
  "PINJA",
  "PINJE",
  "PINJI",
  "PINJO",
  "PINKA",
  "PINKE",
  "PINKI",
  "PINKO",
  "PINOM",
  "PINOT",
  "PINOV",
  "PINOŽ",
  "PINTA",
  "PINTE",
  "PINTI",
  "PINTO",
  "PINTU",
  "PIONA",
  "PIONE",
  "PIONI",
  "PIONU",
  "PIPAH",
  "PIPAJ",
  "PIPAL",
  "PIPAM",
  "PIPAR",
  "PIPAŠ",
  "PIPAT",
  "PIPCA",
  "PIPCE",
  "PIPCI",
  "PIPCU",
  "PIPEC",
  "PIPER",
  "PIPET",
  "PIPIC",
  "PIPNE",
  "PIPNI",
  "PIPSA",
  "PIPSU",
  "PIRAJ",
  "PIRAT",
  "PIRHA",
  "PIRHE",
  "PIRHI",
  "PIRHU",
  "PIRIH",
  "PIRIK",
  "PIRIN",
  "PIRIT",
  "PIRKA",
  "PIRKE",
  "PIRKI",
  "PIRKO",
  "PIROG",
  "PIROM",
  "PIROV",
  "PIRUJ",
  "PISAČ",
  "PISAL",
  "PISAN",
  "PISAR",
  "PISAT",
  "PISAV",
  "PISCA",
  "PISCE",
  "PISCI",
  "PISCU",
  "PISEC",
  "PISEM",
  "PISEN",
  "PISKA",
  "PISKE",
  "PISKI",
  "PISKO",
  "PISKU",
  "PISMA",
  "PISMI",
  "PISMO",
  "PISMU",
  "PISNA",
  "PISNE",
  "PISNI",
  "PISNO",
  "PISTA",
  "PISTE",
  "PISTI",
  "PISTO",
  "PISUN",
  "PIŠČE",
  "PIŠEM",
  "PIŠEŠ",
  "PIŠEV",
  "PIŠIH",
  "PIŠKA",
  "PIŠKE",
  "PIŠKI",
  "PIŠKO",
  "PIŠOČ",
  "PITAH",
  "PITAJ",
  "PITAL",
  "PITAM",
  "PITAN",
  "PITAŠ",
  "PITAT",
  "PITEN",
  "PITIJ",
  "PITJA",
  "PITJE",
  "PITJI",
  "PITJU",
  "PITNA",
  "PITNE",
  "PITNI",
  "PITNO",
  "PITON",
  "PIVCA",
  "PIVCE",
  "PIVCI",
  "PIVCU",
  "PIVEC",
  "PIVEN",
  "PIVIH",
  "PIVIN",
  "PIVKA",
  "PIVKE",
  "PIVKI",
  "PIVKO",
  "PIVNA",
  "PIVNE",
  "PIVNI",
  "PIVNO",
  "PIVOM",
  "PIVOT",
  "PIZDA",
  "PIZDE",
  "PIZDI",
  "PIZDO",
  "PIZZA",
  "PIZZE",
  "PIZZI",
  "PIZZO",
  "PIŽAM",
  "PLACA",
  "PLACE",
  "PLACI",
  "PLACU",
  "PLAČA",
  "PLAČE",
  "PLAČI",
  "PLAČO",
  "PLAHA",
  "PLAHE",
  "PLAHI",
  "PLAHO",
  "PLAHT",
  "PLAJB",
  "PLAKA",
  "PLAKU",
  "PLALA",
  "PLALE",
  "PLALI",
  "PLALO",
  "PLANA",
  "PLANE",
  "PLANI",
  "PLANJ",
  "PLANK",
  "PLANO",
  "PLANU",
  "PLAST",
  "PLAŠČ",
  "PLAŠI",
  "PLATA",
  "PLATE",
  "PLATI",
  "PLATO",
  "PLAVA",
  "PLAVE",
  "PLAVI",
  "PLAVO",
  "PLAVŽ",
  "PLAZA",
  "PLAZE",
  "PLAZI",
  "PLAZO",
  "PLAZU",
  "PLAŽA",
  "PLAŽE",
  "PLAŽI",
  "PLAŽO",
  "PLEBS",
  "PLEČA",
  "PLEČE",
  "PLEČI",
  "PLEČK",
  "PLEHA",
  "PLEHU",
  "PLELA",
  "PLELE",
  "PLELI",
  "PLELO",
  "PLEME",
  "PLENA",
  "PLENE",
  "PLENI",
  "PLENU",
  "PLESA",
  "PLESE",
  "PLESI",
  "PLEST",
  "PLESU",
  "PLEŠA",
  "PLEŠE",
  "PLEŠI",
  "PLEŠK",
  "PLEŠO",
  "PLETA",
  "PLETE",
  "PLETI",
  "PLETO",
  "PLETU",
  "PLEVA",
  "PLEVE",
  "PLEVI",
  "PLEVK",
  "PLEVO",
  "PLEZA",
  "PLIKA",
  "PLIKE",
  "PLIKI",
  "PLIKO",
  "PLIMA",
  "PLIME",
  "PLIMI",
  "PLIMO",
  "PLINA",
  "PLINE",
  "PLINI",
  "PLINU",
  "PLISE",
  "PLIŠA",
  "PLIŠU",
  "PLJUČ",
  "PLODA",
  "PLODE",
  "PLODI",
  "PLODU",
  "PLOHA",
  "PLOHE",
  "PLOHI",
  "PLOHO",
  "PLOHU",
  "PLOMB",
  "PLONK",
  "PLOSK",
  "PLOŠČ",
  "PLOTA",
  "PLOTU",
  "PLOVA",
  "PLOVB",
  "PLOVE",
  "PLOVI",
  "PLOVK",
  "PLOVU",
  "PLOZA",
  "PLUGA",
  "PLUGE",
  "PLUGI",
  "PLUGU",
  "PLUJE",
  "PLULA",
  "PLULE",
  "PLULI",
  "PLULO",
  "PLUSA",
  "PLUSE",
  "PLUSI",
  "PLUSU",
  "PLUTA",
  "PLUTE",
  "PLUTI",
  "PLUTO",
  "PLUŽI",
  "PNEJO",
  "PNEMO",
  "PNETA",
  "PNETE",
  "PNEVA",
  "PNIMO",
  "PNITA",
  "PNITE",
  "PNIVA",
  "POANT",
  "POBCA",
  "POBCE",
  "POBCI",
  "POBCU",
  "POBEC",
  "POBEG",
  "POBIČ",
  "POBIH",
  "POBIJ",
  "POBIL",
  "POBIT",
  "POBJE",
  "POBOJ",
  "POBOM",
  "POBOT",
  "POBOV",
  "POBUD",
  "POCAR",
  "POCEM",
  "POCEV",
  "POCIH",
  "POČEH",
  "POČEL",
  "POČEM",
  "POČEN",
  "POČEP",
  "POČET",
  "POČEZ",
  "POČIJ",
  "POČIL",
  "POČIM",
  "POČIŠ",
  "POČIT",
  "POČJO",
  "POČMI",
  "POČNE",
  "POČNI",
  "POČNO",
  "PODAJ",
  "PODAL",
  "PODAM",
  "PODAN",
  "PODAŠ",
  "PODAT",
  "PODEČ",
  "PODEL",
  "PODEN",
  "PODIH",
  "PODIJ",
  "PODIL",
  "PODIM",
  "PODIŠ",
  "PODIT",
  "PODJE",
  "PODLA",
  "PODLE",
  "PODLI",
  "PODLO",
  "PODNA",
  "PODNE",
  "PODNI",
  "PODNO",
  "PODNU",
  "PODOB",
  "PODOJ",
  "PODOM",
  "PODOR",
  "PODOV",
  "PODRE",
  "PODRI",
  "PODRL",
  "PODRS",
  "PODRT",
  "PODUK",
  "POEMA",
  "POEME",
  "POEMI",
  "POEMO",
  "POETA",
  "POETE",
  "POETI",
  "POETU",
  "POFEL",
  "POFLA",
  "POFLU",
  "POGAČ",
  "POGAN",
  "POGIN",
  "POGOJ",
  "POGON",
  "POGUB",
  "POGUM",
  "POHAB",
  "POHAJ",
  "POHAL",
  "POHAM",
  "POHAN",
  "POHAŠ",
  "POHER",
  "POHOD",
  "POHOT",
  "POHRA",
  "POHRE",
  "POHRI",
  "POHRO",
  "POIDE",
  "POIDI",
  "POITI",
  "POJAJ",
  "POJAL",
  "POJAM",
  "POJAN",
  "POJAŠ",
  "POJAV",
  "POJDE",
  "POJDI",
  "POJEČ",
  "POJEJ",
  "POJEM",
  "POJEŠ",
  "POJIL",
  "POJIM",
  "POJIŠ",
  "POJIT",
  "POJMA",
  "POJME",
  "POJMI",
  "POJMO",
  "POJMU",
  "POJOČ",
  "POJTA",
  "POJTE",
  "POJVA",
  "POKAČ",
  "POKAH",
  "POKAJ",
  "POKAL",
  "POKAM",
  "POKAN",
  "POKAŠ",
  "POKAT",
  "POKAZ",
  "POKCA",
  "POKCE",
  "POKCI",
  "POKCU",
  "POKEC",
  "POKER",
  "POKIH",
  "POKNE",
  "POKNI",
  "POKOJ",
  "POKOL",
  "POKOM",
  "POKOP",
  "POKOR",
  "POKOV",
  "POKRA",
  "POKRU",
  "POLAH",
  "POLAJ",
  "POLAM",
  "POLAR",
  "POLEČ",
  "POLEG",
  "POLEN",
  "POLET",
  "POLHA",
  "POLHE",
  "POLHI",
  "POLHU",
  "POLIA",
  "POLIC",
  "POLIČ",
  "POLIE",
  "POLIH",
  "POLII",
  "POLIJ",
  "POLIL",
  "POLIO",
  "POLIP",
  "POLIR",
  "POLIS",
  "POLIT",
  "POLIU",
  "POLIV",
  "POLJA",
  "POLJE",
  "POLJI",
  "POLJU",
  "POLKA",
  "POLKE",
  "POLKI",
  "POLKO",
  "POLKU",
  "POLNA",
  "POLNE",
  "POLNI",
  "POLNO",
  "POLOG",
  "POLOM",
  "POLOS",
  "POLOV",
  "POLST",
  "POLTI",
  "POLUT",
  "POLZA",
  "POLZE",
  "POLZI",
  "POLZO",
  "POLŽA",
  "POLŽE",
  "POLŽI",
  "POLŽU",
  "POMAD",
  "POMAK",
  "POMEL",
  "POMEN",
  "POMET",
  "POMIG",
  "POMIJ",
  "POMIK",
  "POMIL",
  "POMIT",
  "POMNI",
  "POMOČ",
  "POMOL",
  "POMOR",
  "POMOT",
  "POMPA",
  "POMPE",
  "POMPI",
  "POMPU",
  "POMRE",
  "POMRI",
  "POMRL",
  "PONAH",
  "PONAM",
  "PONCA",
  "PONCE",
  "PONCI",
  "PONCO",
  "PONČA",
  "PONČE",
  "PONČI",
  "PONČO",
  "PONČU",
  "PONDA",
  "PONDE",
  "PONDI",
  "PONDU",
  "PONEV",
  "PONIK",
  "PONKA",
  "PONKE",
  "PONKI",
  "PONKU",
  "PONOR",
  "PONOS",
  "PONUD",
  "PONVE",
  "PONVI",
  "POOLA",
  "POOLE",
  "POOLI",
  "POOLU",
  "POPAJ",
  "POPAL",
  "POPAM",
  "POPAR",
  "POPAŠ",
  "POPAT",
  "POPEČ",
  "POPEK",
  "POPEL",
  "POPER",
  "POPET",
  "POPIH",
  "POPIJ",
  "POPIK",
  "POPIL",
  "POPIS",
  "POPIT",
  "POPJA",
  "POPJE",
  "POPJU",
  "POPKA",
  "POPKE",
  "POPKI",
  "POPKU",
  "POPNE",
  "POPNI",
  "POPOM",
  "POPON",
  "POPOV",
  "POPRA",
  "POPRE",
  "POPRH",
  "POPRI",
  "POPRU",
  "PORAB",
  "PORAH",
  "PORAM",
  "PORAZ",
  "PORDI",
  "POREČ",
  "POREK",
  "POREN",
  "PORIH",
  "PORIJ",
  "PORIL",
  "PORIT",
  "PORIV",
  "PORNA",
  "PORNE",
  "PORNI",
  "PORNO",
  "PORNU",
  "POROD",
  "POROG",
  "POROK",
  "POROM",
  "POROT",
  "POROV",
  "POROZ",
  "PORUJ",
  "POSEČ",
  "POSEG",
  "POSEJ",
  "POSEK",
  "POSEL",
  "POSIJ",
  "POSIP",
  "POSLA",
  "POSLE",
  "POSLI",
  "POSLU",
  "POSOD",
  "POSPI",
  "POSTA",
  "POSTE",
  "POSTI",
  "POSTU",
  "POSUJ",
  "POSUL",
  "POSUT",
  "POŠČI",
  "POŠEL",
  "POŠEV",
  "POŠIJ",
  "POŠIL",
  "POŠIT",
  "POŠKA",
  "POŠKE",
  "POŠKI",
  "POŠKO",
  "POŠLA",
  "POŠLE",
  "POŠLI",
  "POŠLO",
  "POŠTA",
  "POŠTE",
  "POŠTI",
  "POŠTO",
  "POTEČ",
  "POTEG",
  "POTEH",
  "POTEK",
  "POTEM",
  "POTEN",
  "POTEP",
  "POTEZ",
  "POTIC",
  "POTIH",
  "POTIL",
  "POTIM",
  "POTIŠ",
  "POTIT",
  "POTJO",
  "POTKA",
  "POTKE",
  "POTKI",
  "POTKO",
  "POTLI",
  "POTMI",
  "POTNA",
  "POTNE",
  "POTNI",
  "POTNO",
  "POTOK",
  "POTOM",
  "POTOP",
  "POTOV",
  "POTRE",
  "POTRI",
  "POTRL",
  "POTRT",
  "POTUJ",
  "POUČI",
  "POUKA",
  "POUKE",
  "POUKI",
  "POUKU",
  "POVED",
  "POVEJ",
  "POVEM",
  "POVES",
  "POVEŠ",
  "POVEZ",
  "POVIJ",
  "POVIL",
  "POVIT",
  "POVOD",
  "POVOJ",
  "POVOZ",
  "POVRE",
  "POVRG",
  "POVRH",
  "POVRI",
  "POZAB",
  "POZAH",
  "POZAM",
  "POZEB",
  "POZEN",
  "POZER",
  "POZIV",
  "POZLU",
  "POZNA",
  "POZNE",
  "POZNI",
  "POZNO",
  "POZOJ",
  "POZOR",
  "POŽAR",
  "POŽEL",
  "POŽET",
  "POŽGE",
  "POŽGI",
  "POŽIG",
  "POŽRE",
  "POŽRI",
  "POŽRL",
  "POŽRT",
  "PRAČA",
  "PRAČE",
  "PRAČI",
  "PRAČO",
  "PRAGA",
  "PRAGE",
  "PRAGI",
  "PRAGU",
  "PRAHA",
  "PRAHE",
  "PRAHI",
  "PRAHO",
  "PRAHU",
  "PRAKS",
  "PRALA",
  "PRALE",
  "PRALI",
  "PRALK",
  "PRALO",
  "PRAMA",
  "PRAME",
  "PRAMI",
  "PRAMO",
  "PRAMU",
  "PRANA",
  "PRANE",
  "PRANI",
  "PRANJ",
  "PRANO",
  "PRASE",
  "PRASI",
  "PRASK",
  "PRAŠA",
  "PRAŠI",
  "PRATA",
  "PRATE",
  "PRATI",
  "PRATO",
  "PRAVA",
  "PRAVD",
  "PRAVE",
  "PRAVI",
  "PRAVO",
  "PRAVU",
  "PRAŽE",
  "PRAŽI",
  "PRDAH",
  "PRDAM",
  "PRDCA",
  "PRDCE",
  "PRDCI",
  "PRDCU",
  "PRDEC",
  "PRDEČ",
  "PRDEL",
  "PRDET",
  "PRDIM",
  "PRDIŠ",
  "PRDNE",
  "PRDNI",
  "PREČA",
  "PREČE",
  "PREČI",
  "PREČK",
  "PREČO",
  "PREDA",
  "PREDE",
  "PREDI",
  "PREIT",
  "PREJA",
  "PREJE",
  "PREJI",
  "PREJK",
  "PREJO",
  "PREKO",
  "PRELK",
  "PREMA",
  "PREME",
  "PREMI",
  "PREMO",
  "PREST",
  "PREŠA",
  "PREŠC",
  "PREŠE",
  "PREŠI",
  "PREŠO",
  "PRETI",
  "PREZA",
  "PREŽA",
  "PREŽE",
  "PREŽI",
  "PREŽO",
  "PRHAH",
  "PRHAJ",
  "PRHAK",
  "PRHAL",
  "PRHAM",
  "PRHAŠ",
  "PRHAT",
  "PRHEK",
  "PRHEL",
  "PRHKA",
  "PRHKE",
  "PRHKI",
  "PRHKO",
  "PRHLA",
  "PRHLE",
  "PRHLI",
  "PRHLO",
  "PRHNE",
  "PRHNI",
  "PRHUT",
  "PRIBA",
  "PRIBE",
  "PRIBI",
  "PRIBO",
  "PRIČA",
  "PRIČE",
  "PRIČI",
  "PRIČO",
  "PRIDA",
  "PRIDE",
  "PRIDI",
  "PRIDU",
  "PRIJA",
  "PRIMA",
  "PRIME",
  "PRIMI",
  "PRINC",
  "PRINT",
  "PRION",
  "PRIOR",
  "PRIŠČ",
  "PRITI",
  "PROBA",
  "PROBE",
  "PROBI",
  "PROBO",
  "PRODA",
  "PRODE",
  "PRODI",
  "PRODU",
  "PROFI",
  "PROGA",
  "PROGE",
  "PROGI",
  "PROGO",
  "PROJA",
  "PROJE",
  "PROJI",
  "PROJO",
  "PROSA",
  "PROSI",
  "PROSO",
  "PROST",
  "PROSU",
  "PROŠT",
  "PROTA",
  "PROTE",
  "PROTI",
  "PROTO",
  "PROTU",
  "PROZA",
  "PROZE",
  "PROZI",
  "PROZO",
  "PROŽI",
  "PRSAT",
  "PRSCA",
  "PRSCI",
  "PRSEČ",
  "PRSEN",
  "PRSIH",
  "PRSIL",
  "PRSIM",
  "PRSIŠ",
  "PRSIT",
  "PRSKA",
  "PRSKE",
  "PRSKI",
  "PRSKU",
  "PRSMI",
  "PRSNA",
  "PRSNE",
  "PRSNI",
  "PRSNO",
  "PRSOM",
  "PRSTA",
  "PRSTE",
  "PRSTI",
  "PRSTU",
  "PRŠAL",
  "PRŠCA",
  "PRŠCE",
  "PRŠCI",
  "PRŠCU",
  "PRŠEC",
  "PRŠEČ",
  "PRŠEL",
  "PRŠEM",
  "PRŠEN",
  "PRŠET",
  "PRŠEV",
  "PRŠIC",
  "PRŠIČ",
  "PRŠIH",
  "PRŠIL",
  "PRŠIM",
  "PRŠIŠ",
  "PRŠIT",
  "PRŠIV",
  "PRŠNA",
  "PRŠNE",
  "PRŠNI",
  "PRŠNO",
  "PRŠUT",
  "PRTEČ",
  "PRTEN",
  "PRTIČ",
  "PRTIH",
  "PRTIL",
  "PRTIM",
  "PRTIŠ",
  "PRTIT",
  "PRTOM",
  "PRTOV",
  "PRUČK",
  "PRVAK",
  "PRVCA",
  "PRVCE",
  "PRVCI",
  "PRVCU",
  "PRVEC",
  "PRVEM",
  "PRVIČ",
  "PRVIH",
  "PRVIM",
  "PRVIN",
  "PSALM",
  "PSARK",
  "PSARN",
  "PSICA",
  "PSICE",
  "PSICI",
  "PSICO",
  "PSIČK",
  "PSIHA",
  "PSIHE",
  "PSIHI",
  "PSIHO",
  "PSIJA",
  "PSIJE",
  "PSIJI",
  "PSIJU",
  "PSIKA",
  "PSOMA",
  "PSOVK",
  "PSUJE",
  "PŠATA",
  "PŠATE",
  "PŠATI",
  "PŠATO",
  "PŠENA",
  "PŠENO",
  "PŠENU",
  "PTICA",
  "PTICE",
  "PTICI",
  "PTICO",
  "PTIČA",
  "PTIČE",
  "PTIČI",
  "PTIČK",
  "PTIČU",
  "PTOZA",
  "PTOZE",
  "PTOZI",
  "PTOZO",
  "PUBIH",
  "PUBOM",
  "PUBOV",
  "PUCAJ",
  "PUCAL",
  "PUCAM",
  "PUCAŠ",
  "PUČEM",
  "PUČEV",
  "PUČIH",
  "PUDER",
  "PUDIH",
  "PUDLA",
  "PUDOM",
  "PUDOV",
  "PUDRA",
  "PUDRE",
  "PUDRI",
  "PUDRN",
  "PUDRU",
  "PUFER",
  "PUFIH",
  "PUFOM",
  "PUFOV",
  "PUFRA",
  "PUFRE",
  "PUFRI",
  "PUFRU",
  "PUHAJ",
  "PUHAL",
  "PUHAM",
  "PUHAR",
  "PUHAŠ",
  "PUHAT",
  "PUHCA",
  "PUHCE",
  "PUHCI",
  "PUHCU",
  "PUHEC",
  "PUHEK",
  "PUHEL",
  "PUHKA",
  "PUHKE",
  "PUHKI",
  "PUHKU",
  "PUHLA",
  "PUHLE",
  "PUHLI",
  "PUHLO",
  "PUHNE",
  "PUHNI",
  "PUHOM",
  "PUHTE",
  "PUHTI",
  "PUJSA",
  "PUJSE",
  "PUJSI",
  "PUJSO",
  "PUJSU",
  "PUKCA",
  "PUKCE",
  "PUKCI",
  "PUKCU",
  "PUKEC",
  "PUKEL",
  "PUKLA",
  "PUKLE",
  "PUKLI",
  "PUKLU",
  "PULIL",
  "PULIM",
  "PULIŠ",
  "PULIT",
  "PULPA",
  "PULPE",
  "PULPI",
  "PULPO",
  "PULTA",
  "PULTE",
  "PULTI",
  "PULTU",
  "PULZA",
  "PULZE",
  "PULZI",
  "PULZU",
  "PUMAH",
  "PUMAM",
  "PUMPA",
  "PUMPE",
  "PUMPI",
  "PUMPO",
  "PUNCA",
  "PUNCE",
  "PUNCI",
  "PUNCO",
  "PUNČA",
  "PUNČE",
  "PUNČI",
  "PUNČK",
  "PUNČU",
  "PUNKT",
  "PUNTA",
  "PUNTE",
  "PUNTI",
  "PUNTU",
  "PUPAH",
  "PUPAJ",
  "PUPAL",
  "PUPAM",
  "PUPAŠ",
  "PUPCA",
  "PUPEK",
  "PUPIC",
  "PUPIL",
  "PUPKA",
  "PUPKE",
  "PUPKI",
  "PUPKU",
  "PURAH",
  "PURAM",
  "PURAN",
  "PURIC",
  "PURIN",
  "PURJA",
  "PURJE",
  "PURJI",
  "PURJO",
  "PURŠA",
  "PURŠE",
  "PURŠI",
  "PURŠU",
  "PUSTA",
  "PUSTE",
  "PUSTI",
  "PUSTO",
  "PUSTU",
  "PUŠAH",
  "PUŠAM",
  "PUŠČA",
  "PUŠČE",
  "PUŠČI",
  "PUŠČO",
  "PUŠIC",
  "PUŠIL",
  "PUŠIM",
  "PUŠIŠ",
  "PUŠIT",
  "PUŠKA",
  "PUŠKE",
  "PUŠKI",
  "PUŠKO",
  "PUTAH",
  "PUTAM",
  "PUTER",
  "PUTIH",
  "PUTKA",
  "PUTKE",
  "PUTKI",
  "PUTKO",
  "PUTOM",
  "PUTOV",
  "PUTRA",
  "PUTRE",
  "PUTRI",
  "PUTRU",
  "RABAH",
  "RABAM",
  "RABAT",
  "RABEČ",
  "RABIL",
  "RABIM",
  "RABIN",
  "RABIŠ",
  "RABIT",
  "RABOT",
  "RABUK",
  "RACAH",
  "RACAJ",
  "RACAK",
  "RACAL",
  "RACAM",
  "RACAŠ",
  "RACAT",
  "RACAV",
  "RACEM",
  "RACIJ",
  "RAČAK",
  "RAČEK",
  "RAČJA",
  "RAČJE",
  "RAČJI",
  "RAČJO",
  "RAČKA",
  "RAČKE",
  "RAČKI",
  "RAČKO",
  "RAČKU",
  "RAČUN",
  "RADAR",
  "RADIA",
  "RADIČ",
  "RADIE",
  "RADII",
  "RADIJ",
  "RADIO",
  "RADIU",
  "RADON",
  "RADUJ",
  "RADŽA",
  "RADŽE",
  "RADŽI",
  "RADŽO",
  "RAFAL",
  "RAFIJ",
  "RAFTA",
  "RAFTE",
  "RAFTI",
  "RAFTU",
  "RAGAD",
  "RAGBI",
  "RAHEL",
  "RAHLA",
  "RAHLE",
  "RAHLI",
  "RAHLO",
  "RAJAJ",
  "RAJAL",
  "RAJAM",
  "RAJAŠ",
  "RAJAT",
  "RAJCA",
  "RAJCE",
  "RAJCI",
  "RAJCU",
  "RAJDA",
  "RAJDE",
  "RAJDI",
  "RAJDO",
  "RAJEM",
  "RAJEV",
  "RAJHA",
  "RAJHU",
  "RAJIH",
  "RAJNA",
  "RAJNE",
  "RAJNI",
  "RAJNO",
  "RAJON",
  "RAJŠI",
  "RAJTA",
  "RAJŽA",
  "RAJŽE",
  "RAJŽI",
  "RAJŽO",
  "RAKAR",
  "RAKAV",
  "RAKCA",
  "RAKCE",
  "RAKCI",
  "RAKCU",
  "RAKEC",
  "RAKEL",
  "RAKET",
  "RAKEV",
  "RAKIC",
  "RAKIH",
  "RAKIT",
  "RAKLA",
  "RAKLE",
  "RAKLI",
  "RAKLU",
  "RAKOM",
  "RAKOV",
  "RAKUN",
  "RAKVE",
  "RAKVI",
  "RALIH",
  "RALOM",
  "RALOV",
  "RAMAH",
  "RAMAM",
  "RAMBA",
  "RAMBE",
  "RAMBI",
  "RAMBO",
  "RAMBU",
  "RAMEN",
  "RAMIJ",
  "RAMPA",
  "RAMPE",
  "RAMPI",
  "RAMPO",
  "RANAH",
  "RANAM",
  "RANAR",
  "RANČA",
  "RANČE",
  "RANČI",
  "RANČU",
  "RANDA",
  "RANDE",
  "RANDI",
  "RANDU",
  "RANEM",
  "RANGA",
  "RANGE",
  "RANGI",
  "RANGU",
  "RANIC",
  "RANIH",
  "RANIL",
  "RANIM",
  "RANIN",
  "RANIŠ",
  "RANIT",
  "RANUL",
  "RAPER",
  "RAPIR",
  "RAPOM",
  "RAROG",
  "RASAH",
  "RASAM",
  "RASEL",
  "RASEM",
  "RASEN",
  "RASEŠ",
  "RASKA",
  "RASLA",
  "RASLE",
  "RASLI",
  "RASLO",
  "RASNA",
  "RASNE",
  "RASNI",
  "RASNO",
  "RASTE",
  "RASTI",
  "RAŠAH",
  "RAŠAM",
  "RAŠČA",
  "RAŠČE",
  "RAŠČI",
  "RAŠČO",
  "RAŠKA",
  "RAŠKE",
  "RAŠKI",
  "RAŠKO",
  "RAŠPA",
  "RAŠPE",
  "RAŠPI",
  "RAŠPO",
  "RATAJ",
  "RATAL",
  "RATAM",
  "RATAN",
  "RATAR",
  "RATAŠ",
  "RAVAN",
  "RAVBA",
  "RAVEN",
  "RAVNA",
  "RAVNE",
  "RAVNI",
  "RAVNO",
  "RAVSA",
  "RAVSE",
  "RAVSI",
  "RAVSU",
  "RAZAH",
  "RAZAL",
  "RAZAM",
  "RAZAN",
  "RAZDA",
  "RAZDE",
  "RAZEH",
  "RAZEM",
  "RAZEN",
  "RAZIC",
  "RAZID",
  "RAZIH",
  "RAZIL",
  "RAZIM",
  "RAZIŠ",
  "RAZIT",
  "RAZJE",
  "RAZJO",
  "RAZMI",
  "RAZNA",
  "RAZNE",
  "RAZNI",
  "RAZNO",
  "RAZOM",
  "RAZOR",
  "RAZOV",
  "RAZUM",
  "RAZVE",
  "RAŽAH",
  "RAŽAM",
  "RDEČA",
  "RDEČE",
  "RDEČI",
  "RDEČK",
  "RDEČO",
  "RDELA",
  "RDELE",
  "RDELI",
  "RDELO",
  "RDENJ",
  "RDETI",
  "RDIJO",
  "RDIMO",
  "RDITA",
  "RDITE",
  "RDIVA",
  "REALA",
  "REALE",
  "REALI",
  "REALK",
  "REALU",
  "REBEK",
  "REBER",
  "REBRA",
  "REBRC",
  "REBRI",
  "REBRN",
  "REBRO",
  "REBRU",
  "REBUL",
  "REBUS",
  "REČEH",
  "REČEM",
  "REČEN",
  "REČEŠ",
  "REČIC",
  "REČIJ",
  "REČJA",
  "REČJE",
  "REČJI",
  "REČJO",
  "REČJU",
  "REČMI",
  "REČNA",
  "REČNE",
  "REČNI",
  "REČNO",
  "REDAR",
  "REDČI",
  "REDEČ",
  "REDEK",
  "REDEN",
  "REDIH",
  "REDIL",
  "REDIM",
  "REDIŠ",
  "REDIT",
  "REDKA",
  "REDKE",
  "REDKI",
  "REDKO",
  "REDNA",
  "REDNE",
  "REDNI",
  "REDNO",
  "REDOM",
  "REDOV",
  "REDUJ",
  "REDUT",
  "REGAH",
  "REGAJ",
  "REGAL",
  "REGAM",
  "REGAŠ",
  "REGAT",
  "REGIJ",
  "REIKI",
  "REISA",
  "REISE",
  "REISI",
  "REIST",
  "REISU",
  "REJAH",
  "REJAM",
  "REJCA",
  "REJCE",
  "REJCI",
  "REJCU",
  "REJEC",
  "REJEN",
  "REJKA",
  "REJKE",
  "REJKI",
  "REJKO",
  "REJNA",
  "REJNE",
  "REJNI",
  "REJNO",
  "REJON",
  "REJVA",
  "REJVE",
  "REJVI",
  "REJVU",
  "REKAH",
  "REKAJ",
  "REKAL",
  "REKAM",
  "REKAŠ",
  "REKAT",
  "REKEL",
  "REKET",
  "REKIH",
  "REKLA",
  "REKLE",
  "REKLI",
  "REKLO",
  "REKLU",
  "REKOČ",
  "REKOM",
  "REKOV",
  "RELIK",
  "REMEK",
  "REMIZ",
  "RENAH",
  "RENAM",
  "RENČE",
  "RENČI",
  "RENIJ",
  "RENIN",
  "RENTA",
  "RENTE",
  "RENTI",
  "RENTO",
  "REPAH",
  "REPAK",
  "REPAM",
  "REPAT",
  "REPEK",
  "REPEN",
  "REPER",
  "REPIC",
  "REPIH",
  "REPIK",
  "REPKA",
  "REPKE",
  "REPKI",
  "REPKU",
  "REPNA",
  "REPNE",
  "REPNI",
  "REPNO",
  "REPOM",
  "REPOV",
  "REPUH",
  "REPUŠ",
  "RESAH",
  "RESAM",
  "RESAR",
  "RESAV",
  "RESDA",
  "RESED",
  "RESEN",
  "RESIC",
  "RESIH",
  "RESIN",
  "RESJA",
  "RESJE",
  "RESJU",
  "RESKA",
  "RESKE",
  "RESKI",
  "RESKU",
  "RESNA",
  "RESNE",
  "RESNI",
  "RESNO",
  "RESOM",
  "RESOR",
  "RESOV",
  "RESUZ",
  "REŠEN",
  "REŠET",
  "REŠIL",
  "REŠIM",
  "REŠIŠ",
  "REŠIT",
  "REŠKA",
  "REŠKE",
  "REŠKI",
  "REŠKO",
  "REŠUJ",
  "RETAH",
  "RETAM",
  "RETIN",
  "RETOR",
  "RETRO",
  "RETUŠ",
  "REVAH",
  "REVAM",
  "REVEN",
  "REVEŽ",
  "REVIC",
  "REVIJ",
  "REVIR",
  "REVMA",
  "REVME",
  "REVMI",
  "REVMO",
  "REVNA",
  "REVNE",
  "REVNI",
  "REVNO",
  "REVSK",
  "REVŠE",
  "REZAČ",
  "REZAJ",
  "REZAL",
  "REZAN",
  "REZAT",
  "REZBA",
  "REZCA",
  "REZCE",
  "REZCI",
  "REZCU",
  "REZEC",
  "REZED",
  "REZEH",
  "REZEK",
  "REZEM",
  "REZEN",
  "REZEV",
  "REZGA",
  "REZGE",
  "REZGI",
  "REZGU",
  "REZIH",
  "REZIK",
  "REZIL",
  "REZIN",
  "REZJO",
  "REZKA",
  "REZKE",
  "REZKI",
  "REZKO",
  "REZMI",
  "REZNA",
  "REZNE",
  "REZNI",
  "REZNO",
  "REZOM",
  "REZOV",
  "REZUS",
  "REZVA",
  "REZVE",
  "REZVI",
  "REZVO",
  "REŽAH",
  "REŽAJ",
  "REŽAL",
  "REŽAM",
  "REŽAT",
  "REŽAV",
  "REŽEČ",
  "REŽEM",
  "REŽEN",
  "REŽEŠ",
  "REŽIC",
  "REŽIJ",
  "REŽIM",
  "REŽIŠ",
  "REŽNA",
  "REŽNE",
  "REŽNI",
  "REŽNO",
  "RIALA",
  "RIALE",
  "RIALI",
  "RIALU",
  "RIBAH",
  "RIBAJ",
  "RIBAL",
  "RIBAM",
  "RIBAN",
  "RIBAŠ",
  "RIBAT",
  "RIBEZ",
  "RIBIC",
  "RIBIČ",
  "RIBJA",
  "RIBJE",
  "RIBJI",
  "RIBJO",
  "RIBON",
  "RIBOZ",
  "RICIN",
  "RIČEK",
  "RIČEM",
  "RIČEŠ",
  "RIČET",
  "RIČKA",
  "RIČKE",
  "RIČKI",
  "RIČKU",
  "RIČOT",
  "RIDAH",
  "RIDAM",
  "RIGAČ",
  "RIGAJ",
  "RIGAL",
  "RIGAM",
  "RIGAŠ",
  "RIGAT",
  "RIGCA",
  "RIGCE",
  "RIGCI",
  "RIGCU",
  "RIGEC",
  "RIGIH",
  "RIGNE",
  "RIGNI",
  "RIGOM",
  "RIGOV",
  "RIHTA",
  "RIHTE",
  "RIHTI",
  "RIHTO",
  "RIJEM",
  "RIJEŠ",
  "RIJMO",
  "RIJOČ",
  "RIJTA",
  "RIJTE",
  "RIJVA",
  "RIKAJ",
  "RIKAL",
  "RIKAM",
  "RIKAŠ",
  "RIKAT",
  "RIKOT",
  "RIKŠA",
  "RIKŠE",
  "RIKŠI",
  "RIKŠO",
  "RILCA",
  "RILCE",
  "RILCI",
  "RILCU",
  "RILEC",
  "RIMAČ",
  "RIMAH",
  "RIMAJ",
  "RIMAL",
  "RIMAM",
  "RIMAN",
  "RIMAR",
  "RIMAŠ",
  "RIMAT",
  "RIMES",
  "RINEČ",
  "RINEM",
  "RINEŠ",
  "RINEŽ",
  "RINGA",
  "RINGE",
  "RINGI",
  "RINGU",
  "RINIL",
  "RINIT",
  "RINKA",
  "RINKE",
  "RINKI",
  "RINKO",
  "RINŽA",
  "RINŽE",
  "RINŽI",
  "RINŽO",
  "RIPEČ",
  "RISAH",
  "RISAL",
  "RISAM",
  "RISAN",
  "RISAR",
  "RISAT",
  "RISBA",
  "RISBE",
  "RISBI",
  "RISBO",
  "RISIH",
  "RISJA",
  "RISJE",
  "RISJI",
  "RISJO",
  "RISOM",
  "RISOV",
  "RIŠEM",
  "RIŠEŠ",
  "RIŠKA",
  "RIŠKE",
  "RIŠKI",
  "RIŠKO",
  "RITAJ",
  "RITAL",
  "RITAM",
  "RITAŠ",
  "RITAT",
  "RITEM",
  "RITEN",
  "RITIH",
  "RITIJ",
  "RITIM",
  "RITJA",
  "RITJE",
  "RITJI",
  "RITJO",
  "RITJU",
  "RITKA",
  "RITKE",
  "RITKI",
  "RITKO",
  "RITMA",
  "RITME",
  "RITMI",
  "RITMU",
  "RITNA",
  "RITNE",
  "RITNI",
  "RITNO",
  "RITUS",
  "RIVAH",
  "RIVAL",
  "RIVAM",
  "RIZEL",
  "RIZEM",
  "RIZIK",
  "RIZLA",
  "RIZLE",
  "RIZLI",
  "RIZLO",
  "RIZMA",
  "RIZME",
  "RIZMI",
  "RIZMO",
  "RIZOM",
  "RIŽAH",
  "RIŽAM",
  "RIŽEK",
  "RIŽEM",
  "RIŽEN",
  "RIŽKA",
  "RIŽKE",
  "RIŽKI",
  "RIŽKU",
  "RIŽNA",
  "RIŽNE",
  "RIŽNI",
  "RIŽNO",
  "RIŽOT",
  "RJAMA",
  "RJAMI",
  "RJAST",
  "RJAVA",
  "RJAVE",
  "RJAVI",
  "RJAVK",
  "RJAVO",
  "RJOVE",
  "RJOVI",
  "RJUHA",
  "RJUHE",
  "RJUHI",
  "RJUHO",
  "RJUJE",
  "RJULA",
  "RJULE",
  "RJULI",
  "RJULO",
  "RJUTA",
  "RJUTE",
  "RJUTI",
  "RJUTO",
  "RMANA",
  "RMANU",
  "ROBAČ",
  "ROBAT",
  "ROBCA",
  "ROBCE",
  "ROBCI",
  "ROBCU",
  "ROBEC",
  "ROBEČ",
  "ROBEK",
  "ROBEN",
  "ROBID",
  "ROBIH",
  "ROBIL",
  "ROBIM",
  "ROBIŠ",
  "ROBIT",
  "ROBKA",
  "ROBKE",
  "ROBKI",
  "ROBKU",
  "ROBNA",
  "ROBNE",
  "ROBNI",
  "ROBNO",
  "ROBOM",
  "ROBOT",
  "ROBOV",
  "ROCKA",
  "ROCKU",
  "ROČAJ",
  "ROČEK",
  "ROČEM",
  "ROČEN",
  "ROČEV",
  "ROČIC",
  "ROČIH",
  "ROČKA",
  "ROČKE",
  "ROČKI",
  "ROČKO",
  "ROČKU",
  "ROČNA",
  "ROČNE",
  "ROČNI",
  "ROČNO",
  "RODEA",
  "RODEČ",
  "RODEE",
  "RODEI",
  "RODEN",
  "RODEO",
  "RODEU",
  "RODIJ",
  "RODIL",
  "RODIM",
  "RODIŠ",
  "RODIT",
  "RODNA",
  "RODNE",
  "RODNI",
  "RODNO",
  "RODOM",
  "RODOV",
  "ROGAČ",
  "ROGAJ",
  "ROGAL",
  "ROGAM",
  "ROGAR",
  "ROGAŠ",
  "ROGAT",
  "ROGEH",
  "ROGLA",
  "ROGLE",
  "ROGLI",
  "ROGLO",
  "ROGMI",
  "ROGOM",
  "ROGOV",
  "ROGOZ",
  "ROHNI",
  "ROJAH",
  "ROJAK",
  "ROJAM",
  "ROJEČ",
  "ROJEM",
  "ROJEN",
  "ROJEV",
  "ROJIH",
  "ROJIL",
  "ROJIM",
  "ROJIŠ",
  "ROJIT",
  "ROJIV",
  "ROKAD",
  "ROKAH",
  "ROKAM",
  "ROKAV",
  "ROKER",
  "ROKIC",
  "ROKIH",
  "ROKOM",
  "ROKOV",
  "ROKUJ",
  "ROLAH",
  "ROLAJ",
  "ROLAL",
  "ROLAM",
  "ROLAR",
  "ROLAŠ",
  "ROLER",
  "ROLET",
  "ROLIC",
  "ROLKA",
  "ROLKE",
  "ROLKI",
  "ROLKO",
  "ROMAJ",
  "ROMAL",
  "ROMAM",
  "ROMAN",
  "ROMAR",
  "ROMAŠ",
  "ROMAT",
  "ROMBA",
  "ROMBE",
  "ROMBI",
  "ROMBU",
  "ROMIH",
  "ROMOM",
  "ROMOV",
  "RONDO",
  "RONEK",
  "RONKA",
  "RONKU",
  "ROPAJ",
  "ROPAL",
  "ROPAM",
  "ROPAR",
  "ROPAŠ",
  "ROPAT",
  "ROPIH",
  "ROPOM",
  "ROPOT",
  "ROPOV",
  "RORIH",
  "ROROM",
  "ROROV",
  "ROSAH",
  "ROSAM",
  "ROSAN",
  "ROSEČ",
  "ROSEN",
  "ROSIC",
  "ROSIK",
  "ROSIL",
  "ROSIM",
  "ROSIŠ",
  "ROSIT",
  "ROSNA",
  "ROSNE",
  "ROSNI",
  "ROSNO",
  "ROŠAD",
  "ROŠKA",
  "ROŠKE",
  "ROŠKI",
  "ROŠKO",
  "ROTEČ",
  "ROTIL",
  "ROTIM",
  "ROTIŠ",
  "ROTIT",
  "ROTOR",
  "ROVAČ",
  "ROVAR",
  "ROVAŠ",
  "ROVEN",
  "ROVER",
  "ROVIH",
  "ROVKA",
  "ROVKE",
  "ROVKI",
  "ROVKO",
  "ROVNA",
  "ROVNE",
  "ROVNI",
  "ROVNO",
  "ROVOM",
  "ROVOV",
  "ROVTA",
  "ROVTE",
  "ROVTI",
  "ROVTU",
  "ROZET",
  "ROZGA",
  "ROZGE",
  "ROZGI",
  "ROZGO",
  "ROZIN",
  "ROŽAH",
  "ROŽAM",
  "ROŽEN",
  "ROŽIC",
  "ROŽIČ",
  "ROŽJA",
  "ROŽJE",
  "ROŽJU",
  "ROŽKA",
  "ROŽKE",
  "ROŽKI",
  "ROŽKO",
  "ROŽNA",
  "ROŽNE",
  "ROŽNI",
  "ROŽNO",
  "RTAST",
  "RTIČA",
  "RTIČE",
  "RTIČI",
  "RTIČU",
  "RTOMA",
  "RUBEČ",
  "RUBEŽ",
  "RUBIL",
  "RUBIM",
  "RUBIN",
  "RUBIŠ",
  "RUBIT",
  "RUDAH",
  "RUDAM",
  "RUDAR",
  "RUDEN",
  "RUDNA",
  "RUDNE",
  "RUDNI",
  "RUDNO",
  "RUJEM",
  "RUJEN",
  "RUJEŠ",
  "RUJEV",
  "RUJIH",
  "RUJMO",
  "RUJNA",
  "RUJNE",
  "RUJNI",
  "RUJNO",
  "RUJTA",
  "RUJTE",
  "RUJVA",
  "RUKAJ",
  "RUKAL",
  "RUKAM",
  "RUKAN",
  "RUKAŠ",
  "RUKAT",
  "RUKER",
  "RUKIH",
  "RUKNE",
  "RUKNI",
  "RUKOM",
  "RUKOV",
  "RULAD",
  "RULET",
  "RUMBA",
  "RUMBE",
  "RUMBI",
  "RUMBO",
  "RUMEN",
  "RUMIH",
  "RUMOM",
  "RUMOV",
  "RUNAH",
  "RUNAM",
  "RUNDA",
  "RUNDE",
  "RUNDI",
  "RUNDO",
  "RUNIH",
  "RUNJE",
  "RUNOM",
  "RUPIJ",
  "RUSEČ",
  "RUSEM",
  "RUSEN",
  "RUSIH",
  "RUSIL",
  "RUSIM",
  "RUSIŠ",
  "RUSIT",
  "RUSKA",
  "RUSKE",
  "RUSKI",
  "RUSKO",
  "RUŠAH",
  "RUŠAM",
  "RUŠEČ",
  "RUŠEN",
  "RUŠIL",
  "RUŠIM",
  "RUŠIŠ",
  "RUŠIT",
  "RUŠJA",
  "RUŠJE",
  "RUŠJU",
  "RUŠKA",
  "RUŠKE",
  "RUŠKI",
  "RUŠKO",
  "RUŠNA",
  "RUŠNE",
  "RUŠNI",
  "RUŠNO",
  "RUTAH",
  "RUTAM",
  "RUTEN",
  "RUTIC",
  "RUTIN",
  "RUTKA",
  "RUTKE",
  "RUTKI",
  "RUTKO",
  "RUTNA",
  "RUTNE",
  "RUTNI",
  "RUTNO",
  "RUVAČ",
  "RUVAL",
  "RUVAM",
  "RUVAŠ",
  "RUVAT",
  "RUZAK",
  "RVAČA",
  "RVAČE",
  "RVAČI",
  "RVAČK",
  "RVAČU",
  "RVALA",
  "RVALE",
  "RVALI",
  "RVALO",
  "RVATI",
  "RŽENA",
  "RŽENE",
  "RŽENI",
  "RŽENO",
  "RŽIŠČ",
  "RŽULJ",
  "SAABA",
  "SAABE",
  "SAABI",
  "SAABU",
  "SABAT",
  "SABIR",
  "SABOR",
  "SADEČ",
  "SADEN",
  "SADEŽ",
  "SADIK",
  "SADIL",
  "SADIM",
  "SADIŠ",
  "SADIT",
  "SADJA",
  "SADJE",
  "SADJU",
  "SADNA",
  "SADNE",
  "SADNI",
  "SADNO",
  "SADOM",
  "SADOV",
  "SADRA",
  "SADRE",
  "SADRI",
  "SADRO",
  "SADŽA",
  "SADŽE",
  "SADŽI",
  "SADŽO",
  "SAFIR",
  "SAGAH",
  "SAGAM",
  "SAGOM",
  "SAHEL",
  "SAHIB",
  "SAJAH",
  "SAJAM",
  "SAJAV",
  "SAJEČ",
  "SAJEM",
  "SAJEN",
  "SAJEV",
  "SAJGA",
  "SAJGE",
  "SAJGI",
  "SAJGO",
  "SAJIH",
  "SAJIL",
  "SAJIM",
  "SAJIŠ",
  "SAJIT",
  "SAKAR",
  "SAKIH",
  "SAKOM",
  "SAKOV",
  "SAKSA",
  "SAKSE",
  "SAKSI",
  "SAKSU",
  "SALAH",
  "SALAK",
  "SALAM",
  "SALAŠ",
  "SALDA",
  "SALDE",
  "SALDI",
  "SALDO",
  "SALDU",
  "SALEN",
  "SALNA",
  "SALNE",
  "SALNI",
  "SALNO",
  "SALOM",
  "SALON",
  "SALPA",
  "SALPE",
  "SALPI",
  "SALPO",
  "SALSA",
  "SALSE",
  "SALSI",
  "SALSO",
  "SALTA",
  "SALTE",
  "SALTI",
  "SALTO",
  "SALTU",
  "SALUN",
  "SALVA",
  "SALVE",
  "SALVI",
  "SALVO",
  "SAMBA",
  "SAMBE",
  "SAMBI",
  "SAMBO",
  "SAMCA",
  "SAMCE",
  "SAMCI",
  "SAMCU",
  "SAMEC",
  "SAMEM",
  "SAMIC",
  "SAMIH",
  "SAMIM",
  "SAMIT",
  "SAMOA",
  "SAMOE",
  "SAMOI",
  "SAMOL",
  "SAMOO",
  "SAMOS",
  "SAMOT",
  "SAMUJ",
  "SAMUM",
  "SANEH",
  "SANEM",
  "SANIC",
  "SANJA",
  "SANJE",
  "SANJI",
  "SANJO",
  "SANKA",
  "SANKE",
  "SANMI",
  "SAPAH",
  "SAPAM",
  "SAPER",
  "SAPIC",
  "SARIN",
  "SARMA",
  "SARME",
  "SARMI",
  "SARMO",
  "SARSA",
  "SARSU",
  "SARUS",
  "SAŠKA",
  "SAŠKE",
  "SAŠKI",
  "SAŠKO",
  "SATAN",
  "SATEN",
  "SATIH",
  "SATIR",
  "SATJA",
  "SATJE",
  "SATJU",
  "SATNA",
  "SATNE",
  "SATNI",
  "SATNO",
  "SATOM",
  "SATOV",
  "SAVAN",
  "SAVET",
  "SAVNA",
  "SAVNE",
  "SAVNI",
  "SAVNO",
  "SCAGA",
  "SCALU",
  "SCANJ",
  "SCATI",
  "SCEDI",
  "SCEJA",
  "SCELA",
  "SCELI",
  "SCENA",
  "SCENE",
  "SCENI",
  "SCENO",
  "SCUFA",
  "SCUZA",
  "SCVRE",
  "SCVRI",
  "SCVRL",
  "SCVRT",
  "SČARA",
  "SČRNI",
  "SEANS",
  "SEBEK",
  "SEBKA",
  "SEBKE",
  "SEBKI",
  "SEBKU",
  "SEBOJ",
  "SEBUM",
  "SEČEM",
  "SEČEN",
  "SEČIL",
  "SEČNA",
  "SEČNE",
  "SEČNI",
  "SEČNO",
  "SEDAJ",
  "SEDAL",
  "SEDAM",
  "SEDAŠ",
  "SEDAT",
  "SEDEČ",
  "SEDEL",
  "SEDEM",
  "SEDEN",
  "SEDEŠ",
  "SEDET",
  "SEDEŽ",
  "SEDIH",
  "SEDIJ",
  "SEDIM",
  "SEDIŠ",
  "SEDJA",
  "SEDJE",
  "SEDJI",
  "SEDJU",
  "SEDLA",
  "SEDLE",
  "SEDLI",
  "SEDLO",
  "SEDLU",
  "SEDMA",
  "SEDME",
  "SEDMI",
  "SEDMO",
  "SEDNA",
  "SEDNE",
  "SEDNI",
  "SEDNO",
  "SEDOM",
  "SEDOV",
  "SEFIH",
  "SEFOM",
  "SEFOV",
  "SEGAJ",
  "SEGAL",
  "SEGAM",
  "SEGAŠ",
  "SEGAT",
  "SEGEL",
  "SEGLA",
  "SEGLE",
  "SEGLI",
  "SEGLO",
  "SEJAČ",
  "SEJAH",
  "SEJAL",
  "SEJAM",
  "SEJAN",
  "SEJAT",
  "SEJEM",
  "SEJEN",
  "SEJEŠ",
  "SEJMA",
  "SEJME",
  "SEJMI",
  "SEJMO",
  "SEJMU",
  "SEJNA",
  "SEJNE",
  "SEJNI",
  "SEJNO",
  "SEJTA",
  "SEJTE",
  "SEJVA",
  "SEKAČ",
  "SEKAJ",
  "SEKAL",
  "SEKAM",
  "SEKAN",
  "SEKAŠ",
  "SEKAT",
  "SEKIR",
  "SEKSA",
  "SEKSE",
  "SEKSI",
  "SEKST",
  "SEKSU",
  "SEKTA",
  "SEKTE",
  "SEKTI",
  "SEKTO",
  "SELCA",
  "SELCE",
  "SELCI",
  "SELCU",
  "SELEC",
  "SELEČ",
  "SELEN",
  "SELFI",
  "SELIL",
  "SELIM",
  "SELIŠ",
  "SELIT",
  "SELKA",
  "SELKE",
  "SELKI",
  "SELKO",
  "SEMEN",
  "SEMIŠ",
  "SEMLE",
  "SENAH",
  "SENAM",
  "SENAR",
  "SENAT",
  "SENCA",
  "SENCE",
  "SENCI",
  "SENCO",
  "SENCU",
  "SENČI",
  "SENČK",
  "SENEN",
  "SENIK",
  "SENOM",
  "SEPIJ",
  "SEPOJ",
  "SEPSA",
  "SEPSE",
  "SEPSI",
  "SEPSO",
  "SERAF",
  "SERAJ",
  "SERAK",
  "SERAP",
  "SERCA",
  "SERCE",
  "SERCI",
  "SERCU",
  "SEREC",
  "SERIF",
  "SERIH",
  "SERIJ",
  "SERIN",
  "SERJE",
  "SERJI",
  "SERKA",
  "SERKE",
  "SERKI",
  "SERKO",
  "SEROČ",
  "SEROM",
  "SEROV",
  "SEROZ",
  "SERUM",
  "SERVA",
  "SERVE",
  "SERVI",
  "SERVO",
  "SERVU",
  "SERŽA",
  "SERŽU",
  "SESAČ",
  "SESAJ",
  "SESAL",
  "SESAM",
  "SESAN",
  "SESAŠ",
  "SESAT",
  "SESCA",
  "SESCE",
  "SESCI",
  "SESCU",
  "SESEC",
  "SESEK",
  "SESEN",
  "SESIP",
  "SESKA",
  "SESKE",
  "SESKI",
  "SESKU",
  "SESNA",
  "SESNE",
  "SESNI",
  "SESNO",
  "SESTI",
  "SESUJ",
  "SESUL",
  "SESUT",
  "SEŠEL",
  "SEŠIJ",
  "SEŠIL",
  "SEŠIT",
  "SEŠLA",
  "SEŠLE",
  "SEŠLI",
  "SEŠLO",
  "SETER",
  "SETEV",
  "SETIH",
  "SETOM",
  "SETOV",
  "SETRA",
  "SETRE",
  "SETRI",
  "SETRU",
  "SETVE",
  "SETVI",
  "SEVAJ",
  "SEVAL",
  "SEVAM",
  "SEVAŠ",
  "SEVAT",
  "SEVER",
  "SEVIH",
  "SEVKA",
  "SEVKE",
  "SEVKI",
  "SEVKO",
  "SEVOM",
  "SEVOV",
  "SEZAJ",
  "SEZAL",
  "SEZAM",
  "SEZAŠ",
  "SEZAT",
  "SEZON",
  "SEZUJ",
  "SEZUL",
  "SEZUT",
  "SEŽAJ",
  "SEŽEL",
  "SEŽEM",
  "SEŽEŠ",
  "SEŽET",
  "SEŽGE",
  "SEŽGI",
  "SEŽIG",
  "SEŽME",
  "SEŽMI",
  "SFALI",
  "SFENA",
  "SFERA",
  "SFERE",
  "SFERI",
  "SFERO",
  "SFING",
  "SFIŽI",
  "SFRČI",
  "SFRLI",
  "SFUKA",
  "SHAJA",
  "SHEKA",
  "SHEMA",
  "SHEME",
  "SHEMI",
  "SHEMO",
  "SHIFT",
  "SHIRA",
  "SHITI",
  "SHODA",
  "SHODE",
  "SHODI",
  "SHODU",
  "SHOPA",
  "SHOPE",
  "SHOPI",
  "SHOPU",
  "SHUDA",
  "SHUDI",
  "SIALA",
  "SIALU",
  "SIAMA",
  "SIAMK",
  "SIAMU",
  "SICER",
  "SIČAL",
  "SIČAT",
  "SIČEČ",
  "SIČEM",
  "SIČEŠ",
  "SIČIM",
  "SIČIŠ",
  "SIDER",
  "SIDOL",
  "SIDRA",
  "SIDRC",
  "SIDRI",
  "SIDRN",
  "SIDRO",
  "SIDRU",
  "SIEST",
  "SIFAK",
  "SIFLA",
  "SIFLE",
  "SIFLI",
  "SIFLO",
  "SIFON",
  "SIGAJ",
  "SIGAL",
  "SIGAM",
  "SIGAŠ",
  "SIGAT",
  "SIGAV",
  "SIGEL",
  "SIGEM",
  "SIGLA",
  "SIGLE",
  "SIGLI",
  "SIGLO",
  "SIGMA",
  "SIGME",
  "SIGMI",
  "SIGMO",
  "SIJAJ",
  "SIJAL",
  "SIJAT",
  "SIJEM",
  "SIJEŠ",
  "SIJEV",
  "SIJIH",
  "SIJMO",
  "SIJOČ",
  "SIJTA",
  "SIJTE",
  "SIJVA",
  "SIKAH",
  "SIKAJ",
  "SIKAL",
  "SIKAM",
  "SIKAŠ",
  "SIKAT",
  "SIKAV",
  "SIKHA",
  "SIKHE",
  "SIKHI",
  "SIKHU",
  "SIKIH",
  "SIKNE",
  "SIKNI",
  "SIKOM",
  "SIKON",
  "SIKOV",
  "SILAH",
  "SILAK",
  "SILAM",
  "SILAŽ",
  "SILEN",
  "SILFA",
  "SILFE",
  "SILFI",
  "SILFO",
  "SILFU",
  "SILIL",
  "SILIM",
  "SILIN",
  "SILIŠ",
  "SILIT",
  "SILJA",
  "SILJE",
  "SILJI",
  "SILJU",
  "SILNA",
  "SILNE",
  "SILNI",
  "SILNO",
  "SILOS",
  "SILUR",
  "SIMAH",
  "SIMAM",
  "SIMPL",
  "SINAJ",
  "SINEK",
  "SINEM",
  "SINEŠ",
  "SINIC",
  "SINIH",
  "SINIL",
  "SINIT",
  "SINJA",
  "SINJE",
  "SINJI",
  "SINJO",
  "SINKA",
  "SINKE",
  "SINKI",
  "SINKO",
  "SINKU",
  "SINOD",
  "SINOM",
  "SINOV",
  "SINUS",
  "SIONA",
  "SIONU",
  "SIPAČ",
  "SIPAH",
  "SIPAJ",
  "SIPAL",
  "SIPAM",
  "SIPAŠ",
  "SIPAT",
  "SIPEK",
  "SIPIC",
  "SIPIN",
  "SIPJA",
  "SIPJE",
  "SIPJI",
  "SIPJO",
  "SIPKA",
  "SIPKE",
  "SIPKI",
  "SIPKO",
  "SIPOM",
  "SIRAR",
  "SIREČ",
  "SIREK",
  "SIREN",
  "SIRIH",
  "SIRIJ",
  "SIRIL",
  "SIRIM",
  "SIRIŠ",
  "SIRIT",
  "SIRKA",
  "SIRKE",
  "SIRKI",
  "SIRKU",
  "SIRNA",
  "SIRNE",
  "SIRNI",
  "SIRNO",
  "SIROM",
  "SIROT",
  "SIROV",
  "SIRUP",
  "SISAL",
  "SIŠKA",
  "SIŠKE",
  "SIŠKI",
  "SIŠKO",
  "SITAH",
  "SITAM",
  "SITCA",
  "SITCE",
  "SITCI",
  "SITCU",
  "SITEC",
  "SITEM",
  "SITEN",
  "SITIH",
  "SITIM",
  "SITJA",
  "SITJE",
  "SITJU",
  "SITKA",
  "SITKE",
  "SITKI",
  "SITKO",
  "SITNA",
  "SITNE",
  "SITNI",
  "SITNO",
  "SITOM",
  "SITUL",
  "SIVCA",
  "SIVCE",
  "SIVCI",
  "SIVCU",
  "SIVEC",
  "SIVEČ",
  "SIVEL",
  "SIVEM",
  "SIVET",
  "SIVIC",
  "SIVIH",
  "SIVIL",
  "SIVIM",
  "SIVIN",
  "SIVIŠ",
  "SIVIT",
  "SIVKA",
  "SIVKE",
  "SIVKI",
  "SIVKO",
  "SKAČE",
  "SKAČI",
  "SKADI",
  "SKAJA",
  "SKAJU",
  "SKALA",
  "SKALD",
  "SKALE",
  "SKALI",
  "SKALO",
  "SKALP",
  "SKANK",
  "SKATA",
  "SKATE",
  "SKATI",
  "SKATU",
  "SKAVT",
  "SKAZA",
  "SKAZE",
  "SKAZI",
  "SKAZO",
  "SKEČA",
  "SKEČE",
  "SKEČI",
  "SKEČU",
  "SKEJT",
  "SKELE",
  "SKELI",
  "SKEPI",
  "SKEPS",
  "SKESA",
  "SKICA",
  "SKICE",
  "SKICI",
  "SKICO",
  "SKIDA",
  "SKIFA",
  "SKIFE",
  "SKIFI",
  "SKIFU",
  "SKIMA",
  "SKINA",
  "SKINE",
  "SKINI",
  "SKINK",
  "SKINU",
  "SKIPA",
  "SKIPI",
  "SKIRO",
  "SKISA",
  "SKLAČ",
  "SKLAD",
  "SKLAL",
  "SKLAN",
  "SKLAT",
  "SKLEC",
  "SKLED",
  "SKLEP",
  "SKLIC",
  "SKLON",
  "SKLOP",
  "SKOBA",
  "SKOBE",
  "SKOBI",
  "SKOBO",
  "SKOČI",
  "SKOKA",
  "SKOKE",
  "SKOKI",
  "SKOKU",
  "SKOPA",
  "SKOPE",
  "SKOPI",
  "SKOPO",
  "SKORO",
  "SKORŠ",
  "SKOTA",
  "SKOTE",
  "SKOTI",
  "SKOTU",
  "SKOZI",
  "SKRAM",
  "SKRBE",
  "SKRBI",
  "SKRČI",
  "SKRČK",
  "SKRHA",
  "SKRIJ",
  "SKRIL",
  "SKRIT",
  "SKRKA",
  "SKRKE",
  "SKRKI",
  "SKRKO",
  "SKRPA",
  "SKUAH",
  "SKUAM",
  "SKUBE",
  "SKUBI",
  "SKUHA",
  "SKUJA",
  "SKUJE",
  "SKULA",
  "SKULE",
  "SKULI",
  "SKULU",
  "SKUNK",
  "SKUPI",
  "SKURI",
  "SKUSI",
  "SKUŠA",
  "SKUŠE",
  "SKUŠI",
  "SKUŠO",
  "SKUTA",
  "SKUTE",
  "SKUTI",
  "SKUTO",
  "SKVOŠ",
  "SKVOT",
  "SLABA",
  "SLABE",
  "SLABI",
  "SLABO",
  "SLAČI",
  "SLADA",
  "SLADI",
  "SLADU",
  "SLAKA",
  "SLAKU",
  "SLAMA",
  "SLAME",
  "SLAMI",
  "SLAMO",
  "SLANA",
  "SLANE",
  "SLANI",
  "SLANO",
  "SLAPA",
  "SLAPE",
  "SLAPI",
  "SLAPP",
  "SLAPU",
  "SLAST",
  "SLAVA",
  "SLAVE",
  "SLAVI",
  "SLAVO",
  "SLECI",
  "SLEČA",
  "SLEČE",
  "SLEČI",
  "SLEČU",
  "SLEDA",
  "SLEDE",
  "SLEDI",
  "SLEDU",
  "SLEME",
  "SLENG",
  "SLEPA",
  "SLEPE",
  "SLEPI",
  "SLEPK",
  "SLEPO",
  "SLEZA",
  "SLEZE",
  "SLEZI",
  "SLEZU",
  "SLIČI",
  "SLIKA",
  "SLIKE",
  "SLIKI",
  "SLIKO",
  "SLINA",
  "SLINE",
  "SLINI",
  "SLINO",
  "SLIPA",
  "SLIPE",
  "SLIPI",
  "SLIPU",
  "SLIŠE",
  "SLIŠI",
  "SLIVA",
  "SLIVE",
  "SLIVI",
  "SLIVO",
  "SLJUD",
  "SLOČI",
  "SLOGA",
  "SLOGE",
  "SLOGI",
  "SLOGO",
  "SLOGU",
  "SLOJA",
  "SLOJE",
  "SLOJI",
  "SLOJU",
  "SLOKA",
  "SLOKE",
  "SLOKI",
  "SLOKO",
  "SLOMA",
  "SLOMU",
  "SLONA",
  "SLONE",
  "SLONI",
  "SLONU",
  "SLOPA",
  "SLOPE",
  "SLOPI",
  "SLOPU",
  "SLOVA",
  "SLOVE",
  "SLOVI",
  "SLOVK",
  "SLOVO",
  "SLOVU",
  "SLUGA",
  "SLUGE",
  "SLUGI",
  "SLUGO",
  "SLUHA",
  "SLUHU",
  "SLUMA",
  "SLUME",
  "SLUMI",
  "SLUMU",
  "SLUPA",
  "SLUPE",
  "SLUPI",
  "SLUPU",
  "SLUŠA",
  "SLUTI",
  "SLUZA",
  "SLUZI",
  "SLUZU",
  "SLUŽB",
  "SLUŽI",
  "SMALT",
  "SMEHA",
  "SMEHU",
  "SMEJE",
  "SMEJI",
  "SMEJO",
  "SMELA",
  "SMELE",
  "SMELI",
  "SMELO",
  "SMEMO",
  "SMERI",
  "SMEŠI",
  "SMETA",
  "SMETE",
  "SMETI",
  "SMEVA",
  "SMILI",
  "SMILJ",
  "SMODA",
  "SMODE",
  "SMODI",
  "SMODU",
  "SMOGA",
  "SMOGE",
  "SMOGI",
  "SMOGU",
  "SMOLA",
  "SMOLE",
  "SMOLI",
  "SMOLO",
  "SMOTK",
  "SMRAD",
  "SMRČE",
  "SMRČI",
  "SMRDE",
  "SMRDI",
  "SMREK",
  "SMRKA",
  "SMRKE",
  "SMRKI",
  "SMRKU",
  "SMRTI",
  "SMUČA",
  "SMUČE",
  "SMUČI",
  "SMUČK",
  "SMUČO",
  "SMUČU",
  "SMUKA",
  "SMUKE",
  "SMUKI",
  "SMUKO",
  "SMUKU",
  "SMUTI",
  "SNAGA",
  "SNAGE",
  "SNAGI",
  "SNAGO",
  "SNAHA",
  "SNAHE",
  "SNAHI",
  "SNAHO",
  "SNAME",
  "SNAST",
  "SNAŽI",
  "SNEDO",
  "SNEGA",
  "SNEGU",
  "SNEJO",
  "SNELA",
  "SNELE",
  "SNELI",
  "SNELO",
  "SNEMA",
  "SNEMI",
  "SNEMO",
  "SNEST",
  "SNETA",
  "SNETE",
  "SNETI",
  "SNETO",
  "SNEVA",
  "SNEŽI",
  "SNEŽK",
  "SNIDE",
  "SNIDI",
  "SNIFA",
  "SNIFE",
  "SNIFI",
  "SNIFU",
  "SNITI",
  "SNOBA",
  "SNOBE",
  "SNOBI",
  "SNOBU",
  "SNOČI",
  "SNOMA",
  "SNOPA",
  "SNOPE",
  "SNOPI",
  "SNOPU",
  "SNOVI",
  "SNUBI",
  "SNUFF",
  "SNUJE",
  "SNULA",
  "SNULE",
  "SNULI",
  "SNULO",
  "SNUTA",
  "SNUTE",
  "SNUTI",
  "SNUTO",
  "SOBAH",
  "SOBAM",
  "SOBAN",
  "SOBAR",
  "SOBEN",
  "SOBIC",
  "SOBNA",
  "SOBNE",
  "SOBNI",
  "SOBNO",
  "SOBOT",
  "SOČEČ",
  "SOČEN",
  "SOČIL",
  "SOČIM",
  "SOČIŠ",
  "SOČIT",
  "SOČNA",
  "SOČNE",
  "SOČNI",
  "SOČNO",
  "SODAH",
  "SODAM",
  "SODAR",
  "SODAV",
  "SODBA",
  "SODBE",
  "SODBI",
  "SODBO",
  "SODCA",
  "SODCE",
  "SODCI",
  "SODCU",
  "SODEC",
  "SODEČ",
  "SODEL",
  "SODEM",
  "SODEN",
  "SODIH",
  "SODIL",
  "SODIM",
  "SODIN",
  "SODIŠ",
  "SODIT",
  "SODNA",
  "SODNE",
  "SODNI",
  "SODNO",
  "SODOM",
  "SODOV",
  "SODRA",
  "SODRE",
  "SODRG",
  "SODRI",
  "SODRO",
  "SOFIT",
  "SOFOR",
  "SOHAH",
  "SOHAM",
  "SOHIC",
  "SOJEM",
  "SOJEN",
  "SOJEV",
  "SOJIH",
  "SOKOL",
  "SOKOM",
  "SOKOT",
  "SOKOV",
  "SOLAT",
  "SOLDA",
  "SOLDE",
  "SOLDI",
  "SOLDU",
  "SOLEČ",
  "SOLEH",
  "SOLEM",
  "SOLEN",
  "SOLID",
  "SOLIH",
  "SOLIL",
  "SOLIM",
  "SOLIN",
  "SOLIŠ",
  "SOLIT",
  "SOLJO",
  "SOLMI",
  "SOLNA",
  "SOLNC",
  "SOLNE",
  "SOLNI",
  "SOLNO",
  "SOLOM",
  "SOLOV",
  "SOLZA",
  "SOLZE",
  "SOLZI",
  "SOLZO",
  "SOMAN",
  "SOMIC",
  "SOMIČ",
  "SOMIH",
  "SOMOM",
  "SOMOV",
  "SONAR",
  "SONAT",
  "SONCA",
  "SONCE",
  "SONCI",
  "SONCU",
  "SONČI",
  "SONDA",
  "SONDE",
  "SONDI",
  "SONDO",
  "SONET",
  "SONGA",
  "SONGE",
  "SONGI",
  "SONGU",
  "SOOČA",
  "SOOČI",
  "SOPAR",
  "SOPEČ",
  "SOPEL",
  "SOPEM",
  "SOPEŠ",
  "SOPIH",
  "SOPLA",
  "SOPLE",
  "SOPLI",
  "SOPLO",
  "SOPNE",
  "SOPNI",
  "SOPST",
  "SOPUH",
  "SOROD",
  "SORTA",
  "SORTE",
  "SORTI",
  "SORTO",
  "SOSED",
  "SOŠEN",
  "SOŠIC",
  "SOŠKA",
  "SOŠKE",
  "SOŠKI",
  "SOŠKO",
  "SOŠNA",
  "SOŠNE",
  "SOŠNI",
  "SOŠNO",
  "SOTLA",
  "SOTLE",
  "SOTLI",
  "SOTLO",
  "SOULA",
  "SOULU",
  "SOVAH",
  "SOVAM",
  "SOVCA",
  "SOVCE",
  "SOVCI",
  "SOVCU",
  "SOVEC",
  "SOVIC",
  "SOVIR",
  "SOVJA",
  "SOVJE",
  "SOVJI",
  "SOVJO",
  "SOVKA",
  "SOVKE",
  "SOVKI",
  "SOVKO",
  "SOZDA",
  "SOZDE",
  "SOZDI",
  "SOZDU",
  "SPACA",
  "SPAČI",
  "SPADA",
  "SPAHA",
  "SPAHE",
  "SPAHI",
  "SPAHU",
  "SPAJA",
  "SPAJK",
  "SPAKA",
  "SPAKE",
  "SPAKI",
  "SPAKO",
  "SPAKU",
  "SPALA",
  "SPALE",
  "SPALI",
  "SPALK",
  "SPALO",
  "SPAMA",
  "SPAMU",
  "SPANJ",
  "SPARA",
  "SPARI",
  "SPATI",
  "SPECI",
  "SPEČA",
  "SPEČE",
  "SPEČI",
  "SPEČO",
  "SPEHA",
  "SPEJO",
  "SPELA",
  "SPELE",
  "SPELI",
  "SPELO",
  "SPEMO",
  "SPENI",
  "SPERE",
  "SPERI",
  "SPERM",
  "SPETA",
  "SPETE",
  "SPETI",
  "SPETK",
  "SPETO",
  "SPEVA",
  "SPEVE",
  "SPEVI",
  "SPEVU",
  "SPHAJ",
  "SPHAL",
  "SPHAM",
  "SPHAN",
  "SPHAŠ",
  "SPHAT",
  "SPIČI",
  "SPIDA",
  "SPIDU",
  "SPIHA",
  "SPIJE",
  "SPIJO",
  "SPILA",
  "SPILE",
  "SPILI",
  "SPILO",
  "SPIMO",
  "SPINA",
  "SPINE",
  "SPINI",
  "SPINO",
  "SPINU",
  "SPIRA",
  "SPISA",
  "SPISE",
  "SPISI",
  "SPISU",
  "SPIŠE",
  "SPIŠI",
  "SPITA",
  "SPITE",
  "SPITI",
  "SPIVA",
  "SPLAV",
  "SPLET",
  "SPLOH",
  "SPNEM",
  "SPNEŠ",
  "SPODI",
  "SPOHA",
  "SPOJA",
  "SPOJE",
  "SPOJI",
  "SPOJK",
  "SPOJU",
  "SPOKA",
  "SPOLA",
  "SPOLE",
  "SPOLI",
  "SPOLU",
  "SPONA",
  "SPONE",
  "SPONI",
  "SPONK",
  "SPONO",
  "SPORA",
  "SPORE",
  "SPORI",
  "SPORO",
  "SPORU",
  "SPOTA",
  "SPOTE",
  "SPOTI",
  "SPOTU",
  "SPOVE",
  "SPRAL",
  "SPRAN",
  "SPRAT",
  "SPRAV",
  "SPRED",
  "SPREG",
  "SPREJ",
  "SPREM",
  "SPREŠ",
  "SPRET",
  "SPRLA",
  "SPRLE",
  "SPRLI",
  "SPRLO",
  "SPRTA",
  "SPRTE",
  "SPRTI",
  "SPRTO",
  "SPRVA",
  "SPUCA",
  "SPUFA",
  "SPUST",
  "SRAGA",
  "SRAGE",
  "SRAGI",
  "SRAGO",
  "SRAJC",
  "SRAKA",
  "SRAKE",
  "SRAKI",
  "SRAKO",
  "SRALA",
  "SRALE",
  "SRALI",
  "SRALO",
  "SRAMU",
  "SRANJ",
  "SRATI",
  "SRBCA",
  "SRBCE",
  "SRBCI",
  "SRBCU",
  "SRBEC",
  "SRBEČ",
  "SRBEL",
  "SRBET",
  "SRBEŽ",
  "SRBIM",
  "SRBIŠ",
  "SRCEM",
  "SRCIH",
  "SRČEC",
  "SRČEČ",
  "SRČEK",
  "SRČEM",
  "SRČEN",
  "SRČEŠ",
  "SRČEV",
  "SRČIK",
  "SRČIL",
  "SRČIM",
  "SRČIŠ",
  "SRČIT",
  "SRČKA",
  "SRČKE",
  "SRČKI",
  "SRČKU",
  "SRČNA",
  "SRČNE",
  "SRČNI",
  "SRČNO",
  "SRDEČ",
  "SRDEN",
  "SRDIH",
  "SRDIL",
  "SRDIM",
  "SRDIŠ",
  "SRDIT",
  "SRDOM",
  "SRDOV",
  "SREBA",
  "SREČA",
  "SREČE",
  "SREČI",
  "SREČK",
  "SREČO",
  "SREDA",
  "SREDE",
  "SREDI",
  "SREDO",
  "SRENA",
  "SRENE",
  "SRENI",
  "SRENJ",
  "SRENU",
  "SREPA",
  "SREPE",
  "SREPI",
  "SREPO",
  "SREZA",
  "SREZE",
  "SREZI",
  "SREZU",
  "SREŽA",
  "SREŽE",
  "SREŽI",
  "SREŽU",
  "SRFAJ",
  "SRFAL",
  "SRFAM",
  "SRFAR",
  "SRFAŠ",
  "SRHEK",
  "SRHIH",
  "SRHKA",
  "SRHKE",
  "SRHKI",
  "SRHKO",
  "SRHOM",
  "SRHOV",
  "SRKAJ",
  "SRKAL",
  "SRKAM",
  "SRKAŠ",
  "SRKAT",
  "SRKIH",
  "SRKNE",
  "SRKNI",
  "SRKOM",
  "SRKOV",
  "SRNAH",
  "SRNAM",
  "SRNIC",
  "SRNJA",
  "SRNJE",
  "SRNJI",
  "SRNJO",
  "SRPAN",
  "SRPAT",
  "SRPEK",
  "SRPIC",
  "SRPIČ",
  "SRPIH",
  "SRPIJ",
  "SRPJA",
  "SRPJE",
  "SRPJI",
  "SRPJU",
  "SRPKA",
  "SRPKE",
  "SRPKI",
  "SRPKU",
  "SRPOM",
  "SRPOV",
  "SRSTI",
  "SRŠAJ",
  "SRŠAL",
  "SRŠAT",
  "SRŠAV",
  "SRŠEČ",
  "SRŠEL",
  "SRŠEN",
  "SRŠET",
  "SRŠIC",
  "SRŠIL",
  "SRŠIM",
  "SRŠIŠ",
  "SRŠIT",
  "SRTEH",
  "SRTEM",
  "SRTJO",
  "SRTMI",
  "SRŽEH",
  "SRŽEM",
  "SRŽJO",
  "SRŽMI",
  "STAJA",
  "STAJE",
  "STAJI",
  "STAJO",
  "STALA",
  "STALE",
  "STALI",
  "STALO",
  "STALU",
  "STANA",
  "STANC",
  "STANE",
  "STANI",
  "STANJ",
  "STANU",
  "STARA",
  "STARE",
  "STARI",
  "STARK",
  "STARO",
  "STARŠ",
  "START",
  "STASA",
  "STASU",
  "STATI",
  "STAVA",
  "STAVB",
  "STAVE",
  "STAVI",
  "STAVK",
  "STAVO",
  "STAŽA",
  "STAŽE",
  "STAŽI",
  "STAŽU",
  "STECI",
  "STEČE",
  "STEČI",
  "STEGA",
  "STEGE",
  "STEGI",
  "STEGU",
  "STEJK",
  "STEKA",
  "STEKE",
  "STEKI",
  "STEKU",
  "STELA",
  "STELE",
  "STELI",
  "STELO",
  "STENA",
  "STENE",
  "STENI",
  "STENJ",
  "STENO",
  "STENT",
  "STENU",
  "STEPA",
  "STEPE",
  "STEPI",
  "STEPO",
  "STEPU",
  "STERI",
  "STEŠE",
  "STEŠI",
  "STEZA",
  "STEZE",
  "STEZI",
  "STEZO",
  "STIHA",
  "STIHE",
  "STIHI",
  "STIHU",
  "STIKA",
  "STIKE",
  "STIKI",
  "STIKU",
  "STILA",
  "STILE",
  "STILI",
  "STILU",
  "STINT",
  "STISA",
  "STISE",
  "STISI",
  "STISK",
  "STISU",
  "STIŠA",
  "STIVA",
  "STIVE",
  "STIVI",
  "STIVO",
  "STKAL",
  "STKAM",
  "STKAN",
  "STKAŠ",
  "STKAT",
  "STKEM",
  "STKEŠ",
  "STOČE",
  "STOČI",
  "STOGA",
  "STOGE",
  "STOGI",
  "STOGU",
  "STOIK",
  "STOJA",
  "STOJE",
  "STOJI",
  "STOJK",
  "STOJO",
  "STOKA",
  "STOKE",
  "STOKI",
  "STOKU",
  "STOLA",
  "STOLČ",
  "STOLE",
  "STOLI",
  "STOLP",
  "STOLU",
  "STOMA",
  "STOME",
  "STOMI",
  "STOMO",
  "STOOK",
  "STOPA",
  "STOPE",
  "STOPI",
  "STOPO",
  "STOPU",
  "STORE",
  "STORI",
  "STORŽ",
  "STOTA",
  "STOTE",
  "STOTI",
  "STOTO",
  "STOTU",
  "STOŽA",
  "STOŽE",
  "STOŽI",
  "STOŽK",
  "STOŽU",
  "STRAH",
  "STRAN",
  "STRAV",
  "STRAŽ",
  "STRDI",
  "STREČ",
  "STREH",
  "STREL",
  "STREM",
  "STRES",
  "STREŠ",
  "STRET",
  "STRGA",
  "STRIC",
  "STRIČ",
  "STRIG",
  "STRIJ",
  "STRIN",
  "STRIP",
  "STRIŽ",
  "STRKA",
  "STRLA",
  "STRLE",
  "STRLI",
  "STRLO",
  "STRMA",
  "STRME",
  "STRMI",
  "STRMO",
  "STRNA",
  "STRNE",
  "STRNI",
  "STRNU",
  "STROF",
  "STROG",
  "STROJ",
  "STROK",
  "STROM",
  "STROP",
  "STRPA",
  "STRPE",
  "STRPI",
  "STRTA",
  "STRTE",
  "STRTI",
  "STRTO",
  "STRUG",
  "STRUJ",
  "STRUM",
  "STRUN",
  "STRUP",
  "STRŽE",
  "STUDA",
  "STUDI",
  "STUDU",
  "STVAR",
  "STVOR",
  "SUČEM",
  "SUČEN",
  "SUČEŠ",
  "SUČNA",
  "SUČNE",
  "SUČNI",
  "SUČNO",
  "SUDAN",
  "SUFLE",
  "SUHCA",
  "SUHCE",
  "SUHCI",
  "SUHCU",
  "SUHEC",
  "SUHEM",
  "SUHIC",
  "SUHIH",
  "SUHIM",
  "SUHOT",
  "SUITA",
  "SUITE",
  "SUITI",
  "SUITO",
  "SUJEM",
  "SUJEŠ",
  "SUJMO",
  "SUJTA",
  "SUJTE",
  "SUJVA",
  "SUKAČ",
  "SUKAJ",
  "SUKAL",
  "SUKAM",
  "SUKAN",
  "SUKAŠ",
  "SUKAT",
  "SUKNA",
  "SUKNO",
  "SUKNU",
  "SUKUB",
  "SUKUS",
  "SULCA",
  "SULCE",
  "SULCI",
  "SULCU",
  "SULEC",
  "SULIC",
  "SULKI",
  "SUMAH",
  "SUMAM",
  "SUMEČ",
  "SUMIH",
  "SUMIL",
  "SUMIM",
  "SUMIŠ",
  "SUMIT",
  "SUMOM",
  "SUMOV",
  "SUNEK",
  "SUNEM",
  "SUNEŠ",
  "SUNIL",
  "SUNIS",
  "SUNIT",
  "SUNKA",
  "SUNKE",
  "SUNKI",
  "SUNKU",
  "SUPAJ",
  "SUPAL",
  "SUPAM",
  "SUPAŠ",
  "SUPAT",
  "SUPER",
  "SUPIH",
  "SUPIN",
  "SUPOM",
  "SUPOV",
  "SURAH",
  "SURAM",
  "SURIL",
  "SUROV",
  "SUŠAH",
  "SUŠAM",
  "SUŠCA",
  "SUŠCE",
  "SUŠCI",
  "SUŠCU",
  "SUŠEC",
  "SUŠEČ",
  "SUŠEN",
  "SUŠIL",
  "SUŠIM",
  "SUŠIN",
  "SUŠIŠ",
  "SUŠIT",
  "SUŠKA",
  "SUŠKE",
  "SUŠKI",
  "SUŠKO",
  "SUŠNA",
  "SUŠNE",
  "SUŠNI",
  "SUŠNO",
  "SUTAN",
  "SUTER",
  "SUTRA",
  "SUTRE",
  "SUTRI",
  "SUTRO",
  "SUVAJ",
  "SUVAL",
  "SUVAM",
  "SUVAN",
  "SUVAŠ",
  "SUVAT",
  "SVAJA",
  "SVAJE",
  "SVAJI",
  "SVAJO",
  "SVAKA",
  "SVAKE",
  "SVAKI",
  "SVAKU",
  "SVARI",
  "SVAST",
  "SVATA",
  "SVATB",
  "SVATE",
  "SVATI",
  "SVATU",
  "SVAZI",
  "SVEČA",
  "SVEČE",
  "SVEČI",
  "SVEČK",
  "SVEČO",
  "SVETA",
  "SVETE",
  "SVETI",
  "SVETO",
  "SVETU",
  "SVEŽA",
  "SVEŽE",
  "SVEŽI",
  "SVEŽO",
  "SVILA",
  "SVILE",
  "SVILI",
  "SVILO",
  "SVING",
  "SVINJ",
  "SVIRA",
  "SVIŠČ",
  "SVITA",
  "SVITE",
  "SVITI",
  "SVITU",
  "SVIŽA",
  "SVIŽU",
  "SVODA",
  "SVODE",
  "SVODI",
  "SVODU",
  "SVOJA",
  "SVOJE",
  "SVOJI",
  "SVOJO",
  "SVORA",
  "SVORE",
  "SVORI",
  "SVORO",
  "SVRHA",
  "SVRHE",
  "SVRHI",
  "SVRHO",
  "ŠABAT",
  "ŠABES",
  "ŠAFEL",
  "ŠAFLA",
  "ŠAFLE",
  "ŠAFLI",
  "ŠAFLO",
  "ŠAHIH",
  "ŠAHOM",
  "ŠAHOV",
  "ŠAHTA",
  "ŠAHTE",
  "ŠAHTI",
  "ŠAHTU",
  "ŠAJBA",
  "ŠAJBE",
  "ŠAJBI",
  "ŠAJBO",
  "ŠAJNA",
  "ŠAJNE",
  "ŠAJNI",
  "ŠAJNU",
  "ŠAKAL",
  "ŠALAH",
  "ŠALAM",
  "ŠALEČ",
  "ŠALIC",
  "ŠALIH",
  "ŠALIL",
  "ŠALIM",
  "ŠALIŠ",
  "ŠALIT",
  "ŠALOM",
  "ŠALOV",
  "ŠALTA",
  "ŠALUP",
  "ŠAMAN",
  "ŠAMOT",
  "ŠANKA",
  "ŠANKE",
  "ŠANKI",
  "ŠANKU",
  "ŠANSA",
  "ŠANSE",
  "ŠANSI",
  "ŠANSO",
  "ŠANTA",
  "ŠAPAH",
  "ŠAPAM",
  "ŠAPIC",
  "ŠAPKA",
  "ŠAPKE",
  "ŠAPKI",
  "ŠAPKO",
  "ŠAPNE",
  "ŠAPNI",
  "ŠARAD",
  "ŠARCA",
  "ŠARCE",
  "ŠARCI",
  "ŠARCU",
  "ŠAREC",
  "ŠAREČ",
  "ŠARGA",
  "ŠARGE",
  "ŠARGI",
  "ŠARGU",
  "ŠARIL",
  "ŠARIM",
  "ŠARIŠ",
  "ŠARIT",
  "ŠARMA",
  "ŠARMU",
  "ŠARŽA",
  "ŠARŽE",
  "ŠARŽI",
  "ŠARŽO",
  "ŠASIJ",
  "ŠAŠEM",
  "ŠAŠEV",
  "ŠAŠIH",
  "ŠAŠJA",
  "ŠAŠJE",
  "ŠAŠJU",
  "ŠAVER",
  "ŠAVJA",
  "ŠAVJE",
  "ŠAVJU",
  "ŠAVRA",
  "ŠAVRE",
  "ŠAVRI",
  "ŠAVRO",
  "ŠAVSA",
  "ŠČEGI",
  "ŠČEME",
  "ŠČEMI",
  "ŠČENE",
  "ŠČEPA",
  "ŠČEPE",
  "ŠČEPI",
  "ŠČEPU",
  "ŠČETI",
  "ŠČETK",
  "ŠČIJE",
  "ŠČIJO",
  "ŠČIMO",
  "ŠČIPA",
  "ŠČIPE",
  "ŠČIPI",
  "ŠČIPU",
  "ŠČIRA",
  "ŠČIRE",
  "ŠČIRI",
  "ŠČIRU",
  "ŠČITA",
  "ŠČITE",
  "ŠČITI",
  "ŠČITU",
  "ŠČIVA",
  "ŠČOČA",
  "ŠČOČE",
  "ŠČOČI",
  "ŠČOČO",
  "ŠČUJE",
  "ŠČUKA",
  "ŠČUKE",
  "ŠČUKI",
  "ŠČUKO",
  "ŠČUVA",
  "ŠEBOJ",
  "ŠEDIH",
  "ŠEDOM",
  "ŠEDOV",
  "ŠEFIC",
  "ŠEFIH",
  "ŠEFOM",
  "ŠEFOV",
  "ŠEGAH",
  "ŠEGAM",
  "ŠEGAV",
  "ŠEHIH",
  "ŠEHOM",
  "ŠEHOV",
  "ŠEJEK",
  "ŠEJKA",
  "ŠEJKE",
  "ŠEJKI",
  "ŠEJKU",
  "ŠEKEL",
  "ŠEKLA",
  "ŠEKLE",
  "ŠEKLI",
  "ŠEKLU",
  "ŠELAK",
  "ŠELFA",
  "ŠELFE",
  "ŠELFI",
  "ŠELFU",
  "ŠEMAH",
  "ŠEMAM",
  "ŠEMEČ",
  "ŠEMIC",
  "ŠEMIL",
  "ŠEMIM",
  "ŠEMIŠ",
  "ŠEMIT",
  "ŠENIH",
  "ŠENKA",
  "ŠENOM",
  "ŠENOV",
  "ŠEPAJ",
  "ŠEPAL",
  "ŠEPAM",
  "ŠEPAŠ",
  "ŠEPAT",
  "ŠEPAV",
  "ŠEPET",
  "ŠEPNE",
  "ŠEPNI",
  "ŠERAJ",
  "ŠERAL",
  "ŠERAM",
  "ŠERAN",
  "ŠERAŠ",
  "ŠERAT",
  "ŠERIF",
  "ŠERPA",
  "ŠERPE",
  "ŠERPI",
  "ŠERPO",
  "ŠESTA",
  "ŠESTE",
  "ŠESTI",
  "ŠESTO",
  "ŠEŠKA",
  "ŠEŠKE",
  "ŠEŠKI",
  "ŠEŠKO",
  "ŠETAJ",
  "ŠETAL",
  "ŠETAM",
  "ŠETAŠ",
  "ŠETAT",
  "ŠIBAH",
  "ŠIBAJ",
  "ŠIBAL",
  "ŠIBAM",
  "ŠIBAŠ",
  "ŠIBEČ",
  "ŠIBEK",
  "ŠIBER",
  "ŠIBIC",
  "ŠIBIJ",
  "ŠIBIK",
  "ŠIBIL",
  "ŠIBIM",
  "ŠIBIŠ",
  "ŠIBIT",
  "ŠIBJA",
  "ŠIBJE",
  "ŠIBJI",
  "ŠIBJU",
  "ŠIBKA",
  "ŠIBKE",
  "ŠIBKI",
  "ŠIBKO",
  "ŠIBRA",
  "ŠIBRE",
  "ŠIBRI",
  "ŠIBRO",
  "ŠICAJ",
  "ŠICAL",
  "ŠICAM",
  "ŠICAŠ",
  "ŠIFER",
  "ŠIFRA",
  "ŠIFRE",
  "ŠIFRI",
  "ŠIFRO",
  "ŠIHTA",
  "ŠIHTE",
  "ŠIHTI",
  "ŠIHTU",
  "ŠIITA",
  "ŠIITE",
  "ŠIITI",
  "ŠIITU",
  "ŠIKAJ",
  "ŠIKAL",
  "ŠIKAM",
  "ŠIKAN",
  "ŠIKAŠ",
  "ŠILAR",
  "ŠILCA",
  "ŠILCE",
  "ŠILCI",
  "ŠILCU",
  "ŠILEČ",
  "ŠILIH",
  "ŠILIL",
  "ŠILIM",
  "ŠILIŠ",
  "ŠILIT",
  "ŠILOM",
  "ŠILTA",
  "ŠILTE",
  "ŠILTI",
  "ŠILTU",
  "ŠIMFA",
  "ŠINAH",
  "ŠINAM",
  "ŠINEM",
  "ŠINEŠ",
  "ŠINIL",
  "ŠINIT",
  "ŠINJA",
  "ŠINJE",
  "ŠINJI",
  "ŠINJU",
  "ŠIPAH",
  "ŠIPAM",
  "ŠIPEK",
  "ŠIPKA",
  "ŠIPKE",
  "ŠIPKI",
  "ŠIPKU",
  "ŠIPON",
  "ŠIREN",
  "ŠIRIL",
  "ŠIRIM",
  "ŠIRIN",
  "ŠIRIŠ",
  "ŠIRIT",
  "ŠIRJO",
  "ŠIRNA",
  "ŠIRNE",
  "ŠIRNI",
  "ŠIRNO",
  "ŠIROK",
  "ŠIROM",
  "ŠIRŠA",
  "ŠIRŠE",
  "ŠIRŠI",
  "ŠIRŠO",
  "ŠIŠAH",
  "ŠIŠAM",
  "ŠIŠKA",
  "ŠIŠKE",
  "ŠIŠKI",
  "ŠIŠKO",
  "ŠITAH",
  "ŠITAK",
  "ŠITAM",
  "ŠITIH",
  "ŠITOM",
  "ŠITOV",
  "ŠIVAJ",
  "ŠIVAL",
  "ŠIVAM",
  "ŠIVAN",
  "ŠIVAŠ",
  "ŠIVAT",
  "ŠIVEN",
  "ŠIVIH",
  "ŠIVNA",
  "ŠIVNE",
  "ŠIVNI",
  "ŠIVNO",
  "ŠIVOM",
  "ŠIVOV",
  "ŠKAFA",
  "ŠKAFE",
  "ŠKAFI",
  "ŠKAFU",
  "ŠKAJA",
  "ŠKAJE",
  "ŠKAJI",
  "ŠKAJO",
  "ŠKAMP",
  "ŠKARP",
  "ŠKART",
  "ŠKILI",
  "ŠKISA",
  "ŠKISE",
  "ŠKISI",
  "ŠKISU",
  "ŠKLOC",
  "ŠKODA",
  "ŠKODE",
  "ŠKODI",
  "ŠKODO",
  "ŠKOFA",
  "ŠKOFE",
  "ŠKOFI",
  "ŠKOFU",
  "ŠKOLJ",
  "ŠKOPA",
  "ŠKOPE",
  "ŠKOPI",
  "ŠKOPO",
  "ŠKOTA",
  "ŠKOTE",
  "ŠKOTI",
  "ŠKOTO",
  "ŠKRAT",
  "ŠKRBE",
  "ŠKRBI",
  "ŠKRGA",
  "ŠKRGE",
  "ŠKRGI",
  "ŠKRGO",
  "ŠKRIC",
  "ŠKRLI",
  "ŠKROB",
  "ŠKRTA",
  "ŠKRTE",
  "ŠKRTI",
  "ŠKRTO",
  "ŠKURH",
  "ŠKVER",
  "ŠLAFA",
  "ŠLANK",
  "ŠLAPA",
  "ŠLAPE",
  "ŠLAPI",
  "ŠLAPO",
  "ŠLATA",
  "ŠLAUF",
  "ŠLEMA",
  "ŠLEME",
  "ŠLEMI",
  "ŠLEMU",
  "ŠLEPA",
  "ŠLEVA",
  "ŠLEVE",
  "ŠLEVI",
  "ŠLEVO",
  "ŠLICA",
  "ŠLICE",
  "ŠLICI",
  "ŠLICU",
  "ŠLOGA",
  "ŠLUKA",
  "ŠLUKE",
  "ŠLUKI",
  "ŠLUKU",
  "ŠMENT",
  "ŠMINK",
  "ŠMIRA",
  "ŠMIRU",
  "ŠNIRA",
  "ŠNITA",
  "ŠNITE",
  "ŠNITI",
  "ŠNITO",
  "ŠNJUR",
  "ŠNOFA",
  "ŠNOLA",
  "ŠNOLE",
  "ŠNOLI",
  "ŠNOLO",
  "ŠNOPS",
  "ŠOAHA",
  "ŠOAHU",
  "ŠOBAH",
  "ŠOBAM",
  "ŠOBEN",
  "ŠOBIC",
  "ŠOBIL",
  "ŠOBIM",
  "ŠOBIŠ",
  "ŠOBIT",
  "ŠOBNA",
  "ŠOBNE",
  "ŠOBNI",
  "ŠOBNO",
  "ŠODER",
  "ŠODRA",
  "ŠODRU",
  "ŠOFER",
  "ŠOGUN",
  "ŠOJAH",
  "ŠOJAM",
  "ŠOKER",
  "ŠOKIH",
  "ŠOKOM",
  "ŠOKOV",
  "ŠOLAH",
  "ŠOLAJ",
  "ŠOLAL",
  "ŠOLAM",
  "ŠOLAN",
  "ŠOLAR",
  "ŠOLAŠ",
  "ŠOLAT",
  "ŠOLEN",
  "ŠOLNA",
  "ŠOLNE",
  "ŠOLNI",
  "ŠOLNU",
  "ŠOPEK",
  "ŠOPIH",
  "ŠOPKA",
  "ŠOPKE",
  "ŠOPKI",
  "ŠOPKU",
  "ŠOPOM",
  "ŠOPOV",
  "ŠOTAH",
  "ŠOTAM",
  "ŠOTAR",
  "ŠOTEN",
  "ŠOTIŠ",
  "ŠOTNA",
  "ŠOTNE",
  "ŠOTNI",
  "ŠOTNO",
  "ŠOTOR",
  "ŠOVIH",
  "ŠOVOM",
  "ŠOVOV",
  "ŠPAGA",
  "ŠPAGE",
  "ŠPAGI",
  "ŠPAGO",
  "ŠPAJK",
  "ŠPAJZ",
  "ŠPANG",
  "ŠPARA",
  "ŠPARE",
  "ŠPARI",
  "ŠPARU",
  "ŠPASA",
  "ŠPASE",
  "ŠPASI",
  "ŠPASU",
  "ŠPECA",
  "ŠPECE",
  "ŠPECI",
  "ŠPECO",
  "ŠPEGA",
  "ŠPEHA",
  "ŠPEHE",
  "ŠPEHI",
  "ŠPEHU",
  "ŠPICA",
  "ŠPICE",
  "ŠPICI",
  "ŠPICO",
  "ŠPICU",
  "ŠPIČI",
  "ŠPIKA",
  "ŠPIKE",
  "ŠPIKI",
  "ŠPIKU",
  "ŠPILA",
  "ŠPILE",
  "ŠPILI",
  "ŠPILJ",
  "ŠPILO",
  "ŠPILU",
  "ŠPOGA",
  "ŠPONA",
  "ŠPONE",
  "ŠPONI",
  "ŠPONU",
  "ŠPORT",
  "ŠPOTA",
  "ŠPRAH",
  "ŠPRIC",
  "ŠPULA",
  "ŠPULE",
  "ŠPULI",
  "ŠPULO",
  "ŠPURA",
  "ŠPURE",
  "ŠPURI",
  "ŠPURO",
  "ŠRANG",
  "ŠRAUF",
  "ŠROTA",
  "ŠROTU",
  "ŠTABA",
  "ŠTABE",
  "ŠTABI",
  "ŠTABU",
  "ŠTALA",
  "ŠTALE",
  "ŠTALI",
  "ŠTALO",
  "ŠTANC",
  "ŠTANG",
  "ŠTANT",
  "ŠTEDI",
  "ŠTEJE",
  "ŠTEKA",
  "ŠTEKE",
  "ŠTEKI",
  "ŠTEKO",
  "ŠTELA",
  "ŠTELE",
  "ŠTELI",
  "ŠTELO",
  "ŠTEMA",
  "ŠTENG",
  "ŠTEPA",
  "ŠTERN",
  "ŠTETA",
  "ŠTETE",
  "ŠTETI",
  "ŠTETO",
  "ŠTEVK",
  "ŠTIFT",
  "ŠTIHA",
  "ŠTIHE",
  "ŠTIHI",
  "ŠTIHU",
  "ŠTIKA",
  "ŠTILA",
  "ŠTILE",
  "ŠTILI",
  "ŠTILU",
  "ŠTIMA",
  "ŠTIME",
  "ŠTIMI",
  "ŠTIMO",
  "ŠTIRI",
  "ŠTOFA",
  "ŠTOFU",
  "ŠTOKA",
  "ŠTOKE",
  "ŠTOKI",
  "ŠTOKU",
  "ŠTOLA",
  "ŠTOLE",
  "ŠTOLI",
  "ŠTOLO",
  "ŠTOPA",
  "ŠTOPE",
  "ŠTOPI",
  "ŠTOPU",
  "ŠTORA",
  "ŠTORE",
  "ŠTORI",
  "ŠTORU",
  "ŠTOSA",
  "ŠTOSE",
  "ŠTOSI",
  "ŠTOSU",
  "ŠTRAS",
  "ŠTRCA",
  "ŠTREK",
  "ŠTREN",
  "ŠTRIK",
  "ŠTRKA",
  "ŠTRKE",
  "ŠTRKI",
  "ŠTRKU",
  "ŠTRLE",
  "ŠTRLI",
  "ŠTROM",
  "ŠTRUC",
  "ŠTUKA",
  "ŠTUKE",
  "ŠTUKI",
  "ŠTUKU",
  "ŠTULA",
  "ŠTULE",
  "ŠTULI",
  "ŠTULO",
  "ŠTUMF",
  "ŠTUPA",
  "ŠUFTA",
  "ŠUFTE",
  "ŠUFTI",
  "ŠUFTU",
  "ŠUMCA",
  "ŠUMCU",
  "ŠUMEC",
  "ŠUMEČ",
  "ŠUMEL",
  "ŠUMEN",
  "ŠUMET",
  "ŠUMIH",
  "ŠUMIK",
  "ŠUMIM",
  "ŠUMIŠ",
  "ŠUMNA",
  "ŠUMNE",
  "ŠUMNI",
  "ŠUMNO",
  "ŠUMOM",
  "ŠUMOT",
  "ŠUMOV",
  "ŠUNDA",
  "ŠUNDU",
  "ŠUNKA",
  "ŠUNKE",
  "ŠUNKI",
  "ŠUNKO",
  "ŠUPAH",
  "ŠUPAM",
  "ŠURAH",
  "ŠURAM",
  "ŠUSIH",
  "ŠUSOM",
  "ŠUSOV",
  "ŠUŠTE",
  "ŠUŠTI",
  "ŠVABA",
  "ŠVABE",
  "ŠVABI",
  "ŠVABU",
  "ŠVASA",
  "ŠVERC",
  "ŠVICA",
  "ŠVICE",
  "ŠVICI",
  "ŠVICO",
  "ŠVICU",
  "ŠVIGA",
  "ŠVIST",
  "ŠVRKA",
  "ŠVRKE",
  "ŠVRKI",
  "ŠVRKU",
  "TABEL",
  "TABLA",
  "TABLE",
  "TABLI",
  "TABLO",
  "TABOR",
  "TABOT",
  "TABRA",
  "TABRU",
  "TABUN",
  "TACAH",
  "TACAJ",
  "TACAL",
  "TACAM",
  "TACAŠ",
  "TACAT",
  "TAČAS",
  "TAČIC",
  "TAČKA",
  "TAČKE",
  "TAČKI",
  "TAČKO",
  "TADLA",
  "TADVA",
  "TAEBO",
  "TAFTA",
  "TAFTU",
  "TAGIH",
  "TAGOM",
  "TAGOV",
  "TAHTA",
  "TAHTE",
  "TAHTI",
  "TAHTU",
  "TAJAJ",
  "TAJAL",
  "TAJAM",
  "TAJAŠ",
  "TAJAT",
  "TAJEČ",
  "TAJEN",
  "TAJGA",
  "TAJGE",
  "TAJGI",
  "TAJGO",
  "TAJIL",
  "TAJIM",
  "TAJIŠ",
  "TAJIT",
  "TAJNA",
  "TAJNE",
  "TAJNI",
  "TAJNO",
  "TAKAH",
  "TAKEM",
  "TAKIH",
  "TAKIJ",
  "TAKIM",
  "TAKIN",
  "TAKLE",
  "TAKOJ",
  "TAKOV",
  "TAKSA",
  "TAKSE",
  "TAKSI",
  "TAKSO",
  "TAKTA",
  "TAKTE",
  "TAKTI",
  "TAKTU",
  "TALAJ",
  "TALAL",
  "TALAM",
  "TALAN",
  "TALAR",
  "TALAŠ",
  "TALCA",
  "TALCE",
  "TALCI",
  "TALCU",
  "TALEC",
  "TALEČ",
  "TALEN",
  "TALER",
  "TALIB",
  "TALIG",
  "TALIJ",
  "TALIL",
  "TALIM",
  "TALIN",
  "TALIŠ",
  "TALIT",
  "TALKA",
  "TALKE",
  "TALKI",
  "TALKO",
  "TALNA",
  "TALNE",
  "TALNI",
  "TALNO",
  "TALON",
  "TALOZ",
  "TALUS",
  "TAMAL",
  "TAMAR",
  "TAMLE",
  "TANCA",
  "TANEČ",
  "TANEK",
  "TANGA",
  "TANGE",
  "TANGI",
  "TANGO",
  "TANGU",
  "TANIL",
  "TANIM",
  "TANIN",
  "TANIŠ",
  "TANIT",
  "TANJE",
  "TANKA",
  "TANKE",
  "TANKI",
  "TANKO",
  "TANKU",
  "TAPAS",
  "TAPET",
  "TAPIR",
  "TAPKA",
  "TAPNE",
  "TAPNI",
  "TARAH",
  "TARAM",
  "TARČA",
  "TARČE",
  "TARČI",
  "TARČO",
  "TAREM",
  "TAREŠ",
  "TARIF",
  "TARIH",
  "TARNA",
  "TAROK",
  "TAROM",
  "TAROT",
  "TAROV",
  "TASTA",
  "TASTE",
  "TASTI",
  "TASTU",
  "TAŠČA",
  "TAŠČE",
  "TAŠČI",
  "TAŠČO",
  "TAŠKA",
  "TAŠKE",
  "TAŠKI",
  "TAŠKO",
  "TATAH",
  "TATAM",
  "TATER",
  "TATIC",
  "TATIČ",
  "TATOM",
  "TATOV",
  "TATRE",
  "TAUHA",
  "TAUIH",
  "TAUOM",
  "TAUOV",
  "TAVAJ",
  "TAVAL",
  "TAVAM",
  "TAVAŠ",
  "TAVAT",
  "TAVHA",
  "TAVŠA",
  "TEAMA",
  "TEAME",
  "TEAMI",
  "TEAMU",
  "TEBOJ",
  "TEČAJ",
  "TEČEM",
  "TEČEN",
  "TEČEŠ",
  "TEČKA",
  "TEČKE",
  "TEČKI",
  "TEČKO",
  "TEČNA",
  "TEČNE",
  "TEČNI",
  "TEČNO",
  "TEDAJ",
  "TEDEN",
  "TEDNA",
  "TEDNE",
  "TEDNI",
  "TEDNU",
  "TEDVE",
  "TEGOB",
  "TEGOL",
  "TEHLE",
  "TEHNA",
  "TEHNO",
  "TEHNU",
  "TEHTA",
  "TEINA",
  "TEINU",
  "TEIST",
  "TEJLE",
  "TEKAČ",
  "TEKAJ",
  "TEKAL",
  "TEKAM",
  "TEKAŠ",
  "TEKAT",
  "TEKEL",
  "TEKEM",
  "TEKIC",
  "TEKIH",
  "TEKIL",
  "TEKLA",
  "TEKLE",
  "TEKLI",
  "TEKLO",
  "TEKMA",
  "TEKME",
  "TEKMI",
  "TEKMO",
  "TEKNE",
  "TEKNI",
  "TEKOČ",
  "TEKOM",
  "TEKOV",
  "TEKST",
  "TEKUT",
  "TELEG",
  "TELES",
  "TELET",
  "TELIC",
  "TELIL",
  "TELIM",
  "TELIN",
  "TELIŠ",
  "TELIT",
  "TELOH",
  "TELOP",
  "TELUR",
  "TEMAH",
  "TEMAM",
  "TEMEN",
  "TEMIN",
  "TEMLE",
  "TEMNA",
  "TEMNE",
  "TEMNI",
  "TEMNO",
  "TEMOT",
  "TEMPA",
  "TEMPE",
  "TEMPI",
  "TEMPO",
  "TEMPU",
  "TENAK",
  "TENDA",
  "TENDE",
  "TENDI",
  "TENDO",
  "TENEK",
  "TENIH",
  "TENIS",
  "TENKA",
  "TENKE",
  "TENKI",
  "TENKO",
  "TENOM",
  "TENOR",
  "TENOV",
  "TEPAČ",
  "TEPCA",
  "TEPCE",
  "TEPCI",
  "TEPCU",
  "TEPEC",
  "TEPEL",
  "TEPEM",
  "TEPEN",
  "TEPEŠ",
  "TEPEŽ",
  "TEPIH",
  "TEPKA",
  "TEPKE",
  "TEPKI",
  "TEPKO",
  "TEPLA",
  "TEPLE",
  "TEPLI",
  "TEPLO",
  "TEPST",
  "TEPTA",
  "TERAN",
  "TERAS",
  "TERCA",
  "TERCE",
  "TERCI",
  "TERCO",
  "TEREN",
  "TERIC",
  "TERIJ",
  "TERIN",
  "TERJA",
  "TERJE",
  "TERMA",
  "TERME",
  "TERMI",
  "TERMO",
  "TERNA",
  "TERNE",
  "TERNI",
  "TERNO",
  "TEROČ",
  "TEROR",
  "TESAČ",
  "TESAL",
  "TESAN",
  "TESAR",
  "TESAT",
  "TESEL",
  "TESEN",
  "TESLA",
  "TESLE",
  "TESLI",
  "TESLO",
  "TESLU",
  "TESNA",
  "TESNE",
  "TESNI",
  "TESNO",
  "TESTA",
  "TESTE",
  "TESTI",
  "TESTO",
  "TESTU",
  "TEŠČA",
  "TEŠČE",
  "TEŠČI",
  "TEŠČO",
  "TEŠEČ",
  "TEŠEM",
  "TEŠEN",
  "TEŠEŠ",
  "TEŠIL",
  "TEŠIM",
  "TEŠIŠ",
  "TEŠIT",
  "TEŠOČ",
  "TETAH",
  "TETAM",
  "TETER",
  "TETIC",
  "TETIV",
  "TETKA",
  "TETKE",
  "TETKI",
  "TETKO",
  "TETRA",
  "TETRE",
  "TETRI",
  "TETRO",
  "TEVIJ",
  "TEVJA",
  "TEVJE",
  "TEVJI",
  "TEVJU",
  "TEZAH",
  "TEZAM",
  "TEŽAH",
  "TEŽAK",
  "TEŽAM",
  "TEŽAV",
  "TEŽCA",
  "TEŽCU",
  "TEŽEC",
  "TEŽEK",
  "TEŽEN",
  "TEŽIL",
  "TEŽIM",
  "TEŽIN",
  "TEŽIŠ",
  "TEŽJA",
  "TEŽJE",
  "TEŽJI",
  "TEŽJO",
  "TEŽKA",
  "TEŽKE",
  "TEŽKI",
  "TEŽKO",
  "TEŽNA",
  "TEŽNE",
  "TEŽNI",
  "TEŽNO",
  "THETA",
  "THETE",
  "THETI",
  "THETO",
  "TIARA",
  "TIARE",
  "TIARI",
  "TIARO",
  "TIBET",
  "TIČAL",
  "TIČAT",
  "TIČEK",
  "TIČEM",
  "TIČEŠ",
  "TIČEV",
  "TIČIC",
  "TIČIH",
  "TIČIM",
  "TIČIŠ",
  "TIČKA",
  "TIČKE",
  "TIČKI",
  "TIČKO",
  "TIČKU",
  "TIDVE",
  "TIFUS",
  "TIGER",
  "TIGON",
  "TIGRA",
  "TIGRE",
  "TIGRI",
  "TIGRU",
  "TIHEM",
  "TIHIH",
  "TIHIM",
  "TIHOT",
  "TIKAJ",
  "TIKAL",
  "TIKAM",
  "TIKAŠ",
  "TIKAT",
  "TIKIH",
  "TIKOM",
  "TIKOV",
  "TILDA",
  "TILDE",
  "TILDI",
  "TILDO",
  "TILOM",
  "TIMER",
  "TIMIH",
  "TIMIN",
  "TIMOL",
  "TIMOM",
  "TIMOV",
  "TIMUS",
  "TINTA",
  "TINTE",
  "TINTI",
  "TINTO",
  "TIOLA",
  "TIOLE",
  "TIOLI",
  "TIOLU",
  "TIPAJ",
  "TIPAL",
  "TIPAM",
  "TIPAŠ",
  "TIPAT",
  "TIPEN",
  "TIPIC",
  "TIPIH",
  "TIPIK",
  "TIPKA",
  "TIPKE",
  "TIPKI",
  "TIPKO",
  "TIPNA",
  "TIPNE",
  "TIPNI",
  "TIPNO",
  "TIPOM",
  "TIPOV",
  "TIRAD",
  "TIRAJ",
  "TIRAL",
  "TIRAM",
  "TIRAN",
  "TIRAŠ",
  "TIRAT",
  "TIRAŽ",
  "TIREN",
  "TIRIH",
  "TIRNA",
  "TIRNE",
  "TIRNI",
  "TIRNO",
  "TIROM",
  "TIROV",
  "TIRSA",
  "TIRSE",
  "TIRSI",
  "TIRSU",
  "TISAH",
  "TISAM",
  "TISJA",
  "TISJE",
  "TISJU",
  "TISKA",
  "TISKE",
  "TISKI",
  "TISKU",
  "TISOČ",
  "TISTA",
  "TISTE",
  "TISTI",
  "TISTO",
  "TIŠČI",
  "TIŠIN",
  "TIŠJA",
  "TIŠJE",
  "TIŠJI",
  "TIŠJO",
  "TIŠKA",
  "TIŠKE",
  "TIŠKI",
  "TIŠKO",
  "TITAN",
  "TITEL",
  "TITER",
  "TITLA",
  "TITLE",
  "TITLI",
  "TITLU",
  "TITRA",
  "TITRE",
  "TITRI",
  "TITRU",
  "TITUL",
  "TJALE",
  "TKALA",
  "TKALE",
  "TKALI",
  "TKALK",
  "TKALO",
  "TKANA",
  "TKANE",
  "TKANI",
  "TKANJ",
  "TKANO",
  "TKATI",
  "TKEJO",
  "TKEMO",
  "TKETA",
  "TKETE",
  "TKEVA",
  "TKIMO",
  "TKITA",
  "TKITE",
  "TKIVA",
  "TKIVI",
  "TKIVO",
  "TKIVU",
  "TKOČA",
  "TKOČE",
  "TKOČI",
  "TKOČO",
  "TLAČI",
  "TLAKA",
  "TLAKE",
  "TLAKI",
  "TLAKO",
  "TLAKU",
  "TLEČA",
  "TLEČE",
  "TLEČI",
  "TLEČO",
  "TLELA",
  "TLELE",
  "TLELI",
  "TLELO",
  "TLEMI",
  "TLENJ",
  "TLESK",
  "TLETI",
  "TLIJO",
  "TLIMO",
  "TLITA",
  "TLITE",
  "TLIVA",
  "TLIVK",
  "TNALA",
  "TNALC",
  "TNALE",
  "TNALI",
  "TNALO",
  "TNALU",
  "TOAST",
  "TOBAK",
  "TOČAH",
  "TOČAJ",
  "TOČAM",
  "TOČEN",
  "TOČIL",
  "TOČIM",
  "TOČIŠ",
  "TOČIT",
  "TOČKA",
  "TOČKE",
  "TOČKI",
  "TOČKO",
  "TOČNA",
  "TOČNE",
  "TOČNI",
  "TOČNO",
  "TOGAH",
  "TOGAM",
  "TOGEM",
  "TOGIH",
  "TOGIM",
  "TOGOM",
  "TOGOT",
  "TOHEL",
  "TOHLA",
  "TOHLE",
  "TOHLI",
  "TOHLO",
  "TOKAJ",
  "TOKAT",
  "TOKAV",
  "TOKEJ",
  "TOKIH",
  "TOKOJ",
  "TOKOM",
  "TOKOV",
  "TOLAR",
  "TOLCI",
  "TOLČE",
  "TOLČI",
  "TOLIG",
  "TOLIK",
  "TOLPA",
  "TOLPE",
  "TOLPI",
  "TOLPO",
  "TOLST",
  "TOLŠČ",
  "TOMIH",
  "TOMOM",
  "TOMOV",
  "TONAH",
  "TONAM",
  "TONAŽ",
  "TONEČ",
  "TONEM",
  "TONER",
  "TONEŠ",
  "TONGA",
  "TONGE",
  "TONGI",
  "TONGO",
  "TONIH",
  "TONIK",
  "TONIL",
  "TONIT",
  "TONOM",
  "TONOV",
  "TONUS",
  "TOPAZ",
  "TOPEČ",
  "TOPEL",
  "TOPEM",
  "TOPEN",
  "TOPIČ",
  "TOPIH",
  "TOPIK",
  "TOPIL",
  "TOPIM",
  "TOPIŠ",
  "TOPIT",
  "TOPLA",
  "TOPLE",
  "TOPLI",
  "TOPLO",
  "TOPNA",
  "TOPNE",
  "TOPNI",
  "TOPNO",
  "TOPOL",
  "TOPOM",
  "TOPOT",
  "TOPOV",
  "TORBA",
  "TORBE",
  "TORBI",
  "TORBO",
  "TOREJ",
  "TOREK",
  "TOREN",
  "TORIC",
  "TORIH",
  "TORIJ",
  "TORIL",
  "TORKA",
  "TORKE",
  "TORKI",
  "TORKU",
  "TORNA",
  "TORNE",
  "TORNI",
  "TORNO",
  "TOROM",
  "TOROV",
  "TORTA",
  "TORTE",
  "TORTI",
  "TORTO",
  "TORUS",
  "TORZA",
  "TORZE",
  "TORZI",
  "TORZO",
  "TORZU",
  "TOSCA",
  "TOSCU",
  "TOŠČA",
  "TOŠČU",
  "TOŠEL",
  "TOŠLA",
  "TOŠLE",
  "TOŠLI",
  "TOŠLU",
  "TOTAL",
  "TOTEM",
  "TOTIH",
  "TOTIM",
  "TOVOR",
  "TOZDA",
  "TOZDE",
  "TOZDI",
  "TOZDU",
  "TOŽBA",
  "TOŽBE",
  "TOŽBI",
  "TOŽBO",
  "TOŽEČ",
  "TOŽEN",
  "TOŽIL",
  "TOŽIM",
  "TOŽIŠ",
  "TOŽIT",
  "TOŽNA",
  "TOŽNE",
  "TOŽNI",
  "TOŽNO",
  "TRAČA",
  "TRAČE",
  "TRAČI",
  "TRAČU",
  "TRAFA",
  "TRAFE",
  "TRAFI",
  "TRAFO",
  "TRAFU",
  "TRAJA",
  "TRAJN",
  "TRAKT",
  "TRAKU",
  "TRALA",
  "TRAMA",
  "TRAME",
  "TRAMI",
  "TRAMU",
  "TRANS",
  "TRANŠ",
  "TRAPA",
  "TRAPE",
  "TRAPI",
  "TRAPO",
  "TRAPU",
  "TRASA",
  "TRASE",
  "TRASI",
  "TRASO",
  "TRATA",
  "TRATE",
  "TRATI",
  "TRATO",
  "TRAVA",
  "TRAVE",
  "TRAVI",
  "TRAVM",
  "TRAVO",
  "TRČEN",
  "TRČIL",
  "TRČIM",
  "TRČIŠ",
  "TRČIT",
  "TRDEČ",
  "TRDEL",
  "TRDEM",
  "TRDEN",
  "TRDET",
  "TRDIH",
  "TRDIK",
  "TRDIL",
  "TRDIM",
  "TRDIN",
  "TRDIŠ",
  "TRDIT",
  "TRDNA",
  "TRDNE",
  "TRDNI",
  "TRDNO",
  "TRDOT",
  "TREBA",
  "TREBE",
  "TREBI",
  "TREFA",
  "TREJO",
  "TREMA",
  "TREME",
  "TREMI",
  "TREMO",
  "TRENA",
  "TREND",
  "TRENE",
  "TRENI",
  "TRENJ",
  "TREPK",
  "TRESE",
  "TRESI",
  "TRESK",
  "TREST",
  "TRETA",
  "TRETE",
  "TRETI",
  "TREVA",
  "TRGAČ",
  "TRGAJ",
  "TRGAL",
  "TRGAM",
  "TRGAN",
  "TRGAŠ",
  "TRGAT",
  "TRGCA",
  "TRGCE",
  "TRGCI",
  "TRGCU",
  "TRGEC",
  "TRGIH",
  "TRGOM",
  "TRGOV",
  "TRGUJ",
  "TRHEL",
  "TRHLA",
  "TRHLE",
  "TRHLI",
  "TRHLO",
  "TRIAD",
  "TRIAK",
  "TRIAS",
  "TRIAŽ",
  "TRIER",
  "TRIIH",
  "TRIJE",
  "TRIKA",
  "TRIKE",
  "TRIKI",
  "TRIKO",
  "TRIKU",
  "TRIMA",
  "TRIMO",
  "TRIMU",
  "TRIOD",
  "TRIOK",
  "TRIOL",
  "TRIOM",
  "TRIOV",
  "TRIOZ",
  "TRIPA",
  "TRIPS",
  "TRIPU",
  "TRISA",
  "TRISE",
  "TRISI",
  "TRISU",
  "TRITA",
  "TRITE",
  "TRIVA",
  "TRJEN",
  "TRKAJ",
  "TRKAL",
  "TRKAM",
  "TRKAŠ",
  "TRKAT",
  "TRKIH",
  "TRKNE",
  "TRKNI",
  "TRKOM",
  "TRKOV",
  "TRLIC",
  "TRLJA",
  "TRLJE",
  "TRLJI",
  "TRLJO",
  "TRMAH",
  "TRMAM",
  "TRMIC",
  "TRNAČ",
  "TRNAT",
  "TRNAV",
  "TRNEK",
  "TRNEŽ",
  "TRNIC",
  "TRNIH",
  "TRNJA",
  "TRNJE",
  "TRNJU",
  "TRNKA",
  "TRNKE",
  "TRNKI",
  "TRNKU",
  "TRNOM",
  "TRNOV",
  "TROBE",
  "TROBI",
  "TROFI",
  "TROGA",
  "TROHA",
  "TROHE",
  "TROHI",
  "TROHO",
  "TROJA",
  "TROJI",
  "TROJK",
  "TROJO",
  "TROLA",
  "TROLE",
  "TROLI",
  "TROLO",
  "TROLU",
  "TROMB",
  "TRONA",
  "TRONE",
  "TRONI",
  "TRONU",
  "TROPA",
  "TROPE",
  "TROPI",
  "TROPU",
  "TROSA",
  "TROSE",
  "TROSI",
  "TROSU",
  "TROŠI",
  "TROŠT",
  "TROTA",
  "TROTE",
  "TROTI",
  "TROTU",
  "TRPAJ",
  "TRPAL",
  "TRPAM",
  "TRPAŠ",
  "TRPAT",
  "TRPEČ",
  "TRPEK",
  "TRPEL",
  "TRPEN",
  "TRPET",
  "TRPEŽ",
  "TRPIM",
  "TRPIN",
  "TRPIŠ",
  "TRPKA",
  "TRPKE",
  "TRPKI",
  "TRPKO",
  "TRPNA",
  "TRPNE",
  "TRPNI",
  "TRPNO",
  "TRSEN",
  "TRSIH",
  "TRSJA",
  "TRSJE",
  "TRSJU",
  "TRSKA",
  "TRSKE",
  "TRSKI",
  "TRSKO",
  "TRSNA",
  "TRSNE",
  "TRSNI",
  "TRSNO",
  "TRSOM",
  "TRSOV",
  "TRSTA",
  "TRSTE",
  "TRSTI",
  "TRSTU",
  "TRŠAT",
  "TRŠEM",
  "TRŠIH",
  "TRŠIM",
  "TRŠKA",
  "TRŠKE",
  "TRŠKI",
  "TRŠKO",
  "TRTAH",
  "TRTAM",
  "TRTEN",
  "TRTIC",
  "TRTNA",
  "TRTNE",
  "TRTNI",
  "TRTNO",
  "TRUDA",
  "TRUDI",
  "TRUDU",
  "TRUGA",
  "TRUGE",
  "TRUGI",
  "TRUGO",
  "TRULA",
  "TRULE",
  "TRULI",
  "TRULO",
  "TRUMA",
  "TRUME",
  "TRUMI",
  "TRUMO",
  "TRUPA",
  "TRUPE",
  "TRUPI",
  "TRUPU",
  "TRUST",
  "TRUŠČ",
  "TRZAČ",
  "TRZAJ",
  "TRZAL",
  "TRZAM",
  "TRZAŠ",
  "TRZAT",
  "TRZAV",
  "TRZNE",
  "TRZNI",
  "TRŽAN",
  "TRŽEČ",
  "TRŽEM",
  "TRŽEN",
  "TRŽEŠ",
  "TRŽIL",
  "TRŽIM",
  "TRŽIŠ",
  "TRŽIT",
  "TRŽNA",
  "TRŽNE",
  "TRŽNI",
  "TRŽNO",
  "TUBAH",
  "TUBAM",
  "TUBIT",
  "TUBUL",
  "TUBUS",
  "TUFIT",
  "TUFOM",
  "TUGAH",
  "TUGAM",
  "TUHEN",
  "TUHNA",
  "TUHNE",
  "TUHNI",
  "TUHNO",
  "TUHTA",
  "TUIJA",
  "TUIJE",
  "TUIJI",
  "TUIJU",
  "TUJAH",
  "TUJAM",
  "TUJCA",
  "TUJCE",
  "TUJCI",
  "TUJCU",
  "TUJČI",
  "TUJEC",
  "TUJEK",
  "TUJEM",
  "TUJIH",
  "TUJIM",
  "TUJIN",
  "TUJKA",
  "TUJKE",
  "TUJKI",
  "TUJKO",
  "TUJKU",
  "TUKAJ",
  "TUKAN",
  "TUKEL",
  "TUKLA",
  "TUKLE",
  "TUKLI",
  "TUKLO",
  "TULAR",
  "TULCA",
  "TULCE",
  "TULCI",
  "TULCU",
  "TULEC",
  "TULEČ",
  "TULEŽ",
  "TULIH",
  "TULIJ",
  "TULIL",
  "TULIM",
  "TULIŠ",
  "TULIT",
  "TULKA",
  "TULKE",
  "TULKI",
  "TULKO",
  "TULOM",
  "TULOV",
  "TUMBA",
  "TUMOR",
  "TUMUL",
  "TUNAH",
  "TUNAM",
  "TUNEL",
  "TUNIH",
  "TUNIK",
  "TUNKA",
  "TUNOM",
  "TUNOV",
  "TUPAJ",
  "TUPEM",
  "TUPIH",
  "TUPIM",
  "TURAH",
  "TURAK",
  "TURAM",
  "TURAV",
  "TURBA",
  "TURBE",
  "TURBI",
  "TURBO",
  "TURBU",
  "TUREK",
  "TUREN",
  "TURIC",
  "TURIH",
  "TURKA",
  "TURKE",
  "TURKI",
  "TURKU",
  "TURNA",
  "TURNE",
  "TURNI",
  "TURNO",
  "TURNU",
  "TUROM",
  "TUROV",
  "TUŠEM",
  "TUŠEV",
  "TUŠIH",
  "TUTOR",
  "TVEGA",
  "TVEST",
  "TVEZE",
  "TVEZI",
  "TVIDA",
  "TVIDU",
  "TVITA",
  "TVITE",
  "TVITI",
  "TVITU",
  "TVOJA",
  "TVOJE",
  "TVOJI",
  "TVOJO",
  "TVORA",
  "TVORB",
  "TVORE",
  "TVORI",
  "TVORK",
  "TVORU",
  "TVRDK",
  "UAKAR",
  "UBADA",
  "UBERE",
  "UBERI",
  "UBEŽE",
  "UBEŽI",
  "UBIJA",
  "UBIJE",
  "UBILA",
  "UBILE",
  "UBILI",
  "UBILO",
  "UBIRA",
  "UBITA",
  "UBITE",
  "UBITI",
  "UBITO",
  "UBOGA",
  "UBOGE",
  "UBOGI",
  "UBOGO",
  "UBOJA",
  "UBOJE",
  "UBOJI",
  "UBOJU",
  "UBRAL",
  "UBRAN",
  "UBRAT",
  "UCVRE",
  "UCVRI",
  "UCVRL",
  "UČAKA",
  "UČEČA",
  "UČEČE",
  "UČEČI",
  "UČEČO",
  "UČEMA",
  "UČENA",
  "UČENE",
  "UČENI",
  "UČENJ",
  "UČENK",
  "UČENO",
  "UČIJO",
  "UČILA",
  "UČILE",
  "UČILI",
  "UČILN",
  "UČILO",
  "UČILU",
  "UČIMO",
  "UČITA",
  "UČITE",
  "UČITI",
  "UČIVA",
  "UČNEM",
  "UČNIH",
  "UČNIK",
  "UČNIM",
  "UČNIN",
  "UDARA",
  "UDARE",
  "UDARI",
  "UDARU",
  "UDAVA",
  "UDAVE",
  "UDAVI",
  "UDAVU",
  "UDBAŠ",
  "UDELA",
  "UDERE",
  "UDIRA",
  "UDOMA",
  "UDOMI",
  "UDORA",
  "UDORE",
  "UDORI",
  "UDORU",
  "UDREM",
  "UDREŠ",
  "UDRET",
  "UDRLA",
  "UDRLE",
  "UDRLI",
  "UDRLO",
  "UDRTA",
  "UDRTE",
  "UDRTI",
  "UDRTO",
  "UDUŠI",
  "UFSKA",
  "UFSKE",
  "UFSKI",
  "UFSKO",
  "UGAJA",
  "UGANE",
  "UGANI",
  "UGANK",
  "UGASI",
  "UGAŠA",
  "UGIBA",
  "UGLED",
  "UGLEJ",
  "UGNAL",
  "UGNAN",
  "UGNAT",
  "UGODI",
  "UGOJI",
  "UGREZ",
  "UGRIZ",
  "UHAČA",
  "UHAČE",
  "UHAČI",
  "UHAČU",
  "UHAJA",
  "UHANA",
  "UHANE",
  "UHANI",
  "UHANU",
  "UHATA",
  "UHATE",
  "UHATI",
  "UHATO",
  "UHELJ",
  "UHITI",
  "UHLJA",
  "UHLJE",
  "UHLJI",
  "UHLJU",
  "UHODI",
  "UIDEM",
  "UIDEŠ",
  "UIGRA",
  "UJAHA",
  "UJAME",
  "UJAŠE",
  "UJAŠI",
  "UJCEM",
  "UJCEV",
  "UJCIH",
  "UJČKA",
  "UJEBA",
  "UJEDA",
  "UJEDE",
  "UJEDI",
  "UJEDO",
  "UJEJO",
  "UJELA",
  "UJELE",
  "UJELI",
  "UJELO",
  "UJEMA",
  "UJEME",
  "UJEMI",
  "UJEMO",
  "UJEMU",
  "UJEST",
  "UJETA",
  "UJETE",
  "UJETI",
  "UJETO",
  "UJEVA",
  "UJEZI",
  "UJMAH",
  "UJMAM",
  "UJNAH",
  "UJNAM",
  "UKAJE",
  "UKAJO",
  "UKALA",
  "UKALE",
  "UKALI",
  "UKALO",
  "UKAMO",
  "UKANA",
  "UKANE",
  "UKANI",
  "UKANJ",
  "UKANO",
  "UKATA",
  "UKATE",
  "UKATI",
  "UKAVA",
  "UKAZA",
  "UKAZE",
  "UKAZI",
  "UKAZU",
  "UKAŽE",
  "UKAŽI",
  "UKINE",
  "UKINI",
  "UKLEL",
  "UKLET",
  "UKLON",
  "UKORA",
  "UKORE",
  "UKORI",
  "UKORU",
  "UKOSI",
  "UKREP",
  "UKUHA",
  "ULANA",
  "ULANE",
  "ULANI",
  "ULANK",
  "ULANU",
  "ULEČI",
  "ULEKS",
  "ULEMA",
  "ULEME",
  "ULEMI",
  "ULEMO",
  "ULEZI",
  "ULEŽE",
  "ULEŽI",
  "ULICA",
  "ULICE",
  "ULICI",
  "ULICO",
  "ULIJE",
  "ULIJO",
  "ULILA",
  "ULILE",
  "ULILI",
  "ULILO",
  "ULIMO",
  "ULITA",
  "ULITE",
  "ULITI",
  "ULITO",
  "ULIVA",
  "ULJEM",
  "ULJES",
  "ULJEV",
  "ULJIH",
  "ULKUS",
  "ULOMI",
  "ULOVA",
  "ULOVE",
  "ULOVI",
  "ULOVU",
  "ULTRA",
  "UMAMI",
  "UMAŽE",
  "UMAŽI",
  "UMBER",
  "UMBRA",
  "UMBRE",
  "UMBRI",
  "UMBRO",
  "UMEDI",
  "UMEJO",
  "UMELA",
  "UMELE",
  "UMELI",
  "UMELO",
  "UMEMO",
  "UMERI",
  "UMESI",
  "UMEŠA",
  "UMETA",
  "UMETE",
  "UMETI",
  "UMETO",
  "UMEVA",
  "UMIČE",
  "UMIJE",
  "UMIKA",
  "UMIKE",
  "UMIKI",
  "UMIKU",
  "UMILA",
  "UMILE",
  "UMILI",
  "UMILO",
  "UMIMO",
  "UMIRA",
  "UMIRI",
  "UMITA",
  "UMITE",
  "UMITI",
  "UMITO",
  "UMIVA",
  "UMNEM",
  "UMNIH",
  "UMNIM",
  "UMOMA",
  "UMORA",
  "UMORE",
  "UMORI",
  "UMORU",
  "UMREM",
  "UMREŠ",
  "UMRET",
  "UMRJE",
  "UMRLA",
  "UMRLE",
  "UMRLI",
  "UMRLO",
  "UMSKA",
  "UMSKE",
  "UMSKI",
  "UMSKO",
  "UMUJE",
  "UNČAH",
  "UNČAM",
  "UNESE",
  "UNESI",
  "UNEST",
  "UNIAT",
  "UNICA",
  "UNICE",
  "UNICI",
  "UNICO",
  "UNIČI",
  "UNIJA",
  "UNIJE",
  "UNIJI",
  "UNIJO",
  "UNIMA",
  "UNIMI",
  "UNSKA",
  "UNSKE",
  "UNSKI",
  "UNSKO",
  "UNŠKA",
  "UNŠKE",
  "UNŠKI",
  "UNŠKO",
  "UPADA",
  "UPADE",
  "UPADI",
  "UPADU",
  "UPAJE",
  "UPAJO",
  "UPALA",
  "UPALE",
  "UPALI",
  "UPALO",
  "UPAMO",
  "UPANJ",
  "UPARI",
  "UPAST",
  "UPATA",
  "UPATE",
  "UPATI",
  "UPAVA",
  "UPEHA",
  "UPELA",
  "UPELE",
  "UPELI",
  "UPELO",
  "UPENI",
  "UPERE",
  "UPERI",
  "UPETA",
  "UPETE",
  "UPETI",
  "UPETO",
  "UPEVA",
  "UPIRA",
  "UPNIC",
  "UPNIK",
  "UPOJE",
  "UPOMA",
  "UPORA",
  "UPORE",
  "UPORI",
  "UPORU",
  "UPOVE",
  "UPRAL",
  "UPRAT",
  "UPRAV",
  "UPREM",
  "UPREŠ",
  "UPRET",
  "UPRLA",
  "UPRLE",
  "UPRLI",
  "UPRLO",
  "UPRTA",
  "UPRTE",
  "UPRTI",
  "UPRTO",
  "URADA",
  "URADE",
  "URADI",
  "URADU",
  "URALA",
  "URALU",
  "URAMA",
  "URAMI",
  "URANA",
  "URANU",
  "URARK",
  "URARN",
  "URATA",
  "URATE",
  "URATI",
  "URATU",
  "URBAN",
  "URBAR",
  "URECI",
  "UREČE",
  "UREČI",
  "UREDB",
  "UREDI",
  "UREJA",
  "UREJE",
  "UREJI",
  "UREJO",
  "UREZA",
  "UREZE",
  "UREZI",
  "UREZU",
  "UREŽE",
  "UREŽI",
  "URHIH",
  "URHOM",
  "URHOV",
  "URICA",
  "URICE",
  "URICI",
  "URICO",
  "URIJO",
  "URILA",
  "URILE",
  "URILI",
  "URILO",
  "URIMO",
  "URINA",
  "URINU",
  "URITA",
  "URITE",
  "URITI",
  "URIVA",
  "URJEN",
  "URNAH",
  "URNAM",
  "URNEM",
  "URNIH",
  "URNIK",
  "URNIM",
  "UROČI",
  "UROKA",
  "UROKE",
  "UROKI",
  "UROKU",
  "URTIK",
  "URZON",
  "URŽAH",
  "USADA",
  "USADE",
  "USADI",
  "USADU",
  "USAJA",
  "USCAL",
  "USCAN",
  "USCAT",
  "USEDA",
  "USEDE",
  "USEDI",
  "USEDU",
  "USEKA",
  "USEKE",
  "USEKI",
  "USEKU",
  "USEST",
  "USIHA",
  "USIPA",
  "USIRI",
  "USLUG",
  "USNJA",
  "USNJE",
  "USNJU",
  "USODA",
  "USODE",
  "USODI",
  "USODO",
  "USPEH",
  "USPEL",
  "USPEM",
  "USPEŠ",
  "USPET",
  "USRAL",
  "USRAN",
  "USRAT",
  "USTAŠ",
  "USTAV",
  "USTEC",
  "USTEČ",
  "USTEN",
  "USTEŽ",
  "USTIH",
  "USTIJ",
  "USTIL",
  "USTIM",
  "USTIŠ",
  "USTIT",
  "USTJA",
  "USTJE",
  "USTJI",
  "USTJU",
  "USTKA",
  "USTKI",
  "USTNA",
  "USTNE",
  "USTNI",
  "USTNO",
  "USTOM",
  "USUJE",
  "USULA",
  "USULE",
  "USULI",
  "USULO",
  "USUTA",
  "USUTE",
  "USUTI",
  "USUTO",
  "UŠATA",
  "UŠATE",
  "UŠATI",
  "UŠATO",
  "UŠČIJ",
  "UŠČIM",
  "UŠČIP",
  "UŠČIŠ",
  "UŠEMA",
  "UŠESA",
  "UŠESC",
  "UŠESI",
  "UŠESU",
  "UŠICA",
  "UŠICE",
  "UŠICI",
  "UŠICO",
  "UŠIJE",
  "UŠILA",
  "UŠILE",
  "UŠILI",
  "UŠILO",
  "UŠITI",
  "UŠIVA",
  "UŠIVE",
  "UŠIVI",
  "UŠIVK",
  "UŠIVO",
  "UŠKAH",
  "UŠKAM",
  "UŠNIK",
  "UŠTEJ",
  "UŠTEL",
  "UŠTET",
  "UTAHA",
  "UTAHU",
  "UTAJA",
  "UTAJE",
  "UTAJI",
  "UTAJO",
  "UTAMA",
  "UTAMI",
  "UTARE",
  "UTECI",
  "UTEČE",
  "UTEČI",
  "UTEHA",
  "UTEHE",
  "UTEHI",
  "UTEHO",
  "UTEKA",
  "UTEKE",
  "UTEKI",
  "UTEKU",
  "UTEŠI",
  "UTEŽI",
  "UTICA",
  "UTICE",
  "UTICI",
  "UTICO",
  "UTIRA",
  "UTIRI",
  "UTIŠA",
  "UTONE",
  "UTONI",
  "UTOPA",
  "UTOPE",
  "UTOPI",
  "UTOPU",
  "UTORA",
  "UTORE",
  "UTORI",
  "UTORU",
  "UTRDB",
  "UTRDI",
  "UTREM",
  "UTREŠ",
  "UTRET",
  "UTRGA",
  "UTRGE",
  "UTRGI",
  "UTRGU",
  "UTRIP",
  "UTRLA",
  "UTRLE",
  "UTRLI",
  "UTRLO",
  "UTRNE",
  "UTRNI",
  "UTRPI",
  "UTRTA",
  "UTRTE",
  "UTRTI",
  "UTRTO",
  "UTRŽE",
  "UTURI",
  "UTVAH",
  "UTVAM",
  "UTVAR",
  "UVAJA",
  "UVALA",
  "UVALE",
  "UVALI",
  "UVALO",
  "UVARI",
  "UVAŽA",
  "UVEDB",
  "UVEDE",
  "UVEDI",
  "UVELA",
  "UVELE",
  "UVELI",
  "UVELO",
  "UVENI",
  "UVEST",
  "UVEZE",
  "UVEZI",
  "UVIDA",
  "UVIDE",
  "UVIDI",
  "UVIDU",
  "UVIHA",
  "UVIJE",
  "UVILA",
  "UVILE",
  "UVILI",
  "UVILO",
  "UVITA",
  "UVITE",
  "UVITI",
  "UVITO",
  "UVLEČ",
  "UVLEK",
  "UVODA",
  "UVODE",
  "UVODI",
  "UVODU",
  "UVOJA",
  "UVOJE",
  "UVOJI",
  "UVOJU",
  "UVOZA",
  "UVOZE",
  "UVOZI",
  "UVOZU",
  "UVRTA",
  "UVULA",
  "UVULE",
  "UVULI",
  "UVULO",
  "UZANC",
  "UZDAH",
  "UZDAJ",
  "UZDAL",
  "UZDAM",
  "UZDAN",
  "UZDAŠ",
  "UZDAT",
  "UZIJA",
  "UZIJE",
  "UZIJI",
  "UZIJU",
  "UZIRA",
  "UZREM",
  "UZREŠ",
  "UZRET",
  "UZRLA",
  "UZRLE",
  "UZRLI",
  "UZRLO",
  "UZRTA",
  "UZRTE",
  "UZRTI",
  "UZRTO",
  "UZUSA",
  "UZUSE",
  "UZUSI",
  "UZUSU",
  "UŽALI",
  "UŽARI",
  "UŽEJA",
  "UŽENE",
  "UŽENI",
  "UŽGAL",
  "UŽGAT",
  "UŽGEM",
  "UŽGEŠ",
  "UŽIJE",
  "UŽILA",
  "UŽILE",
  "UŽILI",
  "UŽILO",
  "UŽITA",
  "UŽITE",
  "UŽITI",
  "UŽITO",
  "UŽIVA",
  "UŽUGA",
  "VABAH",
  "VABAM",
  "VABEČ",
  "VABEN",
  "VABIL",
  "VABIM",
  "VABIŠ",
  "VABIT",
  "VABNA",
  "VABNE",
  "VABNI",
  "VABNO",
  "VADBA",
  "VADBE",
  "VADBI",
  "VADBO",
  "VADEČ",
  "VADIL",
  "VADIM",
  "VADIŠ",
  "VADIT",
  "VAGAH",
  "VAGAJ",
  "VAGAL",
  "VAGAM",
  "VAGAN",
  "VAGAŠ",
  "VAGIN",
  "VAGON",
  "VAGUS",
  "VAHTA",
  "VAHTE",
  "VAHTI",
  "VAHTO",
  "VAJAH",
  "VAJAM",
  "VAJEN",
  "VAJET",
  "VAJIN",
  "VALAT",
  "VALEČ",
  "VALET",
  "VALIH",
  "VALIL",
  "VALIM",
  "VALIN",
  "VALIŠ",
  "VALIT",
  "VALJA",
  "VALJE",
  "VALJI",
  "VALJU",
  "VALOM",
  "VALOV",
  "VALUJ",
  "VALUK",
  "VALUT",
  "VAMPA",
  "VAMPE",
  "VAMPI",
  "VAMPU",
  "VANEŽ",
  "VANGA",
  "VANGE",
  "VANGI",
  "VANGO",
  "VARAJ",
  "VARAL",
  "VARAM",
  "VARAN",
  "VARAŠ",
  "VARAT",
  "VARAV",
  "VARDA",
  "VARDE",
  "VARDI",
  "VARDO",
  "VAREČ",
  "VAREK",
  "VAREN",
  "VARIC",
  "VARIH",
  "VARIL",
  "VARIM",
  "VARIŠ",
  "VARIT",
  "VARIV",
  "VARKA",
  "VARKE",
  "VARKI",
  "VARKU",
  "VARNA",
  "VARNE",
  "VARNI",
  "VARNO",
  "VAROA",
  "VAROE",
  "VAROI",
  "VAROJ",
  "VAROM",
  "VAROO",
  "VAROV",
  "VAROZ",
  "VARPA",
  "VARPE",
  "VARPI",
  "VARPO",
  "VARUH",
  "VARUJ",
  "VASEH",
  "VASEM",
  "VASIC",
  "VASJO",
  "VASMI",
  "VASUJ",
  "VAŠEM",
  "VAŠIH",
  "VAŠIM",
  "VAŠKA",
  "VAŠKE",
  "VAŠKI",
  "VAŠKO",
  "VATAH",
  "VATAM",
  "VATEL",
  "VATEN",
  "VATIH",
  "VATKA",
  "VATKE",
  "VATKI",
  "VATKO",
  "VATLA",
  "VATLE",
  "VATLI",
  "VATLU",
  "VATNA",
  "VATNE",
  "VATNI",
  "VATNO",
  "VATOM",
  "VATOV",
  "VAZAH",
  "VAZAL",
  "VAZAM",
  "VAZEN",
  "VAZIC",
  "VAZNA",
  "VAZNE",
  "VAZNI",
  "VAZNO",
  "VAŽEČ",
  "VAŽEN",
  "VAŽIČ",
  "VAŽIL",
  "VAŽIM",
  "VAŽIŠ",
  "VAŽNA",
  "VAŽNE",
  "VAŽNI",
  "VAŽNO",
  "VBADA",
  "VBIJA",
  "VBIJE",
  "VBILA",
  "VBILE",
  "VBILI",
  "VBILO",
  "VBITA",
  "VBITE",
  "VBITI",
  "VBITO",
  "VBOČI",
  "VBODA",
  "VBODE",
  "VBODI",
  "VBODU",
  "VBOST",
  "VCEPI",
  "VČASI",
  "VČERI",
  "VČIRI",
  "VČRTA",
  "VDABA",
  "VDABE",
  "VDABI",
  "VDABU",
  "VDAJA",
  "VDAJE",
  "VDAJI",
  "VDAJO",
  "VDALA",
  "VDALE",
  "VDALI",
  "VDALO",
  "VDAMO",
  "VDANA",
  "VDANE",
  "VDANI",
  "VDANO",
  "VDATA",
  "VDATE",
  "VDATI",
  "VDAVA",
  "VDELA",
  "VDELE",
  "VDELI",
  "VDELO",
  "VDENE",
  "VDENI",
  "VDERE",
  "VDETA",
  "VDETE",
  "VDETI",
  "VDETO",
  "VDEVA",
  "VDIHA",
  "VDIHE",
  "VDIHI",
  "VDIHU",
  "VDIRA",
  "VDORA",
  "VDORE",
  "VDORI",
  "VDORU",
  "VDOVA",
  "VDOVE",
  "VDOVI",
  "VDOVO",
  "VDREM",
  "VDREŠ",
  "VDRET",
  "VDRLA",
  "VDRLE",
  "VDRLI",
  "VDRLO",
  "VDRTA",
  "VDRTE",
  "VDRTI",
  "VDRTO",
  "VEBER",
  "VEBRA",
  "VEBRE",
  "VEBRI",
  "VEBRU",
  "VEČAJ",
  "VEČAL",
  "VEČAM",
  "VEČAŠ",
  "VEČAT",
  "VEČEN",
  "VEČER",
  "VEČIM",
  "VEČIN",
  "VEČIŠ",
  "VEČJA",
  "VEČJE",
  "VEČJI",
  "VEČJO",
  "VEČNA",
  "VEČNE",
  "VEČNI",
  "VEČNO",
  "VEDAH",
  "VEDAM",
  "VEDEL",
  "VEDEM",
  "VEDER",
  "VEDEŠ",
  "VEDET",
  "VEDEŽ",
  "VEDLA",
  "VEDLE",
  "VEDLI",
  "VEDLO",
  "VEDNO",
  "VEDOČ",
  "VEDRA",
  "VEDRC",
  "VEDRE",
  "VEDRI",
  "VEDRN",
  "VEDRO",
  "VEDRU",
  "VEDUT",
  "VEDVE",
  "VEGAN",
  "VEGAV",
  "VEHAH",
  "VEHAM",
  "VEJAH",
  "VEJAJ",
  "VEJAL",
  "VEJAM",
  "VEJAŠ",
  "VEJAT",
  "VEJEČ",
  "VEJEM",
  "VEJEN",
  "VEJEŠ",
  "VEJIC",
  "VEJIL",
  "VEJIM",
  "VEJIŠ",
  "VEJIT",
  "VEJMO",
  "VEJNA",
  "VEJNE",
  "VEJNI",
  "VEJNO",
  "VEJOČ",
  "VEJPA",
  "VEJPE",
  "VEJPI",
  "VEJPU",
  "VEJTA",
  "VEJTE",
  "VEJVA",
  "VEKAH",
  "VEKAJ",
  "VEKAL",
  "VEKAM",
  "VEKAŠ",
  "VEKAT",
  "VEKIH",
  "VEKOM",
  "VEKOV",
  "VELAR",
  "VELBA",
  "VELBE",
  "VELBI",
  "VELBU",
  "VELEČ",
  "VELEL",
  "VELES",
  "VELET",
  "VELIK",
  "VELIM",
  "VELIŠ",
  "VELJA",
  "VELUR",
  "VENAH",
  "VENAM",
  "VENCA",
  "VENCE",
  "VENCI",
  "VENCU",
  "VENEC",
  "VENEČ",
  "VENEL",
  "VENEM",
  "VENEŠ",
  "VENET",
  "VENGE",
  "VENIL",
  "VENIM",
  "VENIŠ",
  "VENIT",
  "VENUL",
  "VEPER",
  "VEPRA",
  "VEPRE",
  "VEPRI",
  "VEPRU",
  "VERAH",
  "VERAM",
  "VERBA",
  "VEREN",
  "VERIG",
  "VERNA",
  "VERNE",
  "VERNI",
  "VERNO",
  "VERUJ",
  "VERZA",
  "VERZE",
  "VERZI",
  "VERZU",
  "VESAH",
  "VESAM",
  "VESEL",
  "VESIN",
  "VESLA",
  "VESLI",
  "VESLO",
  "VESLU",
  "VESPA",
  "VESPE",
  "VESPI",
  "VESPO",
  "VESTA",
  "VESTE",
  "VESTI",
  "VEŠAL",
  "VEŠCA",
  "VEŠCE",
  "VEŠCI",
  "VEŠCU",
  "VEŠČA",
  "VEŠČE",
  "VEŠČI",
  "VEŠČO",
  "VEŠČU",
  "VEŠEC",
  "VEŠKA",
  "VEŠKE",
  "VEŠKI",
  "VEŠKO",
  "VETER",
  "VETIH",
  "VETIJ",
  "VETJA",
  "VETJE",
  "VETJI",
  "VETJU",
  "VETOM",
  "VETOV",
  "VETRA",
  "VETRC",
  "VETRN",
  "VETRU",
  "VEZAČ",
  "VEZAH",
  "VEZAJ",
  "VEZAL",
  "VEZAM",
  "VEZAN",
  "VEZAT",
  "VEZAV",
  "VEZEH",
  "VEZEL",
  "VEZEM",
  "VEZEN",
  "VEZEŠ",
  "VEZIC",
  "VEZIH",
  "VEZIJ",
  "VEZIR",
  "VEZIV",
  "VEZJA",
  "VEZJE",
  "VEZJI",
  "VEZJO",
  "VEZJU",
  "VEZLA",
  "VEZLE",
  "VEZLI",
  "VEZLO",
  "VEZMI",
  "VEZNA",
  "VEZNE",
  "VEZNI",
  "VEZNO",
  "VEZOČ",
  "VEZOM",
  "VEZOV",
  "VEZUV",
  "VEŽAH",
  "VEŽAM",
  "VEŽBA",
  "VEŽEM",
  "VEŽEN",
  "VEŽEŠ",
  "VEŽIC",
  "VEŽNA",
  "VEŽNE",
  "VEŽNI",
  "VEŽNO",
  "VEŽOČ",
  "VHODA",
  "VHODE",
  "VHODI",
  "VHODU",
  "VIBAH",
  "VIBAM",
  "VICAH",
  "VICAM",
  "VICEM",
  "VICEV",
  "VICIH",
  "VIDAJ",
  "VIDCA",
  "VIDCE",
  "VIDCI",
  "VIDCU",
  "VIDEA",
  "VIDEC",
  "VIDEČ",
  "VIDEE",
  "VIDEI",
  "VIDEL",
  "VIDEN",
  "VIDEO",
  "VIDER",
  "VIDET",
  "VIDEU",
  "VIDEZ",
  "VIDIK",
  "VIDIM",
  "VIDIŠ",
  "VIDNA",
  "VIDNE",
  "VIDNI",
  "VIDNO",
  "VIDOM",
  "VIDON",
  "VIDRA",
  "VIDRE",
  "VIDRI",
  "VIDRO",
  "VIDVA",
  "VIGEN",
  "VIGNA",
  "VIGNE",
  "VIGNI",
  "VIGNO",
  "VIHAJ",
  "VIHAL",
  "VIHAM",
  "VIHAR",
  "VIHAŠ",
  "VIHAT",
  "VIHER",
  "VIHRA",
  "VIHRE",
  "VIHRI",
  "VIHRO",
  "VIHRU",
  "VIHTI",
  "VIJAK",
  "VIJEM",
  "VIJEŠ",
  "VIJMO",
  "VIJOČ",
  "VIJTA",
  "VIJTE",
  "VIJUG",
  "VIJVA",
  "VIKAJ",
  "VIKAL",
  "VIKAM",
  "VIKAR",
  "VIKAŠ",
  "VIKAT",
  "VIKOM",
  "VILAH",
  "VILAM",
  "VILIC",
  "VILIN",
  "VILLA",
  "VILLE",
  "VILLI",
  "VILLO",
  "VIMEN",
  "VINAR",
  "VINCA",
  "VINCE",
  "VINCI",
  "VINCU",
  "VINIH",
  "VINIK",
  "VINIL",
  "VINOM",
  "VINTA",
  "VINTE",
  "VINTI",
  "VINTO",
  "VIOLA",
  "VIOLE",
  "VIOLI",
  "VIOLO",
  "VIRIH",
  "VIROM",
  "VIROV",
  "VIROZ",
  "VIRUS",
  "VISEČ",
  "VISEL",
  "VISET",
  "VISIM",
  "VISIŠ",
  "VISKI",
  "VISOK",
  "VISTA",
  "VISTU",
  "VIŠAJ",
  "VIŠAL",
  "VIŠAM",
  "VIŠAŠ",
  "VIŠAT",
  "VIŠAV",
  "VIŠEK",
  "VIŠEM",
  "VIŠEŠ",
  "VIŠIN",
  "VIŠJA",
  "VIŠJE",
  "VIŠJI",
  "VIŠJO",
  "VIŠKA",
  "VIŠKE",
  "VIŠKI",
  "VIŠKO",
  "VIŠKU",
  "VIŠOČ",
  "VITEK",
  "VITEL",
  "VITEM",
  "VITER",
  "VITEZ",
  "VITIC",
  "VITIH",
  "VITIJ",
  "VITIM",
  "VITJA",
  "VITJE",
  "VITJI",
  "VITJU",
  "VITKA",
  "VITKE",
  "VITKI",
  "VITKO",
  "VITLA",
  "VITLE",
  "VITLI",
  "VITLO",
  "VITLU",
  "VITRA",
  "VITRE",
  "VITRI",
  "VITRO",
  "VIZAH",
  "VIZAM",
  "VIZIJ",
  "VIZIR",
  "VIZIT",
  "VIZUM",
  "VIZUR",
  "VIŽAH",
  "VIŽAM",
  "VJEDO",
  "VJEMO",
  "VJEST",
  "VJEVA",
  "VKLOP",
  "VKOPA",
  "VKOPE",
  "VKOPI",
  "VKOPU",
  "VKRAJ",
  "VKRCA",
  "VKUHA",
  "VKUJE",
  "VLAČA",
  "VLAČE",
  "VLAČI",
  "VLAČO",
  "VLADA",
  "VLADE",
  "VLADI",
  "VLADO",
  "VLAGA",
  "VLAGE",
  "VLAGI",
  "VLAGO",
  "VLAKA",
  "VLAKE",
  "VLAKI",
  "VLAKO",
  "VLAKU",
  "VLAŽI",
  "VLECI",
  "VLEČE",
  "VLEČI",
  "VLEČK",
  "VLEKA",
  "VLEKE",
  "VLEKI",
  "VLEKO",
  "VLEKU",
  "VLEPI",
  "VLIJE",
  "VLIKA",
  "VLILA",
  "VLILE",
  "VLILI",
  "VLILO",
  "VLITA",
  "VLITE",
  "VLITI",
  "VLITO",
  "VLIVA",
  "VLIVE",
  "VLIVI",
  "VLIVU",
  "VLOGA",
  "VLOGE",
  "VLOGI",
  "VLOGO",
  "VLOGU",
  "VLOMA",
  "VLOME",
  "VLOMI",
  "VLOMU",
  "VLOŽI",
  "VMESI",
  "VMEŠA",
  "VNAME",
  "VNAŠA",
  "VNELA",
  "VNELE",
  "VNELI",
  "VNELO",
  "VNEMA",
  "VNEME",
  "VNEMI",
  "VNEMO",
  "VNESE",
  "VNESI",
  "VNEST",
  "VNETA",
  "VNETE",
  "VNETI",
  "VNETO",
  "VNOSA",
  "VNOSE",
  "VNOSI",
  "VNOSU",
  "VNUČK",
  "VNUKA",
  "VNUKE",
  "VNUKI",
  "VNUKU",
  "VOBČE",
  "VODAH",
  "VODAM",
  "VODAR",
  "VODEB",
  "VODEČ",
  "VODEN",
  "VODIC",
  "VODIČ",
  "VODIH",
  "VODIJ",
  "VODIK",
  "VODIL",
  "VODIM",
  "VODIŠ",
  "VODIT",
  "VODJA",
  "VODJE",
  "VODJI",
  "VODJO",
  "VODKA",
  "VODKE",
  "VODKI",
  "VODKO",
  "VODNA",
  "VODNE",
  "VODNI",
  "VODNO",
  "VODOM",
  "VODOV",
  "VOGAL",
  "VOGEL",
  "VOGLA",
  "VOGLE",
  "VOGLI",
  "VOGLU",
  "VOHAJ",
  "VOHAL",
  "VOHAM",
  "VOHAŠ",
  "VOHAT",
  "VOHKA",
  "VOHOM",
  "VOHUN",
  "VOJAK",
  "VOJEN",
  "VOJNA",
  "VOJNE",
  "VOJNI",
  "VOJNO",
  "VOJSK",
  "VOJUJ",
  "VOKAL",
  "VOKIH",
  "VOKOM",
  "VOKOV",
  "VOLAN",
  "VOLAR",
  "VOLEČ",
  "VOLEJ",
  "VOLEK",
  "VOLHA",
  "VOLHE",
  "VOLHI",
  "VOLHU",
  "VOLIH",
  "VOLIL",
  "VOLIM",
  "VOLIŠ",
  "VOLIT",
  "VOLJA",
  "VOLJE",
  "VOLJI",
  "VOLJO",
  "VOLKA",
  "VOLKE",
  "VOLKI",
  "VOLKU",
  "VOLMI",
  "VOLNA",
  "VOLNE",
  "VOLNI",
  "VOLNO",
  "VOLOM",
  "VOLOV",
  "VOLTA",
  "VOLTE",
  "VOLTI",
  "VOLTU",
  "VOLUT",
  "VOLVA",
  "VOLVE",
  "VOLVI",
  "VOLVO",
  "VOLVU",
  "VOMER",
  "VONIH",
  "VONJA",
  "VONJE",
  "VONJI",
  "VONJU",
  "VONOM",
  "VONOV",
  "VOSAT",
  "VOSEK",
  "VOSIH",
  "VOSKA",
  "VOSKE",
  "VOSKI",
  "VOSKU",
  "VOSOM",
  "VOSOV",
  "VOŠČI",
  "VOŠKA",
  "VOŠKE",
  "VOŠKI",
  "VOŠKO",
  "VOTEK",
  "VOTEL",
  "VOTKA",
  "VOTKE",
  "VOTKI",
  "VOTKU",
  "VOTLA",
  "VOTLE",
  "VOTLI",
  "VOTLO",
  "VOZAČ",
  "VOZAR",
  "VOZEČ",
  "VOZEH",
  "VOZEK",
  "VOZEL",
  "VOZEM",
  "VOZEN",
  "VOZIL",
  "VOZIM",
  "VOZIŠ",
  "VOZIT",
  "VOZJE",
  "VOZKA",
  "VOZKE",
  "VOZKI",
  "VOZKU",
  "VOZLA",
  "VOZLE",
  "VOZLI",
  "VOZLU",
  "VOZMI",
  "VOZNA",
  "VOZNE",
  "VOZNI",
  "VOZNO",
  "VOZOM",
  "VOZOV",
  "VPADA",
  "VPADE",
  "VPADI",
  "VPADU",
  "VPAST",
  "VPELA",
  "VPELE",
  "VPELI",
  "VPELO",
  "VPETA",
  "VPETE",
  "VPETI",
  "VPETO",
  "VPIHA",
  "VPIHE",
  "VPIHI",
  "VPIHU",
  "VPIJA",
  "VPIJE",
  "VPILA",
  "VPILE",
  "VPILI",
  "VPILO",
  "VPISA",
  "VPISE",
  "VPISI",
  "VPISU",
  "VPIŠE",
  "VPIŠI",
  "VPITI",
  "VPLET",
  "VPLIV",
  "VPLUJ",
  "VPLUL",
  "VPLUT",
  "VPNEM",
  "VPNEŠ",
  "VPOJA",
  "VPOJE",
  "VPOJI",
  "VPOJU",
  "VPONA",
  "VPONE",
  "VPONI",
  "VPONK",
  "VPONO",
  "VPREČ",
  "VPREG",
  "VPREK",
  "VPUST",
  "VRAČA",
  "VRAČE",
  "VRAČI",
  "VRAČK",
  "VRAČU",
  "VRAGA",
  "VRAGE",
  "VRAGI",
  "VRAGU",
  "VRANA",
  "VRANE",
  "VRANI",
  "VRANK",
  "VRANO",
  "VRANU",
  "VRASE",
  "VRAST",
  "VRATA",
  "VRATC",
  "VRATI",
  "VRATU",
  "VRAŽA",
  "VRAŽE",
  "VRAŽI",
  "VRAŽO",
  "VRBAH",
  "VRBAM",
  "VRBAR",
  "VRBEN",
  "VRBIC",
  "VRBJA",
  "VRBJE",
  "VRBJI",
  "VRBJO",
  "VRBJU",
  "VRBNA",
  "VRBNE",
  "VRBNI",
  "VRBNO",
  "VRČEK",
  "VRČEM",
  "VRČEV",
  "VRČIC",
  "VRČIH",
  "VRČKA",
  "VRČKE",
  "VRČKI",
  "VRČKU",
  "VREČA",
  "VREČE",
  "VREČI",
  "VREČK",
  "VREČO",
  "VREJO",
  "VRELA",
  "VRELE",
  "VRELI",
  "VRELK",
  "VRELO",
  "VREME",
  "VREMO",
  "VRENJ",
  "VRESA",
  "VRESE",
  "VRESI",
  "VRESO",
  "VRESU",
  "VREŠČ",
  "VRETA",
  "VRETE",
  "VRETI",
  "VREVA",
  "VREZA",
  "VREZE",
  "VREZI",
  "VREZU",
  "VREŽA",
  "VREŽE",
  "VREŽI",
  "VREŽO",
  "VRGEL",
  "VRGLA",
  "VRGLE",
  "VRGLI",
  "VRGLO",
  "VRHAČ",
  "VRHAJ",
  "VRHAL",
  "VRHAM",
  "VRHAN",
  "VRHAŠ",
  "VRHAT",
  "VRHIH",
  "VRHOM",
  "VRHOV",
  "VRIJA",
  "VRIJE",
  "VRIJI",
  "VRIJU",
  "VRIMO",
  "VRINE",
  "VRINI",
  "VRISA",
  "VRISE",
  "VRISI",
  "VRISK",
  "VRISU",
  "VRIŠČ",
  "VRIŠE",
  "VRIŠI",
  "VRITA",
  "VRITE",
  "VRIVA",
  "VRLEM",
  "VRLIH",
  "VRLIM",
  "VRLIN",
  "VRNEM",
  "VRNEŠ",
  "VRNIL",
  "VRNIT",
  "VROČA",
  "VROČE",
  "VROČI",
  "VROČO",
  "VRSTA",
  "VRSTE",
  "VRSTI",
  "VRSTO",
  "VRŠAC",
  "VRŠAH",
  "VRŠAJ",
  "VRŠAL",
  "VRŠAM",
  "VRŠAT",
  "VRŠEČ",
  "VRŠEK",
  "VRŠEL",
  "VRŠEN",
  "VRŠET",
  "VRŠIČ",
  "VRŠIL",
  "VRŠIM",
  "VRŠIŠ",
  "VRŠIT",
  "VRŠKA",
  "VRŠKE",
  "VRŠKI",
  "VRŠKO",
  "VRŠKU",
  "VRŠNA",
  "VRŠNE",
  "VRŠNI",
  "VRŠNO",
  "VRTAČ",
  "VRTAJ",
  "VRTAL",
  "VRTAM",
  "VRTAN",
  "VRTAŠ",
  "VRTAT",
  "VRTAV",
  "VRTCA",
  "VRTCE",
  "VRTCI",
  "VRTCU",
  "VRTEC",
  "VRTEČ",
  "VRTEK",
  "VRTEL",
  "VRTEN",
  "VRTET",
  "VRTEŽ",
  "VRTIČ",
  "VRTIL",
  "VRTIM",
  "VRTIN",
  "VRTIŠ",
  "VRTKA",
  "VRTKE",
  "VRTKI",
  "VRTKU",
  "VRTNA",
  "VRTNE",
  "VRTNI",
  "VRTNO",
  "VRTOM",
  "VRTOV",
  "VRVAR",
  "VRVCA",
  "VRVCE",
  "VRVCI",
  "VRVCO",
  "VRVEČ",
  "VRVEH",
  "VRVEL",
  "VRVEM",
  "VRVEN",
  "VRVET",
  "VRVEŽ",
  "VRVIC",
  "VRVIM",
  "VRVIŠ",
  "VRVJA",
  "VRVJE",
  "VRVJO",
  "VRVJU",
  "VRVMI",
  "VRVNA",
  "VRVNE",
  "VRVNI",
  "VRVNO",
  "VRZEL",
  "VRŽEM",
  "VRŽEN",
  "VRŽEŠ",
  "VSADI",
  "VSAJA",
  "VSAKA",
  "VSAKE",
  "VSAKI",
  "VSAKO",
  "VSEGA",
  "VSEJE",
  "VSEKA",
  "VSEKE",
  "VSEKI",
  "VSEKU",
  "VSELI",
  "VSEMA",
  "VSEMI",
  "VSEMU",
  "VSESA",
  "VSILI",
  "VSIPA",
  "VSIPE",
  "VSIPI",
  "VSIPU",
  "VSKOK",
  "VSLED",
  "VSOTA",
  "VSOTE",
  "VSOTI",
  "VSOTO",
  "VSRČE",
  "VSRKA",
  "VSTAJ",
  "VSTAL",
  "VSTAT",
  "VSTAV",
  "VSTOP",
  "VSUJE",
  "VSULA",
  "VSULE",
  "VSULI",
  "VSULO",
  "VSUTA",
  "VSUTE",
  "VSUTI",
  "VSUTO",
  "VŠIJE",
  "VŠILA",
  "VŠILE",
  "VŠILI",
  "VŠILO",
  "VŠITA",
  "VŠITE",
  "VŠITI",
  "VŠITO",
  "VŠIVA",
  "VŠIVE",
  "VŠIVI",
  "VŠIVU",
  "VŠOLA",
  "VŠTEJ",
  "VŠTEL",
  "VŠTET",
  "VTALI",
  "VTARE",
  "VTECI",
  "VTEČE",
  "VTEČI",
  "VTEKA",
  "VTEPA",
  "VTEPE",
  "VTEPI",
  "VTIČA",
  "VTIČE",
  "VTIČI",
  "VTIČU",
  "VTIKA",
  "VTIKE",
  "VTIKI",
  "VTIKU",
  "VTIRA",
  "VTISA",
  "VTISE",
  "VTISI",
  "VTISK",
  "VTISU",
  "VTKAL",
  "VTKAM",
  "VTKAN",
  "VTKAŠ",
  "VTKAT",
  "VTKEM",
  "VTKEŠ",
  "VTOČI",
  "VTOKA",
  "VTOKE",
  "VTOKI",
  "VTOKU",
  "VTOLČ",
  "VTREM",
  "VTREŠ",
  "VTRET",
  "VTRLA",
  "VTRLE",
  "VTRLI",
  "VTRLO",
  "VTRTA",
  "VTRTE",
  "VTRTI",
  "VTRTO",
  "VULVA",
  "VULVE",
  "VULVI",
  "VULVO",
  "VZAME",
  "VZBUH",
  "VZDEL",
  "VZDET",
  "VZDIG",
  "VZDIH",
  "VZDUH",
  "VZELA",
  "VZELE",
  "VZELI",
  "VZELO",
  "VZEMI",
  "VZETA",
  "VZETE",
  "VZETI",
  "VZETO",
  "VZGIB",
  "VZGON",
  "VZHOD",
  "VZIDA",
  "VZIDE",
  "VZIDI",
  "VZITI",
  "VZKIP",
  "VZLET",
  "VZMAH",
  "VZMET",
  "VZMIK",
  "VZNAK",
  "VZNIK",
  "VZORA",
  "VZORE",
  "VZORI",
  "VZORU",
  "VZPEL",
  "VZPET",
  "VZPNE",
  "VZPNI",
  "VZPON",
  "VZPOR",
  "VZREJ",
  "VZROK",
  "VZŠEL",
  "VZŠLA",
  "VZŠLE",
  "VZŠLI",
  "VZŠLO",
  "VZVOD",
  "VŽGAL",
  "VŽGAN",
  "VŽGAT",
  "VŽGEM",
  "VŽGEŠ",
  "VŽIGA",
  "VŽIGE",
  "VŽIGI",
  "VŽIGU",
  "VŽIVI",
  "ZAARA",
  "ZABAV",
  "ZABEL",
  "ZABIJ",
  "ZABIL",
  "ZABIT",
  "ZABOD",
  "ZABOJ",
  "ZABUŠ",
  "ZAČEL",
  "ZAČET",
  "ZAČNE",
  "ZAČNI",
  "ZAČNO",
  "ZAČUJ",
  "ZAČUL",
  "ZAČUT",
  "ZADAH",
  "ZADAJ",
  "ZADAL",
  "ZADAM",
  "ZADAN",
  "ZADAŠ",
  "ZADAT",
  "ZADEK",
  "ZADEL",
  "ZADET",
  "ZADEV",
  "ZADKA",
  "ZADKE",
  "ZADKI",
  "ZADKU",
  "ZADRE",
  "ZADRG",
  "ZADRI",
  "ZADRL",
  "ZADRT",
  "ZAGAT",
  "ZAGIB",
  "ZAGON",
  "ZAHOD",
  "ZAIDE",
  "ZAIDI",
  "ZAITI",
  "ZAJCA",
  "ZAJCE",
  "ZAJCI",
  "ZAJCU",
  "ZAJEB",
  "ZAJEC",
  "ZAJED",
  "ZAJEJ",
  "ZAJEL",
  "ZAJEM",
  "ZAJER",
  "ZAJEŠ",
  "ZAJET",
  "ZAJKA",
  "ZAJKE",
  "ZAJKI",
  "ZAJKO",
  "ZAJLA",
  "ZAJLE",
  "ZAJLI",
  "ZAJLO",
  "ZAJMA",
  "ZAJME",
  "ZAJMI",
  "ZAJMU",
  "ZAKAJ",
  "ZAKOL",
  "ZAKON",
  "ZAKOP",
  "ZAKOV",
  "ZAKUH",
  "ZAKUJ",
  "ZAKUP",
  "ZALAZ",
  "ZALEČ",
  "ZALEG",
  "ZALEM",
  "ZALET",
  "ZALIH",
  "ZALIJ",
  "ZALIL",
  "ZALIM",
  "ZALIT",
  "ZALIV",
  "ZALOG",
  "ZALOM",
  "ZAMAH",
  "ZAMAN",
  "ZAMAZ",
  "ZAMEN",
  "ZAMER",
  "ZAMET",
  "ZAMIK",
  "ZAMOŽ",
  "ZAMRE",
  "ZAMRI",
  "ZAMRL",
  "ZAMUD",
  "ZANIČ",
  "ZANKA",
  "ZANKE",
  "ZANKI",
  "ZANKO",
  "ZANOS",
  "ZAPAH",
  "ZAPAŽ",
  "ZAPEČ",
  "ZAPEL",
  "ZAPET",
  "ZAPIJ",
  "ZAPIK",
  "ZAPIL",
  "ZAPIS",
  "ZAPIT",
  "ZAPNE",
  "ZAPNI",
  "ZAPOG",
  "ZAPOJ",
  "ZAPON",
  "ZAPOR",
  "ZAPRE",
  "ZAPRI",
  "ZAPRL",
  "ZAPRT",
  "ZARDI",
  "ZAREČ",
  "ZAREK",
  "ZARES",
  "ZAREZ",
  "ZARIH",
  "ZARIJ",
  "ZARIL",
  "ZARIS",
  "ZARIT",
  "ZARJA",
  "ZARJE",
  "ZARJI",
  "ZARJO",
  "ZAROD",
  "ZAROK",
  "ZAROM",
  "ZAROT",
  "ZAROV",
  "ZASEČ",
  "ZASED",
  "ZASEG",
  "ZASEJ",
  "ZASEK",
  "ZASIJ",
  "ZASIP",
  "ZASPI",
  "ZASUJ",
  "ZASUK",
  "ZASUL",
  "ZASUN",
  "ZASUT",
  "ZAŠEL",
  "ZAŠIJ",
  "ZAŠIL",
  "ZAŠIT",
  "ZAŠLA",
  "ZAŠLE",
  "ZAŠLI",
  "ZAŠLO",
  "ZATEČ",
  "ZATEG",
  "ZATEM",
  "ZATIČ",
  "ZATIK",
  "ZATOK",
  "ZATON",
  "ZATOR",
  "ZATRE",
  "ZATRI",
  "ZATRL",
  "ZATRT",
  "ZAUGA",
  "ZAUGE",
  "ZAUGI",
  "ZAUGU",
  "ZAUKA",
  "ZAUPA",
  "ZAVDA",
  "ZAVEJ",
  "ZAVEL",
  "ZAVEM",
  "ZAVER",
  "ZAVES",
  "ZAVEŠ",
  "ZAVET",
  "ZAVEZ",
  "ZAVIH",
  "ZAVIJ",
  "ZAVIL",
  "ZAVIT",
  "ZAVOD",
  "ZAVOJ",
  "ZAVOR",
  "ZAVRA",
  "ZAVRE",
  "ZAVRI",
  "ZAVRL",
  "ZAVRT",
  "ZAVRU",
  "ZAZDI",
  "ZAZIB",
  "ZAZNA",
  "ZAZRE",
  "ZAZRI",
  "ZAZRL",
  "ZAZRT",
  "ZAŽGE",
  "ZAŽGI",
  "ZAŽIG",
  "ZAŽRE",
  "ZAŽRI",
  "ZAŽRL",
  "ZAŽRT",
  "ZBADA",
  "ZBALA",
  "ZBALE",
  "ZBALI",
  "ZBALO",
  "ZBAŠE",
  "ZBAŠI",
  "ZBATI",
  "ZBEGA",
  "ZBERE",
  "ZBERI",
  "ZBEZA",
  "ZBEŽE",
  "ZBEŽI",
  "ZBIČA",
  "ZBIJA",
  "ZBIJE",
  "ZBILA",
  "ZBILE",
  "ZBILI",
  "ZBILO",
  "ZBIRA",
  "ZBIRE",
  "ZBIRI",
  "ZBIRK",
  "ZBIRU",
  "ZBITA",
  "ZBITE",
  "ZBITI",
  "ZBITO",
  "ZBLOD",
  "ZBODE",
  "ZBODI",
  "ZBOJI",
  "ZBOLI",
  "ZBORA",
  "ZBORE",
  "ZBORI",
  "ZBORU",
  "ZBOSI",
  "ZBOST",
  "ZBRAL",
  "ZBRAN",
  "ZBRAT",
  "ZBRCA",
  "ZBRIS",
  "ZBRKA",
  "ZBRKE",
  "ZBRKI",
  "ZBRKO",
  "ZBUDE",
  "ZBUDI",
  "ZBUJA",
  "ZDAJE",
  "ZDAJI",
  "ZDANI",
  "ZDELA",
  "ZDELE",
  "ZDELI",
  "ZDELO",
  "ZDELU",
  "ZDERE",
  "ZDERI",
  "ZDETI",
  "ZDIJO",
  "ZDILA",
  "ZDIMO",
  "ZDIRA",
  "ZDITA",
  "ZDITE",
  "ZDIVA",
  "ZDOMA",
  "ZDOMK",
  "ZDRAH",
  "ZDRAV",
  "ZDRČI",
  "ZDREM",
  "ZDREŠ",
  "ZDRET",
  "ZDRIZ",
  "ZDRKA",
  "ZDRKE",
  "ZDRKI",
  "ZDRKU",
  "ZDRLA",
  "ZDRLE",
  "ZDRLI",
  "ZDRLO",
  "ZDRMA",
  "ZDROB",
  "ZDRSA",
  "ZDRSE",
  "ZDRSI",
  "ZDRSU",
  "ZDRVI",
  "ZDRŽE",
  "ZDRŽI",
  "ZDUŠI",
  "ZEBEL",
  "ZEBEM",
  "ZEBER",
  "ZEBEŠ",
  "ZEBLA",
  "ZEBLE",
  "ZEBLI",
  "ZEBLO",
  "ZEBRA",
  "ZEBRE",
  "ZEBRI",
  "ZEBRO",
  "ZEBST",
  "ZEHAJ",
  "ZEHAL",
  "ZEHAM",
  "ZEHAŠ",
  "ZEHAT",
  "ZEHAV",
  "ZELEH",
  "ZELEM",
  "ZELEN",
  "ZELIK",
  "ZELJA",
  "ZELJE",
  "ZELJO",
  "ZELJU",
  "ZELMI",
  "ZELOT",
  "ZENFA",
  "ZENFE",
  "ZENFI",
  "ZENFU",
  "ZENIC",
  "ZENIT",
  "ZENOM",
  "ZEŠKA",
  "ZEŠKE",
  "ZEŠKI",
  "ZEŠKO",
  "ZETAH",
  "ZETAM",
  "ZETIH",
  "ZETOM",
  "ZETOV",
  "ZEVAJ",
  "ZEVAL",
  "ZEVAM",
  "ZEVAŠ",
  "ZEVAT",
  "ZEVEH",
  "ZEVEM",
  "ZEVIH",
  "ZEVJO",
  "ZEVKA",
  "ZEVKE",
  "ZEVKI",
  "ZEVKO",
  "ZEVMI",
  "ZEVOM",
  "ZEVOV",
  "ZEZAJ",
  "ZEZAL",
  "ZEZAM",
  "ZEZAŠ",
  "ZGAGA",
  "ZGAGE",
  "ZGAGI",
  "ZGAGO",
  "ZGANE",
  "ZGANI",
  "ZGARA",
  "ZGAZI",
  "ZGIBA",
  "ZGIBE",
  "ZGIBI",
  "ZGIBU",
  "ZGINE",
  "ZGINI",
  "ZGLED",
  "ZGLOB",
  "ZGNIJ",
  "ZGNIL",
  "ZGNIT",
  "ZGODA",
  "ZGODB",
  "ZGODE",
  "ZGODI",
  "ZGODO",
  "ZGOLJ",
  "ZGONJ",
  "ZGORI",
  "ZGRBI",
  "ZGRDA",
  "ZGRMI",
  "ZGRNE",
  "ZGRNI",
  "ZGUBA",
  "ZGUBE",
  "ZGUBI",
  "ZGUBO",
  "ZGULI",
  "ZIBAJ",
  "ZIBAL",
  "ZIBAM",
  "ZIBAN",
  "ZIBAŠ",
  "ZIBAT",
  "ZIBEL",
  "ZIBIH",
  "ZIBKA",
  "ZIBKE",
  "ZIBKI",
  "ZIBKO",
  "ZIBOM",
  "ZIBOV",
  "ZICEM",
  "ZICEV",
  "ZICIH",
  "ZICNE",
  "ZICNI",
  "ZIDAJ",
  "ZIDAK",
  "ZIDAL",
  "ZIDAM",
  "ZIDAN",
  "ZIDAR",
  "ZIDAŠ",
  "ZIDAT",
  "ZIDAV",
  "ZIDCA",
  "ZIDCE",
  "ZIDCI",
  "ZIDCU",
  "ZIDEC",
  "ZIDEH",
  "ZIDEK",
  "ZIDEN",
  "ZIDIH",
  "ZIDKA",
  "ZIDKE",
  "ZIDKI",
  "ZIDKU",
  "ZIDNA",
  "ZIDNE",
  "ZIDNI",
  "ZIDNO",
  "ZIDOM",
  "ZIDOV",
  "ZIGOT",
  "ZIGOZ",
  "ZIHER",
  "ZIHRA",
  "ZIJAJ",
  "ZIJAL",
  "ZIJAM",
  "ZIJAŠ",
  "ZIJAT",
  "ZIMAH",
  "ZIMAM",
  "ZIMUJ",
  "ZINEM",
  "ZINEŠ",
  "ZINIL",
  "ZINIT",
  "ZIZAH",
  "ZIZAM",
  "ZIZEK",
  "ZIZKA",
  "ZIZKE",
  "ZIZKI",
  "ZIZKU",
  "ZJAHA",
  "ZJAŠE",
  "ZJAŠI",
  "ZJEBA",
  "ZJEBE",
  "ZJEBI",
  "ZJEDI",
  "ZJEZI",
  "ZJOČE",
  "ZJOČI",
  "ZJOKA",
  "ZLAGA",
  "ZLASA",
  "ZLATA",
  "ZLATE",
  "ZLATI",
  "ZLATO",
  "ZLATU",
  "ZLAŽE",
  "ZLAŽI",
  "ZLEGA",
  "ZLEMU",
  "ZLEPA",
  "ZLEPI",
  "ZLEST",
  "ZLETA",
  "ZLETE",
  "ZLETI",
  "ZLETU",
  "ZLEZE",
  "ZLEZI",
  "ZLIJE",
  "ZLIKA",
  "ZLILA",
  "ZLILE",
  "ZLILI",
  "ZLILO",
  "ZLIMA",
  "ZLIMI",
  "ZLITA",
  "ZLITE",
  "ZLITI",
  "ZLITO",
  "ZLIVA",
  "ZLIŽE",
  "ZLIŽI",
  "ZLOBA",
  "ZLOBE",
  "ZLOBI",
  "ZLOBO",
  "ZLOGA",
  "ZLOGE",
  "ZLOGI",
  "ZLOGU",
  "ZLOMA",
  "ZLOME",
  "ZLOMI",
  "ZLOMU",
  "ZLOTA",
  "ZLOTE",
  "ZLOTI",
  "ZLOTU",
  "ZLOŽB",
  "ZLOŽI",
  "ZMAGA",
  "ZMAGE",
  "ZMAGI",
  "ZMAGO",
  "ZMAJA",
  "ZMAJE",
  "ZMAJI",
  "ZMAJU",
  "ZMAMI",
  "ZMANE",
  "ZMANI",
  "ZMAŠI",
  "ZMAŽE",
  "ZMAŽI",
  "ZMEČE",
  "ZMEČI",
  "ZMEDA",
  "ZMEDE",
  "ZMEDI",
  "ZMEDO",
  "ZMELA",
  "ZMELE",
  "ZMELI",
  "ZMELO",
  "ZMENE",
  "ZMENI",
  "ZMERI",
  "ZMESI",
  "ZMEST",
  "ZMEŠA",
  "ZMETA",
  "ZMETE",
  "ZMETI",
  "ZMETO",
  "ZMEZI",
  "ZMIGA",
  "ZMIJE",
  "ZMILA",
  "ZMILE",
  "ZMILI",
  "ZMILO",
  "ZMITA",
  "ZMITE",
  "ZMITI",
  "ZMITO",
  "ZMLEL",
  "ZMLET",
  "ZMOČI",
  "ZMOLI",
  "ZMORE",
  "ZMOTA",
  "ZMOTE",
  "ZMOTI",
  "ZMOTO",
  "ZMRŠI",
  "ZNAČK",
  "ZNAJO",
  "ZNAJT",
  "ZNAKA",
  "ZNAKE",
  "ZNAKI",
  "ZNAKU",
  "ZNALA",
  "ZNALE",
  "ZNALI",
  "ZNALK",
  "ZNALO",
  "ZNAMK",
  "ZNAMO",
  "ZNANA",
  "ZNANE",
  "ZNANI",
  "ZNANJ",
  "ZNANK",
  "ZNANO",
  "ZNAŠA",
  "ZNATA",
  "ZNATE",
  "ZNATI",
  "ZNAVA",
  "ZNEBI",
  "ZNESE",
  "ZNESI",
  "ZNEST",
  "ZNIŽA",
  "ZNOČI",
  "ZNOJA",
  "ZNOJI",
  "ZNOJU",
  "ZNORE",
  "ZNORI",
  "ZNOSI",
  "ZNOVA",
  "ZOBAČ",
  "ZOBAJ",
  "ZOBAL",
  "ZOBAM",
  "ZOBAN",
  "ZOBAR",
  "ZOBAŠ",
  "ZOBAT",
  "ZOBCA",
  "ZOBCE",
  "ZOBCI",
  "ZOBCU",
  "ZOBČA",
  "ZOBEC",
  "ZOBEČ",
  "ZOBEH",
  "ZOBEK",
  "ZOBEM",
  "ZOBEN",
  "ZOBER",
  "ZOBIL",
  "ZOBIM",
  "ZOBIŠ",
  "ZOBIT",
  "ZOBJE",
  "ZOBJO",
  "ZOBKA",
  "ZOBKE",
  "ZOBKI",
  "ZOBKU",
  "ZOBMI",
  "ZOBNA",
  "ZOBNE",
  "ZOBNI",
  "ZOBNO",
  "ZOBOM",
  "ZOBRA",
  "ZOBRE",
  "ZOBRI",
  "ZOBRU",
  "ZOCEM",
  "ZOCEV",
  "ZOCIH",
  "ZOFAH",
  "ZOFAM",
  "ZOKEN",
  "ZOKNA",
  "ZOKNE",
  "ZOKNI",
  "ZOKNU",
  "ZOLJA",
  "ZOLJE",
  "ZOLJI",
  "ZOLJU",
  "ZOMBI",
  "ZONAH",
  "ZONAM",
  "ZONUL",
  "ZOOMA",
  "ZOPER",
  "ZOPET",
  "ZOPRN",
  "ZORAH",
  "ZORAL",
  "ZORAM",
  "ZORAN",
  "ZORAT",
  "ZOREČ",
  "ZOREL",
  "ZOREN",
  "ZORET",
  "ZORIC",
  "ZORIH",
  "ZORIM",
  "ZORIŠ",
  "ZORJE",
  "ZORJI",
  "ZORNA",
  "ZORNE",
  "ZORNI",
  "ZORNO",
  "ZOROM",
  "ZOROV",
  "ZOSIH",
  "ZOSOM",
  "ZOSOV",
  "ZOVIH",
  "ZOVOM",
  "ZOVOV",
  "ZOŽEN",
  "ZOŽIL",
  "ZOŽIM",
  "ZOŽIŠ",
  "ZOŽIT",
  "ZOŽUJ",
  "ZRAČI",
  "ZRAKA",
  "ZRAKE",
  "ZRAKI",
  "ZRAKU",
  "ZRASE",
  "ZRAST",
  "ZRCAL",
  "ZREDI",
  "ZREJO",
  "ZRELA",
  "ZRELE",
  "ZRELI",
  "ZRELO",
  "ZREMO",
  "ZRENJ",
  "ZRETA",
  "ZRETE",
  "ZRETI",
  "ZREVA",
  "ZREŽE",
  "ZREŽI",
  "ZRIBA",
  "ZRIJE",
  "ZRILA",
  "ZRILE",
  "ZRILI",
  "ZRILO",
  "ZRIMO",
  "ZRINE",
  "ZRINI",
  "ZRISA",
  "ZRIŠE",
  "ZRIŠI",
  "ZRITA",
  "ZRITE",
  "ZRITI",
  "ZRITO",
  "ZRIVA",
  "ZRKEL",
  "ZRKLA",
  "ZRKLI",
  "ZRKLO",
  "ZRKLU",
  "ZRNAT",
  "ZRNCA",
  "ZRNCE",
  "ZRNCI",
  "ZRNCU",
  "ZRNEC",
  "ZRNEČ",
  "ZRNIH",
  "ZRNIL",
  "ZRNIM",
  "ZRNIŠ",
  "ZRNIT",
  "ZRNJA",
  "ZRNJE",
  "ZRNJU",
  "ZRNOM",
  "ZROČA",
  "ZROČE",
  "ZROČI",
  "ZROČO",
  "ZROJI",
  "ZROLA",
  "ZRUŠI",
  "ZRVAH",
  "ZRVAM",
  "ZTVCP",
  "ZUKSB",
  "ZUMIH",
  "ZUMOM",
  "ZUMOV",
  "ZUNAJ",
  "ZVABI",
  "ZVADI",
  "ZVAGA",
  "ZVAJA",
  "ZVALI",
  "ZVARA",
  "ZVARE",
  "ZVARI",
  "ZVARU",
  "ZVEČA",
  "ZVEDE",
  "ZVEDI",
  "ZVEDO",
  "ZVEGA",
  "ZVEJO",
  "ZVEMO",
  "ZVENA",
  "ZVENE",
  "ZVENI",
  "ZVENU",
  "ZVERC",
  "ZVERI",
  "ZVEST",
  "ZVEVA",
  "ZVEZA",
  "ZVEZD",
  "ZVEZE",
  "ZVEZI",
  "ZVEZO",
  "ZVEŽE",
  "ZVEŽI",
  "ZVICA",
  "ZVIJA",
  "ZVIJE",
  "ZVILA",
  "ZVILE",
  "ZVILI",
  "ZVILO",
  "ZVINA",
  "ZVINE",
  "ZVINI",
  "ZVINU",
  "ZVIRA",
  "ZVIŠA",
  "ZVITA",
  "ZVITE",
  "ZVITI",
  "ZVITO",
  "ZVLEČ",
  "ZVODI",
  "ZVOHA",
  "ZVOHU",
  "ZVOJA",
  "ZVOJE",
  "ZVOJI",
  "ZVOJU",
  "ZVOKA",
  "ZVOKE",
  "ZVOKI",
  "ZVOKU",
  "ZVONA",
  "ZVONE",
  "ZVONI",
  "ZVONU",
  "ZVOZI",
  "ZVRHA",
  "ZVRNE",
  "ZVRNI",
  "ZVRST",
  "ZVRTA",
  "ZVRTE",
  "ZVRTI",
  "ZVRTU",
  "ŽABAH",
  "ŽABAM",
  "ŽABCA",
  "ŽABCE",
  "ŽABCI",
  "ŽABCU",
  "ŽABEC",
  "ŽABIC",
  "ŽABJA",
  "ŽABJE",
  "ŽABJI",
  "ŽABJO",
  "ŽABON",
  "ŽADEN",
  "ŽADNA",
  "ŽADNE",
  "ŽADNI",
  "ŽADNO",
  "ŽADOM",
  "ŽAGAH",
  "ŽAGAJ",
  "ŽAGAL",
  "ŽAGAM",
  "ŽAGAN",
  "ŽAGAR",
  "ŽAGAŠ",
  "ŽAGAT",
  "ŽAGEN",
  "ŽAGIC",
  "ŽAGNA",
  "ŽAGNE",
  "ŽAGNI",
  "ŽAGNO",
  "ŽAJFA",
  "ŽAJFE",
  "ŽAJFI",
  "ŽAJFO",
  "ŽALAH",
  "ŽALAM",
  "ŽALCA",
  "ŽALCE",
  "ŽALCI",
  "ŽALCU",
  "ŽALEC",
  "ŽALEČ",
  "ŽALEM",
  "ŽALEN",
  "ŽALIL",
  "ŽALIM",
  "ŽALIŠ",
  "ŽALIT",
  "ŽALNA",
  "ŽALNE",
  "ŽALNI",
  "ŽALNO",
  "ŽALUJ",
  "ŽAMEM",
  "ŽAMEŠ",
  "ŽAMET",
  "ŽANJE",
  "ŽANJI",
  "ŽANRA",
  "ŽANRE",
  "ŽANRI",
  "ŽANRU",
  "ŽARAH",
  "ŽARAM",
  "ŽARČI",
  "ŽAREČ",
  "ŽAREK",
  "ŽAREL",
  "ŽAREN",
  "ŽARET",
  "ŽARIH",
  "ŽARIJ",
  "ŽARIL",
  "ŽARIM",
  "ŽARIN",
  "ŽARIŠ",
  "ŽARIT",
  "ŽARJA",
  "ŽARJE",
  "ŽARJI",
  "ŽARJO",
  "ŽARKA",
  "ŽARKE",
  "ŽARKI",
  "ŽARKO",
  "ŽARKU",
  "ŽARNA",
  "ŽARNE",
  "ŽARNI",
  "ŽARNO",
  "ŽAROM",
  "ŽAROV",
  "ŽAVBA",
  "ŽAVBE",
  "ŽAVBI",
  "ŽAVBO",
  "ŽBEJA",
  "ŽBEJE",
  "ŽBEJI",
  "ŽBEJU",
  "ŽBICA",
  "ŽBICE",
  "ŽBICI",
  "ŽBICO",
  "ŽDEČA",
  "ŽDEČE",
  "ŽDEČI",
  "ŽDEČO",
  "ŽDELA",
  "ŽDELE",
  "ŽDELI",
  "ŽDELO",
  "ŽDENJ",
  "ŽDETI",
  "ŽDIJO",
  "ŽDIMO",
  "ŽDITA",
  "ŽDITE",
  "ŽDIVA",
  "ŽEBRA",
  "ŽEGEN",
  "ŽEGNA",
  "ŽEGNE",
  "ŽEGNI",
  "ŽEGNU",
  "ŽEHTA",
  "ŽEHTE",
  "ŽEHTI",
  "ŽEHTO",
  "ŽEJAJ",
  "ŽEJAL",
  "ŽEJAM",
  "ŽEJAŠ",
  "ŽEJAT",
  "ŽEJEN",
  "ŽEJNA",
  "ŽEJNE",
  "ŽEJNI",
  "ŽEJNO",
  "ŽELAT",
  "ŽELEČ",
  "ŽELEL",
  "ŽELEN",
  "ŽELEZ",
  "ŽELIH",
  "ŽELIM",
  "ŽELIŠ",
  "ŽELJA",
  "ŽELJE",
  "ŽELJI",
  "ŽELJO",
  "ŽELOD",
  "ŽELOM",
  "ŽELVA",
  "ŽELVE",
  "ŽELVI",
  "ŽELVO",
  "ŽENAH",
  "ŽENAM",
  "ŽENEM",
  "ŽENEŠ",
  "ŽENET",
  "ŽENIC",
  "ŽENIL",
  "ŽENIM",
  "ŽENIN",
  "ŽENIŠ",
  "ŽENIT",
  "ŽENKA",
  "ŽENKE",
  "ŽENKI",
  "ŽENKO",
  "ŽENOF",
  "ŽENSK",
  "ŽEPAR",
  "ŽEPEK",
  "ŽEPEN",
  "ŽEPIČ",
  "ŽEPIH",
  "ŽEPKA",
  "ŽEPKE",
  "ŽEPKI",
  "ŽEPKU",
  "ŽEPNA",
  "ŽEPNE",
  "ŽEPNI",
  "ŽEPNO",
  "ŽEPOM",
  "ŽEPOV",
  "ŽERKA",
  "ŽERKE",
  "ŽERKI",
  "ŽERKO",
  "ŽERUH",
  "ŽETEM",
  "ŽETEV",
  "ŽETIH",
  "ŽETIJ",
  "ŽETIM",
  "ŽETJA",
  "ŽETJE",
  "ŽETJI",
  "ŽETJU",
  "ŽETON",
  "ŽETVE",
  "ŽETVI",
  "ŽEZEL",
  "ŽEZLA",
  "ŽEZLI",
  "ŽEZLO",
  "ŽEZLU",
  "ŽGALA",
  "ŽGALE",
  "ŽGALI",
  "ŽGALK",
  "ŽGALO",
  "ŽGANA",
  "ŽGANE",
  "ŽGANI",
  "ŽGANJ",
  "ŽGANO",
  "ŽGATI",
  "ŽGEJO",
  "ŽGEMO",
  "ŽGETA",
  "ŽGETE",
  "ŽGEVA",
  "ŽGIMO",
  "ŽGITA",
  "ŽGITE",
  "ŽGIVA",
  "ŽGOČA",
  "ŽGOČE",
  "ŽGOČI",
  "ŽGOČO",
  "ŽGOLI",
  "ŽICAH",
  "ŽICAJ",
  "ŽICAL",
  "ŽICAM",
  "ŽICAN",
  "ŽICAR",
  "ŽICAŠ",
  "ŽICAT",
  "ŽIČEČ",
  "ŽIČEN",
  "ŽIČIL",
  "ŽIČIM",
  "ŽIČIŠ",
  "ŽIČIT",
  "ŽIČKA",
  "ŽIČKE",
  "ŽIČKI",
  "ŽIČKO",
  "ŽIČNA",
  "ŽIČNE",
  "ŽIČNI",
  "ŽIČNO",
  "ŽIDAN",
  "ŽIDEK",
  "ŽIDIH",
  "ŽIDKA",
  "ŽIDKE",
  "ŽIDKI",
  "ŽIDKO",
  "ŽIDOM",
  "ŽIDOV",
  "ŽIGIH",
  "ŽIGOM",
  "ŽIGOV",
  "ŽIHER",
  "ŽILAH",
  "ŽILAM",
  "ŽILAV",
  "ŽILEN",
  "ŽILIC",
  "ŽILIK",
  "ŽILJA",
  "ŽILJE",
  "ŽILJI",
  "ŽILJK",
  "ŽILJU",
  "ŽILNA",
  "ŽILNE",
  "ŽILNI",
  "ŽILNO",
  "ŽINGA",
  "ŽIRAF",
  "ŽIRIH",
  "ŽIRIJ",
  "ŽIROM",
  "ŽIROV",
  "ŽIŠKA",
  "ŽIŠKE",
  "ŽIŠKI",
  "ŽIŠKO",
  "ŽITCA",
  "ŽITCE",
  "ŽITCI",
  "ŽITCU",
  "ŽITEC",
  "ŽITEN",
  "ŽITIH",
  "ŽITIJ",
  "ŽITJA",
  "ŽITJE",
  "ŽITJI",
  "ŽITJU",
  "ŽITNA",
  "ŽITNE",
  "ŽITNI",
  "ŽITNO",
  "ŽITOM",
  "ŽIVAL",
  "ŽIVCA",
  "ŽIVCE",
  "ŽIVCI",
  "ŽIVCU",
  "ŽIVEC",
  "ŽIVEČ",
  "ŽIVEL",
  "ŽIVEM",
  "ŽIVET",
  "ŽIVEŽ",
  "ŽIVIC",
  "ŽIVIH",
  "ŽIVIK",
  "ŽIVIL",
  "ŽIVIM",
  "ŽIVIN",
  "ŽIVIO",
  "ŽIVIŠ",
  "ŽIVIT",
  "ŽIVOT",
  "ŽIŽOL",
  "ŽIŽUL",
  "ŽLAHT",
  "ŽLAJF",
  "ŽLEBA",
  "ŽLEBE",
  "ŽLEBI",
  "ŽLEBU",
  "ŽLEDA",
  "ŽLEDI",
  "ŽLEDU",
  "ŽLEHT",
  "ŽLEMP",
  "ŽLEZA",
  "ŽLEZE",
  "ŽLEZI",
  "ŽLEZO",
  "ŽLICA",
  "ŽLICE",
  "ŽLICI",
  "ŽLICO",
  "ŽLIČK",
  "ŽLOTA",
  "ŽLOTE",
  "ŽLOTI",
  "ŽLOTO",
  "ŽMEJO",
  "ŽMEMO",
  "ŽMETA",
  "ŽMETE",
  "ŽMEVA",
  "ŽMIMO",
  "ŽMITA",
  "ŽMITE",
  "ŽMIVA",
  "ŽMOHT",
  "ŽMULA",
  "ŽMULE",
  "ŽMULI",
  "ŽMULO",
  "ŽMURK",
  "ŽNORA",
  "ŽNORE",
  "ŽNORI",
  "ŽNORO",
  "ŽOGAH",
  "ŽOGAJ",
  "ŽOGAL",
  "ŽOGAM",
  "ŽOGAŠ",
  "ŽOGAT",
  "ŽOGIC",
  "ŽOKAJ",
  "ŽOKAL",
  "ŽOKAM",
  "ŽOKAŠ",
  "ŽOLCA",
  "ŽOLCE",
  "ŽOLCI",
  "ŽOLCO",
  "ŽOLČA",
  "ŽOLČE",
  "ŽOLČI",
  "ŽOLČU",
  "ŽOLNA",
  "ŽOLNE",
  "ŽOLNI",
  "ŽOLNO",
  "ŽOLTA",
  "ŽOLTE",
  "ŽOLTI",
  "ŽOLTO",
  "ŽRDAN",
  "ŽRDEH",
  "ŽRDEM",
  "ŽRDJO",
  "ŽRDMI",
  "ŽREBA",
  "ŽREBE",
  "ŽREBU",
  "ŽRECA",
  "ŽRECE",
  "ŽRECI",
  "ŽRECU",
  "ŽREJO",
  "ŽRELA",
  "ŽRELC",
  "ŽRELI",
  "ŽRELO",
  "ŽRELU",
  "ŽREMO",
  "ŽRETA",
  "ŽRETE",
  "ŽRETI",
  "ŽREVA",
  "ŽRIMO",
  "ŽRITA",
  "ŽRITE",
  "ŽRIVA",
  "ŽRTEV",
  "ŽRTVE",
  "ŽRTVI",
  "ŽUGAJ",
  "ŽUGAL",
  "ŽUGAM",
  "ŽUGAŠ",
  "ŽUGAT",
  "ŽUKAH",
  "ŽUKAM",
  "ŽULEČ",
  "ŽULIL",
  "ŽULIM",
  "ŽULIŠ",
  "ŽULIT",
  "ŽULJA",
  "ŽULJE",
  "ŽULJI",
  "ŽULJU",
  "ŽUMAR",
  "ŽUPAH",
  "ŽUPAM",
  "ŽUPAN",
  "ŽUPEN",
  "ŽUPIC",
  "ŽUPNA",
  "ŽUPNE",
  "ŽUPNI",
  "ŽUPNO",
  "ŽURAJ",
  "ŽURAL",
  "ŽURAM",
  "ŽURAŠ",
  "ŽURER",
  "ŽURIH",
  "ŽURKA",
  "ŽURKE",
  "ŽURKI",
  "ŽURKO",
  "ŽUROM",
  "ŽUROV",
  "ŽUSEM",
  "ŽUSMA",
  "ŽUSMU",
  "ŽUŽEK",
  "ŽUŽKA",
  "ŽUŽKE",
  "ŽUŽKI",
  "ŽUŽKU",
  "ŽVALA",
  "ŽVALE",
  "ŽVALI",
  "ŽVALO",
  "ŽVEČI",
  "ŽVENK",
  "ŽVERC",
  "ŽVIŽG",
  "ŽVRKA",
]);